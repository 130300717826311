import "./SettingsSideMenu.scss";
import { Link, useLocation } from "react-router-dom";

const SettingsSideMenu = () => {
    const settingsActiveTab = useLocation().pathname.split(`/`).pop();

    // console.log(`settingsActiveTab: `, settingsActiveTab);

    return (
        <div className="settings-side-menu">
            <div className="settings-title">Settings</div>
            <div className="settings-type-wrap">
                <div className="settings-type-title">ORGANISATION</div>
                <ul>
                    <li className={`side-menu-list-text ${(settingsActiveTab === 'pilots_and_instructors') ? 'active' : ''}`}>
                        <Link to="pilots_and_instructors"><strong>My Pilots & Instructors</strong></Link>
                    </li>
                    <li className={`side-menu-list-text ${(settingsActiveTab === 'my_fleet' || settingsActiveTab === 'settings') ? 'active' : ''}`}>
                        <Link to="my_fleet"><strong>My Fleet</strong></Link>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default SettingsSideMenu;










// import "./SettingsSideMenu.scss"
// import { Link, useLocation } from "react-router-dom";

// const SettingsSideMenu = () => {

//     const settingsActiveTab = useLocation().pathname.split(`/`).pop();

//     console.log(`settingsActiveTab: `, settingsActiveTab)

//     return (
//         <div className="settings-side-menu">
//             <div className="settings-title">Settings</div>
//             <div className="settings-type-wrap">
//                 <div className="settings-type-title">ORGANISATION</div>
//                 <ul>
//                     {/* <li className="side-menu-list-text"><Link to="general"><strong>General</strong></Link></li> */}
//                     {/* <li className="side-menu-list-text"><Link to="billing"><strong>Billing</strong></Link></li> */}
//                     <li className="side-menu-list-text"><Link to="pilots_and_instructors"><strong>My Pilots & Instructors</strong></Link></li>
//                     <li className="side-menu-list-text"><Link to="my_fleet"><strong>My Fleet</strong></Link></li>
//                     {/* <li className="side-menu-list-text"><Link to="members_and_roles"><strong>Members & Roles</strong></Link></li> */}
//                     {/* <li className="side-menu-list-text"><Link to="company"><strong>Companies</strong></Link></li> */}
//                     {/* <li className="side-menu-list-text"><Link to="security"><strong>Security</strong></Link></li> */}
//                     {/* <li className="side-menu-list-text"><Link to="tags_and_groups"><strong>Tags & Groups</strong></Link></li> */}
//                     {/* <li className="side-menu-list-text"><Link to="notification"><strong>Notifications</strong></Link></li> */}
//                     {/* <li className="side-menu-list-text"><Link to="compliance"><strong>Compliance</strong></Link></li> */}
//                 </ul>
//             </div>
//             {/* <div className="settings-type-wrap">
//                 <div className="settings-type-title">MY ACCOUNT</div>
//                 <ul>
//                     <li className="side-menu-list-text"><Link to=""><strong>Profile</strong></Link></li>
//                     <li className="side-menu-list-text"><Link to=""><strong>Preferences</strong></Link></li>
//                     <li className="side-menu-list-text"><Link to=""><strong>Notifications</strong></Link></li>
//                     <li className="side-menu-list-text"><Link to=""><strong>Connections</strong></Link></li>
//                 </ul>
//             </div> */}
//         </div>
//     )
// }

// export default SettingsSideMenu;