import axios from "axios";
import {getLocalStorage} from "../common/utils/localStorageHandler";
import { setLocalStorage } from "../common/utils/localStorageHandler";

let API_URL:any =process.env.REACT_APP_AUTH_BASE_URL;

const axiosInstance = axios.create({})
 
// let isRefreshCalling=false;
export function SetAuthTokens(data:any){
  let authToken:any = getLocalStorage("authTokens", "")
  authToken.token = data.accessToken
  authToken.refreshToken = data.refreshToken
  setLocalStorage("authTokens", authToken)
}

// axios.defaults.timeout = 5000; // 5 seconds
// axios.defaults.timeout = 15000; // 5 seconds


axiosInstance.interceptors.request.use(
  async (config) => {
    
    let authToken:any = getLocalStorage("authTokens", "")

    if (authToken.token) {
      config.headers.authToken = authToken.token;//Adds access in header for each request
    }
    else{
      config.headers.authToken = "token";
      
    }
    return config;
  },
  function (error) {
    // console.log(`interceptors error: `, error)
    if (error.code === 'ECONNABORTED') {
      console.log('Request timed out');
    }
    return Promise.reject(error);
  }
);

let isRefreshing = false;
let refreshSubscribers:any[] = [];

axiosInstance.interceptors.response.use(
  function (response) {
    // console.log(`-------------RESPONSE-----------------`);
    // console.log(response);
    return response;
  },
  function (error) {
    // console.log(`-------------ERROR-----------------`);
    // console.log(error);

    const originalRequest = error.config;

    if (error?.response?.status === 401 && originalRequest?.url === `${API_URL}/Token/Refresh`) {
      localStorage.removeItem("authTokens"); // Delete auth token from local storage if refresh token is expired
      window.location.href = window.location.href;
      return Promise.reject(error);
    }

    if (error?.response?.data?.Code == -9001) { // Access token expired
      console.log(`Access Token Expired`)
      if (!isRefreshing) {
        isRefreshing = true;
        const authTokens = getLocalStorage("authTokens", "");

        return axiosInstance.post(`${API_URL}/Token/Refresh`, {
          "AccessToken": authTokens.token,
          "RefreshToken": authTokens.refreshToken
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        }).then((res) => {
          console.log(`res: `, res);
          SetAuthTokens(res.data);
          isRefreshing = false;
          onRrefreshed(res.data.accessToken);
          refreshSubscribers = [];

          return axiosInstance(originalRequest);
        }).catch((err) => {
          console.log("Error refresh api");
          console.log(err);
          localStorage.removeItem("authTokens");
          window.location.href = window.location.href;
          return Promise.reject(err);
        });
      }

      const retryOriginalRequest = new Promise((resolve) => {
        subscribeTokenRefresh((newToken:any) => {
          // replace the expired token and retry
          originalRequest.headers['Authorization'] = 'Bearer ' + newToken;
          resolve(axiosInstance(originalRequest));
        });
      });

      return retryOriginalRequest;
    }

    if (error?.response?.data?.Code == -9002) { // Refresh token is invalid
      console.log(`Refresh Token Expired`);
      localStorage.removeItem("authTokens");
      window.location.href = window.location.href;
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

function subscribeTokenRefresh(cb:any) {
  refreshSubscribers.push(cb);
}

function onRrefreshed(token:any) {
  refreshSubscribers.map((cb) => cb(token));
}

export default axiosInstance



// ---------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------


/*



const axios = require('axios');

// Set default timeout for all requests
axios.defaults.timeout = 5000; // 5 seconds

// Add a request interceptor to handle timeout errors
axios.interceptors.request.use(
  config => {
    // You can modify config here if needed before returning it
    return config;
  },
  error => {
    // Handle request error (e.g., timeout)
    if (error.code === 'ECONNABORTED') {
      console.log('Request timed out');
    }
    return Promise.reject(error);
  }
);

// Example usage of Axios for making requests
axios.get('https://api.example.com/data')
  .then(response => {
    // Handle successful response
    console.log(response.data);
  })
  .catch(error => {
    // Handle other types of errors (e.g., network error, server error)
    console.error('Error:', error.message);
  });




*/