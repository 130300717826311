
import topGroupEle from "./pfdLayerElements/topGroupEle";
import leftGroupEle from "./pfdLayerElements/leftGroupEle";
import rightGroupEle from "./pfdLayerElements/rightGroupEle";
import headingAndGroup from "./pfdLayerElements/headingAndGroup";
// import leftBottomGroup from "./pfdLayerElements/letftBottomGroup";
import rightBottomGroup from "./pfdLayerElements/rightBottomGroup";
import bottomGroupEle from "./pfdLayerElements/bottomGroupEle";
import rollAndPitch from "./pfdLayerElements/rollAndPitch";

const pfdLayerElements = (airSpeedReading : number, TASReading :number, altMslReading : number, VSpdReading : number, BaroAReading:number, headReading:number, windSpdReading:number, windDirReading:number, CrsReading:number, rollReading:number, pitchReading:number, GLatAcReading:number, OATReading:number, E1RPMReading:number, GNormAcReading:number, nav1 : number, nav2 : number, com1 : number, com2 : number) => {
    let canvas = document.getElementById("pfdLayer") as HTMLCanvasElement;
    let ctx = canvas.getContext("2d") as CanvasRenderingContext2D

    let canvasDiv = document.getElementById("pfdLayerParent") as HTMLDivElement;
    canvas.style.width = "100%"
    canvas.style.height = "100%"

    let scalingFactor = 2.3

   

    if(canvasDiv.clientHeight < 400){
        scalingFactor = 3.5
    }

    canvas.width = canvasDiv.clientWidth * scalingFactor
    canvas.height = canvasDiv.clientHeight * scalingFactor



    ctx.clearRect(0, 0, canvas.width, canvas.height);
    topGroupEle(ctx, canvas.width, canvas.height, nav1, nav2, com1, com2)
    
    leftGroupEle(ctx, canvas.width, canvas.height, airSpeedReading, TASReading)
    rightGroupEle(ctx, canvas.width, canvas.height, altMslReading, VSpdReading, BaroAReading)
    headingAndGroup(ctx, canvas.width, canvas.height, scalingFactor, headReading, windSpdReading, windDirReading, CrsReading)
    //leftBottomGroup(ctx, canvas.width, canvas.height, OATReading, altMslReading)
    rightBottomGroup(ctx, canvas.width, canvas.height, OATReading, altMslReading)
    bottomGroupEle(ctx, canvas.width, canvas.height, E1RPMReading, GNormAcReading)
    rollAndPitch(ctx, canvas.width, canvas.height, rollReading, pitchReading, GLatAcReading)
}



// will be removed 
// commented on 31/01/2024

/*
const enum colorStrings {
    bgLight = "rgba(18, 18, 18, 0.32)",
    bgWhiteLight = "rgba(255, 255, 255, 0.37)",
    bgDark = "rgba(18, 18, 18, 0.64)",
    whiteColor = "#fff",
    blackColor = "black",
    fontColor = "#fff",
    cyan = "#80e1d9"

}

const topGroupEle = (canvasCtx : any, canvasWidth : number, canvasHeight : number, nav1 : number, nav2 : number, com1 : number, com2 : number) =>{
    let topGroupEleWidth = 1536

    topGroupEleWidth = canvasWidth < topGroupEleWidth ? canvasWidth : topGroupEleWidth

    canvasCtx.beginPath()
    canvasCtx.fillStyle = "rgba(18, 18, 18, 0.64)"
    canvasCtx.roundRect((canvasWidth - topGroupEleWidth)/2, 0, topGroupEleWidth, 84, [0, 0, 24, 24])
    canvasCtx.fill()
    canvasCtx.closePath()

    canvasCtx.beginPath()
    canvasCtx.fillStyle = "#fff"
    canvasCtx.font = "500 24px Arial"
    canvasCtx.fillText("NAV1",  ((canvasWidth - topGroupEleWidth)/2)+16, 36)
    canvasCtx.fillText(nav1.toFixed(3).toString(), ((canvasWidth - topGroupEleWidth)/2)+100, 36)
    canvasCtx.fillText("NAV2",  ((canvasWidth - topGroupEleWidth)/2)+16, 70)
    canvasCtx.fillText(nav2.toFixed(3).toString(), ((canvasWidth - topGroupEleWidth)/2)+100, 70)
    canvasCtx.fill()
    canvasCtx.closePath()

    canvasCtx.beginPath()
    canvasCtx.fillStyle = "#fff"
    canvasCtx.font = "500 24px Arial"
    canvasCtx.fillText("COM1", ((canvasWidth - topGroupEleWidth)/2)+topGroupEleWidth-80, 36)
    canvasCtx.fillText(com1.toFixed(3).toString(), ((canvasWidth - topGroupEleWidth)/2)+topGroupEleWidth-200, 36)
    canvasCtx.fillText("COM2", ((canvasWidth - topGroupEleWidth)/2)+topGroupEleWidth-80, 70)
    canvasCtx.fillText(com2.toFixed(3).toString(), ((canvasWidth - topGroupEleWidth)/2)+topGroupEleWidth-200, 70)
    canvasCtx.fill()
    canvasCtx.closePath()

}


const rollAndPitch = (canvasCtx:any, canvasWidth:number, canvasHeight:number, rollReading:number, pitchReading:number, GLatAcReading:number) => {


    let eleWidth = 600
    let eleHeight = 510

    let topPos = (canvasHeight - eleHeight)/2
    let leftPos = (canvasWidth - eleWidth) / 2

    if(canvasHeight < 550 * 2.3){                      //scalingFcator = 2.3
        topPos = 100
    }
   
    canvasCtx.beginPath()
    canvasCtx.rect(leftPos, topPos, eleWidth, eleHeight)
    //canvasCtx.stroke()
    canvasCtx.closePath()
  
    //console.log(pitchReading*180/Math.PI)

    if(90 > pitchReading*180/Math.PI  && 180 < pitchReading*180/Math.PI){
        let pitchReadingDeg = 180 - pitchReading*180/Math.PI
        pitchReading = -pitchReadingDeg * Math.PI / 180
        //console.log("pitch angle is more than 90")
    }
    if(180 < pitchReading*180/Math.PI && 270 > pitchReading*180/Math.PI){
        let pitchReadingDeg = 270 - pitchReading*180/Math.PI
        pitchReading = -pitchReadingDeg * Math.PI / 180
        //console.log("pitch angle is between 180 and 270")
    }
    if(270 < pitchReading*180/Math.PI){
        let pitchReadingDeg = 360 - pitchReading*180/Math.PI
        pitchReading = -pitchReadingDeg * Math.PI / 180
        //console.log("pitch angle is more than 270")
    }

    // if(345 < pitchReading*180/Math.PI){
    //     let pitchReadingDeg = 360 - pitchReading*180/Math.PI
    //     pitchReading = -pitchReadingDeg * Math.PI / 180
    // }

    let rollReadingDeg = 360 - rollReading*180/Math.PI                  //change direction of roll
    rollReading = rollReadingDeg * Math.PI/180                          //change roll unit from degree to rad
    
    canvasCtx.save()

    rotateClipRect1(canvasCtx, leftPos+((eleWidth-416)/2), topPos+0, 416, 540, rollReading*180/Math.PI)

    canvasCtx.clip()  //testing

    canvasCtx.save()

    canvasCtx.translate(leftPos+(eleWidth/2), topPos+(eleHeight/2)+15)
    canvasCtx.rotate(rollReading)
    canvasCtx.translate(-leftPos-(eleWidth/2), -topPos-(eleHeight/2)-15)

    canvasCtx.beginPath();
    canvasCtx.fillStyle = "rgba(64, 143, 245, 0.2)";
    
    let grd = canvasCtx.createLinearGradient(leftPos+(eleWidth/2), topPos+(eleHeight/2) - 240 + 15, leftPos+(eleWidth/2), topPos+(eleHeight/2) + 240 + 15);     
    let pitchDeflectionForBg = (pitchReading*180/Math.PI) * 240/20

    let diflectPercent = pitchDeflectionForBg / 480
    if(diflectPercent > 0.49){
        diflectPercent = 0.49
    } 
    if(diflectPercent < -0.49){
        diflectPercent = -0.49
    }
   grd.addColorStop(0, "rgba(64, 143, 245, 0.2)");
   grd.addColorStop(.5 + diflectPercent, "rgba(64, 143, 245, 0.2)");
   grd.addColorStop(.5 + diflectPercent, "rgba(144, 75, 30, 0.2)");
   grd.addColorStop(1, "rgba(144, 75, 30, 0.2)");
   canvasCtx.fillStyle = grd
   canvasCtx.filter ="blur(2px)"

   canvasCtx.arc(leftPos+(eleWidth/2), topPos+(eleHeight/2)+15, 240, 0, 2*Math.PI, false);
    //canvasCtx.stroke()
    canvasCtx.fill();
    canvasCtx.clip()
    canvasCtx.closePath();

    // let pitchDeflectionForBg = (pitchReading*180/Math.PI) * 240/20

    // console.log(pitchReading*180/Math.PI)

    // canvasCtx.beginPath()
    // canvasCtx.fillStyle = "rgba(64, 143, 245, 0.2)"
    // canvasCtx.filter ="blur(2px)"
    // canvasCtx.rect(leftPos,topPos,eleWidth, 270+pitchDeflectionForBg)
    // canvasCtx.fill()
    // canvasCtx.closePath()

    // canvasCtx.beginPath()
    // canvasCtx.fillStyle = "rgba(144, 75, 30, 0.2)"
    // canvasCtx.filter ="blur(2px)"
    // canvasCtx.rect(leftPos,topPos+270+pitchDeflectionForBg,eleWidth,eleHeight)
    // canvasCtx.fill()
    // canvasCtx.closePath()

    canvasCtx.restore()
    

    canvasCtx.restore();

    pitchScale1(canvasCtx, leftPos, topPos, pitchReading*180/Math.PI, rollReading)

    // circle top border 
    canvasCtx.beginPath();
    canvasCtx.lineWidth = 4
    canvasCtx.strokeStyle = "rgba(255, 255, 255, 0.8)"
    canvasCtx.setLineDash([0, 0])
    canvasCtx.arc(leftPos+(eleWidth/2), topPos+(eleHeight/2)+15, 239,(5*Math.PI/4)+rollReading, (7*Math.PI/4)+rollReading);
    canvasCtx.stroke();
    canvasCtx.closePath();

    // centre horizontal dot line
    // ctx!.beginPath();
    // ctx!.lineWidth = 4
    // ctx!.strokeStyle = "#fff"
    // ctx!.setLineDash([12, 8])
    // ctx!.moveTo(90, 270);
    // ctx!.lineTo(510, 270);
    // ctx!.stroke();
    // ctx!.closePath();

    canvasCtx.beginPath()
    // canvasCtx.moveTo(300, 270)
    // canvasCtx.lineTo(120,320)
    // canvasCtx.lineTo(200,320)

    canvasCtx.moveTo(leftPos+(eleWidth/2), topPos+270)
    canvasCtx.lineTo(leftPos+120,topPos+320)
    canvasCtx.lineTo(leftPos+200,topPos+320)
    canvasCtx.fillStyle = "yellow"
    canvasCtx.fill()
    canvasCtx.closePath()

    canvasCtx.beginPath()
    // canvasCtx.moveTo(300, 270)
    // canvasCtx.lineTo(480,320)
    // canvasCtx.lineTo(400,320)

    canvasCtx.moveTo(leftPos+(eleWidth/2), topPos+270)
    canvasCtx.lineTo(leftPos+480,topPos+320)
    canvasCtx.lineTo(leftPos+400,topPos+320)
    canvasCtx.fillStyle = "yellow"
    canvasCtx.fill()
    canvasCtx.closePath()

    canvasCtx.beginPath()
    canvasCtx.lineWidth = 1
    canvasCtx.moveTo(leftPos+(eleWidth/2), topPos+34)
    canvasCtx.lineTo(leftPos+(eleWidth/2)-16, topPos+58)
    canvasCtx.lineTo(leftPos+(eleWidth/2)+16, topPos+58)
    canvasCtx.lineTo(leftPos+(eleWidth/2), topPos+34)
    canvasCtx.fillStyle = "#fff"
    canvasCtx.fill()
    canvasCtx.strokeStyle = "#121212"
    canvasCtx.setLineDash([0, 0])
    canvasCtx.stroke()
    canvasCtx.closePath()

    // ctx!.beginPath()
    // ctx!.lineWidth = 1
    // ctx!.moveTo(282,62)
    // ctx!.lineTo(318, 62)
    // ctx!.lineTo(324, 70)
    // ctx!.lineTo(276, 70)
    // ctx!.lineTo(282, 62)
    // ctx!.fillStyle = "#fff"
    // ctx!.fill()
    // ctx!.strokeStyle = "#121212"
    // ctx!.setLineDash([0, 0])
    // ctx!.stroke()
    // ctx!.closePath()

    canvasCtx.beginPath()
    canvasCtx.lineWidth = 1

    canvasCtx.moveTo(leftPos+(eleWidth/2)-18+(120*GLatAcReading), topPos+62)
    canvasCtx.lineTo(leftPos+(eleWidth/2)+18+(120*GLatAcReading), topPos+62)
    canvasCtx.lineTo(leftPos+(eleWidth/2)+24+(120*GLatAcReading), topPos+70)
    canvasCtx.lineTo(leftPos+(eleWidth/2)-24+(120*GLatAcReading), topPos+70)
    canvasCtx.lineTo(leftPos+(eleWidth/2)-18+(120*GLatAcReading), topPos+62)

    canvasCtx.fillStyle = "#fff"
    canvasCtx.fill()
    canvasCtx.strokeStyle = "#121212"
    canvasCtx.setLineDash([0, 0])
    canvasCtx.stroke()
    canvasCtx.closePath()

    radiantLine(canvasCtx, leftPos+(eleWidth/2), topPos+(eleHeight/2)+15, 240, 256, -135+(rollReading*180/Math.PI), 4, "#fff")
    radiantLine(canvasCtx, leftPos+(eleWidth/2), topPos+(eleHeight/2)+15, 240, 272, -120+(rollReading*180/Math.PI), 4, "#fff")
    radiantLine(canvasCtx, leftPos+(eleWidth/2), topPos+(eleHeight/2)+15, 240, 256, -110+(rollReading*180/Math.PI), 4, "#fff")
    radiantLine(canvasCtx, leftPos+(eleWidth/2), topPos+(eleHeight/2)+15, 240, 256, -100+(rollReading*180/Math.PI), 4, "#fff")
    // rotatingTriangle(canvasCtx, leftPos+(eleWidth/2), topPos+(eleHeight/2)+30, 240, 256, -90+(rollReading*180/Math.PI), 2, "black")
    radiantLine(canvasCtx, leftPos+(eleWidth/2), topPos+(eleHeight/2)+15, 240, 256, -80+(rollReading*180/Math.PI), 4, "#fff")
    radiantLine(canvasCtx, leftPos+(eleWidth/2), topPos+(eleHeight/2)+15, 240, 256, -70+(rollReading*180/Math.PI), 4, "#fff")
    radiantLine(canvasCtx, leftPos+(eleWidth/2), topPos+(eleHeight/2)+15, 240, 272, -60+(rollReading*180/Math.PI), 4, "#fff")
    radiantLine(canvasCtx, leftPos+(eleWidth/2), topPos+(eleHeight/2)+15, 240, 256, -45+(rollReading*180/Math.PI), 4, "#fff")

 
}


const rotateClipRect1 = (ctx : any, x : number, y : number, width : number, height : number, degrees : number) => {
    ctx.save();
    ctx.beginPath();
    ctx.translate(x + width / 2, y + height / 2);                            //to change direction of rotaion
    ctx.rotate(degrees * Math.PI / 180);
    ctx.rect(-width / 2, -height / 2, width, height);
    //ctx.stroke()
    ctx.restore();
}

const IASRange = () =>{

}

const pitchScale1 = (ctx : any, poseX:number, poseY:number, pitchReading : number, rollAngleReading : number) =>{
    let elewidth = 240
    let eleHeight = 400

    let parentWidth = 600
    let parentHeight = 510

    ctx.save()
    //ctx.translate(600/2, 510/2)
    //ctx.translate(poseX/2, poseY/2)
    ctx.translate(poseX+(parentWidth/2), poseY+270)
    ctx.rotate(rollAngleReading);
    //ctx.translate(-600/2, -510/2)
    ctx.translate(-poseX-(parentWidth/2), -poseY-270)
    
    ctx.beginPath()
    ctx.lineWidth = 1
    ctx.moveTo(poseX+(parentWidth/2), poseY+24)
    ctx.lineTo(poseX+(parentWidth/2)-16, poseY+0)
    ctx.lineTo(poseX+(parentWidth/2)+16, poseY+0)
    ctx.lineTo(poseX+(parentWidth/2), poseY+24)
    ctx.fillStyle = "#fff"
    ctx.fill()
    ctx.strokeStyle = "#121212"
    ctx.stroke()
    ctx.closePath()

    ctx.beginPath()
    ctx.rect(poseX+(parentWidth/2)-120, poseY+80, 240, 400)                         
    ctx.clip()
    //ctx.stroke()
    ctx.closePath()
    ctx.strokeStyle = "#fff"

    let nextNumDivBy10 = getNextNumber2(pitchReading);
    let pitchDeflection = (pitchReading - nextNumDivBy10)*480/4/10
    //console.log(pitchReading)
    let offsetY = 0
    ctx!.lineWidth = 3

    for(let i=0; i<=4; i++){
        ctx.beginPath()
        ctx.font = "500 28px Arial";

        ctx.fillText(nextNumDivBy10+20, poseX+200, poseY+40+offsetY+pitchDeflection)
        ctx.fillText(nextNumDivBy10+20, poseX+360, poseY+40+offsetY+pitchDeflection)
        ctx.moveTo(poseX+252, poseY+40-10+offsetY+pitchDeflection)
        ctx.lineTo(poseX+348, poseY+40-10+offsetY+pitchDeflection)

        ctx.stroke()
        ctx.closePath()

        ctx.beginPath()
      
        ctx.moveTo(poseX+284, poseY+40-10+offsetY+pitchDeflection+30)
        ctx.lineTo(poseX+316, poseY+40-10+offsetY+pitchDeflection+30)
        ctx.stroke()
        ctx.closePath()

        if(nextNumDivBy10+20 === 0){
              // ctx!.beginPath();
            ctx.lineWidth = 4
            ctx.strokeStyle = "#fff"
            ctx.setLineDash([12, 8])
            ctx.moveTo(0, 40-10+offsetY+pitchDeflection)
            ctx.lineTo(600, 40-10+offsetY+pitchDeflection)
            ctx.stroke();
            ctx.closePath();
        }

        ctx.beginPath()
        ctx.font = "500 28px Arial";

        ctx.fillText(nextNumDivBy10+20-5, poseX+225, poseY+40+offsetY+pitchDeflection+60)
        ctx.fillText(nextNumDivBy10+20-5, poseX+340, poseY+40+offsetY+pitchDeflection+60)

        ctx.setLineDash([0, 0])
     
        ctx.moveTo(poseX+268, poseY+40-10+offsetY+pitchDeflection+60)
        ctx.lineTo(poseX+332, poseY+40-10+offsetY+pitchDeflection+60)
        ctx.stroke()
        ctx.closePath()

        ctx.beginPath()
  
        ctx.moveTo(poseX+284, poseY+40-10+offsetY+pitchDeflection+90)
        ctx.lineTo(poseX+316, poseY+40-10+offsetY+pitchDeflection+90)
        ctx.stroke()
        ctx.closePath()

        offsetY += 480/4
        nextNumDivBy10 -= 10
        
    }
    ctx.restore()
 
}

const leftGroupEle = (canvasCtx : any, canvasWidth : number, canvasHeight : number, airSpeedReading : number, TASReading :number) =>{
    let groupHeight = 520 //canvasHeight/100 * 60
    let groupWidth = 300 //canvasWidth/100 * 12
    let topPos = (canvasHeight - groupHeight)/2
    let leftPos = (canvasWidth/2 - groupWidth) / 2
    let mobileView = false

    if(canvasWidth > 1536){
        leftPos = (canvasWidth/2) - (1536/2)
    }

    if(canvasWidth < 1536){
        leftPos = 10
    }

    if(canvasHeight < 550 * 2.3){                 //scalingFacotr = 2.3
        topPos = 100
    }

    mobileView = canvasWidth <= 1280 ? true : false

    canvasCtx.beginPath()
    canvasCtx.rect(leftPos, topPos, groupWidth, groupHeight)
    //canvasCtx.stroke()
    canvasCtx.closePath()


    if(mobileView){
        airSpeedScale(canvasCtx, leftPos + 0, topPos + 0, airSpeedReading)
        trueAirSpeedScale(canvasCtx, leftPos+0, topPos+480, TASReading)
    }else{
        airSpeedScale(canvasCtx, leftPos + 140, topPos + 0, airSpeedReading)
        trueAirSpeedScale(canvasCtx, leftPos+140, topPos+480, TASReading)
         //gearLabel(canvasCtx, 44, 48)
        //flapsLabel(canvasCtx, 0, 198)
    }
}

const airSpeedScale = (canvasCtx : any, poseX : number, poseY : number, airSpeedReading : number) =>{

    let eleWidth = 160
    let eleHeight = 480

    let visibleMajorMark = 6
    let lowestVisbleValueIAS = 20
    let majorMarkDiff = 480 / visibleMajorMark
    let nextMajorMark = 0
    let minorMarkDiff = 480 / visibleMajorMark / 2
    let oneUnit = 480 / visibleMajorMark / 10
    let nextNumberinMulti10 = getNextNumber2(airSpeedReading)
    let scaleOffset = (getNextNumber2(airSpeedReading) - airSpeedReading) * oneUnit

    canvasCtx.save()
    canvasCtx.beginPath()
    canvasCtx.rect(poseX, poseY, eleWidth, eleHeight)
    canvasCtx.clip()
    canvasCtx.closePath()

    canvasCtx.beginPath()
    canvasCtx.fillStyle = colorStrings.bgLight
    canvasCtx.roundRect(poseX, poseY, eleWidth, eleHeight, [32, 8, 0, 0])
    canvasCtx.fill()
    canvasCtx.closePath()

    canvasCtx.beginPath()
    canvasCtx.fillStyle = colorStrings.bgWhiteLight
    canvasCtx.roundRect(poseX+142, poseY, 16, eleHeight, [0, 8, 0, 0])
    canvasCtx.fill()
    canvasCtx.closePath()

    canvasCtx.beginPath();
    canvasCtx.fillStyle = colorStrings.whiteColor
    canvasCtx.rect(poseX+158, poseY, 2, 480);
    canvasCtx.fill();

    canvasCtx.strokeStyle = colorStrings.fontColor
    canvasCtx.lineWidth = 2
    canvasCtx.font = "500 28px Arial";

    let airSpeedMid = poseY + (eleHeight/2)
    let airSpeedScaleTopPx = poseY + 480
    let airSpeedScaleBottom = airSpeedReading - 30
    let airSpeedScaleBottomPx = poseY + 480

    //console.log(airSpeedScaleBottom)

    let start = 60
    let end = 90

    for(let i = 0; i <= 60; i++){
        let Ydef = i * (480 /60)

        // if(airSpeedScaleBottom + i > 50){
        //     canvasCtx.beginPath()
        //     canvasCtx.strokeStyle = "red"
        //     canvasCtx.moveTo(poseX+144, airSpeedScaleBottomPx - Ydef)
        //     canvasCtx.lineTo(poseX+152, airSpeedScaleBottomPx - Ydef)
        //     canvasCtx.stroke()
        //     canvasCtx.closePath()
        // }

        // if(airSpeedScaleTop + Ydef < 10){
        //     canvasCtx.beginPath()
        //     canvasCtx.strokeStyle = "red"
        //     canvasCtx.moveTo(poseX+144, airSpeedScaleTopPx + Ydef)
        //     canvasCtx.lineTo(poseX+152, airSpeedScaleTopPx + Ydef)
        //     canvasCtx.stroke()
        //     canvasCtx.closePath()
        // }
       
        // if(airSpeedScaleBottom + i ===  200) {
        //     canvasCtx.beginPath()
        //     canvasCtx.strokeStyle = "red"
        //     canvasCtx.moveTo(poseX+144, poseY+i)
        //     canvasCtx.lineTo(poseX+152, poseY+i)
        //     canvasCtx.stroke()
        //     canvasCtx.closePath()
        // }
        
        // if(airSpeedScaleBottom == airSpeedReading - 50){
        //     canvasCtx.beginPath()
        //     canvasCtx.strokeStyle = "green"
        //     canvasCtx.moveTo(poseX+152, poseY+i)
        //     canvasCtx.lineTo(poseX+160, poseY+i)
        //     canvasCtx.stroke()
        //     canvasCtx.closePath()
        // } 
    }

    for(let i = 0; i <= visibleMajorMark; i++){

        canvasCtx.strokeStyle = colorStrings.fontColor
        canvasCtx.fillStyle = colorStrings.fontColor
        canvasCtx.beginPath()
        canvasCtx.moveTo(poseX+126, poseY+nextMajorMark-scaleOffset)
        canvasCtx.lineTo(poseX+158, poseY+nextMajorMark-scaleOffset)
        canvasCtx.fillText( nextNumberinMulti10 - i*10 + 30, poseX+72, poseY+nextMajorMark-scaleOffset+8)
        canvasCtx.stroke()
        canvasCtx.closePath()

        canvasCtx.beginPath()
        canvasCtx.moveTo(poseX+142, poseY+nextMajorMark+minorMarkDiff-scaleOffset)
        canvasCtx.lineTo(poseX+158, poseY+nextMajorMark+minorMarkDiff-scaleOffset)
        canvasCtx.stroke()
        canvasCtx.closePath()

        nextMajorMark += majorMarkDiff;
    }


    canvasCtx.beginPath()
    canvasCtx.fillStyle = colorStrings.blackColor
    canvasCtx.rect(poseX + 4, poseY + (eleHeight/2) - 33, 138, 66)
    canvasCtx.fill()
    canvasCtx.closePath()

    canvasCtx.beginPath()
    canvasCtx.fillStyle = colorStrings.whiteColor
    canvasCtx.strokeStyle = colorStrings.blackColor
    canvasCtx.moveTo( poseX+156,poseY+240)
    canvasCtx.lineTo(poseX+132,poseY+228)
    canvasCtx.lineTo(poseX+132,poseY+252)
    canvasCtx.lineTo( poseX+156,poseY+240)
    canvasCtx.fill()
    canvasCtx.stroke()
    canvasCtx.closePath()


    canvasCtx.beginPath()
    //canvasCtx.strokeStyle = colorStrings.fontColor
    canvasCtx.fillStyle = colorStrings.fontColor
    canvasCtx.font = "600 42px Arial";
    canvasCtx.fillText(Math.round(airSpeedReading), poseX + 20, poseY + (eleHeight/2) + 12)
    canvasCtx.stroke()
    canvasCtx.closePath()

   canvasCtx.restore()

}

function getNextNumber2(x:number){
    return Math.ceil(x/10)*10
}

function getNextNumber3(x:number){
    return Math.ceil(x/100)*100
}

const trueAirSpeedScale = (canvasCtx : any, poseX : number, poseY : number, TASReading : number) =>{

    canvasCtx.beginPath()
    canvasCtx.fillStyle = colorStrings.bgDark
    canvasCtx.roundRect(poseX,poseY,160,40, [0, 0, 8, 32])
    canvasCtx.fill()
    canvasCtx.closePath()

    canvasCtx.beginPath()
    canvasCtx.fillStyle = colorStrings.fontColor
    canvasCtx.font = "500 28px Arial";
    canvasCtx.fillText("TAS "+Math.round(TASReading)+"kt", poseX+20, poseY+30)
    canvasCtx.fill()
    canvasCtx.closePath()

}

const gearLabel = (canvasCtx : any, poseX : number, poseY : number) =>{
    canvasCtx.beginPath()
    canvasCtx.roundRect(poseX, poseY, 96, 118, [16, 0, 0, 16])
    canvasCtx.fill()
    canvasCtx.closePath()
}

const flapsLabel = (canvasCtx : any, poseX : number, poseY : number) =>{
    canvasCtx.beginPath()
    canvasCtx.roundRect(poseX, poseY, 140, 280, [16, 0, 0, 16])
    canvasCtx.fill()
    canvasCtx.closePath()
}

const rightGroupEle = (canvasCtx : any, canvasWidth : number, canvasHeight : number, altMslReading: number, VSpdReading: number, BaroAReading:number) =>{
    
    let groupWidth = 326
    let groupHeight = 560
    let mobileView = false

    let topPos = (canvasHeight - groupHeight)/2
    let leftPos = canvasWidth/2 + (canvasWidth/2 - groupWidth) / 2

    if(canvasWidth > 1536){
        leftPos = (canvasWidth - 1536)/2 + 1536 - groupWidth
    }

    if(canvasWidth < 1536){
        leftPos = canvasWidth - groupWidth - 10
    }

    if(canvasHeight < 550 * 2.3){           //scalingFactor = 2.3
        topPos = 100
    }

    mobileView = canvasWidth <= 1280 ? true : false

    canvasCtx.beginPath()
    canvasCtx.rect(leftPos, topPos, groupWidth, groupHeight)
    //canvasCtx.stroke()
    canvasCtx.closePath()
    if(mobileView){
        altMslScale(canvasCtx, leftPos+40+126, topPos+40, altMslReading)
        baroALabel(canvasCtx, leftPos+40+126, topPos+520, BaroAReading)
        selectedAlt(canvasCtx, leftPos+40+126, topPos+0)
    }else{
        altMslScale(canvasCtx, leftPos+40, topPos+40, altMslReading)
        vSpdScale(canvasCtx, leftPos+200, topPos+72, VSpdReading)
        baroALabel(canvasCtx, leftPos+40, topPos+520, BaroAReading)
        selectedAlt(canvasCtx, leftPos+40, topPos+0)
    }
}

const altMslScale = (canvasCtx : any, poseX : number, poseY : number, altMslReading: number) =>{

    let eleWidth = 160
    let eleHeight = 480

    let visibleMajorMark = 6

    //let lowestVisbleValueIAS = 20
    let majorMarkDiff = 480 / visibleMajorMark
    let visibleMajorMarkCompensate = (visibleMajorMark%2===0) ? 0 : majorMarkDiff/2
    let nextMajorMark = 0
    let minorMarkDiff = 480 / visibleMajorMark / 5
    let oneUnit = 480 / visibleMajorMark / 100
    //console.log(oneUnit)
    let nextNumberinMulti100 = getNextNumber3(altMslReading)
    let scaleOffset = (getNextNumber3(altMslReading) - altMslReading) * oneUnit

    //console.log(scaleOffset)

    canvasCtx.save()
    canvasCtx.beginPath()
    canvasCtx.rect(poseX, poseY, eleWidth, eleHeight)
    canvasCtx.clip()
    canvasCtx.closePath()

    canvasCtx.beginPath()
    canvasCtx.fillStyle = colorStrings.bgLight
    canvasCtx.roundRect(poseX, poseY, eleWidth, eleHeight)
    canvasCtx.fill()
    canvasCtx.closePath()

    canvasCtx.beginPath()

    canvasCtx.strokeStyle = colorStrings.whiteColor
    canvasCtx.fillStyle = colorStrings.fontColor
    canvasCtx.lineWidth = 2
    canvasCtx.font = "500 28px Arial";

    canvasCtx.stroke()
    canvasCtx.fillStyle = colorStrings.whiteColor
    canvasCtx.rect(poseX, poseY, 2, eleHeight);
    canvasCtx.fill()
    canvasCtx.closePath()

    for(let i = 0; i <= visibleMajorMark; i++){

        canvasCtx.beginPath()
        canvasCtx.moveTo(poseX+2, poseY+nextMajorMark-scaleOffset+visibleMajorMarkCompensate)
        canvasCtx.lineTo(poseX+34, poseY+nextMajorMark-scaleOffset+visibleMajorMarkCompensate)
        canvasCtx.fillText( nextNumberinMulti100 - i*100+200, poseX+38, poseY+nextMajorMark-scaleOffset-8+100+visibleMajorMarkCompensate)
        canvasCtx.stroke()
        canvasCtx.closePath()

        for(let minortick = 1; minortick < 5 ;minortick++){
            canvasCtx.beginPath()
            canvasCtx.moveTo(poseX+2, poseY+nextMajorMark+(minorMarkDiff*minortick)-scaleOffset+visibleMajorMarkCompensate)
            canvasCtx.lineTo(poseX+18, poseY+nextMajorMark+(minorMarkDiff*minortick)-scaleOffset+visibleMajorMarkCompensate)
            canvasCtx.stroke()
            canvasCtx.closePath()
        }

        nextMajorMark += majorMarkDiff ;
    }

    canvasCtx.beginPath()
    canvasCtx.fillStyle = colorStrings.blackColor
    canvasCtx.rect(poseX + 20, poseY + (eleHeight/2) - 33, 138, 66)
    canvasCtx.fill()
    canvasCtx.closePath()

    canvasCtx.beginPath()
    canvasCtx.fillStyle = colorStrings.whiteColor
    canvasCtx.strokeStyle = colorStrings.blackColor
    canvasCtx.moveTo(poseX+0,poseY+240)
    canvasCtx.lineTo(poseX+24,poseY+228)
    canvasCtx.lineTo(poseX+24,poseY+252)
    canvasCtx.lineTo(poseX+0,poseY+240)
    canvasCtx.fill()
    canvasCtx.stroke()
    canvasCtx.closePath()

    canvasCtx.beginPath()
    canvasCtx.strokeStyle = "#fff"
    canvasCtx.fillStyle = "#fff"
    canvasCtx.font = "600 42px Arial";
    canvasCtx.fillText(Math.round(altMslReading), poseX + 40, poseY + (eleHeight/2) + 12)
    canvasCtx.stroke()
    canvasCtx.closePath()

    canvasCtx.restore()
}


const vSpdScale = (canvasCtx: any, poseX:any, poseY:any, VSpdReading: number) =>{
    let eleWidth = 80
    let eleHeight = 416

    canvasCtx.beginPath()
    canvasCtx.fillStyle = colorStrings.bgLight
    canvasCtx.roundRect(poseX, poseY, eleWidth, eleHeight, [0, 32, 32, 0])
    canvasCtx.fill()
    canvasCtx.closePath()

    canvasCtx.beginPath();
    canvasCtx.fillStyle = colorStrings.whiteColor
    canvasCtx.rect(poseX, poseY, 2, 416);
    canvasCtx.fill();

    canvasCtx.font = "500 28px Arial";
    //VSpeed scale lines
    canvasCtx.lineWidth = 2
    canvasCtx.strokeStyle = colorStrings.blackColor
    canvasCtx.fillStyle = colorStrings.blackColor

    let scaleMarkDiff = eleHeight/10
    let nextMark = scaleMarkDiff

    let scaleNo = 2
    for(let scalemark = 1; scalemark<10; scalemark++){
        canvasCtx.beginPath()
        canvasCtx.fillStyle = colorStrings.fontColor
        canvasCtx.strokeStyle = colorStrings.whiteColor
        if(scalemark % 2 === 0){
            canvasCtx.moveTo(poseX+0, poseY+nextMark)
            canvasCtx.lineTo(poseX+12, poseY+nextMark)
        }else{
            canvasCtx.moveTo(poseX+0, poseY+nextMark)
            canvasCtx.lineTo(poseX+24, poseY+nextMark)
            canvasCtx.fillText(scaleNo, poseX+36, poseY+nextMark+8)
            scaleNo--
        }
        canvasCtx.stroke()
        canvasCtx.closePath()
        nextMark += scaleMarkDiff
    }

    //vSpeed label
    canvasCtx.beginPath()
    canvasCtx.fillStyle = colorStrings.blackColor
    if(Math.round(VSpdReading) > 2500){
        canvasCtx.rect(poseX+16,poseY+(eleHeight/2)-28+(-(2500)*0.08),108,56)
    }else if(Math.round(VSpdReading) < -2500){
        canvasCtx.rect(poseX+16,poseY+(eleHeight/2)-28+(-(-2500)*0.08),108,56)
    }else{
        canvasCtx.rect(poseX+16,poseY+(eleHeight/2)-28+(-Math.round(VSpdReading)*0.08),108,56)
    }
    //canvasCtx.rect(poseX+16,poseY+(eleHeight/2)-28+(-Math.round(VSpdReading)*0.08),108,56)
    canvasCtx.fill()
    canvasCtx.closePath()

    //Vspeed label text
    canvasCtx.beginPath()
    canvasCtx.font = "600 28px Arial";
    canvasCtx.fillStyle = colorStrings.fontColor
    let stringVSpeed = Math.round(VSpdReading);
    if(Math.round(VSpdReading) > 2500){
        canvasCtx.fillText(stringVSpeed.toString(), poseX+35, poseY+(eleHeight/2)+(-(2500)*0.08)+7)
    }else if(Math.round(VSpdReading) < -2500){
        canvasCtx.fillText(stringVSpeed.toString(), poseX+35, poseY+(eleHeight/2)+(-(-2500)*0.08)+7)
    }else{
        canvasCtx.fillText(stringVSpeed.toString(), poseX+35, poseY+(eleHeight/2)+(-Math.round(VSpdReading)*0.08)+7)
    }
    //canvasCtx.fillText(stringVSpeed.toString(), poseX+35, poseY+(eleHeight/2)+(-Math.round(VSpdReading)*0.08)+7)
    canvasCtx.closePath()

    //vSpeed arrow
    canvasCtx.beginPath()
    canvasCtx.fillStyle = "#fff"
    canvasCtx.strokeStyle = "#000"
    if(Math.round(VSpdReading) > 2500){
        canvasCtx.moveTo(poseX+0, poseY+(eleHeight/2)+(-(2500)*0.08))
        canvasCtx.lineTo(poseX+24, poseY+(eleHeight/2)-12+(-(2500)*0.08))
        canvasCtx.lineTo(poseX+24, poseY+(eleHeight/2)+12+(-(2500)*0.08))
        canvasCtx.lineTo(poseX+0, poseY+(eleHeight/2)+(-(2500)*0.08))
    }else if(Math.round(VSpdReading) < -2500){
        canvasCtx.moveTo(poseX+0, poseY+(eleHeight/2)+(-(-2500)*0.08))
        canvasCtx.lineTo(poseX+24, poseY+(eleHeight/2)-12+(-(-2500))*0.08)
        canvasCtx.lineTo(poseX+24, poseY+(eleHeight/2)+12+(-(-2500))*0.08)
        canvasCtx.lineTo(poseX+0, poseY+(eleHeight/2)+(-(-2500)*0.08))
    }else{
        canvasCtx.moveTo(poseX+0, poseY+(eleHeight/2)+(-Math.round(VSpdReading)*0.08))
        canvasCtx.lineTo(poseX+24, poseY+(eleHeight/2)-12+(-Math.round(VSpdReading)*0.08))
        canvasCtx.lineTo(poseX+24, poseY+(eleHeight/2)+12+(-Math.round(VSpdReading)*0.08))
        canvasCtx.lineTo(poseX+0, poseY+(eleHeight/2)+(-Math.round(VSpdReading)*0.08))
    }
    // canvasCtx.moveTo(poseX+0, poseY+(eleHeight/2)+(-Math.round(VSpdReading)*0.08))
    // canvasCtx.lineTo(poseX+24, poseY+(eleHeight/2)-12+(-Math.round(VSpdReading)*0.08))
    // canvasCtx.lineTo(poseX+24, poseY+(eleHeight/2)+12+(-Math.round(VSpdReading)*0.08))
    // canvasCtx.lineTo(poseX+0, poseY+(eleHeight/2)+(-Math.round(VSpdReading)*0.08))
    canvasCtx.fill()
    canvasCtx.stroke()
    canvasCtx.closePath()
}

const baroALabel = (canvasCtx:any, poseX:any, poseY:any, BaroAReading:number) =>{
    let eleHeight = 40
    let eleWidth = 160

    canvasCtx.beginPath()
    canvasCtx.fillStyle = colorStrings.bgDark 
    canvasCtx.roundRect(poseX, poseY, eleWidth, eleHeight, [0, 0, 32, 8])
    canvasCtx.fill()
    canvasCtx.closePath()

    canvasCtx.beginPath()
    canvasCtx.fillStyle = colorStrings.cyan
    canvasCtx.font = "500 28px Arial";
    canvasCtx.fillText(BaroAReading.toFixed(2)+" inch", poseX+10, poseY+30)
    canvasCtx.fill()
    canvasCtx.closePath()
}

const selectedAlt = (canvasCtx:any, poseX:any, poseY:any) =>{
    let eleHeight = 40
    let eleWidth = 160

    canvasCtx.beginPath()
    canvasCtx.fillStyle = colorStrings.bgDark 
    canvasCtx.roundRect(poseX, poseY, eleWidth, eleHeight, [8, 32, 0, 0])
    canvasCtx.fill()
    canvasCtx.closePath()
}


const headingAndGroup = (canvasCtx:any, canvasWidth:any, canvasHeight:any, scalingFactor:number, headReading:number, windSpdReading:number, windDirReading:number, CrsReading:number) =>{

    let eleHeight = 540
    let eleWidth = 850
    let isHalfHeadingScale = false

    let topPos = canvasHeight - eleHeight - 56
    let leftPos = (canvasWidth - eleWidth) / 2

    if(canvasHeight / scalingFactor < 800){
        isHalfHeadingScale = true
    }

    canvasCtx.save()
    if(isHalfHeadingScale){
        topPos = canvasHeight - eleHeight - 56 + 208
        canvasCtx.beginPath()
        canvasCtx.rect(leftPos, topPos, eleWidth, eleHeight-208)
        //canvasCtx.stroke()
        canvasCtx.closePath()
        canvasCtx.clip()
    }
    
    canvasCtx.beginPath()
    canvasCtx.rect(leftPos, topPos, eleWidth, eleHeight)
    //canvasCtx.stroke()
    //canvasCtx.fill()
    canvasCtx.closePath()

    //position center
    headingScale(canvasCtx, leftPos+(eleWidth/2), topPos+48, headReading)
    //localizer(canvasCtx, leftPos+(eleWidth/2), topPos+0)                                                               // left position as parent centre
    windSpdDir(canvasCtx, leftPos, topPos, windSpdReading, windDirReading)
    CrsReadingLabel(canvasCtx, leftPos, topPos, CrsReading)
    canvasCtx.restore()
}

const headingScale = (canvasCtx:any, poseX:any, poseY:any, headReading:number) =>{

    let eleWidth = 416
    let eleHeight = 492

    //box for head reading

    canvasCtx.beginPath()
    canvasCtx.fillStyle = "rgba(18, 18, 18, 0.46)";
    canvasCtx.roundRect(poseX-50, poseY, 100, 52, [10, 10, 10, 10])
    canvasCtx.fill()
    canvasCtx.closePath()

    //traingle below head reading box
    canvasCtx.beginPath()
    canvasCtx.fillStyle = "#fff"
    canvasCtx.moveTo(poseX-12, poseY+50)
    canvasCtx.lineTo(poseX+12, poseY+50)
    canvasCtx.lineTo(poseX, poseY+74)
    canvasCtx.lineTo(poseX-12, poseY+50)
    canvasCtx.fill()
    canvasCtx.stroke()
    canvasCtx.closePath() 



    canvasCtx.beginPath()
    canvasCtx.fillStyle = "#fff"
    canvasCtx.font = "600 40px Arial"
    let headReadingRound = Math.round(headReading*180/Math.PI)
    if(headReadingRound<=9){
        canvasCtx.fillText(headReadingRound+"°", poseX-20, poseY+40)
    }else if(headReadingRound<=99 && headReadingRound>9){
        canvasCtx.fillText(headReadingRound+"°", poseX-28, poseY+40)
    }else{
        canvasCtx.fillText(headReadingRound+"°", poseX-40, poseY+40)
    }
    canvasCtx.fill()
    canvasCtx.closePath()

    canvasCtx.beginPath()
    canvasCtx.rect(poseX-(eleWidth/2), poseY, eleWidth, eleHeight)
    //canvasCtx.stroke()
    canvasCtx.closePath()

    canvasCtx.beginPath()
    canvasCtx.fillStyle = "rgba(18, 18, 18, 0.48)";
    //canvasCtx.fillStyle = "red"
    canvasCtx.arc(poseX, poseY+eleHeight-208, 208, 0, 2 * Math.PI);
    canvasCtx.fill()
    canvasCtx.closePath()


    canvasCtx.save()
    canvasCtx.translate(poseX, poseY+eleHeight-208)
    canvasCtx.rotate(-headReading)
    canvasCtx.translate(-poseX,  -poseY-eleHeight+208)
    //canvasCtx.restore()
    //canvasCtx.save()

    radiantLine(canvasCtx, poseX, poseY+eleHeight-208, 0, 20, 0, 2, "#fff")
    radiantLine(canvasCtx, poseX, poseY+eleHeight-208, 0, 45, 90, 2, "#fff")
    radiantLine(canvasCtx, poseX, poseY+eleHeight-208, 0, 20, 180, 2, "#fff")
    radiantLine(canvasCtx, poseX, poseY+eleHeight-208, 0, 45, 270, 2, "#fff")
    for(let i=0; i<=36; i++){
        radiantLine(canvasCtx, poseX, poseY+eleHeight-208, 200, 208, i*10+5, 2, "#fff")
        radiantLine(canvasCtx, poseX, poseY+eleHeight-208, 192, 208, i*10, 2, "#fff")
    }
    
    //canvasCtx.save()

    //canvas rotating text worked code start

    // canvasCtx.save()
    // canvasCtx.beginPath()
    // canvasCtx.fillStyle = "#fff"
    // canvasCtx.font = "28px Arial";

    // canvasCtx.save();
    // canvasCtx.translate(poseX-9, poseY+eleHeight-208);
    // canvasCtx.rotate(90 * Math.PI/180);
    // canvasCtx.textAlign = "center";
    // canvasCtx.translate(0, -160);

    // canvasCtx.fillText("A", 0, 0);
    // canvasCtx.restore();

    // canvasCtx.closePath()

    //canvas rotating text worked code end
    //rotateText2(canvasCtx, "A", 0, poseX, poseY+eleHeight-208);






    let textAngle = 0
    while(textAngle < 360){
        if(textAngle===0){
            rotateText2(canvasCtx, "N", textAngle, poseX, poseY+eleHeight-208)
            textAngle += 30
            continue
        }else if(textAngle===90){
            rotateText2(canvasCtx, "E", textAngle, poseX, poseY+eleHeight-208)
            textAngle += 30
            continue
        }else if(textAngle===180){
            rotateText2(canvasCtx, "S", textAngle, poseX, poseY+eleHeight-208)
            textAngle += 30
            continue
        }else if(textAngle===270){
            rotateText2(canvasCtx, "W", textAngle, poseX, poseY+eleHeight-208)
            textAngle += 30
            continue
        }

        rotateText2(canvasCtx, textAngle.toString(), textAngle, poseX, poseY+eleHeight-208)
        textAngle += 30

    }
    //canvasCtx.restore()
   
    
    canvasCtx.restore()

}

const rotateText2 = (canvasCtx:any, text:string, angle:number, poseX:any, poseY:any ) => {
    canvasCtx.save()
    canvasCtx.beginPath()
    canvasCtx.fillStyle = "#fff"
    canvasCtx.font = "28px Arial";
    //canvasCtx.save();
    canvasCtx.translate(poseX, poseY);
    if(text.length > 2){
        canvasCtx.rotate((angle) * Math.PI/180)
    }else{
        canvasCtx.rotate(angle * Math.PI/180)
    }
    canvasCtx.textAlign = "center";
    canvasCtx.translate(0, -160);
    canvasCtx.fillText(text, 0, 0);
    canvasCtx.closePath()
    canvasCtx.restore();
}

const localizer = (canvasCtx:any, leftPos:number, topPos:number) =>{
    let eleHeight = 32
    let eleWidth = 352

    canvasCtx.beginPath()
    canvasCtx.fillStyle = "rgba(18, 18, 18, 0.48)"
    canvasCtx.roundRect(leftPos - (eleWidth/2), topPos, eleWidth, eleHeight, [16, 16, 16, 16])
    canvasCtx.fill()
    canvasCtx.closePath()
}

const windSpdDir = (canvasCtx:any, leftPos:number, topPos:number, windSpdReading:number, windDirReading:number) =>  {
    canvasCtx.beginPath()
    canvasCtx.fillStyle = "rgba(18, 18, 18, 0.48)";
    canvasCtx.roundRect(leftPos+100, topPos, 120, 80, [16, 16, 16, 16])
    canvasCtx.fill()
    canvasCtx.closePath()

    canvasCtx.save()
    canvasCtx.beginPath()
    canvasCtx.translate(leftPos+140, topPos+37)
    canvasCtx.rotate(windDirReading)
    canvasCtx.translate(-leftPos-140, -topPos-37)

    canvasCtx.fillStyle = "#fff";
    let arrowCenterX = leftPos+140
    let arrowCenterY = topPos+37

    canvasCtx.moveTo(arrowCenterX, arrowCenterY-16)
    canvasCtx.lineTo(arrowCenterX+10, arrowCenterY+0)
    canvasCtx.lineTo(arrowCenterX+5, arrowCenterY+0)
    canvasCtx.lineTo(arrowCenterX+5, arrowCenterY+16)
    canvasCtx.lineTo(arrowCenterX-5, arrowCenterY+16)
    canvasCtx.lineTo(arrowCenterX-5, arrowCenterY+0)
    canvasCtx.lineTo(arrowCenterX-10, arrowCenterY+0)
    canvasCtx.lineTo(arrowCenterX, arrowCenterY-16)

    canvasCtx.fill()
    canvasCtx.closePath()
    canvasCtx.restore()

    canvasCtx.beginPath()
    canvasCtx.font = "500 28px Arial"
    canvasCtx.fillStyle = "#fff";
    canvasCtx.fillText(windSpdReading.toFixed(1), leftPos+160, topPos+47)
    canvasCtx.fill()
    canvasCtx.closePath()

}

const CrsReadingLabel = (canvasCtx:any, leftPos:number, topPos:number, CrsReading:number) => {
    //CRS label
    canvasCtx.beginPath()
    canvasCtx.fillStyle = "rgba(18, 18, 18, 0.48)";
    canvasCtx.roundRect(leftPos+552, topPos+100, 126, 48, [10, 10, 10, 10])
    canvasCtx.fill()
    canvasCtx.closePath()

    canvasCtx.beginPath()
    canvasCtx.fillStyle = "#fff";
    canvasCtx.font = "600 24px Arial"
    canvasCtx.fillText("CRS", leftPos+560, topPos+132)
    canvasCtx.fill()
    canvasCtx.closePath()

    canvasCtx.beginPath()
    canvasCtx.fillStyle = "#ce63f7";
    canvasCtx.font = "600 24px Arial"
    canvasCtx.fillText(Math.round(CrsReading*180/Math.PI)+"°", leftPos+620, topPos+132)
    canvasCtx.fill()
    canvasCtx.closePath()
}

const leftBottomGroup = (canvasCtx:any, canvasWidth:number, canvasHeight:number, OATReading:number, altMslReading:number) => {
    let eleWidth = 336
    let eleHeight = 168

    let leftPos = (canvasWidth - 1536)/2 + 1536 - eleWidth - 10
    let topPos = canvasHeight - eleHeight - 56

    if(canvasWidth < 1536){
        leftPos = canvasWidth - eleWidth - 10  
    }

    let mobileView = canvasWidth <= 1280 ? true : false
    if(!mobileView){

        canvasCtx.fillStyle = "rgba(18, 18, 18, 0.48)";
        canvasCtx.beginPath()
        canvasCtx.rect( leftPos, topPos, eleWidth, eleHeight)
        //canvasCtx.stroke()
        canvasCtx.closePath()

        canvasCtx.beginPath()
        canvasCtx.roundRect(leftPos+0, topPos+120, 160, 48, [8,8,8,8])
        canvasCtx.fill()
        canvasCtx.closePath()

        canvasCtx.beginPath()
        canvasCtx.roundRect(leftPos+176, topPos+120, 160, 48, [8,8,8,8])       
        canvasCtx.fill()
        canvasCtx.closePath()

        canvasCtx.beginPath()
        canvasCtx.fillStyle = colorStrings.fontColor
        canvasCtx.fillText("OAT "+" "+Math.round(OATReading)+"°C", leftPos+12, topPos+155)
        canvasCtx.closePath()

        let isaReading = (15 - (1.98*altMslReading/1000)) - OATReading

        canvasCtx.beginPath()
        canvasCtx.fillStyle = colorStrings.fontColor
        canvasCtx.fillText("ISA "+" "+Math.round(isaReading)+"°C", leftPos+190, topPos+155)
        canvasCtx.closePath()
    }
  
    
}

const bottomGroupEle = (canvasCtx : any, canvasWidth : number, canvasHeight : number, E1RPMReading:number, GNormAcReading:number ) =>{
   
    let bottomGroupEleWidth = 1536
    let bottomGroupEleheight = 48

    bottomGroupEleWidth = canvasWidth < bottomGroupEleWidth ? canvasWidth : bottomGroupEleWidth

    let topPos = canvasHeight - bottomGroupEleheight
    let leftPose = (canvasWidth - bottomGroupEleWidth)/2

    canvasCtx.beginPath()
    canvasCtx.fillStyle = "rgba(18, 18, 18, 0.64)"
    canvasCtx.roundRect(leftPose, topPos, bottomGroupEleWidth, 48, [0, 0, 14, 14])
    canvasCtx.fill()
    canvasCtx.closePath()

    canvasCtx.beginPath()
    canvasCtx.fillStyle = "#fff"
    canvasCtx.font = "500 28px Arial"
    canvasCtx.fillText("RPM "+E1RPMReading, leftPose+(bottomGroupEleWidth * 0.2), topPos+36)
    canvasCtx.fill()
    canvasCtx.closePath()

    canvasCtx.beginPath()
    canvasCtx.fillStyle = "#fff"
    canvasCtx.font = "500 28px Arial"
    canvasCtx.fillText("G "+GNormAcReading.toFixed(2), leftPose+(bottomGroupEleWidth * 0.6), topPos+36)
    canvasCtx.fill()
    canvasCtx.closePath()

    canvasCtx.beginPath()
    canvasCtx.fillStyle = "black"
    canvasCtx.roundRect(leftPose+(bottomGroupEleWidth * 0.6)+160, topPos+16, 160, 16, [16, 16, 16, 16])
    canvasCtx.fill()
    canvasCtx.closePath()

    canvasCtx.beginPath()
    canvasCtx.fillStyle = "#fff"
    canvasCtx.roundRect(leftPose+(bottomGroupEleWidth * 0.6)+240, +topPos+16, 2, 16)
    canvasCtx.fill()
    canvasCtx.closePath()

    canvasCtx.beginPath()
    canvasCtx.fillStyle = "#5df558"
    canvasCtx.roundRect(leftPose+(bottomGroupEleWidth * 0.6)+240 + (80 * GNormAcReading), topPos+16, 4, 16)
    canvasCtx.fill()
    canvasCtx.closePath()
}
*/
export default pfdLayerElements