import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { csvJSonArray } from '../../../../common/utils/cesiumDataHandler';
import { getTailNumber, getStartTime } from '../debrief/debriefPlayer/DebriefPlayer';

// Function to extract trace name from a label string
export const extractTraceName = (labelString: string): string | null => {
  const regularExpression = /(.+?) \(/;
  const match = labelString.match(regularExpression);
  return match ? match[1] : null;
};

const ThreeAxisGraphTime = (props: {
  y1axisArray: any[],
  xaxisArray: any[],
  y2axisArray: any[],
  xlabel: string,
  y1label: string,
  y2label: string,
}) => {

  // console.log(`props 3A : `, props)

  // State for storing data and layout for the Plotly graph
  const [data, setData] = useState<any[] | undefined>(undefined);
  const [layout, setLayout] = useState<any | undefined>(undefined);

  useEffect(() => {

    const updateTooltip = () => {
      const anchorElement = document.querySelector('.modebar-btn[data-title="Download plot as a png"]');
      if (anchorElement) {
        anchorElement.setAttribute('data-title', 'Download as an Image');
      }
    };

    // Extract trace names from y1label and y2label
    const traceName1 = extractTraceName(props.y1label);
    const traceName2 = extractTraceName(props.y2label);

    // Define Plotly traces for the two sets of data
    const trace1 = {
      x: props.xaxisArray,
      y: props.y1axisArray,
      type: 'scatter',
      name: traceName1,
      showLegend: true,
    };

    const trace2 = {
      x: props.xaxisArray,
      y: props.y2axisArray,
      yaxis: 'y2',
      type: 'scatter',
      name: traceName2,
      showLegend: true,
    };

    // Combine the traces into an array for Plotly data
    const graphData = [trace1, trace2];

    // Layout configuration for the Plotly graph
    const graphLayout = {
      dragmode: false,
      xaxis: {
        title: {
          text: props.xlabel,
          font: {
            size: 12
          }
        },
        tickformat: '%H:%M:%S',
        tickfont: {
          size: 12,
        },
        // nticks: 8, //specifying the number of ticks on the x-axis
        // range: [Math.min(...props.xaxisArray), Math.max(...props.xaxisArray)],
        autorange: true,
      },
      yaxis: {
        title: {
          text: props.y1label,
          font: {
            size: 12
          }
        },
        tickfont: {
          size: 12,
        },
        side: 'left',
        // range: [Math.min(...props.y1axisArray), Math.max(...props.y1axisArray)],
        autorange: true,
      },
      yaxis2: {
        title: {
          text: props.y2label,
          font: {
            size: 12
          }
        },
        tickfont: {
          size: 12,
        },
        side: 'right',
        overlaying: 'y',
        // range: [Math.min(...props.y2axisArray), Math.max(...props.y2axisArray)],
        autorange: true,
      },
      legend: {
        x: 0.000001,
        // x:0.355,
        // y:1.25,
        y: 1.22,
        orientation: 'h',
      },
      hovermode: 'closest',
      autosize: true,
      margin: {
        t: 20,
        b: 50,
        l: 50,
        r: 50,
      },
      // showlegend: false,
    };

    // Update the state with the new data and layout
    setData(graphData);
    setLayout(graphLayout);

    updateTooltip();

  }, [csvJSonArray, props.xaxisArray, props.y1axisArray, props.y2axisArray]);

  // Function to extract trace name from a label string
  const extractTraceName = (labelString: string): string | null => {
    const regularExpression = /(.+?) \(/;
    const match = labelString.match(regularExpression);
    return match ? match[1] : null;
  };

  // Function to combine labels into a single string
  const combineLabels = (): string => {
    // Extract trace names from y1label, y2label, and xlabel
    const traceName1 = extractTraceName(props.y1label);
    const traceName2 = extractTraceName(props.y2label);
    const xLabelName = extractTraceName(props.xlabel);

    // Combine the names into a single string
    return `${traceName1} vs ${traceName2} vs ${xLabelName}`;
  };

  const [graphName, setGraphName] = useState(combineLabels());

  // Render the Plotly graph component
  return (
    <Plot
      data={data as any}
      layout={layout as any}
      // config={{ displayModeBar: false, responsive: true }}
      config={{
        // doubleClick: false,
        responsive: true,
        displayModeBar: true,
        // scrollZoom: true,
        displaylogo: false,
        modeBarButtonsToRemove: ['autoScale2d', 'resetScale2d', 'lasso2d', 'select2d'],
        toImageButtonOptions: {
          format: `png`,
          filename: `${getTailNumber()}_${graphName}_${getStartTime()}`,
        },
      }}
      style={{ height: "100%", width: "100%" }}
    />
  );
};

export default React.memo(ThreeAxisGraphTime);