import { Modal, Button, Dropdown, Form, Badge } from "react-bootstrap";
import { useState, useEffect, Fragment } from "react";
import { getLocalStorage } from "../../../../../../common/utils/localStorageHandler";
import { Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";
// import PilotService from "../../../../../../services/pilot.service";
// import './UpdateFileInfoMoal.scss'
import "./UpdateFileInfoModal.scss"
import { ConstantPositionProperty } from "cesium";


const UpdateFileInfoModule = (props: any) => {

    // console.log(`UpdateFileInfoModule Props: `, props);

    const { pilotTypeListValue, pilotListValue, instructorListValue, aircraftListValue, fileInfo, callBackUpdate, airportList, exerciseListValue, updateMultipleFiles, ismultiplefilesselected, ...others } = props

    // console.log(`pilotListValue: `, pilotListValue)
    // console.log(`instructorListValue: `, instructorListValue)
    
    // console.log(`pilotTypeListValue: `, pilotTypeListValue);

    const [firstCrewType, setFirstCrewType] = useState("Pilot");
    const [secondCrewType, setSecondCrewType] = useState("Pilot");
    // const [secodCrewTypeId, setSecodCrewTypeId] = useState(pilotTypeListValue.filter((u: any) => u.type_name === secondCrewType).map((u: any) => u.type_id)[0]);
    // const [firstCrewTypeId, setFirstCrewTypeId] = useState(pilotTypeListValue.filter((u: any) => u.type_name === firstCrewType).map((u: any) => u.type_id)[0]);
    const [secodCrewTypeId, setSecodCrewTypeId] = useState(1); // Default type is Pilot(id = 1)
    const [firstCrewTypeId, setFirstCrewTypeId] = useState(1); // Default type is Pilot(id = 1)

    const [firstCrewUserId, setFirstCrewUserId] = useState(null)
    const [secondCrewUserId, setSecondCrewUserId] = useState(null)
    const [aircraftId, setAircraftId] = useState(null)

    const [secondCrew, setSecondCrew] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [airportcode, setAirportcode] = useState("");
    const [exerciseType, setExercisetype] = useState(null)

    // const [pilotPIC, setPilotPIC] = useState(false)
    const [pilotPIC, setPilotPIC] = useState(true)
    const [secPilotPIC, setSecPilotPIC] = useState(false)
    const [pilotCheckride, setPilotCheckride] = useState(false)
    const [secPilotCheckride, setSecPilotCheckride] = useState(false)
    const [aircraftModel, setAircraftModel] = useState("")

    useEffect(() => {
        setAirportcode(airportList.filter((airport: any) => airport.flag === 1).map((u: any) => u.airportCode))
    }, [airportList]);

    const selectFirstCrewType = (pilotType: string) => {
        // console.log(`1 pilotType: `, pilotType);
        setFirstCrewType(pilotType)
        let typeID = pilotTypeListValue.filter((u: any) => u.type_name === pilotType).map((u: any) => u.type_id)[0];
        setFirstCrewTypeId(typeID)
    }

    const selectSecondCrewType = (pilotType: string) => {
        // console.log(`2 pilotType: `, pilotType);
        setSecondCrewType(pilotType);
        let typeID = pilotTypeListValue.filter((u: any) => u.type_name === pilotType).map((u: any) => u.type_id)[0];
        setSecodCrewTypeId(typeID);
    }

    const enableButton = (secondCrew: any, secondCrewUserId: any, firstCrewUserId: any, aircraftId: any) => {
        //  console.log("Enable for ");
        // console.log(firstCrewUserId);
        if (secondCrew && secondCrewUserId !== null && firstCrewUserId !== null && aircraftId !== null) {
            setIsDisabled(false);
        }
        else if (!secondCrew && firstCrewUserId !== null && aircraftId !== null) {
            setIsDisabled(false);
        }
        else {
            setIsDisabled(true);
        }
    }

    const setAircraftID = (id: any) => {
        setAircraftId(id);

        if (id) {
            enableButton(secondCrew, secondCrewUserId, firstCrewUserId, id)
        } else {
            enableButton(secondCrew, secondCrewUserId, firstCrewUserId, null)
        }

    }

    const changeFirstCrewID = (id: any) => {
        // console.log("changed Value");
        // console.log(id);
        setFirstCrewUserId(id);
        if (id) {
            enableButton(secondCrew, secondCrewUserId, id, aircraftId)
        } else {
            enableButton(secondCrew, secondCrewUserId, null, aircraftId)
        }

    }
    const changeSecondCrewID = (id: any) => {

        setSecondCrewUserId(id);
        if (id) {
            enableButton(secondCrew, id, firstCrewUserId, aircraftId)
        } else {
            enableButton(secondCrew, null, firstCrewUserId, aircraftId)
        }

    }

    const changeNumberofCrew = (flag: boolean) => {
        setSecondCrew(flag);
        enableButton(flag, secondCrewUserId, firstCrewUserId, aircraftId)
        if (!flag) {
            setSecPilotPIC(false)
            setSecPilotCheckride(false)
        }
    }

    const hideModal = () => {
        props.onHide()
        setIsDisabled(true)
    }

    // useEffect(() => {
    //     console.log(`secodCrewTypeId: `, secodCrewTypeId)
    // }, [secodCrewTypeId])

    // useEffect(() => {
    //     console.log(`firstCrewTypeId: `, firstCrewTypeId)
    // }, [firstCrewTypeId])
    
    const updateFileInfo = () => {
        let userDetails = getLocalStorage("authTokens", "");
        let pilotName = pilotListValue.filter((u: any) => u.pilotId === firstCrewUserId).map((u: any) => u.pilotName)[0]
        //  console.log("pilot name "+pilotName)

        let aircrafTail = aircraftListValue.filter((aircraft: any) => aircraft.aircraftId === aircraftId).map((aircraft: any) => aircraft.aircraftTailNo);
        let updateData: any = {
            "PilotId": firstCrewUserId,
            "PrimaryPilotType": firstCrewTypeId,
            "UserUploaderId": userDetails.userid,
            "RegistrationNumber": aircraftId,
            "Remark": "Good",
            "DataSource": 2,
            "IsDeleted": 0,
            "CompanyId": userDetails.companyid,
            "rowid": fileInfo.Id,
            "pilotName": pilotName,
            "aircrafTail": aircrafTail,
            "airportcode": airportcode,

            "SoloFlying": !secondCrew ? 1 : 0,
            "PilotPIC": pilotPIC ? 1 : 0,
            "SecPilotPIC": secPilotPIC ? 1 : 0,
            "PilotCheckride": pilotCheckride ? 1 : 0,
            "SecPilotCheckride": secPilotCheckride ? 1 : 0,
            "ExcerciseTypeId": exerciseType
        }

        if (secondCrewUserId !== null) {
            updateData["SecondaryPilotId"] = secondCrewUserId;
            updateData["SecondaryPilotType"] = secodCrewTypeId;
        }

        // console.log(`updateData: `, updateData)

        if (ismultiplefilesselected) {
            updateMultipleFiles(updateData)
        }
        else {
            callBackUpdate(updateData);
        }
        setIsDisabled(true);
        props.onHide();
        setSecondCrew(false);
        setPilotPIC(false)
        setSecPilotPIC(false)
        setPilotCheckride(false)
        setSecPilotCheckride(false)
        setAircraftModel("")
    }

    return (<>
        <Modal
            {...others}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton className="assign-modal-header" onClick={hideModal}>
            </Modal.Header>
            <Modal.Body className="assign-modal-body">
                <div className="assign-modal-filename">
                    <p className="title"> <strong> Assign Logs </strong> </p>
                    <div>
                        <div className="d-flex align-items-center">
                            {fileInfo.fileName}{` `}
                            <div className="d-flex justify-content-end mx-2">
                                {(fileInfo.totalFiles > 1) &&
                                    // <Badge pill className="badge-pill" bg='primary'><div className="badge-text" >+{fileInfo.totalFiles - 1}{` `}more</div></Badge>}
                                    <div className="badge-pill badge rounded-pill bg-primary"><div className="badge-text" >+{fileInfo.totalFiles - 1}{` `}more</div></div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="assign-modal-scroll-container">
                    <p><i></i><strong>AIRPORT DETAILS</strong></p>
                    {/* <p><i></i><strong>AIRPORT DETAILS</strong></p> */}
                    <div className="airport-detail">
                        <div className="row">
                            <label>Departure Airport</label>
                            <div className="col-16 mb-2">
                                <Typeahead
                                    onChange={(value) => {
                                        setAirportcode(value[0]?.toString());
                                    }}
                                    id="1"
                                    options={airportList.map((airport: any) => `${airport.airportCode}`)}
                                    className="assign-modal-text-input"
                                    placeholder="Departure Airport"
                                    defaultSelected={airportList.filter((airport: any) => airport.flag === 1).map((u: any) => u.airportCode)}
                                // selected={["sd"]}
                                />
                            </div>
                        </div>
                    </div>

                    <p><i></i><strong>FLIGHT DECK CREW</strong></p>
                    <div className="deck-crew">
                        <p><span>1</span>First Crew</p>
                        <div className="row">
                            <div className="col-6">
                                <label>Type</label>
                                <div className="pilot-type-dropdown-wrapper">
                                    <Dropdown className="dropdown-box">
                                        <Dropdown.Toggle variant="none" id="dropdown-basic" className="dropdown-toggle">
                                            {firstCrewType}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className="assign-modal-dropdown-menu-box">
                                            {props.pilotTypeListValue.map((value: any) => {
                                                return (<Fragment key={value.type_id}>
                                                    <Dropdown.Item href="" key={value.type_id} onClick={() => {
                                                        selectFirstCrewType(value.type_name)
                                                    }}>{value.type_name}</Dropdown.Item>
                                                    <Dropdown.Divider />
                                                </Fragment>)
                                            })}
                                        </Dropdown.Menu>

                                    </Dropdown>
                                </div>
                            </div>
                            <div className="col-10">
                                <label>Crew Name</label>
                                <div>
                                    <Typeahead
                                        onChange={(value) => {
                                            // console.log(`value: `, value);
                                            let id = (firstCrewType !== 'Flying-Instructor') ?               
                                            pilotListValue?.filter((u: any) => u.pilotName === value[0]).map((u: any) => u.pilotId)[0] :
                                            instructorListValue?.filter((u: any) => u.instructorName === value[0]).map((u: any) => u.instructorId)[0] ; 
                                            // console.log(`id: `, id)
                                            changeFirstCrewID(id);
                                        }}
                                        id="2"
                                        labelKey="pilotName"
                                        placeholder="Enter crew name"
                                        //options={pilotListValue}
                                        className="assign-modal-text-input"
                                        // renderMenu={(results, { newSelectionPrefix, paginationText, renderMenuItemChildren, ...menuProps }) => {
                                        //     return (
                                        //         <Menu {...menuProps}>
                                        //             {/* {(results.length < 3) && <button className="add-new-button">Add New Crew</button>} */}
                                        //             {results.map((result: any, index) => (
                                        //                 <MenuItem key={index} option={result} position={index}>
                                        //                     {result}
                                        //                 </MenuItem>
                                        //             ))}
                                        //         </Menu>
                                        //     )
                                        // }}
                                        // options={pilotListValue?.map((p: any) => `${p.pilotName}`)}
                                        options={(firstCrewType !== 'Flying-Instructor') ? pilotListValue?.map((p: any) => `${p.pilotName}`) : instructorListValue?.map((p: any) => `${p.instructorName}`)}
                                    />

                                </div>
                            </div>
                        </div>
                        <div className="row switch-buttons">
                            <div className="col-8 mb-3">
                                <Form.Check
                                    type="switch"
                                    id="first-crew-pilot-in-command"
                                    label="Pilot In Command"
                                    checked={pilotPIC}
                                    onChange={() => setPilotPIC(!pilotPIC)}
                                />
                            </div>
                            <div className="col-8">
                                <Form.Check
                                    type="switch"
                                    id="first-crew-solo-flying"
                                    label="Solo Flying"
                                    checked={!secondCrew}
                                    onChange={() => changeNumberofCrew(!secondCrew)}
                                />
                            </div>
                            {/* <div className="col-8">
                                <Form.Check
                                    type="switch"
                                    id="first-crew-checkride"
                                    label="Checkride"
                                    checked={pilotCheckride}
                                    onChange={() => setPilotCheckride(!pilotCheckride)}
                                />
                            </div> */}
                        </div>
                        <div className="row d-flex align-items-center second-crew-title me-1">
                            <p className="col-8"><span>2</span>Second Crew</p>

                            <div className="col-8 d-flex justify-content-end">
                                <Form.Check
                                    type="switch"
                                    id="second-crew"
                                    label={secondCrew ? null : "None"}
                                    checked={secondCrew}
                                    onChange={() => changeNumberofCrew(!secondCrew)} />
                            </div>
                        </div>
                        <div id="second-crew-details-form" className={secondCrew == false ? "collapse" : ""}>
                            <div className="row">
                                <div className="col-6">
                                    <label>Type</label>
                                    <div className="pilot-type-dropdown-wrapper">
                                        <Dropdown className="dropdown-box">
                                            <Dropdown.Toggle variant="none" id="dropdown-basic" className="dropdown-toggle">
                                                {secondCrewType}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className="assign-modal-dropdown-menu-box">
                                                {props.pilotTypeListValue.map((value: any) => {
                                                    return (<Fragment key={value.type_id}>
                                                        <Dropdown.Item href="" key={value.type_id} onClick={() => {
                                                            selectSecondCrewType(value.type_name);
                                                            // enableButton();
                                                        }}>{value.type_name}</Dropdown.Item>
                                                        <Dropdown.Divider />
                                                    </Fragment>)
                                                })}
                                            </Dropdown.Menu>
                                        </Dropdown>

                                    </div>

                                </div>
                                <div className="col-10">
                                    <label>Crew Name</label>
                                    <div>
                                        <Typeahead
                                            // onChange={(value) => {
                                            //     let id = pilotListValue.filter((u: any) => u.pilotName == value[0]).map((u: any) => u.pilotId)[0]
                                            //     changeSecondCrewID(id);

                                            // }}

                                            onChange={(value) => {
                                                // console.log(`value: `, value);
                                                // console.log(instructorListValue.filter((item:any) => item.instructorName === value[0]))
                                                let id = (secondCrewType !== 'Flying-Instructor') ?               
                                                pilotListValue.filter((u: any) => u.pilotName === value[0]).map((u: any) => u.pilotId)[0] :
                                                instructorListValue?.filter((u: any) => u.instructorName === value[0]).map((u: any) => u.instructorId)[0] ; 
                                                // console.log(`id: `, id)
                                                changeSecondCrewID(id);
                                            }}
                                            id="3"
                                            placeholder="Enter crew name"
                                            // renderMenu={(results, { newSelectionPrefix, paginationText, renderMenuItemChildren, ...menuProps }) => {
                                            //     return (
                                            //         <Menu {...menuProps}>
                                            //             {/* {(results.length < 3) && <button className="add-new-button">Add New Crew</button>} */}
                                            //             {results.map((result: any, index) => (
                                            //                 <MenuItem key={index} option={result} position={index}>
                                            //                     {result}
                                            //                 </MenuItem>
                                            //             ))}
                                            //         </Menu>
                                            //     )
                                            // }}
                                            // options={pilotListValue.map((p: any) => `${p.pilotName}`)}
                                            options={(secondCrewType !== 'Flying-Instructor') ? pilotListValue?.map((p: any) => `${p.pilotName}`) : instructorListValue?.map((p: any) => `${p.instructorName}`)}
                                            className="assign-modal-text-input"
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row switch-buttons">
                                <div className="col-8">
                                    <Form.Check
                                        type="switch"
                                        id="second-crew-pilot-in-command"
                                        label="Pilot In Command"
                                        checked={secPilotPIC}
                                        onChange={() => setSecPilotPIC(!secPilotPIC)}
                                    />
                                </div>
                                <div className="col-8">
                                    <Form.Check
                                        type="switch"
                                        id="second-crew-checkride"
                                        label="Checkride"
                                        checked={secPilotCheckride}
                                        onChange={() => setSecPilotCheckride(!secPilotCheckride)}
                                    />
                                </div>
                            </div> */}
                        </div>
                        <div className="m-2"></div>
                    </div>

                    <p className="pt-2"> <i></i> <strong> EXERCISE DETAILS </strong> </p>
                    <div className="airport-detail">
                        <div className="row">
                            <label>Exercise Type</label>
                            <div className="col-16 mb-2">
                                <Typeahead
                                    onChange={(value) => {
                                        let id = exerciseListValue?.filter((e: any) => e.excercise == value[0]).map((u: any) => u.id)[0]
                                        setExercisetype(id)
                                        //console.log(id)
                                    }}
                                    id="4"
                                    options={exerciseListValue?.map((e: any) => `${e.excercise}`)}
                                    className="assign-modal-text-input"
                                    placeholder="Exercise Type"
                                //defaultSelected={exerciseListValue.filter((exercise:any)=>airport.flag===1).map((u:any)=>u.airportCode)}
                                // selected={["sd"]}
                                />
                            </div>
                        </div>
                    </div>
                    <p className="mt-1"><i></i><strong>AIRCRAFT DETAILS</strong></p>
                    <div className="aircraft-details row">
                        <div className="col-8">
                            <label>Registration/Tail Number</label>
                            <div>
                                <Typeahead
                                    onChange={(value) => {
                                        let id = aircraftListValue.filter((u: any) => u.aircraftTailNo == value[0]).map((u: any) => u.aircraftId)[0]
                                        setAircraftID(id);
                                        let aircraftmodel = aircraftListValue.filter((u: any) => u.aircraftTailNo == value[0]).map((u: any) => u.aircraftModel)[0]
                                        setAircraftModel(aircraftmodel)
                                    }
                                    }
                                    defaultInputValue={aircraftModel}
                                    id="5"
                                    placeholder="Enter tail number"
                                    options={aircraftListValue.map((a: any) => `${a.aircraftTailNo}`)}
                                    renderMenu={(results, { newSelectionPrefix, paginationText, renderMenuItemChildren, ...menuProps }) => {
                                        return (
                                            <Menu {...menuProps}>
                                                {/* {(results.length < 3) && <button className="add-new-button">Add New Aircraft</button>} */}
                                                {results.map((result: any, index) => (
                                                    <MenuItem key={index} option={result} position={index}>
                                                        {result}
                                                    </MenuItem>
                                                ))}
                                            </Menu>
                                        )
                                    }}
                                    className="assign-modal-text-input"
                                />
                            </div>
                        </div>
                        <div className="col-8 d-flex justify-content-center align-items-end">
                            <input type="text" className="assign-aircraft-model w-100" placeholder="Airplane model" onChange={() => { }} value={aircraftModel || ""} />
                        </div>
                    </div>



                </div>
            </Modal.Body>
            <Modal.Footer className="assign-modal-footer">
                <Button variant="light border" onClick={hideModal}>Close</Button>
                <Button onClick={updateFileInfo} disabled={isDisabled}>Assign Details</Button>

            </Modal.Footer>
        </Modal>
    </>)
}

export default UpdateFileInfoModule;