import { radiantLine } from "./colorStrings"

/**
 * this function prints following elements in canvas
 * heading scale
 * wind speed and direction scale
 * CrsReading label
 * @param {any} canvasCtx - 2d canvas context
 * @param {any} canvasWidth - canvas width
 * @param {any} canvasHeight - canvas height
 * @param {number} scalingFactor - used to scale element image
 * @param {number} headReading - heading value
 * @param {number} windSpdReading - wind speed value
 * @param {number} windDirReading - wind direction value
 * @param {number} CrsReading - Crs value
 */
const headingAndGroup = (canvasCtx:any, canvasWidth:any, canvasHeight:any, scalingFactor:number, headReading:number, windSpdReading:number, windDirReading:number, CrsReading:number) =>{

    let eleHeight = 540 //element width
    let eleWidth = 850  //element height
    let isHalfHeadingScale = false  // for small height screens heading scale will be rendered as half circle  

    let topPos = canvasHeight - eleHeight - 56  // position of heading group element from canvas top
    let leftPos = (canvasWidth - eleWidth) / 2  // position of heading group element always horizontaly in centre of canvas

    if(canvasHeight / scalingFactor < 800){ //for small screens heading scale will be half circle
        isHalfHeadingScale = true
    }

    canvasCtx.save()
    if(isHalfHeadingScale){ //if heading scale is half circle then adjust its position from canvas top
        topPos = canvasHeight - eleHeight - 56 + 208
        canvasCtx.beginPath()
        canvasCtx.rect(leftPos, topPos, eleWidth, eleHeight-208)
        //canvasCtx.stroke()
        canvasCtx.closePath()
        canvasCtx.clip()
    }
    
    canvasCtx.beginPath()
    canvasCtx.rect(leftPos, topPos, eleWidth, eleHeight)
    //canvasCtx.stroke()
    //canvasCtx.fill()
    canvasCtx.closePath()

    //position center
    headingScale(canvasCtx, leftPos+(eleWidth/2), topPos+48, headReading)                                                //horizotal position in centre of canvas
    //localizer(canvasCtx, leftPos+(eleWidth/2), topPos+0)                                                               // left position as parent centre
    windSpdDir(canvasCtx, leftPos, topPos, windSpdReading, windDirReading)
    CrsReadingLabel(canvasCtx, leftPos, topPos, CrsReading)
    canvasCtx.restore()
}

/**
 * this function prints heading scale
 * @param {any} canvasCtx - 2D canvas context
 * @param {any} poseX - X coordinate position
 * @param {any} poseY - Y cordinate position
 * @param {number} headReading - heading value
 */
const headingScale = (canvasCtx:any, poseX:any, poseY:any, headReading:number) =>{

    let eleWidth = 416
    let eleHeight = 492

    //box for head reading

    canvasCtx.beginPath()
    canvasCtx.fillStyle = "rgba(18, 18, 18, 0.46)";
    canvasCtx.roundRect(poseX-50, poseY, 100, 52, [10, 10, 10, 10])
    canvasCtx.fill()
    canvasCtx.closePath()

    //traingle below head reading box
    canvasCtx.beginPath()
    canvasCtx.fillStyle = "#fff"
    canvasCtx.moveTo(poseX-12, poseY+50)
    canvasCtx.lineTo(poseX+12, poseY+50)
    canvasCtx.lineTo(poseX, poseY+74)
    canvasCtx.lineTo(poseX-12, poseY+50)
    canvasCtx.fill()
    canvasCtx.stroke()
    canvasCtx.closePath() 



    canvasCtx.beginPath()
    canvasCtx.fillStyle = "#fff"
    canvasCtx.font = "600 40px Arial"
    //to print heading value horizontaly in center of label for single digit two digit or three digit number
    let headReadingRound = Math.round(headReading*180/Math.PI)
    if(headReadingRound<=9){
        canvasCtx.fillText(headReadingRound+"°", poseX-20, poseY+40)
    }else if(headReadingRound<=99 && headReadingRound>9){
        canvasCtx.fillText(headReadingRound+"°", poseX-28, poseY+40)
    }else{
        canvasCtx.fillText(headReadingRound+"°", poseX-40, poseY+40)
    }
    canvasCtx.fill()
    canvasCtx.closePath()

    canvasCtx.beginPath()
    canvasCtx.rect(poseX-(eleWidth/2), poseY, eleWidth, eleHeight)
    //canvasCtx.stroke()
    canvasCtx.closePath()

    canvasCtx.beginPath()
    canvasCtx.fillStyle = "rgba(18, 18, 18, 0.48)";
    //canvasCtx.fillStyle = "red"
    canvasCtx.arc(poseX, poseY+eleHeight-208, 208, 0, 2 * Math.PI);
    canvasCtx.fill()
    canvasCtx.closePath()


    canvasCtx.save()
    canvasCtx.translate(poseX, poseY+eleHeight-208)
    canvasCtx.rotate(-headReading)
    canvasCtx.translate(-poseX,  -poseY-eleHeight+208)
    //canvasCtx.restore()
    //canvasCtx.save()

    //for center cros lines
    radiantLine(canvasCtx, poseX, poseY+eleHeight-208, 0, 20, 0, 2, "#fff")
    radiantLine(canvasCtx, poseX, poseY+eleHeight-208, 0, 45, 90, 2, "#fff")
    radiantLine(canvasCtx, poseX, poseY+eleHeight-208, 0, 20, 180, 2, "#fff")
    radiantLine(canvasCtx, poseX, poseY+eleHeight-208, 0, 45, 270, 2, "#fff")

    //for radial major and minor scale lines
    for(let i=0; i<=36; i++){
        radiantLine(canvasCtx, poseX, poseY+eleHeight-208, 200, 208, i*10+5, 2, "#fff")
        radiantLine(canvasCtx, poseX, poseY+eleHeight-208, 192, 208, i*10, 2, "#fff")
    }
    
    // for 'N', 'E', 'W', 'S' alphabets on heading compass
    let textAngle = 0
    while(textAngle < 360){
        if(textAngle===0){
            rotateText2(canvasCtx, "N", textAngle, poseX, poseY+eleHeight-208)
            textAngle += 30
            continue
        }else if(textAngle===90){
            rotateText2(canvasCtx, "E", textAngle, poseX, poseY+eleHeight-208)
            textAngle += 30
            continue
        }else if(textAngle===180){
            rotateText2(canvasCtx, "S", textAngle, poseX, poseY+eleHeight-208)
            textAngle += 30
            continue
        }else if(textAngle===270){
            rotateText2(canvasCtx, "W", textAngle, poseX, poseY+eleHeight-208)
            textAngle += 30
            continue
        }

        rotateText2(canvasCtx, textAngle.toString(), textAngle, poseX, poseY+eleHeight-208)
        textAngle += 30

    }

    canvasCtx.restore()
}


/**
 * prints radial text and numbers on heading compass
 * @param {any} canvasCtx - 2d canvas context
 * @param {string} text - text to print
 * @param {number} angle - angle for text axis
 * @param {any} poseX - X coordinate of centre point of circle
 * @param {any} poseY - Y coordinate of center  point of circle
 */
const rotateText2 = (canvasCtx:any, text:string, angle:number, poseX:any, poseY:any ) => {
    canvasCtx.save()
    canvasCtx.beginPath()
    canvasCtx.fillStyle = "#fff"
    canvasCtx.font = "28px Arial";
    //canvasCtx.save();
    canvasCtx.translate(poseX, poseY);

    //if text length is greater than 2
    if(text.length > 2){
        canvasCtx.rotate((angle) * Math.PI/180)
    }else{
        canvasCtx.rotate(angle * Math.PI/180)
    }
    canvasCtx.textAlign = "center";
    canvasCtx.translate(0, -160);
    canvasCtx.fillText(text, 0, 0);
    canvasCtx.closePath()
    canvasCtx.restore();
}

/**
 * this function prints label with wind speed and wind direction
 * @param {any} canvasCtx - 2d canvas context
 * @param {number} leftPos - position from left 
 * @param {number} topPos - position from right
 * @param {number} windSpdReading - wind speed value
 * @param {number} windDirReading - wind direction value
 */
const windSpdDir = (canvasCtx:any, leftPos:number, topPos:number, windSpdReading:number, windDirReading:number) =>  {

    //rectangle for label
    canvasCtx.beginPath()
    canvasCtx.fillStyle = "rgba(18, 18, 18, 0.48)";
    canvasCtx.roundRect(leftPos+100, topPos, 120, 80, [16, 16, 16, 16])
    canvasCtx.fill()
    canvasCtx.closePath()

    canvasCtx.save()
    canvasCtx.beginPath()
    //rotate and translate context to origin
    canvasCtx.translate(leftPos+140, topPos+37)
    canvasCtx.rotate(windDirReading)
    canvasCtx.translate(-leftPos-140, -topPos-37)

    canvasCtx.fillStyle = "#fff";
    let arrowCenterX = leftPos+140
    let arrowCenterY = topPos+37

    // print arrow
    canvasCtx.moveTo(arrowCenterX, arrowCenterY-16)
    canvasCtx.lineTo(arrowCenterX+10, arrowCenterY+0)
    canvasCtx.lineTo(arrowCenterX+5, arrowCenterY+0)
    canvasCtx.lineTo(arrowCenterX+5, arrowCenterY+16)
    canvasCtx.lineTo(arrowCenterX-5, arrowCenterY+16)
    canvasCtx.lineTo(arrowCenterX-5, arrowCenterY+0)
    canvasCtx.lineTo(arrowCenterX-10, arrowCenterY+0)
    canvasCtx.lineTo(arrowCenterX, arrowCenterY-16)

    canvasCtx.fill()
    canvasCtx.closePath()
    canvasCtx.restore()

    //print wind speed number
    canvasCtx.beginPath()
    canvasCtx.font = "500 28px Arial"
    canvasCtx.fillStyle = "#fff";
    canvasCtx.fillText(windSpdReading.toFixed(1), leftPos+160, topPos+47)
    canvasCtx.fill()
    canvasCtx.closePath()

}

/**
 * this function prints Crs label and Crs value on canvas
 * @param {any} canvasCtx - 2d canvas context
 * @param {number} leftPos - position of parent from left of canvas
 * @param {number} topPos - position of  from top
 * @param {number} CrsReading - Crs value
 */
const CrsReadingLabel = (canvasCtx:any, leftPos:number, topPos:number, CrsReading:number) => {
    //CRS label
    canvasCtx.beginPath()
    canvasCtx.fillStyle = "rgba(18, 18, 18, 0.48)";
    canvasCtx.roundRect(leftPos+552, topPos+100, 126, 48, [10, 10, 10, 10])
    canvasCtx.fill()
    canvasCtx.closePath()

    //Crs text and value
    canvasCtx.beginPath()
    canvasCtx.fillStyle = "#fff";
    canvasCtx.font = "600 24px Arial"
    canvasCtx.fillText("CRS", leftPos+560, topPos+132)
    canvasCtx.fill()
    canvasCtx.closePath()

    canvasCtx.beginPath()
    canvasCtx.fillStyle = "#ce63f7";
    canvasCtx.font = "600 24px Arial"
    canvasCtx.fillText(Math.round(CrsReading*180/Math.PI)+"°", leftPos+620, topPos+132)
    canvasCtx.fill()
    canvasCtx.closePath()
}

export default headingAndGroup