import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Navigate } from "react-router-dom";
import "./App.scss";
import { getLocalStorage } from "./common/utils/localStorageHandler";
import Login from "./pages/authenticate/Login";
import { SetAppTheme, ThemeProvider } from "./context/themeContext";
import Dashboard from "./pages/dashboard/Dashboard";
import Flights from "./pages/dashboard/components/flights/Flights";
import Import from "./pages/dashboard/components/import/Import";
import FlyingDutyTime from "./pages/dashboard/components/flyingDutyTime/FlyingDutyTime";
import Reports from "./pages/dashboard/components/reports/Reports";
import Settings from "./pages/dashboard/components/settings/Settings";
import Debrief from "./pages/dashboard/components/debrief/Debrief";
import Notfound from "./pages/extrapages/Notfound";
import Logincomp from "./pages/authenticate/components/Logincomp/Logincomp";
import Resetpass from "./pages/authenticate/components/resetpass/Resetpass";
import PrivacyPolicy from "./pages/authenticate/components/Logincomp/PrivacyPolicy";
import { useAuth } from "./context/authProvider";
import Protected from "./services/Protected";
import FlyingInstructorsReport from "./pages/dashboard/components/reports/components/flyingReport/FlyingInstructorsReport";
import AuditReport from "./pages/dashboard/components/reports/components/auditReport/AuditRport";
import EGCAUploadReport from "./pages/dashboard/components/reports/components/auditReport/EGCAUploadReport";
import EventReport from "./pages/dashboard/components/reports/components/dataAnalysis/EventReport";
import SummaryReport from "./pages/dashboard/components/reports/components/dataAnalysis/SummaryReport";
import AircraftsReport from "./pages/dashboard/components/reports/components/flyingReport/AircraftsReport";
import PilotsReport from "./pages/dashboard/components/reports/components/flyingReport/PilotsReport";
import TabPfd from "./pages/dashboard/components/specialTabs/TabPfd";
import TabFlightPath from "./pages/dashboard/components/specialTabs/TabFlightPath";
import TabGraph from "./pages/dashboard/components/specialTabs/TabGraph";
import TabPhaseGraphs from "./pages/dashboard/components/specialTabs/TabPhaseGraphs";
import General from "./pages/dashboard/components/settings/routedComponents/General";
import MyFleet from "./pages/dashboard/components/settings/routedComponents/MyFleet";
import Billing from "./pages/dashboard/components/settings/routedComponents/Billing";
import Company from "./pages/dashboard/components/settings/routedComponents/Company";
import Compliance from "./pages/dashboard/components/settings/routedComponents/Compliance";
import MembersAndRoles from "./pages/dashboard/components/settings/routedComponents/MembersAndRoles";
import Notification from "./pages/dashboard/components/settings/routedComponents/Notification";
import PilotsAndInstructors from "./pages/dashboard/components/settings/routedComponents/PilotsAndInstructors";
import Security from "./pages/dashboard/components/settings/routedComponents/Security";
import TagsAndGroups from "./pages/dashboard/components/settings/routedComponents/TagsAndGroups";

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en", "ar", "fr"],
    fallbackLng: "en",
    debug: false,
    // Options for language detector
    detection: {
      order: ["querystring", "cookie", "navigator", "path", "htmlTag"],
      caches: ["cookie", "localStorage"],
    },
    // react: { useSuspense: false },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
  });

function App() {
  const authData = useAuth();
  const authToken = getLocalStorage("authTokens", "");
  // console.log(`authToken: `, authToken);
  const [pathToLoad, SetPathToLoad] = useState("/dashboard");
  
  // const [anyLogsUploaded, setAnyLogsUploaded] = useState(() => {
  //   if (authToken) {
  //     DebriefService.getAllLogs().then((response) => {
  //       if (response.data.length !== 0) {
  //         console.log(`Inside IF`);
  //         return (true);
  //       } else {
  //         console.log(`Inside ELSE`);
  //         return (false);
  //       }
  //     });
  //   }
  // });

  // kashishlodha78@gmail.com

  // let anyLogsUploaded = true;

  // const getAnyLogsUploaded = async () => {
  //   if (authToken) {
  //     DebriefService.getAllLogs().then((response) => {
  //       if (response.data.length !== 0) {
  //         // console.log(`Inside IF`);
  //         return true;
  //       } else {
  //         // console.log(`Inside ELSE`);
  //         return false;
  //       }
  //     });
  //   }
  // };

  // (async () => {
  //   anyLogsUploaded = await getAnyLogsUploaded();
  //   // console.log(`anyLogsUploaded: `, anyLogsUploaded);
  // })();

  
  return (
    // <Suspense>
    <ThemeProvider>
      <Router>
        <Routes>

          <Route path="/privacy_policy" element={<PrivacyPolicy />} />

          <Route path="/" element={<Login />}>
            <Route path="/" element={<Logincomp path={pathToLoad} />} />
            <Route path="/reset-password" element={<Resetpass />} />
          </Route>

          <Route path="/:client" element={<Login />}>
            <Route path="" element={<Logincomp />} />
            <Route path="reset-password" element={<Resetpass />} />
          </Route>

          <Route
            path="/dashboard"
            element={
              <Protected setPath={SetPathToLoad}>
                <Dashboard />
              </Protected>
            }
          >
            <Route index element={<Navigate to="flights" />} />
            {/* <Route index element={<Navigate to="debrief" />} /> */}
            {/* <Route index element={<Navigate to="import" />} /> */}
            {/* {anyLogsUploaded && (
              <Route index element={<Navigate to="flights" />} />
            )} */}
            {/* {!anyLogsUploaded && (
              <Route index element={<Navigate to="import" />} />
            )} */}
            <Route path="debrief" element={<Debrief />} />
            <Route path="flights" element={<Flights />} />
            <Route path="import" element={<Import />} />

            <Route path="flying_duty_time" element={<FlyingDutyTime />} />
            <Route path="reports" element={<Reports />}>
              <Route index element={<FlyingInstructorsReport />} />
              <Route
                path="flying_instructors_report"
                element={<FlyingInstructorsReport />}
              />
              <Route path="audit_report" element={<AuditReport />} />
              <Route path="egca_upload_report" element={<EGCAUploadReport />} />
              <Route path="event_report" element={<EventReport />} />
              <Route path="summary_report" element={<SummaryReport />} />
              <Route path="aircrafts_report" element={<AircraftsReport />} />
              <Route path="pilots_report" element={<PilotsReport />} />
            </Route>

            {/* <Route path="settings" element={<Settings />} > */}
            {/* <Route index element = {<General />} ></Route> */}
            <Route
              path="settings"
              element={
                authToken?.roleid === 1 ? <Settings /> : <RestrictedSettings />
              }
            >
              <Route index element={<MyFleet />}></Route>
              <Route path="general" element={<General />}></Route>
              <Route path="my_fleet" element={<MyFleet />}></Route>
              <Route path="billing" element={<Billing />}></Route>
              <Route path="company" element={<Company />}></Route>
              <Route path="compliance" element={<Compliance />}></Route>
              <Route
                path="members_and_roles"
                element={<MembersAndRoles />}
              ></Route>
              <Route path="notification" element={<Notification />}></Route>
              <Route
                path="pilots_and_instructors"
                element={<PilotsAndInstructors />}
              ></Route>
              <Route path="security" element={<Security />}></Route>
              <Route path="tags_and_groups" element={<TagsAndGroups />}></Route>
            </Route>
          </Route>

          <Route
            path="/:client/dashboard"
            element={
              <Protected>
                <Dashboard />
              </Protected>
            }
          >
            <Route index element={<Navigate to="debrief" />} />
            <Route path="debrief" element={<Debrief />} />
            <Route path="flights" element={<Flights />} />
            <Route path="import" element={<Import />} />
            <Route path="flying_duty_time" element={<FlyingDutyTime />} />
            <Route path="reports" element={<Reports />} />
            <Route path="settings" element={<Settings />} />
          </Route>

          <Route path="/special-tabs">
            <Route path="pfd" element={<TabPfd />} />
            <Route path="flight-path" element={<TabFlightPath />} />
            <Route path="graph" element={<TabGraph />} />
            <Route path="phaseGraph/:data" element={<TabPhaseGraphs />} />
          </Route>

          <Route path="/*" element={<Notfound />} />
        </Routes>
      </Router>
    </ThemeProvider>
    // </Suspense>
  );
}

function RestrictedSettings() {
  return <Navigate to="/dashboard" replace />;
}

export default App;
