import { useState } from "react";

/**
 * Custom React hook for handling local storage with a default value.
 * @param {any} keyName - The key for the item to be stored/retrieved from local storage.
 * @param {any} defaultValue - The default value to use if the key is not found in local storage.
 * @returns {Array} - An array containing the stored value and a function to set a new value.
 */
export const useLocalStorage = (keyName: any, defaultValue: any) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = localStorage.getItem(keyName);
      if (value) {
        return JSON.parse(value);
      } else {
        localStorage.setItem(keyName, JSON.stringify(defaultValue));
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });

  /**
   * Sets a new value in local storage and updates the state.
   * @param {any} newValue - The new value to be stored.
   */
  const setValue = (newValue: any) => {
    try {
      localStorage.setItem(keyName, JSON.stringify(newValue));
    } catch (err) { }
    setStoredValue(newValue);
  };

  return [storedValue, setValue];
};
