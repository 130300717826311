import { useState, useRef, useMemo, useEffect } from "react";
import "./RoutedComponents.scss";
import {
  useBlockLayout,
  useColumnOrder,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { useSticky } from "react-table-sticky";
import StickyTable from "../../../../../common/components/StickyTable/StickyTable";
import {
  Accordion,
  Modal,
  OverlayTrigger,
  Popover,
  Dropdown,
  Card,
} from "react-bootstrap";
import { Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";
import ColumnSettings from "../../../../../common/components/ColumnSettings/ColumnSettings";
import AircraftService from "../../../../../services/aircraft.service";
import ReactivateAccountModal from "./Modals/ReactivateAccountModal";
import DeactivateAccountModal from "./Modals/DeactivateAccountModal";
import { getLocalStorage } from "../../../../../common/utils/localStorageHandler";
import CustomToast from "../../../../../common/components/Toast/CustomToast";
import React from "react";
import NoResultsFound from "../../debrief/NoResultsFound";
import { isNonNullChain } from "typescript";

const MyFleet = () => {
  const dummyAircraftData: any = [];

  // const dummyAircraftData = [
  //     {
  //         'tailNumber': 'VJ-131',
  //         'model': 'Cessna 172 R',
  //         'airtime': '18 hr 4 min',
  //         'noOfFlights': 23,
  //         'endTimestamp': null,
  //         'status': 0,
  //     }
  // ]

  const [data, setData] = useState<any[]>([]);
  const [responseDataCopy, setResponseDataCopy] = useState([]);
  const [showReactivateAccountModal, setShowReactivateAccountModal] =
    useState(false);
  const [showDeactivateAccountModal, setShowDeactivateAccountModal] =
    useState(false);
  const [currentRowSelectedData, setCurrentRowSelectedData] = useState<any>();
  const [apiCaller, setApiCaller] = useState(true);
  const [menuOpen, setMenuOpen] = useState<boolean[]>([]);
  const popoverRef = useRef<HTMLDivElement>(null);
  const [isAnyTrue, setIsAnyTrue] = useState(false);
  const [nameToPass, setNameToPass] = useState("");
  const [forcefulRerender, setForcefulRerender] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchText, setSearchText] = useState("");
  const [addAircraftModalOpen, setAddAircraftModalOpen] = useState(false);
  const [columnSettingsModalShow, setColumnSettingsModalShow] = useState(false);
  const [filterModalShow, setFilterModalShow] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<number>(NaN);
  const [aircraftModels, setAircraftModels] = useState<string[]>([]);
  const [selectedMake, setSelectedMake] = useState<string>("");
  const [uploadedModelsList, setUploadedModelsList] = useState<string[]>([]);
  const [aircraftRegNo, setAircraftRegNo] = useState("");
  const [aircraftType, setAircraftType] = useState([]);
  const [aircraftModelInput, setAircraftModelInput] = useState("");
  const [aircraftEngineType, setAircraftEngineType] = useState([]);

  const [rotationSpeed, setRotationSpeed] = useState<any>(null);
  const [stallSpeed, setStallSpeed] = useState<any>(null);
  const [cleanStallSpeed, setCleanStallSpeed] = useState<any>(null);
  const [bestAngleOfClimbSpeed, setBestAngleOfClimbSpeed] = useState<any>(null);
  const [touchdownSpeed, setTouchdownSpeed] = useState<any>(null);
  const [referenceLandingSpeed, setReferenceLandingSpeed] = useState<any>(null);
  const [velocityNeverExceedSpeed, setVelocityNeverExceedSpeed] = useState<any>(null);
  const [maxTaxiGroundSpeed, setMaxTaxiGroundSpeed] = useState<any>(null);
  const [takeoffDecisionSpeed, setTakeoffDecisionSpeed] = useState<any>(null);
  const [takeoffSafetySpeed, setTakeoffSafetySpeed] = useState<any>(null);
  const [maxFlapExtensionSpeed, setMaxFlapExtensionSpeed] = useState<any>(null);
  const [maxLandingGearExtensionSpeed, setMaxLandingGearExtensionSpeed] = useState<any>(null);
  const [maxLandingGearOperationSpeed, setMaxLandingGearOperationSpeed] = useState<any>(null);

  const [toastVisibleSave, setToastVisibleSave] = useState(false);
  const [toastVisibleDeactivate, setToastVisibleDeactivate] = useState(false);
  const [toastVisibleReactivate, setToastVisibleReactivate] = useState(false);

  const anyMenuOpenRef = useRef<boolean>(false);
  const menuIconRef = useRef<any>(null);
  const filterPopoverRef = useRef<any>(null);
  const filterPopoverBodyRef = useRef<any>(null);
  const [noResultsFound, setNoResultsFound] = useState(false);

  const processDataForTable = (input: any) => {
    if (input) {
      let processedData = input.map((item: any, index: number) => ({
        ...item,
        endTimestamp: convertDateData(item.endTimestamp),
        airtime: !item.airtime ? `-` : item.airtime,
        noOfFlights: !item.noOfFlights ? `-` : item.noOfFlights,
        // status: getAircraftStatus(item.status, index, item)
      }));
      return processedData;
    }
    return [];
  };

  useEffect(() => {
    AircraftService.getOrgAircraftInfo().then((res: any) => {
      setResponseDataCopy(res.data);
      setData(() => processDataForTable(res.data));
      setUploadedModelsList(() =>
        Array.from(new Set(res.data.map((item: any) => item.model)))
      );
    });

    // setData(() => processDataForTable(dummyAircraftData));
  }, [apiCaller]);

  const convertDateData = (dateTimeString: string) => {
    if (dateTimeString?.includes(`2050-01-01`) || dateTimeString === null) {
      return `-`;
    }

    const date = new Date(dateTimeString);

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const day = date.getDate().toString().padStart(2, "0");
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    const formattedDateTime = `${day} ${month} ${year}, ${hours}:${minutes} IST`;

    return formattedDateTime;
  };

  const onClickMenu = (index: any, rowData: any) => {
    // console.log(`Row Index `, index);
    // console.log(`Row Data: `, rowData);

    // console.log(`Before init: `, menuOpen)

    const initState = menuOpen[index];
    // console.log(`Init state: `, initState)
    let updatedMenuOpen = menuOpen.map(() => false);
    if (!initState) {
      // console.log(`inside if`)
      setIsAnyTrue(true);
    }
    updatedMenuOpen[index] = !initState;
    setMenuOpen(updatedMenuOpen);
  };

  // const handleClick = (event: any) => {
  //   if (event.type === "click") {
  //     const isClickOnMenuIcon =
  //       (event.target as HTMLElement).className ===
  //       menuIconRef.current?.className;
  //     const isClickOnFilterButton =
  //       (event.target as HTMLElement).className ===
  //       filterPopoverRef.current?.className;
  //     const isClickInsideFilterBody = filterPopoverBodyRef.current?.contains(
  //       event.target as HTMLElement
  //     );
  //     if (anyMenuOpenRef.current && !isClickOnMenuIcon) {
  //       anyMenuOpenRef.current = false;
  //       let updatedMenuOpen = menuOpen.map(() => false);
  //       setMenuOpen(updatedMenuOpen);
  //     }
  //     if (!isClickOnFilterButton && !isClickInsideFilterBody) {
  //       setFilterModalShow(false);
  //     }
  //   }
  // };

  const handleClick = (event: any) => {
    if (event.type === "click") {
      // Check if the click is on the menu icon or any of its children
      const isClickOnMenuIcon = menuIconRef.current?.contains(event.target as HTMLElement);
  
      // Check if the click is on the filter button or any of its children
      const isClickOnFilterButton = filterPopoverRef.current?.contains(event.target as HTMLElement);
  
      // Check if the click is inside the filter body
      const isClickInsideFilterBody = filterPopoverBodyRef.current?.contains(event.target as HTMLElement);
  
      if (anyMenuOpenRef.current && !isClickOnMenuIcon) {
        anyMenuOpenRef.current = false;
        let updatedMenuOpen = menuOpen.map(() => false);
        setMenuOpen(updatedMenuOpen);
      }
  
      if (!isClickOnFilterButton && !isClickInsideFilterBody) {
        setFilterModalShow(false);
      }
    }
  }; 

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const changeStatus = (index: number, rowData: any) => {
    setCurrentRowSelectedData(rowData);
    setNameToPass(rowData.tailNumber);

    if (rowData.status === 0) {
      setShowDeactivateAccountModal(true);
      const initState = menuOpen[index];
      let updatedMenuOpen = menuOpen.map(() => false);
      updatedMenuOpen[index] = !initState;
      setMenuOpen(updatedMenuOpen);
    } else {
      setShowReactivateAccountModal(true);
      const initState = menuOpen[index];
      let updatedMenuOpen = menuOpen.map(() => false);
      updatedMenuOpen[index] = !initState;
      setMenuOpen(updatedMenuOpen);
    }
  };

  useEffect(() => {
    setApiCaller(!apiCaller);
  }, [forcefulRerender]);

  const onClickDeactivate = () => {
    let obj = {
      tailNo: currentRowSelectedData?.tailNumber,
      status: 1,
    };
    AircraftService.updateAircraftStatus(obj)
      .then((response) => {
        // console.log(`response: `, response);
        console.log(`Deactivating Aircraft`);
        setToastVisibleDeactivate(true);
        setApiCaller(!apiCaller);
      })
      .catch((error) => {
        console.error(`Error changing aircraft status:`, error);
      });
    setApiCaller(!apiCaller);
    setForcefulRerender(!forcefulRerender);
  };

  const onClickReactivate = () => {
    let obj = {
      tailNo: currentRowSelectedData?.tailNumber,
      status: 0,
    };
    AircraftService.updateAircraftStatus(obj)
      .then((response) => {
        // console.log(`response: `, response);
        console.log(`Re-activating Aircraft`);
        setToastVisibleReactivate(true);
        setApiCaller(!apiCaller);
      })
      .catch((error) => {
        console.error(`Error changing aircraft status:`, error);
      });
    setApiCaller(!apiCaller);
    setForcefulRerender(!forcefulRerender);
  };

  /**
   * Handles the change event for the search input, updating the search text and applying filters based on the search type.
   * @param {Object} e - The event object.
   * @returns {void}
   */
  const onSearchInputChange = (e: any) => {
    setSearchText(e.target.value);
    setFilter("tailNumber", e.target.value);
  };

  const showAddAircraftModal = () => setAddAircraftModalOpen(true);
  const hideAddAircraftModal = () => setAddAircraftModalOpen(false);

  const columns: any = useMemo(
    () => [
      {
        id: `tailNumber`,
        Header: `Registration No.`,
        accessor: `tailNumber`,
        isFixed: true,
        isDisplayed: true,
        sticky: `left`,
        maxWidth: 1,
      },
      {
        id: `model`,
        Header: `Make`,
        accessor: `model`,
        isFixed: false,
        isDisplayed: true,
        maxWidth: 1,
      },
      {
        id: `airtime`,
        Header: `Total Flying Hrs`,
        accessor: `airtime`,
        isFixed: false,
        isDisplayed: true,
        maxWidth: 1,
      },
      {
        id: `noOfFlights`,
        Header: `No Of Flights`,
        accessor: `noOfFlights`,
        isFixed: false,
        isDisplayed: true,
        maxWidth: 1,
      },
      {
        id: `endTimestamp`,
        Header: `Last Flight Details`,
        accessor: `endTimestamp`,
        isFixed: false,
        isDisplayed: true,
        // maxWidth: 1,
      },
      {
        id: `status`,
        Header: `Status`,
        Cell: (data: any) => {
          // console.log(`CELL menuOpen: `, menuOpen);

          if (menuOpen.some((value) => value === true)) {
            setTimeout(() => {
              anyMenuOpenRef.current = true;
            }, 100);
          }

          const index = data.row?.index;
          const rowData = data.row?.original;

          let buttonText =
            rowData.status === 0
              ? "Deactivate Aircraft"
              : "Reactivate Aircraft";

          const popover = (
            <Popover id={`popover-status-${index}`} ref={popoverRef}>
              <Card style={{ backgroundColor: `white` }}>
                <div style={{ backgroundColor: `white` }}>
                  <Accordion.Item eventKey="1">
                    <Card.Header style={{ backgroundColor: `white` }}>
                      <button
                        onClick={() => changeStatus(index, rowData)}
                        className="btn btn-light"
                        style={{
                          color: rowData.status === 0 ? "#c1251a" : "#31993f",
                        }}
                      >
                        {buttonText}
                      </button>
                    </Card.Header>
                  </Accordion.Item>
                </div>
              </Card>
            </Popover>
          );

          return (
            <div className="row">
              <div className="col">
                <div
                  className="status-pill badge"
                  style={
                    rowData.status !== 0
                      ? {
                          border: `1px solid #c1251a`,
                          backgroundColor: `rgba(193, 37, 26, 0.16)`,
                          width: `110px`,
                        }
                      : { width: `110px` }
                  }
                >
                  <div
                    className="status-text"
                    style={
                      rowData.status !== 0
                        ? { color: `#c1251a` }
                        : { color: `#31993f` }
                    }
                  >
                    {rowData.status === 0 ? `Active` : `Deactivated`}
                  </div>
                </div>
              </div>
              <div className="col pt-1">
                <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  show={menuOpen[index] || false}
                  overlay={popover}
                >
                  <i
                    className="menu-icon"
                    ref={menuIconRef}
                    onClick={() => onClickMenu(index, rowData)}
                  ></i>
                </OverlayTrigger>
              </div>
            </div>
          );
        },
        accessor: `status`,
        isFixed: true,
        isDisplayed: true,
        sticky: `right`,
        // maxWidth: 1,
      },
    ],
    [menuOpen, data, apiCaller]
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "tailNumber",
            desc: false,
          },
        ],
      },
    },
    useColumnOrder,
    useFilters,
    useGlobalFilter,
    useSortBy,
    useBlockLayout,
    usePagination,
    useSticky
  );

  const { setFilter, allColumns, setColumnOrder } = tableInstance;

  /**
   * Handles the application of column settings by updating the order of columns.
   * @param {Array} columnData - The column data containing information about each column.
   * @returns {void}
   */
  const onColumnSettingApply = (columnData: any) => {
    // console.log(`columnData: `, columnData)
    // Extracts the order of columns from the column data
    const orderOfColumns = columnData.map((column: any) => {
      return column.id;
    });

    // Calls the changeOrder function to update the order of columns
    changeOrder(orderOfColumns);

    // Displays a success toast notification
    // toast.success('Columns updated successfully', {
    //     position: "bottom-center",
    //     autoClose: 2000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: 0,
    //     theme: "light",
    // });

    // Closes the modal
    setColumnSettingsModalShow(false);
  };

  /**
   * Updates the order of columns based on the provided order array.
   * @param {Array} order - The new order of columns.
   * @returns {void}
   */
  const changeOrder = (order: any[]) => {
    setColumnOrder(order);
  };

  useEffect(() => {
    AircraftService.getAircraftModelList().then((response: any) => {
      // console.log(`Models: `, response.data.model)
      setAircraftModels(response.data.model);
    });
  }, []);

  const resetFilter = () => {
    setSelectedStatus(NaN);
    setSelectedMake("");
    setNoResultsFound(false);
    setFilterModalShow(false);
    setStatusFilterChecked(false);
    setMakeFilterChecked(false);
    setData(() => processDataForTable(responseDataCopy));
  };

  const applyFilter = () => {
    setNoResultsFound(false);
    let unfilteredData = responseDataCopy.map((item: any) => ({ ...item })); //creating a deep copy

    let filteredData = unfilteredData;

    if ((statusFilterChecked && selectedStatus) || selectedStatus === 0) {
      filteredData = unfilteredData.filter(
        (item: any) => item.status === selectedStatus
      );
    }
    if (makeFilterChecked && selectedMake) {
      filteredData = unfilteredData.filter(
        (item: any) => item.model === selectedMake
      );
    }

    if (filteredData && filteredData.length === 0) {
      setNoResultsFound(true);
    }

    setData(processDataForTable(filteredData));
    setFilterModalShow(false);
  };

  const [statusFilterChecked, setStatusFilterChecked] = useState(false);
  const [makeFilterChecked, setMakeFilterChecked] = useState(false);

  const filterPopoverElement = (
    <Popover>
      <div className="filter-box" ref={filterPopoverBodyRef}>
        <Accordion>
          <div className="scroll-div">
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <input
                  type="checkbox"
                  checked={statusFilterChecked}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    setStatusFilterChecked((prevState) => !prevState);
                  }}
                />{" "}
                Status
              </Accordion.Header>
              <Accordion.Body>
                <Dropdown className="dropdown-box">
                  <Dropdown.Toggle
                    variant="none"
                    id="dropdown-basic"
                    className="dropdown-toggle"
                  >
                    <span className="debrieftype-text">
                      {isNaN(selectedStatus)
                        ? "Select status"
                        : selectedStatus === 0
                        ? "Active"
                        : "Deactivated"}
                    </span>
                  </Dropdown.Toggle>

                  {/* <Dropdown.Menu className="dropdown-menu-box shadow"> */}
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => setSelectedStatus(0)}
                      style={{ color: `#31993f`, textAlign: `center` }}
                    >
                      Active
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      onClick={() => setSelectedStatus(1)}
                      style={{ color: `#c1251a`, textAlign: `center` }}
                    >
                      Deactivated
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <input
                  type="checkbox"
                  checked={makeFilterChecked}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    setMakeFilterChecked((prevState) => !prevState);
                  }}
                />{" "}
                Make
              </Accordion.Header>

              <Accordion.Body>
                <Dropdown className="dropdown-box">
                  <Dropdown.Toggle
                    variant="none"
                    id="dropdown-basic"
                    className="dropdown-toggle"
                  >
                    <span className="debrieftype-text">
                      {selectedMake ? selectedMake : "Select make"}
                    </span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {uploadedModelsList.map((item: any, index: number) => (
                      <React.Fragment key={index}>
                        <Dropdown.Item onClick={() => setSelectedMake(item)}>
                          {item}
                        </Dropdown.Item>
                        {index !== uploadedModelsList.length - 1 && (
                          <Dropdown.Divider />
                        )}
                      </React.Fragment>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Accordion.Body>
            </Accordion.Item>
          </div>
          <div className="footer">
            <button
              className="btn shadow-sm border btn-sm reset"
              onClick={resetFilter}
            >
              Reset
            </button>
            <button className="btn border btn-sm apply" onClick={applyFilter}>
              Apply
            </button>
          </div>
        </Accordion>
      </div>
    </Popover>
  );

  const rowClick = (rowid: any, data: any) => {};

  useEffect(() => {
    if (aircraftModelInput) {
      let obj = {
        modelname: aircraftModelInput,
      };
      AircraftService.getAircraftType(obj)
        .then((response) => {
          setAircraftType(response.data.aircrafttypes);
        })
        .catch((error) => {
          console.error("Error fetching aircraft type:", error);
        });
    }
  }, [aircraftModelInput]);

  const onAircraftTypeSelect = () => {
    //writing this function beacuse using useEffect with `if (aircraftType) {` condition was calling before needed
    let obj = {
      modelname: aircraftModelInput,
      aircrafttype: aircraftType[0],
    };
    AircraftService.getEngineType(obj)
      .then((response) => {
        setAircraftEngineType(response.data.engineTypes);
      })
      .catch((error) => {
        console.error(`Error fetching engine type:`, error);
      });
  };

  const saveAircraftDetails = () => {
    let authTokens = getLocalStorage("authTokens", "companyid");

    let vSpeedInfo: any = {
      RotationSpeed: rotationSpeed,
      StallSpeed: stallSpeed,
      CleanStallSpeed: cleanStallSpeed,
      BestAngleOfClimbSpeed: bestAngleOfClimbSpeed,
      TouchdownSpeed: touchdownSpeed,
      ReferenceLandingSpeed: referenceLandingSpeed,
      VelocityNeverExceedSpeed: velocityNeverExceedSpeed,
      MaxTaxiGroundSpeed: maxTaxiGroundSpeed,
      TakeoffDecisionSpeed: takeoffDecisionSpeed,
      TakeoffSafetySpeed: takeoffSafetySpeed,
      MaxFlapExtensionSpeed: maxFlapExtensionSpeed,
      MaxLandingGearExtensionSpeed: maxLandingGearExtensionSpeed,
      MaxLandingGearOperationSpeed: maxLandingGearOperationSpeed,
    };

    let parameter = "vspeed";

    // Check if all values in vSpeedInfo are empty strings
    const allEmpty = Object.values(vSpeedInfo).every((value) => value === "");

    if (allEmpty) {
      vSpeedInfo = {};
      parameter = "";
    }

    let obj = {
      aircraftData: {
        registerNo: aircraftRegNo,
        modelname: aircraftModelInput,
        engintype: aircraftEngineType[0],
        aircrafttype: aircraftType[0],
        companyid: authTokens.companyid,
      },
      vSpeedInfo: vSpeedInfo,
      parameter: parameter,
    };

    AircraftService.saveAircraftData(obj)
      .then((response: any) => {
        setToastVisibleSave(true);
        console.log(`Aircraft saved successfully`);
        setApiCaller(!apiCaller);
        console.log(response);
      })
      .catch((error) => {
        console.error(`Error saving aircraft details:`, error);
      });
    hideAddAircraftModal();
  };

  // To get the total number of matching rows based on search to show appropriate component if nothing is found
  const matchingRows = tableInstance.rows.length;

  const showPaginationDiv = matchingRows > 0 ? true : false; //show Pagination Div or not

  return (
    <>
      {toastVisibleSave && (
        <CustomToast
          title={`Success`}
          message={`Aircraft has been added successfully!`}
          visible={toastVisibleSave}
          setVisible={setToastVisibleSave}
        ></CustomToast>
      )}
      {toastVisibleDeactivate && (
        <CustomToast
          title={`Success`}
          message={`Aircraft has been deactivated successfully!`}
          visible={toastVisibleDeactivate}
          setVisible={setToastVisibleDeactivate}
        ></CustomToast>
      )}
      {toastVisibleReactivate && (
        <CustomToast
          title={`Success`}
          message={`Aircraft has been reactivated successfully!`}
          visible={toastVisibleReactivate}
          setVisible={setToastVisibleReactivate}
        ></CustomToast>
      )}

      <section className="fleet-setting-wrap">
        <section className="top-section">
          <span className="section-text">
            <span className="colored-text">Organisation</span> / My Fleet
          </span>
          <div className="heading">Manage Aircrafts</div>
          <div className="description">
            Lets you add and organize aircrafts to streamline your flight
            operations
          </div>
        </section>

        <section className="bottom-section">
          {/* <div className="container-fluid d-flex align-items-center justify-content-around ml-0 pl-0" style={{ width: `100%` }} > */}

          <div className="container d-flex align-items-center justify-content-around">
            
            <div className="col-9 filter-wrap justify-content-left">
              <div className="search-filter">
                <div className="input-select-wrapper ">
                  <input
                    ref={inputRef}
                    type="text"
                    className="text-field text-space debrief-search-icon shadow-none"
                    placeholder={`Search by aircraft registration no`}
                    onChange={onSearchInputChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-auto d-flex justify-content-end">
              <div className="row justify-content-left">
                <div className="col-auto">
                  <button
                    type="button"
                    className="btn btn-light settings-btn"
                    onClick={() => setColumnSettingsModalShow(true)}
                  >
                    <i className="settings-btn-icon"></i>
                  </button>
                </div>
                <div className="col-auto">
                  <OverlayTrigger
                    trigger="click"
                    placement="bottom"
                    show={filterModalShow}
                    overlay={filterPopoverElement}
                  >
                    <button
                      type="button"
                      className="btn btn-light filter-btn"
                      ref={filterPopoverRef}
                      onClick={() => setFilterModalShow(!filterModalShow)}
                    >
                      <i className="filter-btn-icon"></i>Filter
                    </button>
                  </OverlayTrigger>
                </div>
                <div className="col-auto mr-1">
                  <button
                    type="button"
                    className="btn btn-primary add-btn"
                    onClick={showAddAircraftModal}
                  >
                    <i className="add-icon"></i>
                    Add Aircraft
                  </button>
                </div>
                <div className="add-aircraft-modal-wrapper">
                  <Modal
                    show={addAircraftModalOpen}
                    onHide={hideAddAircraftModal}
                  >
                    <Modal.Header
                      closeButton
                      className="settings-modal-header pt-4"
                    >
                      <Modal.Title className=" pt-4">
                        <p className="modal-title-heading">Add New Aircraft</p>
                        <p className="modal-title-subheading">
                          Fill the below details to add a new aircraft to your
                          organisation.
                        </p>
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="assign-modal-scroll-container">
                        <p className="modal-body-heading">
                          <i></i> GENERIC DETAILS
                        </p>
                        <div className="container generic-details mt-1 pb-4">
                          <div className="row">
                            <div className="col">
                              <p className="modal-input-heading mt-2">
                                Registration No.
                              </p>
                              <div className="rbt assign-modal-text-input">
                                <input
                                  placeholder="Enter aircraft’s registration no."
                                  type="text"
                                  role="combobox"
                                  className="rbt-input-main form-control rbt-input"
                                  onChange={(inp) =>
                                    setAircraftRegNo(inp.target.value)
                                  }
                                  style={{ height: `100%` }}
                                />
                              </div>
                            </div>
                            <div className="col">
                              <p className="modal-input-heading mt-2">
                                Make and Model
                              </p>
                              <Typeahead
                                id='1'
                                options={aircraftModels}
                                className="assign-modal-text-input"
                                onChange={(value) =>
                                  setAircraftModelInput(value[0]?.toString())
                                }
                                placeholder="Select aircraft’s make   ⮟"
                              ></Typeahead>
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="col">
                              <p className="modal-input-heading">
                                Airctaft Type
                              </p>
                              <Typeahead
                                id='2'
                                className="assign-modal-text-input"
                                options={aircraftType}
                                onChange={onAircraftTypeSelect}
                                // selected={aircraftType ? [aircraftType] : []}
                                placeholder="Select a type of aircraft"
                              ></Typeahead>
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="col">
                              <p className="modal-input-heading">Engine Type</p>
                              <Typeahead
                                id='3'
                                options={aircraftEngineType}
                                className="assign-modal-text-input"
                                placeholder="Select an engine type"
                              ></Typeahead>
                            </div>
                          </div>
                        </div>

                        <p className="modal-body-heading">
                          <i></i>V-SPEEDS
                        </p>
                        <div className="container generic-details mt-1">
                          <div className="row align-items-center">
                            <div className="col-11">
                              <div className="modal-vspeed-heading">
                                Rotation Speed{" "}
                                <p className="bracket-content">(Vr)</p>
                              </div>
                              <div className="vspeed-heading-description">
                                Speed at which the pilot starts to lift the nose
                                of the aircraft off the runway during takeoff.
                              </div>
                            </div>
                            <div className="col-5">
                              <input
                                className="assign-modal-text-input"
                                tabIndex={-1}
                                onChange={(inp) =>
                                  setRotationSpeed(inp.target.value)
                                }
                                style={{
                                  width: "92%",
                                  borderBottom: "1px solid black",
                                }}
                              ></input>
                            </div>
                          </div>

                          <div className="row align-items-center mt-4">
                            <div className="col-11">
                              <div className="modal-vspeed-heading">
                                Stall Speed{" "}
                                <p className="bracket-content">(Vs0)</p>
                              </div>
                              <div className="vspeed-heading-description">
                                Minimum flight airspeed in landing
                                configuration.
                              </div>
                            </div>
                            <div className="col-5">
                              <input
                                className="assign-modal-text-input"
                                tabIndex={-1}
                                onChange={(inp) =>
                                  setStallSpeed(inp.target.value)
                                }
                                style={{
                                  width: "92%",
                                  borderBottom: "1px solid black",
                                }}
                              ></input>
                            </div>
                          </div>

                          <div className="row align-items-center mt-4">
                            <div className="col-11">
                              <div className="modal-vspeed-heading">
                                Clean Stall Speed{" "}
                                <p className="bracket-content">(Vs1)</p>
                              </div>
                              <div className="vspeed-heading-description">
                                Minimum flight airspeed in clean configuration.
                              </div>
                            </div>
                            <div className="col-5">
                              <input
                                className="assign-modal-text-input"
                                tabIndex={-1}
                                onChange={(inp) =>
                                  setCleanStallSpeed(inp.target.value)
                                }
                                style={{
                                  width: "92%",
                                  borderBottom: "1px solid black",
                                }}
                              ></input>
                            </div>
                          </div>

                          <div className="row align-items-center mt-4">
                            <div className="col-11">
                              <div className="modal-vspeed-heading">
                                Best Angle of Climb Speed{" "}
                                <p className="bracket-content">(Vx)</p>
                              </div>
                              <div className="vspeed-heading-description">
                                Airspeed at which the aircraft will gain the
                                most altitude.
                              </div>
                            </div>
                            <div className="col-5">
                              <input
                                className="assign-modal-text-input"
                                tabIndex={-1}
                                onChange={(inp) =>
                                  setBestAngleOfClimbSpeed(inp.target.value)
                                }
                                style={{
                                  width: "92%",
                                  borderBottom: "1px solid black",
                                }}
                              ></input>
                            </div>
                          </div>

                          <div className="row align-items-center mt-4">
                            <div className="col-11">
                              <div className="modal-vspeed-heading">
                                Touchdown Speed{" "}
                                <p className="bracket-content">(Vsw)</p>
                              </div>
                              <div className="vspeed-heading-description">
                                Airspeed at which the aircraft makes contact
                                with the runway during landing.
                              </div>
                            </div>
                            <div className="col-5">
                              <input
                                className="assign-modal-text-input"
                                tabIndex={-1}
                                onChange={(inp) =>
                                  setTouchdownSpeed(inp.target.value)
                                }
                                style={{
                                  width: "92%",
                                  borderBottom: "1px solid black",
                                }}
                              ></input>
                            </div>
                          </div>

                          <div className="row align-items-center mt-4">
                            <div className="col-11">
                              <div className="modal-vspeed-heading">
                                Reference Landing Speed{" "}
                                <p className="bracket-content">(Vref)</p>
                              </div>
                              <div className="vspeed-heading-description">
                                Speed at which the aircraft should be flying
                                just before landing.
                              </div>
                            </div>
                            <div className="col-5">
                              <input
                                className="assign-modal-text-input"
                                tabIndex={-1}
                                onChange={(inp) =>
                                  setReferenceLandingSpeed(inp.target.value)
                                }
                                style={{
                                  width: "92%",
                                  borderBottom: "1px solid black",
                                }}
                              ></input>
                            </div>
                          </div>

                          <div className="row align-items-center mt-4">
                            <div className="col-11">
                              <div className="modal-vspeed-heading">
                                Velocity Never Exceed Speed{" "}
                                <p className="bracket-content">(Vne)</p>
                              </div>
                              <div className="vspeed-heading-description">
                                Maximum indicated airspeed that an aircraft is
                                certified to operate safely.
                              </div>
                            </div>
                            <div className="col-5">
                              <input
                                className="assign-modal-text-input"
                                tabIndex={-1}
                                onChange={(inp) =>
                                  setVelocityNeverExceedSpeed(inp.target.value)
                                }
                                style={{
                                  width: "92%",
                                  borderBottom: "1px solid black",
                                }}
                              ></input>
                            </div>
                          </div>

                          <div className="row align-items-center mt-4">
                            <div className="col-11">
                              <div className="modal-vspeed-heading">
                                Max. Taxi Ground Speed{" "}
                                <p className="bracket-content">(Vmxt)</p>
                              </div>
                              <div className="vspeed-heading-description">
                                Highest speed at which an aircraft is permitted
                                to move on the ground, while when taxiing..
                              </div>
                            </div>
                            <div className="col-5">
                              <input
                                className="assign-modal-text-input"
                                tabIndex={-1}
                                onChange={(inp) =>
                                  setMaxTaxiGroundSpeed(inp.target.value)
                                }
                                style={{
                                  width: "92%",
                                  borderBottom: "1px solid black",
                                }}
                              ></input>
                            </div>
                          </div>

                          <div className="row align-items-center mt-4">
                            <div className="col-11">
                              <div className="modal-vspeed-heading">
                                Takeoff Decision Speed{" "}
                                <p className="bracket-content">(V1)</p>
                              </div>
                              <div className="vspeed-heading-description">
                                Speed at which the pilot must decide whether to
                                continue the takeoff or abort it.
                              </div>
                            </div>
                            <div className="col-5">
                              <input
                                className="assign-modal-text-input"
                                tabIndex={-1}
                                onChange={(inp) =>
                                  setTakeoffDecisionSpeed(inp.target.value)
                                }
                                style={{
                                  width: "92%",
                                  borderBottom: "1px solid black",
                                }}
                              ></input>
                            </div>
                          </div>

                          <div className="row align-items-center mt-4">
                            <div className="col-11">
                              <div className="modal-vspeed-heading">
                                Takeoff Safety Speed{" "}
                                <p className="bracket-content">(V2)</p>
                              </div>
                              <div className="vspeed-heading-description">
                                Minimum speed at which the aircraft can climb
                                safely in the event of an engine failure.
                              </div>
                            </div>
                            <div className="col-5">
                              <input
                                className="assign-modal-text-input"
                                tabIndex={-1}
                                onChange={(inp) =>
                                  setTakeoffSafetySpeed(inp.target.value)
                                }
                                style={{
                                  width: "92%",
                                  borderBottom: "1px solid black",
                                }}
                              ></input>
                            </div>
                          </div>

                          <div className="row align-items-center mt-4">
                            <div className="col-11">
                              <div className="modal-vspeed-heading">
                                Max. Flap Extension Speed{" "}
                                <p className="bracket-content">(VFE)</p>
                              </div>
                              <div className="vspeed-heading-description">
                                Highest speed at which the flaps can be extended
                                during approach and landing.
                              </div>
                            </div>
                            <div className="col-5">
                              <input
                                className="assign-modal-text-input"
                                tabIndex={-1}
                                onChange={(inp) =>
                                  setMaxFlapExtensionSpeed(inp.target.value)
                                }
                                style={{
                                  width: "92%",
                                  borderBottom: "1px solid black",
                                }}
                              ></input>
                            </div>
                          </div>

                          <div className="row align-items-center mt-4">
                            <div className="col-11">
                              <div className="modal-vspeed-heading">
                                Max. Landing Gear Extension Speed{" "}
                                <p className="bracket-content">(VLE)</p>
                              </div>
                              <div className="vspeed-heading-description">
                                Highest speed at which the landing gear can be
                                extended during approach and landing.
                              </div>
                            </div>
                            <div className="col-5">
                              <input
                                className="assign-modal-text-input"
                                tabIndex={-1}
                                onChange={(inp) =>
                                  setMaxLandingGearExtensionSpeed(
                                    inp.target.value
                                  )
                                }
                                style={{
                                  width: "92%",
                                  borderBottom: "1px solid black",
                                }}
                              ></input>
                            </div>
                          </div>

                          <div className="row align-items-center mt-4">
                            <div className="col-11">
                              <div className="modal-vspeed-heading">
                                Max. Landing Gear Operating Speed
                                <p className="bracket-content">(VLO)</p>
                              </div>
                              <div className="vspeed-heading-description">
                                Highest speed at which the landing gear can be
                                extended or retracted.
                              </div>
                            </div>
                            <div className="col-5">
                              <input
                                className="assign-modal-text-input"
                                tabIndex={-1}
                                onChange={(inp) =>
                                  setMaxLandingGearOperationSpeed(
                                    inp.target.value
                                  )
                                }
                                style={{
                                  width: "92%",
                                  borderBottom: "1px solid black",
                                }}
                              ></input>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <div className="container-fluid d-flex justify-content-between">
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={() => hideAddAircraftModal()}
                        >
                          <strong>Close</strong>
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={saveAircraftDetails}
                        >
                          Add Aircraft
                        </button>
                      </div>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="sticky-table-wrapper" style={{height: `60%`}}> */}
          {!data.length && !noResultsFound && (
            <div className="text-center no-files-text">No Aircraft Added!</div>
          )}
          {noResultsFound && matchingRows === 0 && <NoResultsFound />}
          {data.length !== 0 && (
            <div className="sticky-table-wrapper">
              <StickyTable
                tableInstance={tableInstance}
                rowClick={rowClick}
                showPaginationDiv={showPaginationDiv}
              ></StickyTable>

              <ReactivateAccountModal
                show={showReactivateAccountModal}
                onHide={() => setShowReactivateAccountModal(false)}
                type={`Aircraft`}
                name={nameToPass}
                onClickReactivate={onClickReactivate}
              ></ReactivateAccountModal>

              <DeactivateAccountModal
                show={showDeactivateAccountModal}
                onHide={() => setShowDeactivateAccountModal(false)}
                type={`Aircraft`}
                name={nameToPass}
                onClickDeactivate={onClickDeactivate}
              ></DeactivateAccountModal>
            </div>
          )}
          <ColumnSettings
            show={columnSettingsModalShow}
            allColumns={allColumns}
            columnsettingapply={(columnData: any) =>
              onColumnSettingApply(columnData)
            }
            onHide={() => setColumnSettingsModalShow(false)}
          ></ColumnSettings>
        </section>
      </section>
    </>
  );
};

export default MyFleet;