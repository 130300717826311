import { useState, useRef } from "react"
import { Accordion, Button, Dropdown } from "react-bootstrap"
import { Typeahead } from "react-bootstrap-typeahead"
import AccordionBody from "react-bootstrap/esm/AccordionBody"
import AccordionItem from "react-bootstrap/esm/AccordionItem"
import { airportFilterColumnName } from "../../../../common/utils/utils"
import AccordionHeader from "react-bootstrap/esm/AccordionHeader"

const FilterBox = (props: any) => {
    const { className, resetFilter, airPortNRunwayList, airportAndRunwayFilterParam, setAirportAndRunwayFilterParamCheck, airportAndRunwayFilterParamCheck, setAirportAndRunwayFilterParam, exerciseList, setExerciseFilterParamCheck, exerciseFilterParamCheck, setExerciseFilterParam, exerciseFilterParam, applyFilter, runways, setRunways, ...others } = props
    //const [runways, setRunways] = useState<string[]>([])
    const runwayTypeheadRef = useRef<any>(null)

    /**
 * Toggles the airport and runway filter parameter check.
 * @param {Event} e - The event object.
 * @returns {void}
 */
    const setAirportRunwayFilter = (e: any) => {
        e.stopPropagation();
        setAirportAndRunwayFilterParamCheck(!airportAndRunwayFilterParamCheck);
    };


    /**
 * Toggles the exercise filter parameter check.
 * @param {Event} e - The event object.
 * @returns {void}
 */
    const setExerciseFilter = (e: any) => {
        e.stopPropagation();
        setExerciseFilterParamCheck(!exerciseFilterParamCheck);
    };



    return (
        <div className="filter-box">
            <Accordion {...others}>
                <div className="scroll-div">
                    
                    <AccordionItem eventKey="1">
                        <Accordion.Header>
                            {/* <input type="checkbox" onChange={setFilter} checked={airportAndRunwayFilterParam["setFilter"]} /> */}
                            <input type="checkbox" onClick={setAirportRunwayFilter} onChange={()=>{}} checked={airportAndRunwayFilterParamCheck} />
                            Airports & Runways
                        </Accordion.Header>
                        <Accordion.Body>
                            <Dropdown className="dropdown-box shadow-sm border dropdown">
                            {/* <Dropdown className="dropdown-box shadow-sm border dropdown"> */}
                                <Dropdown.Toggle variant="none" className="dropdown-toggle">
                                    {airportFilterColumnName(airportAndRunwayFilterParam)}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu">
                                    <Dropdown.Item href="" onClick={() => setAirportAndRunwayFilterParam({ ...airportAndRunwayFilterParam, filterColumn: 2 })}>Only on "To" column</Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item href="" onClick={() => setAirportAndRunwayFilterParam({ ...airportAndRunwayFilterParam, filterColumn: 1 })}>Only on "From" column</Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item href="" onClick={() => setAirportAndRunwayFilterParam({ ...airportAndRunwayFilterParam, filterColumn: 3 })}>"Both" columns</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <div>
                                <label>Airport</label>
                                <Typeahead
                                    placeholder="Enter airport code"
                                    options={airPortNRunwayList.map((a: any) => `${a.airport}`)}
                                    id=""
                                    defaultInputValue={airportAndRunwayFilterParam["airport"]}
                                    className="typehead-input"
                                    onChange={(value) => {
                                        let runwaysList = (airPortNRunwayList.filter((u: any) => u.airport == value[0]).map((u: any) => u.runways)[0] || [])
                                        let runwayArr: Array<string> = []
                                        runwaysList.map((runway: string) => runwayArr.push(runway.trim()))
                                        setRunways(runwayArr)
                                        setAirportAndRunwayFilterParam({ ...airportAndRunwayFilterParam, airport: value[0] || "" })
                                        runwayTypeheadRef.current.clear()
                                    }}
                                >
                                </Typeahead>
                            </div>
                            <div>
                                <label>Runway</label>
                                <Typeahead
                                    placeholder="Select a runway"
                                    options={props.runways.map((val: any) => `RWY ${val}`) || []}
                                    id=""
                                    defaultInputValue={airportAndRunwayFilterParam["runway"]}
                                    className="typehead-input"
                                    onChange={(value) => setAirportAndRunwayFilterParam({ ...airportAndRunwayFilterParam, runway: value[0] || "" })}
                                    ref={runwayTypeheadRef}
                                >
                                </Typeahead>
                            </div>
                        </Accordion.Body>
                    </AccordionItem>
                    <AccordionItem eventKey="2">
                        <Accordion.Header>
                            <input type="checkbox" onClick={setExerciseFilter} onChange={()=>{}} checked={exerciseFilterParamCheck}/>
                            Exercise
                        </Accordion.Header>
                        <Accordion.Body>
                        <label>Exercise</label>
                                <Typeahead
                                placeholder="Enter exercise name"
                                options={exerciseList.map((val: any) => `${val.excercise}`)}
                                defaultInputValue={exerciseFilterParam["exercise"]}
                                id=""
                                className="typehead-input"
                                onChange={(value)=> setExerciseFilterParam({...exerciseFilterParam, exercise: value[0] || ""})}
                                >
                                </Typeahead>
                        </Accordion.Body>
                    </AccordionItem>
                </div>
                <div className="footer">
                    <button className="btn shadow-sm border btn-sm reset" onClick={resetFilter} >Reset</button>
                    <button className="btn border btn-sm apply" onClick={applyFilter}>Apply</button>
                </div>
            </Accordion>
        </div>
    )
}

export default FilterBox