import { useState, useEffect, useRef } from "react"
import { Outlet } from "react-router-dom"
import Header from "../../common/components/header/Header"
import Sidebar from "../../common/components/sidebar/Sidebar"
import SidebarExpanded from "../../common/components/sidebar/SidebarExpanded"
import './Dashboard.scss'
//import { setLocalStorage } from "../../context/themeContext"
//import getComapanyInfo from "../../services/companyInfo.service"

const Dashboard = (props : any) =>{

    const [sideBarState, setSidebarState] = useState(`collapsed`);

    const handleClick = (event:MouseEvent) => {
        const isClickOnSidebarOverlay = (event.target as HTMLElement).className === `expanded-sidebar-overlay`;
            if (isClickOnSidebarOverlay) {
                setSidebarState(`collapsed`);
            }
    };

    useEffect(() => {
        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        }
    }, []);

    return (
        <>
            <div className="dashboard-container">
                <div className="header-wrapper">
                    <Header 
                        sideBarState={sideBarState}
                        setSidebarState={setSidebarState} />
                </div>
                <div className="sidebar-component-wrapper d-flex">
                    {/* <div className="sidebar-wrapper d-flex d-none d-md-flex"> */}
                    <div className="sidebar-wrapper d-flex d-md-flex">
                        
                        {/* {sideBarState === `collapsed` && 
                            <Sidebar sideBarState={sideBarState} /> } */}

                        <Sidebar sideBarState={sideBarState} />

                        {sideBarState === `expanded` &&
                            <SidebarExpanded sideBarState={sideBarState} />}

                    </div>
                    <div className="component-wrapper">
                        {/* {props.page} */}
                        <Outlet/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Dashboard