// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cesium-div {
  resize: both;
  overflow: hidden;
}

#viewer {
  position: relative;
  width: 100%;
  height: 100%;
}

#viewer3d {
  position: relative;
  width: 100%;
  height: 100%;
}

#flightPath2d {
  position: relative;
  width: 100%;
  height: 100%;
}

.viewer-wrapper {
  border-radius: 8px;
}

.cesium-widget-credits {
  display: none !important;
}

.cesium-timeline-bar, .cesium-timeline-main {
  display: none !important;
}

#viewrDrag .cesium-viewer-animationContainer {
  display: none !important;
}

.cesium-2d {
  height: 88px;
  width: 158px;
  border-radius: 8px;
}`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/components/cesium3dView/CesiumComp.scss"],"names":[],"mappings":"AAAA;EAII,YAAA;EACA,gBAAA;AAFJ;;AAMA;EACI,kBAAA;EACA,WAAA;EACA,YAAA;AAHJ;;AAMA;EACI,kBAAA;EACA,WAAA;EACA,YAAA;AAHJ;;AAMA;EACI,kBAAA;EACA,WAAA;EACA,YAAA;AAHJ;;AASI;EAGI,kBAAA;AARR;;AAYI;EACI,wBAAA;AATR;;AAWI;EACI,wBAAA;AARR;;AAWI;EACI,wBAAA;AARR;;AAWI;EAII,YAAA;EACA,YAAA;EACA,kBAAA;AAXR","sourcesContent":[".cesium-div{\n    // width: 1000px;\n    // height: 800px;\n   // background-color: red;\n    resize: both;\n    overflow: hidden;\n    //display: flex;\n}\n\n#viewer{\n    position: relative;\n    width: 100%;\n    height: 100%;\n}\n\n#viewer3d{\n    position: relative;\n    width: 100%;\n    height: 100%;\n}\n\n#flightPath2d{\n    position: relative;\n    width: 100%;\n    height: 100%;\n}\n.cesium-svgPath-svg{\n    //display: none;\n    }\n    \n    .viewer-wrapper{\n        // resize: both;\n        // overflow: hidden;\n        border-radius: 8px;\n        //display: flex;\n    }\n    \n    .cesium-widget-credits  {\n        display: none !important;\n    }\n    .cesium-timeline-bar,.cesium-timeline-main{\n        display: none !important;\n    }\n    \n    #viewrDrag .cesium-viewer-animationContainer{\n        display: none !important;\n    }\n    \n    .cesium-2d{\n        //position: relative;\n       // left :30%;\n        //bottom: 40%;\n        height: 88px;\n        width: 158px;\n        border-radius: 8px;\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
