import { useEffect, useState, useMemo } from "react"
import "./specialTabs.scss"
import { AddVariablesToSampleProperty, clockViewModel, GetJulianDate } from "../../../../common/utils/cesiumDataHandler"
import { JulianDate, Viewer, SceneMode, ClockRange, ClockStep } from "cesium"
import { TabDataBrodacast, TabTimeSyncBroadcast, TabDataMsgType } from "../../../../common/utils/utils"
import { parseDateTimeArray } from "../debrief/debriefPlayer/IasVsAltVsTimeContainer"
import Plotlygraph from "../graphs/Plotlygraph"
import { GetJulianDateFromTimeStamp } from "../../../../common/utils/cesiumDataHandler"

const TabGraph = () => {
    const [data, setData] = useState<any>(null);
    const [viewer, setViewer] = useState<any>();
    const [isViewerLoaded, setIsViewerLoaded] = useState(false);
    const [isLoadingViewer, setIsLoadingViewer] = useState(false);
    const [clockState, setClockState] = useState<boolean>();

    TabDataBrodacast.onmessage = (msg) => {
        if (msg.id === TabDataMsgType.setGraphData) {
            setData(msg.data)
        }
        if (msg.id === TabDataMsgType.setClockState) {
            setClockState(msg.data);
            clockViewModel.canAnimate = msg.data
            clockViewModel.shouldAnimate = msg.data
        }
        if (msg.id === TabDataMsgType.closeNewTabs) {
            window.close()
        }
        if (msg.id === TabDataMsgType.setClockSpeed) {
            clockViewModel.multiplier = msg.data
        }
        if (msg.id === TabDataMsgType.resetTab) {
            resetTab()
        }
    }

    TabTimeSyncBroadcast.onmessage = (time) => {
        //console.log("On message called in TabGraph")
        if (JulianDate.secondsDifference(clockViewModel.currentTime, time) > 1 || JulianDate.secondsDifference(clockViewModel.currentTime, time) < -1) {
            clockViewModel.currentTime = time;
        }
    }

    useEffect(() => {
        TabDataBrodacast.postMessage({ id: TabDataMsgType.getClockState, data: "" })
    }, [viewer])

    const altMSLArray = useMemo(() => {
        if (data)
            return data.map((item: any) => item.AltMSL);
        return;
    }, [data]);

    const iasArray = useMemo(() => {
        if (data)
            return data.map((item: any) => item.IAS);
        return;
    }, [data]);

    const lclTimeArray = useMemo(() => {
        if (data)
            return parseDateTimeArray(data);
        return;
    }, [data]);

    useEffect(() => {
        try {
            if (!data) {
                TabDataBrodacast.postMessage({ id: TabDataMsgType.getGraphData, data: "" });
            }

            if (isViewerLoaded && data) {
                AddVariablesToSampleProperty(data);
                SetClock(data, viewer);
            }

            if (!isViewerLoaded && data && !isLoadingViewer) {
                setIsViewerLoaded(true);
                setIsLoadingViewer(true);

                InitViewer().then((newViewer) => {
                    setViewer(newViewer);
                    setIsLoadingViewer(false);
                }).catch((error) => {
                    console.error("Error loading viewer:", error);
                    setIsLoadingViewer(false);
                });
            }
        } catch (err) {
            console.error("Error in useEffect:", err);
        }
    }, [data, viewer, isLoadingViewer]);

    window.onbeforeunload = function () {
        //TabChannel.postMessage({id:"pfd-close", data:""})
    };

    function SetClock(csvJSonArray: any, viewer: any) {
        const element = csvJSonArray[20] || csvJSonArray[25] || csvJSonArray[35];
        const startTime = GetJulianDateFromTimeStamp(element);

        const lastElement = csvJSonArray[csvJSonArray.length - 1] || csvJSonArray[csvJSonArray.length - 10] || csvJSonArray[csvJSonArray.length - 20];
        const endTime = GetJulianDateFromTimeStamp(lastElement);

        viewer.clock.startTime = startTime.clone();
        viewer.clock.stopTime = endTime.clone();
        viewer.clock.currentTime = startTime.clone();
        viewer.timeline.zoomTo(startTime, endTime);
        viewer.clock.multiplier = 1;
        viewer.clock.shouldAnimate = clockState;
        viewer.clock.clockRange = ClockRange.LOOP_STOP // loop when we hit the end time
        viewer.clock.clockStep = ClockStep.SYSTEM_CLOCK_MULTIPLIER
        viewer.clock.canAnimate = clockState;
    }

    const InitViewer = async (): Promise<any> => {
        const Viewer2 = new Viewer('viewer', {
            clockViewModel: clockViewModel,
            sceneMode: SceneMode.SCENE3D,
            baseLayerPicker: false,
            vrButton: false,
            homeButton: false,
            sceneModePicker: false,
            projectionPicker: false,
            navigationHelpButton: false,
            animation: false,
            fullscreenButton: false,
            infoBox: false,
            geocoder: false
        });

        return Viewer2
    }

    const resetTab = () => {
        setTimeout(() => {
            setData(null)
        }, 500)
    }

    return (
        <div className="spl-tab">
            <div id="viewer" style={{ display: 'none' }}></div>
            <div className='tab-parent' id="pfdLayerParent">
                {data && !isLoadingViewer && (
                    <Plotlygraph
                        xaxisArray={lclTimeArray}
                        y1axisArray={iasArray}
                        y2axisArray={altMSLArray}
                        xlabel='Local Time'
                        y1label='IAS'
                        y2label='Altitude'
                        viewer={viewer}
                    />
                )}
            </div>
        </div>
    );
}

export default TabGraph;