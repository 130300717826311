// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../../assets/images/debrief/icon-severe-alert.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.exceedance-wrapper {
  border: 1px solid #da202a;
  border-radius: 8px;
  background-color: #fff5f5;
  width: 100%;
}

.exceedance-name {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-position: 0px 7px;
  background-size: 12px 12px;
}`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/components/debrief/debriefPlayer/ExceedancesAndAlerts.scss"],"names":[],"mappings":"AAEA;EACI,yBAAA;EACA,kBAAA;EACA,yBAAA;EACA,WAAA;AADJ;;AAIA;EAEI,yDAAA;EACA,4BAAA;EACA,4BAAA;EACA,0BAAA;AAFJ","sourcesContent":["$debriefImgPath: \"../../../../../assets/images/debrief/\";\n\n.exceedance-wrapper {\n    border: 1px solid #da202a;\n    border-radius: 8px;\n    background-color: #fff5f5;\n    width: 100%;\n}\n\n.exceedance-name {\n    // background-image: (url(#{$debriefImgPath}/icon-severe-alert.svg');\n    background-image: url('../../../../../assets/images/debrief/icon-severe-alert.svg');\n    background-repeat: no-repeat;\n    background-position: 0px 7px;\n    background-size: 12px 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
