import React, { useState, useEffect } from 'react';

const CustomToast = (props:any) => {

    const { title, message, duration = 10000, visible, setVisible } = props;

    // const [visible, setVisible] = useState(false);

    useEffect(() => {
        setVisible(true);
        const timer = setTimeout(() => {
            setVisible(false);
        }, duration);

        return () => clearTimeout(timer);
    }, [duration]);

    
    return (
        <>
            {visible && (
                <div className="toast-msg">
                    <span className="title">{title}:</span>
                    <span className="msg">{message}</span>
                </div>
            )}
        </>
    );
};

export default CustomToast;