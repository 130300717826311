import './Sidebar.scss'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import '../../../../src/assets/styles/_variables.scss'
import SidebarIcons from './SidebarIcons';
import { useEffect, useState } from 'react';
import { getLocalStorage, removeLocalStorage } from '../../utils/localStorageHandler';
import { useAuth } from '../../../context/authProvider';
import { Modal } from 'react-bootstrap';

const SidebarExpanded = (props: any) => {
    let navigate = useNavigate();
    let { client } = useParams();
    let activeTab = useLocation();

    const { authTokens, setLoginData } = useAuth();
    const [authToken, setAuthToken] = useState(getLocalStorage("authTokens", ""))
    // const authToken = getLocalStorage("authTokens", "");
    const [showLogoutBtn, setShowLogoutBtn] = useState(false)

    // Function to logout
    const logOut = () => {
        removeLocalStorage("authTokens")
        setLoginData()
        navigate("/", { replace: true });
    }

    // All the available pages on the side bar which the user can navigate to
    let tabs = [
        {
            tabName: "Debrief",
            isTabActive: true,
            isTabDisplayed: true,
            id: "debrief"
        },
        {
            tabName: "Flights",
            isTabActive: false,
            isTabDisplayed: true,
            id: "flights"
        },
        {
            tabName: "Imports",
            isTabActive: false,
            isTabDisplayed: true,
            id: "import"
        },
        // {
        //     tabName: "Flight Time",
        //     isTabActive: false,
        //     isTabDisplayed: true,
        //     id: "flying_duty_time"
        // },
        {
            tabName: "Settings",
            isTabActive: false,
            isTabDisplayed: true,
            id: "settings"
        }
    ]

    if (authToken && authToken.roleid !== 1) {
        tabs = tabs.filter(tab => tab.id !== "settings");
    }

    const [allTabs, setAllTabs] = useState(tabs)


    // Function to go to any of the selected page
    const goToPage = (page: any) => {
        allTabs.forEach(tabs => {
            tabs.isTabActive = false
        });
        page.isActive = true;
        if (client !== undefined) {
            navigate(`/${client}/dashboard/${page.id}`)
        } else {
            navigate(`/dashboard/${page.id}`)
        }
    }

    const [showExpandedModal, setShowExpandedModal] = useState(false);

    useEffect(() => {
        if (props.sideBarState === `expanded`) {
            setShowExpandedModal(true);
        } else {
            setShowExpandedModal(false);
        }
    }, [props.sideBarState])

    const activeTabName = activeTab.pathname.split('/');

    // console.log(authToken)
    
    return (
        <>
            {showExpandedModal &&
                <div className="expanded-sidebar-overlay">
                    <div className="sidebarExpanded-container d-flex flex-column justify-content-center">
                        <div className='d-flex flex-column flex-grow-1 justify-content-center'>
                            {
                                allTabs.map((tab) => {
                                    let textColor = `black`;
                                    if (activeTabName.includes(tab.id)) {
                                        textColor = `#477afe`;
                                    }
                                    return (
                                        <button className="btn-sidebar" key={'div-' + tab.id} onClick={() => { goToPage(tab) }}>
                                        <span key={'span-' + tab.id} className={activeTab.pathname.split('/').pop() === tab.id ? "sidebar-" + tab.id + " active" : "sidebar-" + tab.id}>
                                            <SidebarIcons props={tab} />
                                            <div className='tab-names' style={{ color: textColor }}>
                                                {tab.tabName}
                                            </div>
                                        </span>
                                    </button>
                                )})
                            }
                        </div>
                        <div className="row">
                            <div className='btn-sidebar profile col-1' onClick={() => setShowLogoutBtn(!showLogoutBtn)} style={{ marginLeft: '8px'}}>
                                <button className={showLogoutBtn === false ? "btn-logout hide" : "btn-logout shadow"} onClick={logOut}></button>
                            </div>
                            <div className="col user-info">
                                <span className='username'>{(authToken.name.length > 23) ? `${authToken.name.slice(0, 20)}...` : authToken.name}</span>
                                <br />
                                <span className='useremail'>{(authToken.username.length > 25) ? `${authToken.username.slice(0, 22)}...` : authToken.username}</span>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default SidebarExpanded;