import React from "react";
import StickyTable from "../../../../common/components/StickyTable/StickyTable";

const RecentDebrief = (props: any) => {
    return ((<>
  <div className="recent-debrief-text">RECENT DEBRIEFS</div>
        <StickyTable tableInstance={props.tableInstance}
            searchField={props.SearchField}
            rowClick={props.rowClick}
        ></StickyTable>

    </>))
}

export default React.memo(RecentDebrief);