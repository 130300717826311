import fullScreenWindow from "../../../../common/utils/fullScreenWindow"
import { Scene, ScreenSpaceCameraController, Viewer } from "resium"
import { CesiumTerrainProvider,Ion,SceneMode, createWorldTerrainAsync } from "cesium";
import { clockViewModel } from "../../../../common/utils/cesiumDataHandler";
import { useEffect, useState } from "react";


const CesiumFlightPath3D = (props) =>{

    return (
    <>
        <div className={props.ispointerEventDisable ? 'h-100 w-100 pe-none' : 'h-100 w-100'} id="viewer3dWrap">
            <div  id="viewer3d" className="viewer"> </div>
            
            
        </div>
    </>
    )
}

export default CesiumFlightPath3D