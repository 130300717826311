import React, { useEffect } from "react";
import './StickyTable.scss'
import { Styles } from "./StickyTableFlyingDutyTimeStyles";
import { Dropdown } from "react-bootstrap";

const StickyTableFlyingDutyTime = (props: any) => 
{
  const [SearchText, setSearchText] = React.useState('');
  const tableInstance: any = props.tableInstance
  const searchField: any = props.searchField;
  //const {rowClick} = props
  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    nextPage,
    canNextPage,
    previousPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    state,
    prepareRow,
    allColumns,
    getHeaderGroupProps,
    getToggleHideAllColumnsProps,
  } = tableInstance;

  // console.log(`object`)

  const { pageIndex } = state;
  const dataArray = props.showPaginationDiv ? page : rows;

  const tableColumnStyle = {
    maxWidth: 'fit-content',
    paddingLeft: '20px',
    paddingRight: '0px',
  }

  useEffect(() => {
    setSearchText(props.searchField);
  }, [props.searchField]);

    const tableData = document.getElementsByClassName('td');
    if (tableData) {
        for (let i = 0; i < tableData.length; i++) {
            if (i%4 === 0) {
              (tableData[i] as HTMLElement).style.minWidth = '150px';
              (tableData[i] as HTMLElement).style.maxWidth = '200px';
            } else if (i%4 !== 3) {
              (tableData[i] as HTMLElement).style.minWidth = '50px';
              (tableData[i] as HTMLElement).style.maxWidth = '110px';
            }
        }
    }

    const tableHeaders = document.getElementsByClassName('th');
    if (tableHeaders) {
        for (let i = 0; i < tableHeaders.length; i++) {
            if (i===0) {
                (tableHeaders[i] as HTMLElement).style.minWidth = '150px';
                (tableHeaders[i] as HTMLElement).style.maxWidth = '200px';
                (tableHeaders[i] as HTMLElement).style.cursor = 'pointer';

            } else if (i!==3) {
              (tableHeaders[i] as HTMLElement).style.minWidth = '50px';
              (tableHeaders[i] as HTMLElement).style.maxWidth = '110px';
              (tableHeaders[i] as HTMLElement).style.cursor = 'pointer';

            }
            else {
                (tableHeaders[i] as HTMLElement).style.pointerEvents = 'none';
            }
            (tableHeaders[i] as HTMLElement).style.borderTop =  '1px solid rgb(235, 235, 235)';
        }
    }


  return (
    <Styles>
      <section {...getTableProps()} className={`table sticky ${!dataArray.length ? "over-flow-none" : ""}`}>
        <div className="header">
          {headerGroups.map((headerGroup: any) => (
            <div {...headerGroup.getHeaderGroupProps()} className="tr">
              {headerGroup.headers.map((column: any) => { 
                // console.log(`Header column`, column);
                return (
                <div {...column.getHeaderProps(column.getSortByToggleProps())} className="th" style={(column.id !== 'chart_part')? tableColumnStyle : {}}>
                  {column.render('Header')}
                  <span className={column.isSorted ? (column.isSortedDesc ? 'desc' : 'asc') : ''}>
                  </span>
                </div>
              )}
              )}
            </div>
          ))}
        </div>

        <div {...getTableBodyProps()} className="body" style={{ pointerEvents: 'none' }}>
          {dataArray.map((row: any) => {
        //   console.log(`row: `, row);
            prepareRow(row);
            return (
              <div {...row.getRowProps()} className="tr" onClick={() => props.rowClick(row.id, tableInstance.data)}>
                  {row.cells.map((cell: any) => {
                    // console.log(`Row cell: `, cell);
                    return (
                    <div {...cell.getCellProps()} className="td" style={(cell.column.id !== 'chart_part')? tableColumnStyle : {}} >
                      {cell.render('Cell')}
                    </div>
                  )}
                  )}
                </div>
              );
          
          })}
        </div>
      </section>
      {props.showPaginationDiv &&
        <div className="paginationTab">
          <div className="pagination-details">
            <div className="pagination-text">Items per page : 10</div>
            <div className="vertical-line mx-2"></div>
            <div className="pagination-item-num"> {` `}
              {((pageIndex + 1) < pageCount) &&
                `${pageIndex * 10 + 1} - ${pageIndex * 10 + 10}`
              }
              {((pageIndex + 1) === pageCount) &&
                `${pageIndex * 10 + 1} - ${tableInstance.rows.length}`
              }
              {tableInstance.rows.length === 0 && `0`
              }
              <span className="pagination-text"> {` `}of {tableInstance.rows.length} items </span>
            </div>
          </div>

          <div className="pagination-content">
            <button className="btn btn-light btn-sm mx-2" onClick={previousPage} disabled={!canPreviousPage}>{`❮`}</button>
            {pageOptions.map((page: any, index: any) => (
              <React.Fragment key={index}>
                {(index === 0 || index === pageCount - 1 || (index >= pageIndex - 1 && index <= pageIndex + 1)) ? (
                  <button
                    onClick={() => gotoPage(index)}
                    className={pageIndex === index ? "btn btn-primary btn-sm active mx-1" : "btn btn-light btn-sm mx-1"} >
                    {index + 1}
                  </button>
                ) : (index === 1 || index === pageCount - 2) && (
                  <span className="mx-1">...</span>
                )}
              </React.Fragment>
            ))}
            <button className="btn btn-light btn-sm mx-2" onClick={nextPage} disabled={!canNextPage}>{`❯`}</button>
            <div className="vertical-line mx-2"></div>
            <select className="paginationDropdown btn btn-light btn-sm mx-1" style={{width: `fit-content`}} value={pageIndex} onChange={(e: any) => gotoPage(Number(e.target.value))} >
              {pageOptions.map((page: any, index: any) => (
                <option key={index} value={index}>
                  {index + 1}
                </option>
              ))}
            </select>
            <span className="pagination-text"> {` `} of {pageOptions.length} pages </span>
          </div>
        </div>
      }
    </Styles>
  );
}

export default StickyTableFlyingDutyTime;



