/**
 * Requests full-screen mode for the specified HTML element.
 * @param {string} fullDiv - The ID of the HTML element to enter full-screen mode.
 */
const fullScreenWindow = (fullDiv: string) => {
    let elem = document.getElementById(fullDiv);

    // Check if the browser supports the standard requestFullscreen method
    if (elem?.requestFullscreen) {
        elem.requestFullscreen();
    }
    // Uncomment the following lines if you want to provide additional vendor-prefixed methods
    // (e.g., for older versions of some browsers)
    // else if (elem?.msRequestFullscreen) {
    //     elem?.msRequestFullscreen();
    // } else if (elem?.mozRequestFullScreen) {
    //     elem?.mozRequestFullScreen();
    // } else if (elem.webkitRequestFullscreen) {
    //     elem.webkitRequestFullscreen();
    // }
};

export default fullScreenWindow;
