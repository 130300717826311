import "./FlyingDutyTime.scss"
import { Button, Col, Container, Row } from "react-bootstrap";
import React, { useEffect } from "react";
import { useSticky } from "react-table-sticky";
import StickyTableFlyingDutyTime from "../../../../common/components/StickyTable/StickyTableFlyingDutyTime";
import { useBlockLayout, useColumnOrder, useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
import { useState } from "react";
import Plot from 'react-plotly.js';
import FlyingDutyService from '../../../../services/FlyingDuty.service';
import { DatePicker, dateTimePickerToolbarClasses, } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { getDate } from "rsuite/esm/utils/dateUtils";



const FlyingDutyTime = () => {

  const dummyPilotRes = [
      {
          "name": "Abcdid",
          "registerNo": 3,
          "tailNumber": "VJ-101",
          "airtime": "0hr 50min ",
          "ground_Time": "0hr 18min ",
          "fileData": [
              {
                  "debriefId": 416,
                  "startTimestamp": "2024-04-13T07:30:26+05:30",
                  "endTimestamp": "2024-04-13T08:20:59+05:30"
              }
          ]
      },
      {
          "name": "Kab",
          "registerNo": 3,
          "tailNumber": "VJ-101",
          "airtime": "23hr 40min ",
          "ground_Time": "12hr 48min ",
          "fileData": [
              {
                  "debriefId": 416,
                  "startTimestamp": "2024-04-13T03:30:26+05:30",
                  "endTimestamp": "2024-04-13T04:20:59+05:30"
              }
          ]
      },
      {
          "name": "Anand",
          "registerNo": 3,
          "tailNumber": "VJ-101",
          "airtime": "0hr 50min ",
          "ground_Time": "0hr 18min ",
          "fileData": [
              {
                  "debriefId": 426,
                  "startTimestamp": "2024-04-13T07:30:26+05:30",
                  "endTimestamp": "2024-04-13T08:20:59+05:30"
              }
          ]
      },
      {
          "name": "Kashish",
          "registerNo": 3,
          "tailNumber": "VJ-101",
          "airtime": "2hr 9min ",
          "ground_Time": "0hr 31min ",
          "fileData": [
              {
                  "debriefId": 407,
                  "startTimestamp": "2024-04-13T06:08:57+05:30",
                  "endTimestamp": "2024-04-13T07:23:59+05:30"
              },
              {
                  "debriefId": 409,
                  "startTimestamp": "2024-04-13T07:26:52+05:30",
                  "endTimestamp": "2024-04-13T08:20:59+05:30"
              }
          ]
      }
  ];

  const [activeTab, setActiveTab] = useState('pilotsTab')
  const [searchType, setSearchType] = useState("Pilot");
  const [searchText, setSearchText] = useState('');

  const [totalFlyingTime, setTotalFlyingTime] = useState({hours: 0, mins: 0});
  const [totalGroundTime, setTotalGroundTime] = useState({hours: 0, mins: 0});
  const [averageFlyingTime, setAverageFlyingTime] = useState({hours: 0, mins: 0});
  const [aircraftUtilization, setAircraftUtilization] = useState({most: '-', mostHours: 0, least: '-', leastHours: 0});
  
  const [data, setData] = useState<any[]>([]);
  const [startDate, setStartDate] = useState<any>("");
  const [endDate, setEndDate] = useState<any>("");

  const [date, setDate] = useState<any>();

  const processDataForTable = (input: any) => {
    // console.log(`processDataForTable Input: `, input);
    
    let totalFlyingHours:number = 0, totalFlyingMins:number = 0, totalGroundHours:number = 0,
    totalGroundMins:number = 0, averageFlyingMins:number = 0, averageFlyingHours:number = 0,
    maxTotalAircraftFlyingMins:number = 0, minTotalAircraftFlyingMins:number = Number.MAX_VALUE,
    mostUtilizedAircraft:string = '-', leastUtilizedAircraft:string = '-',
    mostUtilizedAircraftTimeHour:number = 0, leastUtilizedAircraftTimeHour:number = 0;

    if (input.length > 0) {
      
      let processedData = input.map((item:any) => {

        let airtime = item.airtime.split(' ');
        let airhr:(number | string) = parseFloat(airtime[0]);
        totalFlyingHours += airhr;
        
        let airmin:(number | string) = parseFloat(airtime[1]);
        totalFlyingMins += airmin;

        if (activeTab === 'aircraftsTab') {
          if (((airhr * 60) + airmin) > maxTotalAircraftFlyingMins) {
            mostUtilizedAircraft = item.tailNumber;
            mostUtilizedAircraftTimeHour = airhr;
            maxTotalAircraftFlyingMins = ((airhr * 60) + airmin);
          }
          if (((airhr * 60) + airmin) < minTotalAircraftFlyingMins) {
            leastUtilizedAircraft = item.tailNumber;
            leastUtilizedAircraftTimeHour = airhr;
            minTotalAircraftFlyingMins = ((airhr * 60) + airmin);
          }
        }

        if (airhr < 10) {
          airhr = '0' + airhr;
        }
        if (airmin < 10) {
          airmin = '0' + airmin;
        }
        let groundTime = item.ground_Time.split(' ');
        let groundhr:(number | string) = parseFloat(groundTime[0]);
        totalGroundHours += groundhr;
        
        let groundmin:(number | string) = parseFloat(groundTime[1]);
        totalGroundMins += groundmin;

        if (groundhr < 10) {
          groundhr = '0' + groundhr;
        }
        if (groundmin < 10) {
          groundmin = '0' + groundmin;
        }

        return {
          ...item,
          name: (item.lastName) ? (item.name + " " +item.lastName) : item.name,
          airtime: airhr+':'+airmin,
          ground_Time: groundhr+':'+groundmin,
        }
      })

      if (activeTab === 'aircraftsTab') {
        setAircraftUtilization((prevState) => ({
          // ...prevState,
          most: mostUtilizedAircraft,
          mostHours: mostUtilizedAircraftTimeHour,
          least: leastUtilizedAircraft,
          leastHours: leastUtilizedAircraftTimeHour,
        }))
      }

      averageFlyingMins = ((totalFlyingHours * 60) + totalFlyingMins) / input.length;
      
      if (averageFlyingMins >= 60) {
        averageFlyingHours += Math.trunc(averageFlyingMins/60);
        averageFlyingMins = averageFlyingMins % 60;
      }

      setAverageFlyingTime((prevState) => ({
        // ...prevState,
        hours: averageFlyingHours,
        mins: averageFlyingMins
      }));

      if (totalFlyingMins >= 60) {
        totalFlyingHours += Math.trunc(totalFlyingMins/60);
        totalFlyingMins = totalFlyingMins % 60;
      }

      if (totalGroundMins >= 60) {
        totalGroundHours += Math.trunc(totalGroundMins/60);
        totalGroundMins = totalGroundMins % 60;
      }

      setTotalFlyingTime((prevState) => ({
        // ...prevState,
        hours: totalFlyingHours,
        mins: totalFlyingMins
      }));
      setTotalGroundTime((prevState) => ({
        // ...prevState,
        hours: totalGroundHours,
        mins: totalGroundMins
      }));
      return processedData;
    }
    return [];
  };

  const getLeftMarginFromStartTimeStamp = (startTime:any, endTime:any) => {
    startTime = startTime.split('T')[1].split('+')[0];
    const [hourStart, minStart, secStart] = startTime.split(':');
    const timeInSecStart = parseInt(hourStart) * 3600 + parseInt(minStart) * 60 + parseInt(secStart);

    endTime = endTime.split('T')[1].split('+')[0];
    const [hourEnd, minEnd, secEnd] = endTime.split(':');
    const timeInSecEnd = parseInt(hourEnd) * 3600 + parseInt(minEnd) * 60 + parseInt(secEnd);

    const startTimeInPercentage = (timeInSecStart / 864) + '%';
    const endTimeInPercentage = ((timeInSecEnd - timeInSecStart) / 864) + '%';

    return ([startTimeInPercentage, endTimeInPercentage]); 
  }

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    
    window.addEventListener('resize', handleResize);
    
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const columns: any = React.useMemo(
    () => [
      {
        id: (activeTab === 'pilotsTab') ? 'name' : (activeTab === 'instructorsTab') ? 'name' : 'tailNumber',
        Header: () => { return (activeTab === 'pilotsTab') ? 'Pilot Name' : (activeTab === 'instructorsTab') ? 'Instructor Name' : 'Reg. No.' },
        accessor: (activeTab === 'pilotsTab') ? 'name' : (activeTab === 'instructorsTab') ? 'name' : 'tailNumber',
        isFixed: true,
        isDisplayed: true,
        sticky: 'left',
        maxWidth: 1,
      },

      {
        id: 'airtime',
        Header: 'Total Hrs',
        accessor: 'airtime',
        isFixed: false,
        isDisplayed: true,
        maxWidth: 1,
      },

      {
        id: 'ground_Time',
        Header: 'GND Hrs',
        accessor: 'ground_Time',
        isFixed: false,
        isDisplayed: true,
        maxWidth: 1,
      },

      {
        id: 'chart_part',
        Header: () => {
          return (<>
            <div style={{ width: '1px', height: '200%', borderRight: '1px solid #ebebeb', zIndex: '10', position: 'absolute', top: -20 }}></div>
          <div id="timelineHeader" style={{ display: `flex`, width: `100%`}} >
            {Array.from({ length: 24 }, (_, index) => (
              <div key={index} style={{ 
                flex: 1, 
                position: 'relative', 
                // borderRight: '1px solid gray',
                height: '100%',
                boxSizing: 'border-box'
              }}>
                {(index%2 !== 0) &&
                <span style={{ color: `gray`, fontSize: `10px`, position: `absolute`, left: -3, top: 0 }}>{index}</span>}
                {(index !== 23) && 
                <div style={{
                  position: 'absolute',
                  // bottom: 0,
                  // top: 22,
                  top: (index%2 === 0) ? 18 : 23,
                  right: 0,
                  width: '100%',
                  height: '100%',
                  zIndex: `100`,
                  borderRight: '1px solid #ebebeb',
                  borderLeft: index === 0 ? '1px solid #ebebeb' : 'none',
                  boxSizing: 'border-box'
                }} />
              }
              </div>
            ))}
          </div>
          </>)
        },
        Cell: (cell:any) => {
          //cell.row.original has data for current row
          // console.log(`cell: `, cell);
          // console.log(`cell: `, cell.row.original);
          // console.log(cell.row.id)

          return (<>
            <div className="d-flex" style={{ height: '100%', position: 'absolute', width: getHeaderElementChartWidth() , top: 0 }}>

            {Array.from({ length: 24 }, (_, index) => (
              <div key={index} style={{ 
                flex: 1, 
                position: 'relative', 
                // borderRight: '1px solid gray',
                height: '100%',
                boxSizing: 'border-box'
              }}>
                {(index !== 23) && 
                <div style={{
                  position: 'absolute',
                  bottom: 0,
                  // top: 22,
                  // top: (index%2 === 0) ? 18 : 23,
                  right: 0,
                  width: '100%',
                  height: '100%',
                  zIndex: `100`,
                  borderRight: '1px solid #ebebeb',
                  borderLeft: index === 0 ? '1px solid #ebebeb' : 'none',
                  boxSizing: 'border-box'
                }} />
              }
              </div>
            ))}

            </div>
            <div className="d-flex gantt-area" style={{backgroundColor: '#feeeea', width: '100%', height: '100%'}}>
              
              {cell.row.original.fileData.map((rowItem:any, index:number) => {
                // const [leftMargin, width] = getLeftMarginFromStartTimeStamp(rowItem.startTimestamp, rowItem.endTimestamp);
                // console.log(`Left Margin at ${index}: `, leftMargin);
                // console.log(`Width at ${index}: `, width);
                const [left, width] = getLeftMarginFromStartTimeStamp(rowItem.startTimestamp, rowItem.endTimestamp);
                // console.log(`Left value at ${index}: `, left);
                // console.log(`Width at ${index}: `, width);

                return (
                  // <div className="gantt-plot" style={{ marginLeft: leftMargin, width: width, height: '100%', borderRadius: '4px' }} />
                  <div className="gantt-plot" style={{ left: left, width: width, height: '100%', borderRadius: '4px', position: 'sticky' }} />
                )
              })}
              
            </div>
            </>)
        },
        accessor: 'chart_part',
        isFixed: false,
        isDisplayed: true,
        sticky: 'right',
        maxWidth: 10,
      },

    ], [activeTab, data, windowWidth]);

    
  const getHeaderElementChartWidth = () => {
    const headerElementChart = document.getElementById('timelineHeader');
    if (headerElementChart) {
      return headerElementChart.offsetWidth;
    }
    return '100%'
  }


  useEffect(() => {
    if (date?.$d) {
      setStartDate(() => getDateString(date?.$d));

      // console.log(`getDateString Input Param: `, date?.$d);
      // console.log(`Start Date: `, getDateString(date?.$d));

      setEndDate(() => getDateString(addDays(date?.$d, 1)));
    } else {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      setStartDate(() => getDateString(today));
      setEndDate(() => getDateString(addDays(today, 1)));
    }
  }, [date]);


  useEffect(() => {

    // setData(() => processDataForTable(dummyPilotRes));

    setTotalFlyingTime(() => ({hours: 0, mins: 0}));
    setTotalGroundTime(() => ({hours: 0, mins: 0}));
    setAverageFlyingTime(() => ({hours: 0, mins: 0}));
    setAircraftUtilization(() => ({most: '-', mostHours: 0, least: '-', leastHours: 0}));

    // type: 1-pilot, 2-aircraft, 3-instructor
    if (activeTab === `pilotsTab` && startDate) {
      
      const dataBody = {
        "type": 1,
        "startdate": startDate,
        "enddate": endDate,
      }
      // console.log(`dataBody: `, dataBody);
      
      FlyingDutyService.flightOverview(dataBody).then((response:any) => {
        console.log(`Pilot res: `, response);

        setData(() => processDataForTable(response.data));
      }).catch((error:any) => {
        console.log(`Error getting pilots' flight overview details: `, error)
      })
    }
    else if (activeTab === `aircraftsTab` && startDate) {
      const dataBody = {
        "type": 2,
        "startdate": startDate,
        "enddate": endDate,
      }
      FlyingDutyService.flightOverview(dataBody).then((response:any) => {
        console.log(`Aircraft res: `, response);
        setData(() => processDataForTable(response.data));
      }).catch((error:any) => {
        console.log(`Error getting aircrafts' flight overview details: `, error)
      })
    }
    else if (activeTab === `instructorsTab` && startDate) {
      const dataBody = {
        "type": 3,
        "startdate": startDate,
        "enddate": endDate,
      }
      
      // const dataBody = {
      //   "startdate": "2024-04-02T13:00:00.000Z",
      //   "enddate": "2024-04-04T13:00:00.000Z",
      //   "type": 3,
      // }
      // console.log(`Instructor dataBody: `, dataBody);
      FlyingDutyService.flightOverview(dataBody).then((response:any) => {
        console.log(`Instructor res: `, response);
        setData(() => processDataForTable(response.data));
      }).catch((error: any) => {
        console.log(`Error getting instructors' flight overview details: `, error)
      })
    }
  }, [activeTab, startDate]);

  const getDateString = (localDate:any) => {
    // console.log(`localDate: `, localDate);
    // Get the local year, month, date, hours, minutes, and seconds
    let year = localDate.getFullYear();
    let month = String(localDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    let day = String(localDate.getDate()).padStart(2, '0');
    let hours = String(localDate.getHours()).padStart(2, '0');
    let minutes = String(localDate.getMinutes()).padStart(2, '0');
    let seconds = String(localDate.getSeconds()).padStart(2, '0');
    
    // Construct the ISO 8601 formatted string
    // let isoString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
    let isoString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}+05:30`;
   
    return isoString;
  }

  const addDays = (date:any, days:any) => {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  const onSearchInputChange = (e: any) => {
    setSearchText(e.target.value);

    // Apply filters based on the selected search type
    if (searchType === "Reg. No") {
      setFilter("tailNumber", e.target.value);
    }

    else if (searchType === "Pilot") {
      setFilter("name", e.target.value);
    }
  };


  const tableInstance = useTable({
    columns,
    data,
  },
    useColumnOrder,
    useFilters,
    useGlobalFilter,
    useSortBy,
    useBlockLayout,
    usePagination,
    useSticky
  );


  const rowClick = (rowid: any, data: any) => {

  }

  const [SearchField, setSearchField] = useState("");

  // Function to handle button click and update the active tab
  const handleTabClick = (tabId: any) => {
    if (activeTab !== tabId) {
      setActiveTab(tabId);
    }
    if (tabId === 'aircraftsTab') {
      setSearchType('Reg. No');
    }
  }

  const [requiredDateFormat, setRequiredDateFormat] = useState(() => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = today.toLocaleString('en-US', { month: 'short' });
    return `${day} ${month}`;
  });

  const [isOpen, setIsOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const changeDate = (newValue: any) => {
    // console.log(`newValue: `, newValue);
    const dateObject = new Date(newValue.$d)
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      day: '2-digit',
      month: 'short'
    }).format(dateObject);
    const DateArr = formattedDate.split(" ");
    const requiredDateFormat = DateArr[1] + " " + DateArr[0];
    setDate(newValue);
    setRequiredDateFormat(requiredDateFormat);
  };

  const [totalHours, setTotalHours] = useState("-");
  const [totalMinutes, setTotalMinutes] = useState("-");
  const [avgFlyingHr, setAvgFlyingHr] = useState("-");
  const [avgFlyingMin, setAvgFlyingMin] = useState("-");

  const total_hours_in_min = parseInt(totalHours) * 60;
  const total_min = parseInt(totalMinutes);

  const totalDuration = total_hours_in_min + total_min;

  const
    {
      setFilter,
      getTableProps,
      getTableBodyProps,
      headerGroups,
      page,
      rows,
      prepareRow,
      allColumns,
      setColumnOrder,
      getToggleHideAllColumnsProps,
    } = tableInstance;

  // return (<>Flight Time Overview</>)


  return (
    <>
      <div className="flyingDuty-bgStyle">
        <div className="flyingDuty-page-style">
          <div className="Flight-Time-Overview">Flight Time Overview</div>
          <div className="Provides-you-para">Provides you with a visual overview of pilots' flying hours distribution throughout the day.</div>

          <div>
            <Row className='row-cols-3 row-cols-lg-5 g-2 g-lg-3'>
              <Col xs={12} md={4} lg={5} className="col-style p-3" >
                <div>
                  <div className="col-top-line">Total Flying Time</div>
                  <span className="bold-text"><span className="normal-text">{totalFlyingTime.hours}</span>hr <span className="normal-text"> {totalFlyingTime.mins}</span>min</span>
                </div>
              </Col>

              <Col xs={12} md={4} lg={5} className="col-style p-3">
                < div>
                  <div className="col-top-line">Total Time Spent on Ground</div>
                  <span className="bold-text"><span className="normal-text">{totalGroundTime.hours}</span>hr<span className="normal-text"> {totalGroundTime.mins}</span>min</span>
                </div>
              </Col>

              <Col xs={12} md={4} lg={5} className="col-style p-3">
                <div>
                  <div className="col-top-line">Peak Flying Hour</div>
                  <span className="flyingDuration">13:20 - 15:45</span>
                </div>
              </Col>

              <Col xs={12} md={4} lg={5} className="col-style p-3">
                < div>
                  <div className="col-top-line">Average Flying Hours</div>
                  <span className="bold-text"><span className="normal-text">{averageFlyingTime.hours}</span>hr<span className="normal-text"> {averageFlyingTime.mins}</span>min</span>
                </div>
              </Col>


              {(activeTab === 'aircraftsTab') && <Col xs={12} md={4} lg={5} className="aircraftUtilisationCol p-3">
                <div>
                  <div className="Aircraft-Utilization-top-line">Aircraft Ulilization  (Most / Least)</div>
                  <span className="bold-text"><span className="normal-text">{aircraftUtilization.most}</span>(~{aircraftUtilization.mostHours}hr)</span>
                  <span className="divider">|</span>
                  <span className="bold-text"><span className="normal-text">{aircraftUtilization.least}</span>(~{aircraftUtilization.leastHours}hr)</span>
                </div>
              </Col>}

            </Row>
          </div>

          <div className="tab-button-style">
            <button onClick={() => handleTabClick('pilotsTab')} className={`tab-button-pilot ${activeTab === 'pilotsTab' ? 'tab-active' : ''}`}>Pilot</button>
            <div className="buttonDivider"></div>
            <button onClick={() => handleTabClick('instructorsTab')} className={`tab-button-flying-instructor ${activeTab === 'instructorsTab' ? 'tab-active' : ''}`}>Flying Instructor</button>
            <div className="buttonDivider"></div>
            <button onClick={() => handleTabClick('aircraftsTab')} className={`tab-button-aircraft ${activeTab === 'aircraftsTab' ? 'tab-active' : ''}`}>Aircraft</button>
          </div>

        </div>


        <div className="flyingDutyBottom-section">
          <Row className="bottom-section-position">
            <Col xs={8} md={4} lg={3}></Col>

            <Col xs={12} md={4} className="bottom-section-col-style">
              <div className='searchBarStyle'>
                <input type="text" className="searchArea searchBarText-space search-icon-style" placeholder={activeTab === 'aircraftsTab' ? "Search by reg. no" : "Search by name"} onChange={onSearchInputChange} />
              </div>
            </Col>

            <Col xs={8} md={4} lg={4} className="bottom-section-tab-btn">
              <Button variant="secondary" className="settings-btn me-3">
                <i className="settings-btn-icon"></i>
              </Button>

              <Button className="filter-btn offset-me-6">
                <i className="filter-btn-icon"></i>Filter
              </Button>
            </Col>

            <LocalizationProvider dateAdapter={AdapterDayjs}>

              <Col className="calendarBtnStyle">

                <DatePicker className="text-field" localeText={{
                  fieldMonthPlaceholder: () => 'MMM',
                }} format='D MMM YYYY' value={date || Date.now} onChange={(newValue: any) => changeDate(newValue)}
                  open={isOpen}
                  onClose={() => setIsOpen(false)}
                  slotProps={{
                    textField: {
                      onClick: () => setIsOpen(true),
                    },
                  }}
                  views={['year', 'month', 'day']} />
                <Button className="calendar-btn"
                  onClick={() => setIsOpen(!isOpen)}>

                    <i className="calendar-btn-icon" />

                  Date<div className="dropdown-btn-icon"></div>
                  <div className="btndivider" /><span className="dateArea">{requiredDateFormat}</span><div className="btndivider" />
                  <span className="timeArea">00 - 24</span>
                </Button>
              </Col>
            </LocalizationProvider>
          </Row>

          {/* {!data.length && !noResultsFound && ( */}
          {!data.length && (
            <div className="text-center no-files-text">No data for selected date!</div>
          )}

          {data.length !== 0 && (
            <div className="table-styling">
              <StickyTableFlyingDutyTime tableInstance={tableInstance}
                searchField={SearchField}
                rowClick={rowClick}
                showPaginationDiv={true}>
              </StickyTableFlyingDutyTime>
            </div>
          )}



        </div>

      </div>

    </>
  )
}

export default FlyingDutyTime;