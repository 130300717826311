import axiosInstance from "./baseService";
import { getLocalStorage } from "../common/utils/localStorageHandler";

const API_URL = process.env.REACT_APP_AUTHENTICATED_URL;

//let authTokens = getLocalStorage("authTokens", "companyid")
// const [authTokens, setAuthTokens] = useState(getLocalStorage("authTokens", ""));

//gets list of registered aircrafts
const getAircraftList = () =>
{
    let authToken:any = getLocalStorage("authTokens", "")  //Getting authtokens in function scope 
    return axiosInstance.get(API_URL+"/AircraftCompany/GetAircraftList/"+authToken.companyid)
    .then(response=>{
        return response.data
    })
}



const getOrgAircraftInfo = () => {
    let authTokens = getLocalStorage("authTokens", "companyid")  //Getting authtokens in function scope 
    let formData = new FormData();
    formData.append(`companyid`, authTokens.companyid);
    return axiosInstance.post(API_URL +`/ManageAircraftPilot/GetOrgAircraftInfo`,formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
                'roleid': authTokens.roleid,

            }
        }).then(response => {
            return response.data;
        });
}

const updateAircraftStatus = (obj: any) => {
    let authTokens = getLocalStorage("authTokens", "companyid")  //Getting authtokens in function scope 
    return axiosInstance.post(API_URL + `/ManageAircraftPilot/UpdateAircraftStatus`, obj,
        {
            headers: {
                'Content-Type': 'application/json',
                'roleid': authTokens.roleid,

            }
        }
    ).then (response => {
        return response;
    })
}

const getAircraftModelList = () => {
    let authTokens = getLocalStorage("authTokens", "companyid")
    return axiosInstance.get(API_URL +`/ManageAircraftPilot/GetAircraftModelList`,
    {
        headers: {
            'roleid': authTokens.roleid
        }
    })
    .then(response => {
        return response;
    })
}

const getAircraftType = (obj:any) => {
    let authTokens = getLocalStorage("authTokens", "companyid")
    return axiosInstance.post(API_URL +`/ManageAircraftPilot/GetAircraftType`,obj,
    {
        headers: {
            'Content-Type': 'application/json',
            'roleid': authTokens.roleid,
        }
    }).then(response => {
        return response
    })
}

const getEngineType = (obj:any) => {
    let authTokens = getLocalStorage("authTokens", "companyid")
    return axiosInstance.post(API_URL +`/ManageAircraftPilot/GetEngineType`,obj,
    {
        headers: {
            'Content-Type': 'application/json',
            'roleid': authTokens.roleid,
        }   
    }).then(response => {
        return response
    })
}

const saveAircraftData = (inputObj:any) => {
    // console.log(`saveAircraftData: `, inputObj);
    let authTokens = getLocalStorage("authTokens", "companyid");
    return axiosInstance.post(API_URL +`/ManageAircraftPilot/SaveAircraftData`,inputObj,
    {
        headers: {
            'Content-Type': 'application/json',
            'roleid': authTokens.roleid,
        }
    }).then(response => {
        return response
    })
}


const AircraftService = {
    getAircraftList,
    getOrgAircraftInfo,
    updateAircraftStatus,
    getAircraftModelList,
    getAircraftType,
    getEngineType,
    saveAircraftData,
}

export default AircraftService;