import { Modal, Button } from "react-bootstrap";
import { Default } from "react-toastify/dist/utils";

const DeleteLogsModal = (props: any) => {

    // console.log(`DeleteLogsModal Props: `, props)

    const { deleteCallBack, fileId, onHide, ismultiplefilesselected, deleteSelectedFiles, rowIndex,...others } = props;

    const deleteFile = () => {
        if(ismultiplefilesselected)  deleteSelectedFiles();
        else  deleteCallBack(fileId, rowIndex);
        onHide();
    }
    
    return (
        <>
            <Modal {...others}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                {/* <Modal.Header closeButton className="delete-modal-header"  onClick={() => onHide()}> */}
                <Modal.Header closeButton id="delete-modal-header"  onClick={() => onHide()}>
                </Modal.Header>
                <Modal.Body>
                    <div className="delete-modal-title">Delete Logs</div>
                    <div className="delete-modal-body">
                        Are you ceratin you want to delete the selected log? This action if performed is irreversible.
                    </div>
                </Modal.Body>
                <Modal.Footer className="assign-modal-footer">
                    <Button variant="light border" onClick={() => onHide()}>Close</Button>
                    <Button variant="danger" onClick={deleteFile}>Yes, Delete Logs</Button>
                </Modal.Footer>

            </Modal>
        </>
    )
}
export default DeleteLogsModal 