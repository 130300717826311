import './Sidebar.scss'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import '../../../../src/assets/styles/_variables.scss'
import SidebarIcons from './SidebarIcons';
import { useState } from 'react';
import { getLocalStorage, removeLocalStorage } from '../../utils/localStorageHandler';
import { useAuth } from '../../../context/authProvider';
import React from 'react';
import ReactDOM from 'react-dom';

const Sidebar = (props: any) => {
    let navigate = useNavigate();
    let { client } = useParams()
    let activeTab = useLocation()

    const { authTokens, setLoginData } = useAuth();
    const [authToken, setAuthToken] = useState(getLocalStorage("authTokens", ""))
    // const authToken = getLocalStorage("authTokens", "");
    const [showLogoutBtn, setShowLogoutBtn] = useState(false)
    
    // Function to logout
    const logOut = () => {
        removeLocalStorage("authTokens");
        setLoginData();
        navigate("/", { replace: true });
    }

    // All the available pages on the side bar which the user can navigate to
    // let tabs = [
    //     {
    //         tabName: "Debrief",
    //         isTabActive: true,
    //         isTabDisplayed: true,
    //         id: "debrief"
    //     },
        // {
        //     tabName: "Flights",
        //     isTabActive: false,
        //     isTabDisplayed: true,
        //     id: "flights"
        // },
    //     {
    //         tabName: "Imports",
    //         isTabActive: false,
    //         isTabDisplayed: true,
    //         id: "import"
    //     },
    //     {
    //         tabName: "Flying Duty Time",
    //         isTabActive: false,
    //         isTabDisplayed: true,
    //         id: "flying_duty_time"
    //     },
    //     {
    //         tabName: "Reports",
    //         isTabActive: false,
    //         isTabDisplayed: true,
    //         id: "reports"
    //     },
    //     {
    //         tabName: "Settings",
    //         isTabActive: false,
    //         isTabDisplayed: true,
    //         id: "settings"
    //     }
    // ]

    let tabs = [
        {
            tabName: "Debrief",
            isTabActive: false,
            // isTabActive: true,
            isTabDisplayed: true,
            id: "debrief"
        },
        {
            tabName: "Flights",
            isTabActive: true,
            // isTabActive: false,
            isTabDisplayed: true,
            id: "flights"
        },
        {
            tabName: "Imports",
            isTabActive: false,
            isTabDisplayed: true,
            id: "import"
        },
        // {
        //     tabName: "Flight Time",
        //     isTabActive: false,
        //     isTabDisplayed: true,
        //     id: "flying_duty_time"
        // },
        {
            tabName: "Settings",
            isTabActive: false,
            isTabDisplayed: true,
            id: "settings"
        }
    ]

    if (authToken && authToken.roleid !== 1) {
        tabs = tabs.filter(tab => tab.id !== "settings");
    }

    const [allTabs, setAllTabs] = useState(tabs);

    // React.useEffect(() => {
    //     console.log(`allTabs: `, allTabs)
    // }, [allTabs])

    
    // Function to go to any of the selected page
    const goToPage = (page: any) => {
        allTabs.forEach(tabs => {
            tabs.isTabActive = false
        });
        page.isActive = true;
        if (client !== undefined) {
            navigate(`/${client}/dashboard/${page.id}`)
        } else {
            navigate(`/dashboard/${page.id}`)
        }
    }
    return (
        <>
            <div className="sidebar-container d-flex flex-column justify-content-center">
                <div className='d-flex flex-column flex-grow-1 justify-content-center'>
                    {
                        allTabs.map((tab) => (
                            <button className="btn-sidebar" key={'div-' + tab.id} onClick={() => {
                                goToPage(tab)
                            }}>
                                <span key={'span-' + tab.id} className={activeTab.pathname.split('/').pop() === tab.id ? "sidebar-" + tab.id + " active" : "sidebar-" + tab.id}>
                                    <SidebarIcons props={tab} />
                                </span>
                            </button>
                        ))
                    }
                </div>
                <div className='btn-sidebar profile' onClick={() => setShowLogoutBtn(!showLogoutBtn)}>
                    <button className={showLogoutBtn === false ? "btn-logout hide" : "btn-logout shadow"} onClick={logOut}></button>
                </div>
            </div>
        </>
    )
}

export default Sidebar;