import { Slider } from "@mui/material"
import { useState, useEffect } from "react"
import { Dropdown } from "react-bootstrap"
import { JulianDate } from "cesium"
import "./DebriefPlayer.scss"
import { clockViewModel } from "../../../../../common/utils/cesiumDataHandler"
import { TabTimeSyncBroadcast, TabDataBrodacast, TabDataMsgType } from "../../../../../common/utils/utils"

const SliderComp = (props: any) => {

    const funcHolder: any = {};

    // console.log(`Slider Props: `, props)

    const [playerSpeed, setPlayerSpeed] = useState<Number>(1);
    // const [isDebriefPause, setIsDebriefPause] = useState(false)
    const [isDebriefPause, setIsDebriefPause] = useState(true)
    const [timeChangeData, setTimeChangeData] = useState({ currentTimeValue: 0, currentLocalValue: "", currentUtcValue: "" })

    useEffect(() => {
        if (props?.viewer?.clock) {
            // console.log(`SliderComp useEffect Ran: `, props.gotoPhase)
            // console.log(`Slider Props: `, props)
            jumpToDate(props.gotoPhase)
        }
    }, [props.gotoPhase]);

    /**
 * Speeds up or slows down the Cesium viewer's clock by adjusting the multiplier.
 * @param {Number} multiplier - The multiplier to set for the clock speed.
 * @returns {void}
 */
    const SpeedUp = (multiplier: Number) => {
        // Get the clock from the viewer's props
        let clock = props.viewer.clock;

        // Set the multiplier for the clock speed
        clock.multiplier = multiplier;

        // Update the player speed state
        setPlayerSpeed(multiplier);

        // Broadcast the clock speed change to other tabs or components
        TabDataBrodacast.postMessage({ id: TabDataMsgType.setClockSpeed, data: multiplier });
    }

    /**
 * Skips the Cesium viewer's clock forward or backward by a specified number of seconds.
 * @param {number} seconds - The number of seconds to skip. Positive values skip forward, negative values skip backward.
 * @returns {void}
 */
    const skipBySecond = (seconds: number) => {
        // console.log(`skipBySecond: `, seconds);

        // Get the current Julian Date from the viewer's clock
        let currentJulianDate = props.viewer.clock.currentTime;

        // console.log(`currentJulianDate: `, currentJulianDate);

        // Add or subtract the specified number of seconds to the current Julian Date
        JulianDate.addSeconds(currentJulianDate, seconds, props.viewer.clock.currentTime);

        // Compare the start time with the current time
        let value = JulianDate.compare(props.viewer.clock.startTime, currentJulianDate);

        // console.log(`value: `, value);

        // If the current time is before the start time, reset the current time to the calculated value
        if (value > 0) {
            // console.log(`Inside IF Statement: `, value)
            JulianDate.addSeconds(props.viewer.clock.currentTime, seconds, props.viewer.clock.currentTime);
        }
    };

    /**
     * Jumps the Cesium viewer's clock to a specific date and time.
     * @param {any} value - The Julian Date value to set for the viewer's clock.
     * @returns {void}
     */
    const jumpToDate = (value: any) => {
        // console.log(`Jump Value: `, value)
        // Add the specified number of seconds to the start time to set the current time
        JulianDate.addSeconds(props.viewer.clock.startTime, value, props.viewer.clock.currentTime);
    };

    const [clockAvailable, setClockAvailable] = useState(false);

    useEffect(() => {
        if (props?.viewer?.clock) {
            // console.log(`here 1`)
            // PauseNPlay();
        }
    }, [clockAvailable]);

    /**
 * Pauses or resumes the animation of the Cesium viewer's clock.
 * @returns {void}
 */
    const PauseNPlay = () => {
        // console.log(`here 2`)
        // Get the clock from the viewer's props
        let clock = props.viewer.clock;

        // Toggle the animation state of the clock
        clock.shouldAnimate = !clock.shouldAnimate;

        // Update the debrief pause state
        setIsDebriefPause(!clock.shouldAnimate);

        // Toggle the ability to animate the clock
        clock.canAnimate = !clock.canAnimate;

        // Broadcast the clock state change to other tabs or components
        TabDataBrodacast.postMessage({ id: TabDataMsgType.setClockState, data: clock.shouldAnimate });
    };

    /**
     * Handles keyboard key down events, triggering the play/pause action when the space bar is pressed.
     * @param {KeyboardEvent} event - The keyboard event.
     * @returns {void}
     */
    const keyBoardDownEvents = (event: KeyboardEvent) => {
        // Check if the pressed key is the space bar
        if (event.key === " " || event.code === "Space") {
            event.preventDefault();
            // Trigger the play/pause action by simulating a click on the play/pause button
            document.getElementById("playNPause")?.click();
        } else if (event.key === "ArrowRight" || event.code === "ArrowRight") {
            document.getElementById("skipForward")?.click();
        } else if (event.key === "ArrowLeft" || event.code === "ArrowLeft") {
            document.getElementById("skipBackward")?.click();
        }
    };



    /**
 * Monitors the Cesium viewer's clock and updates the time change data.
 * @returns {void}
 */
    const compClock = () => {
        // Unsubscribe from the previous event if it exists
        if (funcHolder.unsubScribeEvent1) funcHolder.unsubScribeEvent1();

        // Add a new onTick event listener to the viewer's clock
        let event = props.viewer.clock.onTick.addEventListener(function (clock: any) {
            if (!clockAvailable) {
                // console.log(`here 3`)
                setClockAvailable(true);
            }

            let currentTime1 = clock.currentTime;

            let currentUtcTime = JulianDate.addHours(currentTime1,  -5, new JulianDate());
            
            currentUtcTime = JulianDate.addMinutes(currentUtcTime,  -30, new JulianDate());

            // let utcTimeString1 = JulianDate.toIso8601(clock.currentTime).substr(11, 8);
            let utcTimeString = JulianDate.toIso8601(currentUtcTime).substr(11, 8);

            // let localTime1 = new Date(currentTime1);
            
            let localTimeString = JulianDate.toIso8601(clock.currentTime).substr(11, 8);

            // console.log(`utcTimeString1: `, utcTimeString1);
            // console.log(`localTime1: `, localTime1);

            // Calculate the difference in seconds between the current time and the start time
            let diffSeconds = JulianDate.secondsDifference(clock.currentTime, clock.startTime);

            // Update the time change data with the calculated values
            setTimeChangeData({ currentTimeValue: diffSeconds, currentLocalValue: localTimeString, currentUtcValue: utcTimeString });

            // Broadcast the current time to synchronize with other components or tabs
            TabTimeSyncBroadcast.postMessage(clockViewModel.currentTime);
        });

        // Store the event in the function holder for later unsubscription
        funcHolder["unsubScribeEvent1"] = event;
    };


    /**
 * Handles the onbeforeunload event to broadcast a message before closing all tabs.
 * @returns {void}
 */
    window.onbeforeunload = function () {
        // Broadcast a message to synchronize and close all tabs
        TabTimeSyncBroadcast.postMessage("close-all-tabs");
    };

    /**
     * Sets up the clock synchronization and keyboard events when the viewer and range.max props change.
     * @returns {void}
     */
    useEffect(() => {
        // Check if the viewer prop exists
        if (props.viewer) {
            // Initialize clock synchronization
            compClock();
        }
    }, [props.viewer, props.range.max]);

    /**
     * Adds and removes the keydown event listener for keyboard events.
     * @returns {void}
     */
    useEffect(() => {
        // Add the keydown event listener when the range.max prop changes
        window.addEventListener('keydown', keyBoardDownEvents);

        // Remove the keydown event listener when the component unmounts or the range.max prop changes
        return () => {
            window.removeEventListener('keydown', keyBoardDownEvents);
        };
    }, [props.range.max]);


    return (<>
        <div className="timeline">
            <Slider
                value={Math.round(timeChangeData.currentTimeValue)}
                onChange={(_, value) => jumpToDate(value)}
                //valueLabelDisplay="auto"
                //step={1}
                min={props.range.min}
                max={props.range.max}
                sx={{
                    "& .MuiSlider-rail": {
                        background: props.analysisData.timeLineBgString,
                        opacity: 1
                    },
                    "& .MuiSlider-rail:hover": {
                        height: "6px",
                        opacity: 0.6
                    }
                }}
                size="medium"
                className="silder-timeline">
            </Slider>

            <div className="timeline-control-wrapper d-flex align-items-center h-100">
                <button className="btn-backward" id="skipBackward" onClick={() => skipBySecond(-5)}></button>
                <button className={isDebriefPause ? "btn-play" : "btn-pause"} id="playNPause" onClick={PauseNPlay}></button>
                <button className="btn-forward" id="skipForward" onClick={() => skipBySecond(5)}></button>
                <div className="dropdown">
                    <Dropdown className="dropdown-box">
                        <Dropdown.Toggle variant="none" id="dropdown-basic" className="dropdown-toggle">
                            {`${playerSpeed}x`}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu-box">
                            {/* <Dropdown.Item href="" onClick={() => SpeedUp(0.25)}>0.25x</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item href="" onClick={() => SpeedUp(0.5)}>0.5x</Dropdown.Item>
                            <Dropdown.Divider /> */}
                            <Dropdown.Item href="" onClick={() => SpeedUp(1)}>1x</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item href="" onClick={() => SpeedUp(2)}>2x</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item href="" onClick={() => SpeedUp(4)}>4x</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className="timeline-current-time">
                    {/* <span className="label-utc">{timeChangeData.currentUtcValue} UTC</span> */}
                    <span className="label-ist">{timeChangeData.currentLocalValue} IST</span>
                    <span className="label-spacer"> / </span>
                    {/* <span className="label-ist">{timeChangeData.currentLocalValue} IST</span> */}
                    <span className="label-utc">{timeChangeData.currentUtcValue} UTC</span>
                </div>
            </div>
        </div>
    </>
    )
}
export default SliderComp;