import "./ReportsSideMenu.scss"
import { Link } from "react-router-dom";

const ReportsSideMenu = () =>{
    return (
    <div className="reports-side-menu">
        <div className="reports-title">Reports</div>
        <div className="reports-type-wrap">
            <div className="reports-type-title">DATA ANALYSIS</div>
            <ul>
                <li><Link to="summary_report">Summary</Link></li>
                <li><Link to="event_report">Event Report</Link></li>
            </ul>
        </div>
        <div className="reports-type-wrap">
            <div className="reports-type-title">FLYING REPORT</div>
            <ul>
                <li><Link to="pilots_report">Pilots</Link></li>
                <li><Link to="flying_instructors_report">Flying instructors</Link></li>
                <li><Link to="aircrafts_report">Aircrafts</Link></li>
            </ul>
        </div>
        <div className="reports-type-wrap">
            <div className="reports-type-title">VIOLATIONS REPORT</div>
            <ul>
                <li>Pilots</li>
                <li>Flying instructors</li>
                <li>Aircrafts</li>
            </ul>
        </div>
        <div className="reports-type-wrap">
            <div className="reports-type-title">AUDIT REPORT</div>
            <ul>
                <li><Link to="audit_report">Report</Link></li>
                <li><Link to="egca_upload_report">eGCA upload</Link></li>
            </ul>
        </div>
    </div>
    )
}

export default ReportsSideMenu;