import {colorStrings} from "./colorStrings"

/**
 * this function prints following elements
 * altMsl Scale (altitude from mean sea level)
 * vertical speed
 * Baro altitude label
 * @param {any} canvasCtx - 2d canvas context 
 * @param {number} canvasWidth - canvas width
 * @param {number} canvasHeight - canvas height
 * @param {number} altMslReading - alttude from mean sea level reading
 * @param {number} VSpdReading - vertical speed reading
 * @param {number} BaroAReading - Baro altutde reading
 */
const rightGroupEle = (canvasCtx : any, canvasWidth : number, canvasHeight : number, altMslReading: number, VSpdReading: number, BaroAReading:number) =>{
    
    let groupWidth = 326
    let groupHeight = 560
    let mobileView = false

    let topPos = (canvasHeight - groupHeight)/2                                             //positioned vertcaly centered on canvas
    let leftPos = canvasWidth/2 + (canvasWidth/2 - groupWidth) / 2                          // position from center of canvas

    if(canvasWidth > 1536){
        leftPos = (canvasWidth - 1536)/2 + 1536 - groupWidth
    }

    if(canvasWidth < 1536){
        leftPos = canvasWidth - groupWidth - 10
    }

    if(canvasHeight < 550 * 2.3){           //scalingFactor = 2.3
        topPos = 100
    }

    mobileView = canvasWidth <= 1280 ? true : false                      // mobileView for screen width less than equal to 1280

    canvasCtx.beginPath()
    canvasCtx.rect(leftPos, topPos, groupWidth, groupHeight)
    //canvasCtx.stroke()
    canvasCtx.closePath()
    if(mobileView){                                                         //on mobile screen vertical speed element is not available 
        altMslScale(canvasCtx, leftPos+40+126, topPos+40, altMslReading)
        baroALabel(canvasCtx, leftPos+40+126, topPos+520, BaroAReading)
        selectedAlt(canvasCtx, leftPos+40+126, topPos+0)
    }else{
        altMslScale(canvasCtx, leftPos+40, topPos+40, altMslReading)                
        vSpdScale(canvasCtx, leftPos+200, topPos+72, VSpdReading)
        baroALabel(canvasCtx, leftPos+40, topPos+520, BaroAReading)
        selectedAlt(canvasCtx, leftPos+40, topPos+0)
    }
}

/**
 * prints altMsl scale
 * @param {anyy} canvasCtx -2d canvas context 
 * @param {number} poseX - position from top of canvas
 * @param {number} poseY - position from canvas left
 * @param {number} altMslReading - altitude from mean sea level reading
 */
const altMslScale = (canvasCtx : any, poseX : number, poseY : number, altMslReading: number) =>{

    let eleWidth = 160
    let eleHeight = 480

    // scale minor and major mark calculation
    let visibleMajorMark = 6

    //let lowestVisbleValueIAS = 20
    let majorMarkDiff = 480 / visibleMajorMark
    let visibleMajorMarkCompensate = (visibleMajorMark%2===0) ? 0 : majorMarkDiff/2
    let nextMajorMark = 0
    let minorMarkDiff = 480 / visibleMajorMark / 5
    let oneUnit = 480 / visibleMajorMark / 100                                              //smallest unit of scale
    //console.log(oneUnit)
    let nextNumberinMulti100 = getNextNumber3(altMslReading)
    let scaleOffset = (getNextNumber3(altMslReading) - altMslReading) * oneUnit

    //console.log(scaleOffset)

    canvasCtx.save()
    canvasCtx.beginPath()
    canvasCtx.rect(poseX, poseY, eleWidth, eleHeight)
    canvasCtx.clip()
    canvasCtx.closePath()

    canvasCtx.beginPath()
    canvasCtx.fillStyle = colorStrings.bgLight
    canvasCtx.roundRect(poseX, poseY, eleWidth, eleHeight)
    canvasCtx.fill()
    canvasCtx.closePath()

    canvasCtx.beginPath()

    canvasCtx.strokeStyle = colorStrings.whiteColor
    canvasCtx.fillStyle = colorStrings.fontColor
    canvasCtx.lineWidth = 2
    canvasCtx.font = "500 28px Arial";

    canvasCtx.stroke()
    canvasCtx.fillStyle = colorStrings.whiteColor
    canvasCtx.rect(poseX, poseY, 2, eleHeight);
    canvasCtx.fill()
    canvasCtx.closePath()

    //print majaor, minor tick mark, and numbers on scale
    for(let i = 0; i <= visibleMajorMark; i++){

        canvasCtx.beginPath()
        canvasCtx.moveTo(poseX+2, poseY+nextMajorMark-scaleOffset+visibleMajorMarkCompensate)
        canvasCtx.lineTo(poseX+34, poseY+nextMajorMark-scaleOffset+visibleMajorMarkCompensate)
        canvasCtx.fillText( nextNumberinMulti100 - i*100+200, poseX+38, poseY+nextMajorMark-scaleOffset-8+100+visibleMajorMarkCompensate)
        canvasCtx.stroke()
        canvasCtx.closePath()

        for(let minortick = 1; minortick < 5 ;minortick++){
            canvasCtx.beginPath()
            canvasCtx.moveTo(poseX+2, poseY+nextMajorMark+(minorMarkDiff*minortick)-scaleOffset+visibleMajorMarkCompensate)
            canvasCtx.lineTo(poseX+18, poseY+nextMajorMark+(minorMarkDiff*minortick)-scaleOffset+visibleMajorMarkCompensate)
            canvasCtx.stroke()
            canvasCtx.closePath()
        }

        nextMajorMark += majorMarkDiff ;
    }

    // rectangle for altMsl label
    canvasCtx.beginPath()
    canvasCtx.fillStyle = colorStrings.blackColor
    canvasCtx.rect(poseX + 20, poseY + (eleHeight/2) - 33, 138, 66)
    canvasCtx.fill()
    canvasCtx.closePath()

    // triangle next to lebel rectangle
    canvasCtx.beginPath()
    canvasCtx.fillStyle = colorStrings.whiteColor
    canvasCtx.strokeStyle = colorStrings.blackColor
    canvasCtx.moveTo(poseX+0,poseY+240)
    canvasCtx.lineTo(poseX+24,poseY+228)
    canvasCtx.lineTo(poseX+24,poseY+252)
    canvasCtx.lineTo(poseX+0,poseY+240)
    canvasCtx.fill()
    canvasCtx.stroke()
    canvasCtx.closePath()

    //label text
    canvasCtx.beginPath()
    canvasCtx.strokeStyle = "#fff"
    canvasCtx.fillStyle = "#fff"
    canvasCtx.font = "600 42px Arial";
    canvasCtx.fillText(Math.round(altMslReading), poseX + 40, poseY + (eleHeight/2) + 12)
    canvasCtx.stroke()
    canvasCtx.closePath()

    canvasCtx.restore()
}

/**
 * prints selected alt
 * @param {any} canvasCtx - 2d canvas context
 * @param {number} poseX - position from top 
 * @param {number} poseY - position  from left
 */
const selectedAlt = (canvasCtx:any, poseX:number, poseY:number) =>{
    let eleHeight = 40
    let eleWidth = 160

    canvasCtx.beginPath()
    canvasCtx.fillStyle = colorStrings.bgDark 
    canvasCtx.roundRect(poseX, poseY, eleWidth, eleHeight, [8, 32, 0, 0])
    canvasCtx.fill()
    canvasCtx.closePath()
}

/**
 * prints baro altitude label
 * @param {any} canvasCtx - 2d canvas context
 * @param {number} poseX - position from top
 * @param {number} poseY - position from left
 * @param {number} BaroAReading - baro altitude reading
 */
const baroALabel = (canvasCtx:any, poseX:number, poseY:number, BaroAReading:number) =>{
    let eleHeight = 40
    let eleWidth = 160

    //label rectangle
    canvasCtx.beginPath()
    canvasCtx.fillStyle = colorStrings.bgDark 
    canvasCtx.roundRect(poseX, poseY, eleWidth, eleHeight, [0, 0, 32, 8])
    canvasCtx.fill()
    canvasCtx.closePath()

    //label text
    canvasCtx.beginPath()
    canvasCtx.fillStyle = colorStrings.cyan
    canvasCtx.font = "500 28px Arial";
    canvasCtx.fillText(BaroAReading.toFixed(2)+" inch", poseX+10, poseY+30)
    canvasCtx.fill()
    canvasCtx.closePath()
}

/**
 * prints vertcal speed reading
 * @param {any} canvasCtx - 2d canvas context 
 * @param {number} poseX - position from top
 * @param {number} poseY - position from left
 * @param {number} VSpdReading - vercal speed reading
 */
const vSpdScale = (canvasCtx: any, poseX:number, poseY:number, VSpdReading: number) =>{
    let eleWidth = 80
    let eleHeight = 416

    canvasCtx.beginPath()
    canvasCtx.fillStyle = colorStrings.bgLight
    canvasCtx.roundRect(poseX, poseY, eleWidth, eleHeight, [0, 32, 32, 0])
    canvasCtx.fill()
    canvasCtx.closePath()

    canvasCtx.beginPath();
    canvasCtx.fillStyle = colorStrings.whiteColor
    canvasCtx.rect(poseX, poseY, 2, 416);
    canvasCtx.fill();

    canvasCtx.font = "500 28px Arial";
    //VSpeed scale lines
    canvasCtx.lineWidth = 2
    canvasCtx.strokeStyle = colorStrings.blackColor
    canvasCtx.fillStyle = colorStrings.blackColor

    let scaleMarkDiff = eleHeight/10
    let nextMark = scaleMarkDiff

    // print scale marks on scale
    let scaleNo = 2
    for(let scalemark = 1; scalemark<10; scalemark++){
        canvasCtx.beginPath()
        canvasCtx.fillStyle = colorStrings.fontColor
        canvasCtx.strokeStyle = colorStrings.whiteColor
        if(scalemark % 2 === 0){
            canvasCtx.moveTo(poseX+0, poseY+nextMark)
            canvasCtx.lineTo(poseX+12, poseY+nextMark)
        }else{
            canvasCtx.moveTo(poseX+0, poseY+nextMark)
            canvasCtx.lineTo(poseX+24, poseY+nextMark)
            canvasCtx.fillText(scaleNo, poseX+36, poseY+nextMark+8)
            scaleNo--
        }
        canvasCtx.stroke()
        canvasCtx.closePath()
        nextMark += scaleMarkDiff
    }

    //vSpeed label
    canvasCtx.beginPath()
    canvasCtx.fillStyle = colorStrings.blackColor
    if(Math.round(VSpdReading) > 2500){
        canvasCtx.rect(poseX+16,poseY+(eleHeight/2)-28+(-(2500)*0.08),108,56)
    }else if(Math.round(VSpdReading) < -2500){
        canvasCtx.rect(poseX+16,poseY+(eleHeight/2)-28+(-(-2500)*0.08),108,56)
    }else{
        canvasCtx.rect(poseX+16,poseY+(eleHeight/2)-28+(-Math.round(VSpdReading)*0.08),108,56)
    }
    //canvasCtx.rect(poseX+16,poseY+(eleHeight/2)-28+(-Math.round(VSpdReading)*0.08),108,56)
    canvasCtx.fill()
    canvasCtx.closePath()

    //Vspeed label text
    canvasCtx.beginPath()
    canvasCtx.font = "600 28px Arial";
    canvasCtx.fillStyle = colorStrings.fontColor
    let stringVSpeed = Math.round(VSpdReading);
    if(Math.round(VSpdReading) > 2500){
        canvasCtx.fillText(stringVSpeed.toString(), poseX+35, poseY+(eleHeight/2)+(-(2500)*0.08)+7)
    }else if(Math.round(VSpdReading) < -2500){
        canvasCtx.fillText(stringVSpeed.toString(), poseX+35, poseY+(eleHeight/2)+(-(-2500)*0.08)+7)
    }else{
        canvasCtx.fillText(stringVSpeed.toString(), poseX+35, poseY+(eleHeight/2)+(-Math.round(VSpdReading)*0.08)+7)
    }
    //canvasCtx.fillText(stringVSpeed.toString(), poseX+35, poseY+(eleHeight/2)+(-Math.round(VSpdReading)*0.08)+7)
    canvasCtx.closePath()

    //vSpeed arrow
    canvasCtx.beginPath()
    canvasCtx.fillStyle = "#fff"
    canvasCtx.strokeStyle = "#000"
    if(Math.round(VSpdReading) > 2500){                                                 // do not move label outside of scale in upword direction if reading is greater 2500
        canvasCtx.moveTo(poseX+0, poseY+(eleHeight/2)+(-(2500)*0.08))
        canvasCtx.lineTo(poseX+24, poseY+(eleHeight/2)-12+(-(2500)*0.08))
        canvasCtx.lineTo(poseX+24, poseY+(eleHeight/2)+12+(-(2500)*0.08))
        canvasCtx.lineTo(poseX+0, poseY+(eleHeight/2)+(-(2500)*0.08))
    }else if(Math.round(VSpdReading) < -2500){                                          // do not move label outside of scale in downword direction if reading is less 2500
        canvasCtx.moveTo(poseX+0, poseY+(eleHeight/2)+(-(-2500)*0.08))
        canvasCtx.lineTo(poseX+24, poseY+(eleHeight/2)-12+(-(-2500))*0.08)
        canvasCtx.lineTo(poseX+24, poseY+(eleHeight/2)+12+(-(-2500))*0.08)
        canvasCtx.lineTo(poseX+0, poseY+(eleHeight/2)+(-(-2500)*0.08))
    }else{
        canvasCtx.moveTo(poseX+0, poseY+(eleHeight/2)+(-Math.round(VSpdReading)*0.08))
        canvasCtx.lineTo(poseX+24, poseY+(eleHeight/2)-12+(-Math.round(VSpdReading)*0.08))
        canvasCtx.lineTo(poseX+24, poseY+(eleHeight/2)+12+(-Math.round(VSpdReading)*0.08))
        canvasCtx.lineTo(poseX+0, poseY+(eleHeight/2)+(-Math.round(VSpdReading)*0.08))
    }
    // canvasCtx.moveTo(poseX+0, poseY+(eleHeight/2)+(-Math.round(VSpdReading)*0.08))
    // canvasCtx.lineTo(poseX+24, poseY+(eleHeight/2)-12+(-Math.round(VSpdReading)*0.08))
    // canvasCtx.lineTo(poseX+24, poseY+(eleHeight/2)+12+(-Math.round(VSpdReading)*0.08))
    // canvasCtx.lineTo(poseX+0, poseY+(eleHeight/2)+(-Math.round(VSpdReading)*0.08))
    canvasCtx.fill()
    canvasCtx.stroke()
    canvasCtx.closePath()
}

/**
 * returns integer which is mulitple of 100 
 * @param x - number
 * @returns {} nearest integer which is mulitple of 100 
 */
function getNextNumber3(x:number){
    return Math.ceil(x/100)*100
}

export default rightGroupEle