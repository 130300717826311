// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 16px;
  height: 16px;
  border: 2px solid #fff; /* Light grey */
  border-top: 2px solid #1d1414; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.loading-spinner-big {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}`, "",{"version":3,"sources":["webpack://./src/common/components/loadSpinner/loadSpinner.scss"],"names":[],"mappings":"AAAA;EACI;IACE,uBAAA;EACJ;EACE;IACE,yBAAA;EACJ;AACF;AACE;EACE,WAAA;EACA,YAAA;EACA,sBAAA,EAAA,eAAA;EACA,6BAAA,EAAA,UAAA;EACA,kBAAA;EACA,uCAAA;AACJ;;AAEE;EACE,WAAA;EACA,YAAA;EACA,0BAAA,EAAA,eAAA;EACA,8BAAA,EAAA,UAAA;EACA,kBAAA;EACA,uCAAA;AACJ","sourcesContent":["@keyframes spinner {\n    0% {\n      transform: rotate(0deg);\n    }\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n  .loading-spinner {\n    width: 16px;\n    height:16px;\n    border: 2px solid  #fff;  /* Light grey */\n    border-top: 2px solid #1d1414; /* Black */\n    border-radius: 50%;\n    animation: spinner 1.5s linear infinite;\n  }\n\n  .loading-spinner-big{\n    width: 50px;\n    height:50px;\n    border: 10px solid  #f3f3f3;  /* Light grey */\n    border-top: 10px solid #383636; ; /* Black */\n    border-radius: 50%;\n    animation: spinner 1.5s linear infinite;\n  }\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
