import React, { useEffect } from "react";
import './StickyTable.scss'
import { Styles } from "./StickyTableStyles";
import { Dropdown } from "react-bootstrap";

const StickyTable = (props: any) => 
{
  const [SearchText, setSearchText] = React.useState('');
  const tableInstance: any = props.tableInstance
  const searchField: any = props.searchField;
  //const {rowClick} = props
  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    nextPage,
    canNextPage,
    previousPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    state,
    prepareRow,
    allColumns,
    getHeaderGroupProps,
    getToggleHideAllColumnsProps,
  } = tableInstance;

  // console.log(`object`)

  const { pageIndex } = state;
  const dataArray = props.showPaginationDiv ? page : rows;

  useEffect(() => {
    setSearchText(props.searchField);
  }, [props.searchField]);

  // useEffect(() => {
  //   console.log(`dataArray: `, dataArray);
  // }, []);

  return (
    <Styles>
      <section {...getTableProps()} className={`table sticky ${!dataArray.length ? "over-flow-none" : ""}`}>
        <div className="header">
          {headerGroups.map((headerGroup: any) => (
            <div {...headerGroup.getHeaderGroupProps()} className="tr" >
              {headerGroup.headers.map((column: any) => (
                <div {...column.getHeaderProps(column.getSortByToggleProps())} className="th">
                  {column.render('Header')}
                  <span className={column.isSorted ? (column.isSortedDesc ? 'desc' : 'asc') : ''}>
                  </span>
                </div>
              ))}
            </div>
          ))}
        </div>

        <div {...getTableBodyProps()} className="body">
          {dataArray.map((row: any) => {
          // console.log(`row: `, row);
          //had to add complete else because setting bgcolor through a variable was making the rows eexhibit unexpected behaviour
          //`avgOverallScore` is only present in Debrief Log List APIs
          if (row?.original?.hasOwnProperty('avgOverallScore') && row?.original?.status !== 3) {
          prepareRow(row);
          return (
            <div {...row.getRowProps()} className="tr" onClick={() => props.rowClick(row.id, tableInstance.data)}>
                {row.cells.map((cell: any) => (
                  <div {...cell.getCellProps()} className="td" style={{backgroundColor: `#FFF9C4`, left: '0px'}}>
                    {cell.render('Cell')}
                  </div>
                ))}
              </div>
            );
          } else {
            prepareRow(row);
            return (
              <div {...row.getRowProps()} className="tr" onClick={() => props.rowClick(row.id, tableInstance.data)}>
                  {row.cells.map((cell: any) => (
                    <div {...cell.getCellProps()} className="td">
                      {cell.render('Cell')}
                    </div>
                  ))}
                </div>
              );
          }
          })}
        </div>
      </section>
      {props.showPaginationDiv &&
        <div className="paginationTab">
          <div className="pagination-details">
            <div className="pagination-text">Items per page : 10</div>
            <div className="vertical-line mx-2"></div>
            <div className="pagination-item-num"> {` `}
              {((pageIndex + 1) < pageCount) &&
                `${pageIndex * 10 + 1} - ${pageIndex * 10 + 10}`
              }
              {((pageIndex + 1) === pageCount) &&
                `${pageIndex * 10 + 1} - ${tableInstance.rows.length}`
              }
              {tableInstance.rows.length === 0 && `0`
              }
              <span className="pagination-text"> {` `}of {tableInstance.rows.length} items </span>
            </div>
          </div>

          <div className="pagination-content">
            <button className="btn btn-light btn-sm mx-2" onClick={previousPage} disabled={!canPreviousPage}>{`❮`}</button>
            {pageOptions.map((page: any, index: any) => (
              <React.Fragment key={index}>
                {(index === 0 || index === pageCount - 1 || (index >= pageIndex - 1 && index <= pageIndex + 1)) ? (
                  <button
                    onClick={() => gotoPage(index)}
                    className={pageIndex === index ? "btn btn-primary btn-sm active mx-1" : "btn btn-light btn-sm mx-1"} >
                    {index + 1}
                  </button>
                ) : (index === 1 || index === pageCount - 2) && (
                  <span className="mx-1">...</span>
                )}
              </React.Fragment>
            ))}
            <button className="btn btn-light btn-sm mx-2" onClick={nextPage} disabled={!canNextPage}>{`❯`}</button>
            <div className="vertical-line mx-2"></div>
            <select className="paginationDropdown btn btn-light btn-sm mx-1" style={{width: `fit-content`}} value={pageIndex} onChange={(e: any) => gotoPage(Number(e.target.value))} >
              {pageOptions.map((page: any, index: any) => (
                <option key={index} value={index}>
                  {index + 1}
                </option>
              ))}
            </select>
            <span className="pagination-text"> {` `} of {pageOptions.length} pages </span>
          </div>
        </div>
      }
    </Styles>
  );
}

export default StickyTable;



