import { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';

const GaugeGraphPlot = (props: any) => {

    // console.log(`GaugeGraphPlot props: `, props);

    const chartRef = useRef(null);

    useEffect(() => {

        // console.log(`Outside IF STATEMENT: `, props.value);
        let graphValue = props.value;
        if (isNaN(graphValue)) {
            // console.log(`Not a Number`);
            graphValue = 0;
        }

        // console.log(`graphValue: `, graphValue);
        
        if (graphValue || graphValue === 0) {

            // console.log(`Inside IF STATEMENT: `, props.value);

            const width = 70;  //hard coded values for now
            const height = 70;  //hard coded values for now
            const radius = Math.min(width, height) / 2;

            // Remove previous chart
            d3.select(chartRef.current).select('svg').remove();

            const svg = d3
                .select(chartRef.current)
                .append('svg')
                .attr('width', width)
                .attr('height', height)
                .append('g')
                .attr('transform', `translate(${width / 2},${height / 1.5}) rotate(-90)`); // Adjust the rotation here

            // Background Arc
            const background = svg
                .append('path')
                .datum({ endAngle: Math.PI })
                .style('fill', '#ddd')
                .attr('d', d3.arc<SVGPathElement, { endAngle: number }>()
                    .innerRadius(0.7 * radius)
                    .outerRadius(radius)
                    .startAngle(0)
                    .cornerRadius(10) // Set the radius for rounded corners
                );

            // Value Arc
            const foreground = svg
                .append('path')
                .datum({ endAngle: graphValue / 10 * Math.PI })
                .style('fill', props.fillColor)
                .attr('d', d3.arc<SVGPathElement, { endAngle: number }>()
                    .innerRadius(0.7 * radius)
                    .outerRadius(radius)
                    .startAngle(0)
                    .cornerRadius(10) // Set the radius for rounded corners
                );

            // Add needle
            const needleLength = radius;
            const needle = svg
                .append('line')
                .attr('class', 'needle')
                .attr('x1', 0)
                .attr('y1', 0)
                .attr('x2', needleLength)
                .attr('y2', 0)
                .attr('stroke', '#333')
                .attr('stroke-width', '2');

            // Update needle position based on value
            const needleAngle = graphValue / 10 * Math.PI - Math.PI / 2;
            needle.attr('transform', `rotate(${needleAngle * (180 / Math.PI)})`);
        }
    }, [props.value]);

    return <div ref={chartRef}></div>;
};

export default GaugeGraphPlot;