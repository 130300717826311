import { useState, useRef, useMemo, useEffect } from "react";
import StickyTable from "../../../../../common/components/StickyTable/StickyTable";
import {
  useBlockLayout,
  useTable,
  useSortBy,
  useColumnOrder,
  useFilters,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { useSticky } from "react-table-sticky";
import AddPilotModal from "./Modals/AddPilotModal";
import {
  Accordion,
  Modal,
  OverlayTrigger,
  OverlayTriggerProps,
  Popover,
  Dropdown,
  Card,
} from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import ColumnSettings from "../../../../../common/components/ColumnSettings/ColumnSettings";
import PilotFilterBox from "./FilterBoxes/PilotFilterBox";
import PilotService from "../../../../../services/pilot.service";
import ReactivateAccountModal from "./Modals/ReactivateAccountModal";
import DeactivateAccountModal from "./Modals/DeactivateAccountModal";
import CustomToast from "../../../../../common/components/Toast/CustomToast";
import NoResultsFound from "../../debrief/NoResultsFound";

const PilotsAndInstructors = () => {
  interface Instructor {
    instructorId: number;
    instructorName: string;
  }

  interface TagGroupPair {
    [key: string]: string;
  }

  const [tagGroupPair, setTagGroupPair] = useState<TagGroupPair>({});
  const [tagsList, setTagsList] = useState<any[]>([]);
  const [groupsList, setGroupsList] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState("instructorsTab");
  // const [activeTab, setActiveTab] = useState("pilotsTab");
  // const [pilotData, setPilotData] = useState([]);
  // const [instructorData, setInstructorData] = useState([]);
  const [responseDataCopy, setResponseDataCopy] = useState<any[]>([]);
  const [showReactivateAccountModal, setShowReactivateAccountModal] =
    useState(false);
  const [showDeactivateAccountModal, setShowDeactivateAccountModal] =
    useState(false);
  const [currentRowSelectedData, setCurrentRowSelectedData] = useState<any>();
  const [apiCaller, setApiCaller] = useState(true);
  const [nameToPass, setNameToPass] = useState("");
  const [editPilotDetailsModal, setEditPilotDetailsModal] = useState(false);
  const [editInstructorDetailsModal, setEditInstructorDetailsModal] =
    useState(false);
  const [resetPasswordModal, setResetPasswordModal] = useState(false);
  const [data, setData] = useState([]);
  const [forcefulRerender, setForcefulRerender] = useState(false);
  const [instructorList, setInstructorList] = useState<Instructor[]>([]);
  const [pilotToastVisible, setPilotToastVisible] = useState(false);
  const [instructorToastVisible, setInstructorToastVisible] = useState(false);
  const [allExistingEmails, setAllExistingEmails] = useState<any[]>([]);

  const menuIconRef = useRef<any>(null);
  const filterPopoverRef = useRef<any>(null);
  const filterPopoverBodyRef = useRef<any>(null);

  const [noResultsFound, setNoResultsFound] = useState(false);

  //Adding it as a buffer state variable to let the menuOpen change to empty array when user switches tab
  const [stateChangeBuffer, setStateChangeBuffer] = useState(false);

  useEffect(() => {
    PilotService.getTagList().then((response: any) => {
      // console.log(response)
      setTagGroupPair(response.data.tagGroupPairs);
      
      setTagsList(() => Object.keys(response?.data?.tagGroupPairs));
      // setGroupsList(() => response?.data?.groups);
    });
    PilotService.getAssignableCfi().then((response: any) => {
      // console.log(`getAssignableCfi: `, response);
      if (response.data.activeInstructor !== null) {
        let instructorListWithLastName = response.data.activeInstructor.map(
          (item: any) => ({
            instructorId: item.instructorId,
            instructorName: item.instructorName + ` ` + item.lastName,
          })
        );
        // console.log(`instructorListWithLastName: `, instructorListWithLastName)
        setInstructorList(instructorListWithLastName);
        // setInstructorList(response.data.activeInstructor);
      } else {
        setInstructorList([]);
      }
    });
    PilotService.getExistingEmails().then((response: any) => {
      // console.log(`getExistingEmails response: `, response);
      setAllExistingEmails(() => response);
    });
  }, [stateChangeBuffer]);

  useEffect(() => {
    setApiCaller(!apiCaller);
  }, [forcefulRerender]);
  
  const convertDateData = (dateTimeString: string) => {
    if (dateTimeString?.includes(`2050-01-01`) || dateTimeString === null) {
      return `-`;
    }

    const date = new Date(dateTimeString);

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const day = date.getDate().toString().padStart(2, "0");
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    const formattedDateTime = `${day} ${month} ${year}, ${hours}:${minutes} IST`;

    return formattedDateTime;
  };

  const menuOpenPilotRef = useRef<boolean[]>([]);
  const menuOpenInstructorRef = useRef<boolean[]>([]);
  const [rerenderColumns, setRerenderColumns] = useState(false);

  const setMenuOpenToFalse = () => {
    menuOpenPilotRef.current = Array(menuOpenPilotRef.current.length).fill(
      false
    );
    menuOpenInstructorRef.current = Array(
      menuOpenInstructorRef.current.length
    ).fill(false);
  };

  const onClickMenu = (index: number, rowData: any) => {
    setCurrentRowSelectedData(rowData);

    if (activeTab === `pilotsTab`) {
      const updatedMenuOpen = [...menuOpenPilotRef.current];
      if (updatedMenuOpen[index]) {
        updatedMenuOpen[index] = false;
      } else {
        updatedMenuOpen.fill(false);
        updatedMenuOpen[index] = true;
      }
      menuOpenPilotRef.current = updatedMenuOpen;
    } else {
      const updatedMenuOpen = [...menuOpenInstructorRef.current];
      if (updatedMenuOpen[index]) {
        updatedMenuOpen[index] = false;
      } else {
        updatedMenuOpen.fill(false);
        updatedMenuOpen[index] = true;
      }
      menuOpenInstructorRef.current = updatedMenuOpen;
    }
    setRerenderColumns((prevState) => !prevState);
  };

  const changeStatus = (index: number, rowData: any) => {
    setMenuOpenToFalse();
    // console.log(`changeStatus called`)
    // console.log(`status: `, rowData.status)
    const name = rowData.pilotName ? rowData.pilotName : rowData.instructorName;
    // console.log(`name: `, name)
    setNameToPass(name);

    // console.log(`status: `, rowData.status);

    if (rowData.status === 0 || rowData.status === 1) {
      // console.log(`inside IF`);
      setShowDeactivateAccountModal(true);
      // const initState = menuOpen[index];
      // let updatedMenuOpen = menuOpen.map(() => false);
      // updatedMenuOpen[index] = !initState;
      // setMenuOpen(updatedMenuOpen);
    } else {
      // console.log(`inside ELSE`);
      setShowReactivateAccountModal(true);
      // const initState = menuOpen[index];
      // let updatedMenuOpen = menuOpen.map(() => false);
      // updatedMenuOpen[index] = !initState;
      // setMenuOpen(updatedMenuOpen);
    }
  };

  const onClickReactivate = () => {
    // console.log(`Inside onClickReactivate`)
    const userId = currentRowSelectedData.pilotId
      ? currentRowSelectedData.pilotId
      : currentRowSelectedData.instructorId;
    if (activeTab === `pilotsTab`) {
      // console.log(`Inside IF`)
      // console.log(`currentRowSelectedData: `, currentRowSelectedData)
      // const userId = currentRowSelectedData.pilotId;
      // console.log(`Pilot userId: `, userId)
      PilotService.reactivateUser(userId)
        .then((response) => {
          setStateChangeBuffer((prevState) => !prevState);
          console.log(response);
        })
        .catch((error) => {
          console.log(`Error reactivating pilot account`);
          console.log(error);
        });
    } else {
      // console.log(`Inside ELSE`)
      // console.log(`current row: `, currentRowSelectedData)
      const userId = currentRowSelectedData.instructorId;
      // console.log(`Instructor userId: `, userId)
      PilotService.updateInstructorStatus(userId, 0)
        .then((response: any) => {
          console.log(response);
          setApiCaller(!apiCaller);
          setStateChangeBuffer((prevState) => !prevState);
          setForcefulRerender(!forcefulRerender);
        })
        .catch((error) => {
          console.log(`Error reactivating instructor's account`);
          console.log(error);
          setApiCaller(!apiCaller);
          setForcefulRerender(!forcefulRerender);
        });

      PilotService.reactivateUser(userId)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(`Error reactivating instructor's account`);
          console.log(error);
        });
    }
    setApiCaller(!apiCaller);
    setForcefulRerender(!forcefulRerender);
  };

  const onClickDeactivate = () => {
    // console.log(`Inside onClickDeactivate`)
    const userId = currentRowSelectedData.pilotId
      ? currentRowSelectedData.pilotId
      : currentRowSelectedData.instructorId;
    if (activeTab === `pilotsTab`) {
      // const userId = currentRowSelectedData.pilotId;
      PilotService.deactivateUser(userId)
        .then((response) => {
          console.log(response);
          setApiCaller(!apiCaller);
          setForcefulRerender(!forcefulRerender);
          setStateChangeBuffer((prevState) => !prevState);
        })
        .catch((error) => {
          console.log(`Error deactivating pilot's account`);
          console.log(error);
          setApiCaller(!apiCaller);
          setStateChangeBuffer((prevState) => !prevState);
          setForcefulRerender(!forcefulRerender);
        });
    } else {
      // console.log(`Inside ELSE -> Instructor`)
      const userId = currentRowSelectedData.instructorId;
      PilotService.updateInstructorStatus(userId, 2)
        .then((response: any) => {
          console.log(response);
          setApiCaller(!apiCaller);
          setStateChangeBuffer((prevState) => !prevState);
          setForcefulRerender(!forcefulRerender);
        })
        .catch((error) => {
          console.log(`Error deactivating instructor's account`);
          console.log(error);
          setApiCaller(!apiCaller);
          setForcefulRerender(!forcefulRerender);
        });

      PilotService.deactivateUser(userId)
        .then((response) => {
          console.log(response);
          setApiCaller(!apiCaller);
          setForcefulRerender(!forcefulRerender);
          setStateChangeBuffer((prevState) => !prevState);
        })
        .catch((error) => {
          console.log(`Error deactivating instructor's account`);
          console.log(error);
          setApiCaller(!apiCaller);
          setForcefulRerender(!forcefulRerender);
        });
    }
    setApiCaller(!apiCaller);
    setForcefulRerender(!forcefulRerender);
  };

  const processDataForTable = (input: any) => {
    let processedData = input.map((item: any) => ({
      ...item,
      endTimestamp: convertDateData(item.endTimestamp),
      joined: convertDateData(item.joined),
      airtime: item.airtime === null ? `-` : item.airtime,
      assignedCfi: item.hasOwnProperty('assignedCfi') && item.assignedCfi === null ? `-` : item.assignedCfi,
      // status: getPilotInstructorStatus(item.status, index, item),
    }));
    return processedData;
  }; 

  // TEMP COMMENT
  useEffect(() => {
    setData(() => []);
    if (activeTab === `pilotsTab`) {
      PilotService.getPilotInstructorInfo(1).then((response: any) => {
        // console.log(`Pilot response: `, response);
        setResponseDataCopy(response.data.res);
        setData(() => processDataForTable(response.data.res));
      });
      // setResponseDataCopy(dumPilotData);
      // setData(() => processDataForTable(dumPilotData));
    } else {
      PilotService.getPilotInstructorInfo(2).then((response: any) => {
        // console.log(`Instructor response: `, response);
        setResponseDataCopy(response.data.res);
        setData(() => processDataForTable(response.data.res));
      });
      // setResponseDataCopy(dumInstData);
      // setData(() => processDataForTable(dumInstData));
    }
  }, [stateChangeBuffer]);

  const [searchFieldPlaceholder, setSearchFieldPlaceholder] = useState(
    "Search by pilot name"
  );
  const [searchType, setSearchType] = useState("Pilot");

  const handleTabSwitch = (tabName: string) => {
    setActiveTab(tabName);
  };

  const inputRef = useRef<HTMLInputElement>(null);
  const [searchText, setSearchText] = useState("");

  /**
   * Handles the change event for the search input, updating the search text and applying filters based on the search type.
   * @param {Object} e - The event object.
   * @returns {void}
   */
  const onSearchInputChange = (e: any) => {
    setSearchText(e.target.value);

    // Apply filters based on the selected search type
    if (activeTab === `pilotsTab`) {
      if (searchType === "Pilot") {
        setFilter("pilotName", e.target.value);
      } else if (searchType === "CFI") {
        setFilter("assignedCfi", e.target.value);
      }
    } else {
      if (searchType === `Instructor`) {
        setFilter("instructorName", e.target.value);
      } else if (searchType === `License`) {
        setFilter("license", e.target.value);
      }
    }
  };

  /**
   * Handles the change event for the search type, updating the search type and placeholder text.
   * @param {any} searchTypeText - The selected search type.
   * @returns {void}
   */
  const onSearchTypeChange = (searchTypeText: any) => {
    // Set the selected search type
    setSearchType(searchTypeText);

    if (activeTab === `pilotsTab`) {
      if (searchTypeText === "Pilot") {
        setSearchFieldPlaceholder("Search by pilot name");
      } else if (searchTypeText === "CFI") {
        setSearchFieldPlaceholder("Search by CFI name");
      }
    } else {
      if (searchTypeText === "Instructor") {
        setSearchFieldPlaceholder("Search by instructor name");
      } else if (searchTypeText === "License") {
        setSearchFieldPlaceholder("Search by license");
      }
    }
  };

  // const handleClick = (event: any) => {
  //   if (event.type === "click") {
  //     const isClickOnMenuIcon =
  //       (event.target as HTMLElement).className ===
  //       menuIconRef.current?.className;
  //     const isClickOnFilterButton =
  //       (event.target as HTMLElement).className ===
  //       filterPopoverRef.current?.className;
  //     const isClickInsideFilterBody = filterPopoverBodyRef.current?.contains(
  //       event.target as HTMLElement
  //     );
  //     if (!isClickOnMenuIcon) {
  //       setMenuOpenToFalse();
  //       setRerenderColumns((prevState) => !prevState);
  //     }
  //     if (!isClickOnFilterButton && !isClickInsideFilterBody) {
  //       setFilterModalShow(false);
  //     }
  //   }
  // };

  const handleClick = (event: any) => {
    if (event.type === "click") {
      // Check if the click is on the menu icon or any of its children
      const isClickOnMenuIcon = menuIconRef.current?.contains(event.target as HTMLElement);
  
      // Check if the click is on the filter button or any of its children
      const isClickOnFilterButton = filterPopoverRef.current?.contains(event.target as HTMLElement);
  
      // Check if the click is inside the filter body
      const isClickInsideFilterBody = filterPopoverBodyRef.current?.contains(event.target as HTMLElement);
  
      if (!isClickOnMenuIcon) {
        setMenuOpenToFalse();
        setRerenderColumns((prevState) => !prevState);
      }
  
      if (!isClickOnFilterButton && !isClickInsideFilterBody) {
        setFilterModalShow(false);
      }
    }
  };
  

  useEffect(() => {
    document.addEventListener(`click`, handleClick);
    return () => {
      document.removeEventListener(`click`, handleClick);
    };
  }, []);

  const popoverRef = useRef<HTMLDivElement>(null);

  const [pilotCFIId, setPilotCFIId] = useState<Number>(-1);

  const editDetails = (index: number, rowData: any) => {
    setCurrentRowSelectedData(rowData);
    // console.log(`Inside editDetails function`)
    // console.log(`rowData: `, rowData)
    setMenuOpenToFalse();
    if (activeTab === `pilotsTab`) {
      setPilotFirstName(() => rowData?.pilotName?.split(" ")[0]);
      // setPilotLastName(() => rowData?.pilotName?.split(" ")[1]);
      setPilotLastName(() => rowData?.pilotName?.split(" ").slice(1).join(" "));
      setPilotEmail(rowData?.email);
      setPilotCFI(rowData?.assignedCfi !== '-' ? rowData?.assignedCfi : '');
      setPilotCFIId(rowData?.assignedCfiId);

      menuOpenPilotRef.current[index] = false;
      setEditPilotDetailsModal(true);
    } else {
      setInstructorFirstName(() => rowData?.instructorName?.split(" ")[0]);
      // setInstructorLastName(() => rowData?.instructorName?.split(" ")[1]);
      setInstructorLastName(() => rowData?.instructorName?.split(" ").slice(1).join(" "));
      setInstructorLicense(rowData?.license);
      setInstructorEmail(rowData?.email);
      menuOpenInstructorRef.current[index] = false;
      setEditInstructorDetailsModal(true);
    }
  };

  const resendActivationLink = (index: number, rowData: any) => {
    // console.log(`Inside resendActivationLink function`)
    // console.log(rowData)
    setMenuOpenToFalse();
    resetPassword(index, rowData);
  };

  const deactivateAccount = (index: number, rowData: any) => {
    // console.log(`Inside deactivateAccount function`)
    changeStatus(index, rowData);
  };

  const resetPassword = (index: number, rowData: any) => {
    // console.log(`Inside resetPassword`);
    // console.log(rowData)
    setMenuOpenToFalse();
    // const userId = (currentRowSelectedData.pilotId ? currentRowSelectedData.pilotId : currentRowSelectedData.instructorId)
    const userId = rowData.pilotId ? rowData.pilotId : rowData.instructorId;
    // console.log(`userId: `, userId);
    PilotService.resetPassword(userId)
      .then((response) => {
        console.log(response);
        setStateChangeBuffer((prevState) => !prevState);
      })
      .catch((error) => {
        console.log(`Error resetting user password`);
        console.log(error);
      });
    setApiCaller(!apiCaller);
    setForcefulRerender(!forcefulRerender);
  };

  const pilotColumns: any = useMemo(
    () => [
      {
        id: `pilotName`,
        Header: `Name`,
        accessor: `pilotName`,
        isFixed: true,
        isDisplayed: true,
        sticky: `left`,
      },
      {
        id: `joined`,
        Header: `Joined`,
        accessor: `joined`,
        isFixed: false,
        isDisplayed: true,
      },
      {
        id: `airtime`,
        Header: `Total Flying Hrs`,
        accessor: `airtime`,
        isFixed: false,
        isDisplayed: true,
      },
      {
        id: `assignedCfi`,
        Header: `Assigned CFI`,
        accessor: `assignedCfi`,
        isFixed: false,
        isDisplayed: true,
      },
      {
        id: `endTimestamp`,
        Header: `Last Flight Details`,
        accessor: `endTimestamp`,
        isFixed: false,
        isDisplayed: true,
      },
      {
        id: `status`,
        Header: `Status`,
        Cell: (data: any) => {
          const index = data.row?.index;
          const rowData = data.row?.original;

          // console.log(`menuOpenPilot[index]: `, menuOpenPilotRef.current[index])

          const popoverPending = (
            <Popover id={`pilot-popover-status-${index}`}>
              {/* <Popover id={`pilot-popover-status-${index}`} style={{ left: `-90px`, top: `-100px`, borderRadius: `7px`, overflow: `hidden` }}> */}
              <Card style={{ backgroundColor: `white` }}>
                <div style={{ backgroundColor: `white` }}>
                  <Accordion.Item eventKey="1">
                    <Card.Header style={{ backgroundColor: `white` }}>
                      <button
                        onClick={() => editDetails(index, rowData)}
                        className="btn btn-light"
                        style={{
                          color: "#222222",
                          fontWeight: `500`,
                          fontSize: `14px`,
                          width: `100%`,
                        }}
                      >
                        Edit Details
                      </button>
                    </Card.Header>
                    {/* <hr /> Divider */}
                    <Card.Header style={{ backgroundColor: `white` }}>
                      <button
                        onClick={() => resendActivationLink(index, rowData)}
                        className="btn btn-light"
                        style={{
                          color: "blue",
                          fontWeight: `500`,
                          fontSize: `14px`,
                          width: `100%`,
                        }}
                      >
                        Resend Activation Link
                      </button>
                    </Card.Header>
                    {/* <hr /> Divider */}
                    <Card.Header style={{ backgroundColor: `white` }}>
                      <button
                        onClick={() => deactivateAccount(index, rowData)}
                        className="btn btn-light"
                        style={{ color: "#c1251a" }}
                      >
                        Deactivate Account
                      </button>
                    </Card.Header>
                  </Accordion.Item>
                </div>
              </Card>
            </Popover>
          );

          const popoverActive = (
            <Popover id={`pilot-popover-status-${index}`}>
              <Card style={{ backgroundColor: `white`, borderRadius: `10px` }}>
                <div style={{ backgroundColor: `white` }}>
                  <Accordion.Item eventKey="1">
                    <Card.Header style={{ backgroundColor: `white` }}>
                      <button
                        onClick={() => editDetails(index, rowData)}
                        className="btn btn-light"
                        style={{
                          color: "#222222",
                          fontWeight: `500`,
                          fontSize: `14px`,
                          width: `100%`,
                        }}
                      >
                        Edit Details
                      </button>
                    </Card.Header>
                    <Card.Header style={{ backgroundColor: `white` }}>
                      <button
                        onClick={() => resetPassword(index, rowData)}
                        className="btn btn-light"
                        style={{
                          color: "#222222",
                          fontWeight: `500`,
                          fontSize: `14px`,
                          width: `100%`,
                        }}
                      >
                        Reset Password
                      </button>
                    </Card.Header>
                    {/* <hr /> Divider */}
                    <Card.Header style={{ backgroundColor: `white` }}>
                      <button
                        onClick={() => deactivateAccount(index, rowData)}
                        className="btn btn-light"
                        style={{ color: "#c1251a" }}
                      >
                        Deactivate Account
                      </button>
                    </Card.Header>
                  </Accordion.Item>
                </div>
              </Card>
            </Popover>
          );

          const popoverDeacivated = (
            <Popover id={`pilot-popover-status-${index}`}>
              <Card style={{ backgroundColor: `white` }}>
                <div style={{ backgroundColor: `white` }}>
                  <Accordion.Item eventKey="1">
                    <Card.Header style={{ backgroundColor: `white` }}>
                      <button
                        onClick={() => changeStatus(index, rowData)}
                        className="btn btn-light"
                        style={{ color: `blue` }}
                      >
                        Reactivate Account
                      </button>
                    </Card.Header>
                  </Accordion.Item>
                </div>
              </Card>
            </Popover>
          );

          if (rowData.status === 0) {
            // PENDING
            return (
              <div className="row">
                <div className="col">
                  <div
                    className="status-pill badge"
                    style={{
                      border: `solid 1px #d87300`,
                      backgroundColor: `rgba(216, 115, 0, 0.16)`,
                      width: `110px`,
                    }}
                  >
                    <div className="status-text" style={{ color: `#d87300` }}>
                      Pending
                    </div>
                  </div>
                </div>
                <div className="col pt-1">
                  <OverlayTrigger
                    trigger="click"
                    placement="left"
                    show={menuOpenPilotRef.current[index]}
                    overlay={popoverPending}
                  >
                    {/* <OverlayTrigger trigger="click" placement="left" show={menuOpenPilotRef.current[index] || false} overlay={popoverPending}> */}
                    <i
                      className="menu-icon"
                      ref={menuIconRef}
                      onClick={() => onClickMenu(index, rowData)}
                    ></i>
                  </OverlayTrigger>
                </div>
              </div>
            );
          } else if (rowData.status === 1) {
            // ACTIVE
            return (
              <div className="row">
                <div className="col">
                  <div
                    className="status-pill badge"
                    style={{
                      border: `solid 1px #39b34a`,
                      backgroundColor: `rgba(57, 179, 74, 0.16)`,
                      width: `110px`,
                    }}
                  >
                    <div className="status-text" style={{ color: `#31993f` }}>
                      Active
                    </div>
                  </div>
                </div>
                <div className="col pt-1">
                  <OverlayTrigger
                    trigger="click"
                    placement="left"
                    show={menuOpenPilotRef.current[index]}
                    overlay={popoverActive}
                  >
                    {/* <OverlayTrigger trigger="click" placement="left" show={menuOpenPilotRef.current[index] || false} overlay={popoverActive}> */}
                    <i
                      className="menu-icon"
                      ref={menuIconRef}
                      onClick={() => onClickMenu(index, rowData)}
                    ></i>
                  </OverlayTrigger>
                </div>
              </div>
            );
          } else {
            // DEACTIVATED
            return (
              <div className="row">
                <div className="col">
                  <div
                    className="status-pill badge"
                    style={{
                      border: `1px solid #c1251a`,
                      backgroundColor: `rgba(193, 37, 26, 0.16)`,
                      width: `110px`,
                    }}
                  >
                    <div className="status-text" style={{ color: `#c1251a` }}>
                      Deactivated
                    </div>
                  </div>
                </div>
                <div className="col pt-1">
                  <OverlayTrigger
                    trigger="click"
                    placement="left"
                    show={menuOpenPilotRef.current[index]}
                    overlay={popoverDeacivated}
                  >
                    {/* <OverlayTrigger trigger="click" placement="left" show={menuOpenPilotRef.current[index] || false} overlay={popoverPending}> */}
                    <i
                      className="menu-icon"
                      ref={menuIconRef}
                      onClick={() => onClickMenu(index, rowData)}
                    ></i>
                  </OverlayTrigger>
                </div>
              </div>
            );
          }
        },
        accessor: `status`,
        isFixed: true,
        isDisplayed: true,
        sticky: `right`,
        // maxWidth: 1,
      },
    ],
    [stateChangeBuffer, activeTab, data, apiCaller, rerenderColumns]
  );

  const instructorColumns: any = useMemo(
    () => [
      {
        id: `instructorName`,
        Header: `Name`,
        accessor: `instructorName`,
        isFixed: true,
        isDisplayed: true,
        sticky: `left`,
      },
      {
        id: `joined`,
        Header: `Joined`,
        accessor: `joined`,
        isFixed: false,
        isDisplayed: true,
      },
      {
        id: `airtime`,
        Header: `Total Flying Hrs`,
        accessor: `airtime`,
        isFixed: false,
        isDisplayed: true,
      },
      {
        id: `license`,
        Header: `License Details`,
        accessor: `license`,
        isFixed: false,
        isDisplayed: true,
      },
      {
        id: `endTimestamp`,
        Header: `Last Flight Details`,
        accessor: `endTimestamp`,
        isFixed: false,
        isDisplayed: true,
      },
      {
        id: `status`,
        Header: `Status`,
        Cell: (data: any) => {
          const index = data.row?.index;
          const rowData = data.row?.original;

          const popoverPending = (
            <Popover id={`instructor-popover-status-${index}`} ref={popoverRef}>
              <Card style={{ backgroundColor: `white` }}>
                <div style={{ backgroundColor: `white` }}>
                  <Accordion.Item eventKey="1">
                    <Card.Header style={{ backgroundColor: `white` }}>
                      <button
                        onClick={() => editDetails(index, rowData)}
                        className="btn btn-light"
                        style={{
                          color: "#222222",
                          fontWeight: `500`,
                          fontSize: `14px`,
                          width: `100%`,
                        }}
                      >
                        Edit Details
                      </button>
                    </Card.Header>
                    {/* <hr /> Divider */}
                    <Card.Header style={{ backgroundColor: `white` }}>
                      <button
                        onClick={() => resendActivationLink(index, rowData)}
                        className="btn btn-light"
                        style={{
                          color: "blue",
                          fontWeight: `500`,
                          fontSize: `14px`,
                          width: `100%`,
                        }}
                      >
                        Resend Activation Link
                      </button>
                    </Card.Header>
                    {/* <hr /> Divider */}
                    <Card.Header style={{ backgroundColor: `white` }}>
                      <button
                        onClick={() => deactivateAccount(index, rowData)}
                        className="btn btn-light"
                        style={{ color: "#c1251a" }}
                      >
                        Deactivate Account
                      </button>
                    </Card.Header>
                  </Accordion.Item>
                </div>
              </Card>
            </Popover>
          );

          const popoverActive = (
            <Popover id={`instructor-popover-status-${index}`} ref={popoverRef}>
              <Card style={{ backgroundColor: `white` }}>
                <div style={{ backgroundColor: `white` }}>
                  <Accordion.Item eventKey="1">
                    <Card.Header style={{ backgroundColor: `white` }}>
                      <button
                        onClick={() => editDetails(index, rowData)}
                        className="btn btn-light"
                        style={{
                          color: "#222222",
                          fontWeight: `500`,
                          fontSize: `14px`,
                          width: `100%`,
                        }}
                      >
                        Edit Details
                      </button>
                    </Card.Header>
                    <Card.Header style={{ backgroundColor: `white` }}>
                      <button
                        onClick={() => resetPassword(index, rowData)}
                        className="btn btn-light"
                        style={{
                          color: "#222222",
                          fontWeight: `500`,
                          fontSize: `14px`,
                          width: `100%`,
                        }}
                      >
                        Reset Password
                      </button>
                    </Card.Header>
                    {/* <hr /> Divider */}
                    <Card.Header style={{ backgroundColor: `white` }}>
                      <button
                        onClick={() => deactivateAccount(index, rowData)}
                        className="btn btn-light"
                        style={{ color: "#c1251a" }}
                      >
                        Deactivate Account
                      </button>
                    </Card.Header>
                  </Accordion.Item>
                </div>
              </Card>
            </Popover>
          );

          const popoverDeacivated = (
            <Popover id={`instructor-popover-status-${index}`} ref={popoverRef}>
              <Card style={{ backgroundColor: `white` }}>
                <div style={{ backgroundColor: `white` }}>
                  <Accordion.Item eventKey="1">
                    <Card.Header style={{ backgroundColor: `white` }}>
                      <button
                        onClick={() => changeStatus(index, rowData)}
                        className="btn btn-light"
                        style={{ color: `blue` }}
                      >
                        Reactivate Account
                      </button>
                    </Card.Header>
                  </Accordion.Item>
                </div>
              </Card>
            </Popover>
          );

          if (rowData.status === 0) {
            // PENDING
            return (
              <div className="row">
                <div className="col">
                  <div
                    className="status-pill badge"
                    style={{
                      border: `solid 1px #d87300`,
                      backgroundColor: `rgba(216, 115, 0, 0.16)`,
                      width: `110px`,
                    }}
                  >
                    <div className="status-text" style={{ color: `#d87300` }}>
                      Pending
                    </div>
                  </div>
                </div>
                <div className="col pt-1">
                  <OverlayTrigger
                    trigger="click"
                    placement="left"
                    show={menuOpenInstructorRef.current[index]}
                    overlay={popoverPending}
                  >
                    {/* <OverlayTrigger trigger="click" placement="left" show={menuOpenInstructor[index] || false} overlay={popoverPending}> */}
                    <i
                      className="menu-icon"
                      ref={menuIconRef}
                      onClick={() => onClickMenu(index, rowData)}
                    ></i>
                  </OverlayTrigger>
                </div>
              </div>
            );
          } else if (rowData.status === 1) {
            // ACTIVE
            return (
              <div className="row">
                <div className="col">
                  <div
                    className="status-pill badge"
                    style={{
                      border: `solid 1px #39b34a`,
                      backgroundColor: `rgba(57, 179, 74, 0.16)`,
                      width: `110px`,
                    }}
                  >
                    <div className="status-text" style={{ color: `#31993f` }}>
                      Active
                    </div>
                  </div>
                </div>
                <div className="col pt-1">
                  <OverlayTrigger
                    trigger="click"
                    placement="left"
                    show={menuOpenInstructorRef.current[index]}
                    overlay={popoverActive}
                  >
                    {/* <OverlayTrigger trigger="click" placement="left" show={menuOpenInstructor[index] || false} overlay={popoverActive}> */}
                    <i
                      className="menu-icon"
                      ref={menuIconRef}
                      onClick={() => onClickMenu(index, rowData)}
                    ></i>
                  </OverlayTrigger>
                </div>
              </div>
            );
          } else {
            // DEACTIVATED
            return (
              <div className="row">
                <div className="col">
                  <div
                    className="status-pill badge"
                    style={{
                      border: `1px solid #c1251a`,
                      backgroundColor: `rgba(193, 37, 26, 0.16)`,
                      width: `110px`,
                    }}
                  >
                    <div className="status-text" style={{ color: `#c1251a` }}>
                      Deactivated
                    </div>
                  </div>
                </div>
                <div className="col pt-1">
                  <OverlayTrigger
                    trigger="click"
                    placement="left"
                    show={menuOpenInstructorRef.current[index]}
                    overlay={popoverDeacivated}
                  >
                    {/* <OverlayTrigger trigger="click" placement="left" show={menuOpenInstructor[index] || false} overlay={popoverPending}> */}
                    <i
                      className="menu-icon"
                      ref={menuIconRef}
                      onClick={() => onClickMenu(index, rowData)}
                    ></i>
                  </OverlayTrigger>
                </div>
              </div>
            );
          }
        },
        accessor: `status`,
        isFixed: true,
        isDisplayed: true,
        sticky: `right`,
      },
    ],
    [stateChangeBuffer, activeTab, data, apiCaller, rerenderColumns]
  );

  const [columns, setColumns] = useState(pilotColumns);

  // TEMP COMMENT
  useEffect(() => {
    setColumns(activeTab === `pilotsTab` ? pilotColumns : instructorColumns);
    setSearchFieldPlaceholder(
      activeTab === `pilotsTab`
        ? `Search by pilot name`
        : `Search by instructor name`
    );
    setSearchType(activeTab === `instructorsTab` ? `Instructor` : `Pilot`);
  }, [stateChangeBuffer]);

  useEffect(() => {
    // setMenuOpenPilot(new Array(data.length).fill(false));
    menuOpenPilotRef.current = new Array(data.length).fill(false);
    menuOpenInstructorRef.current = new Array(data.length).fill(false);
  }, [data]);

  // useEffect(() => {
  //     console.log(`columns: `, columns)
  // }, [columns]);

  // useEffect(() => {
  //     console.log(`activeTab: `, activeTab)
  // }, [activeTab]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: activeTab === "pilotsTab" ? "pilotName" : "instructorName",
            desc: false,
          },
        ],
      },
    },
    useColumnOrder,
    useFilters,
    useGlobalFilter,
    useSortBy,
    useBlockLayout,
    usePagination,
    useSticky
  );

  const { setFilter, allColumns, setColumnOrder } = tableInstance;

  useEffect(() => {
    menuOpenPilotRef.current = [];
    menuOpenInstructorRef.current = [];
    setStateChangeBuffer((prevState) => !prevState);
    // Testing below code
    setColumns(activeTab === `pilotsTab` ? pilotColumns : instructorColumns);
    // setData(activeTab !== `pilotsTab` ? pilotData : instructorData)
    setSearchFieldPlaceholder(
      activeTab === `pilotsTab`
        ? `Search by pilot name`
        : `Search by instructor name`
    );
    setSearchType(activeTab === `instructorsTab` ? `Instructor` : `Pilot`);
  }, [activeTab]);

  const [columnSettingsModalShow, setColumnSettingsModalShow] = useState(false);

  /**
   * Handles the application of column settings by updating the order of columns.
   * @param {Array} columnData - The column data containing information about each column.
   * @returns {void}
   */
  const onColumnSettingApply = (columnData: any) => {
    // Extracts the order of columns from the column data
    const orderOfColumns = columnData.map((column: any) => {
      return column.id;
    });

    // Calls the changeOrder function to update the order of columns
    changeOrder(orderOfColumns);

    // Displays a success toast notification
    // toast.success('Columns updated successfully', {
    //     position: "bottom-center",
    //     autoClose: 2000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: 0,
    //     theme: "light",
    // });

    // Closes the modal
    setColumnSettingsModalShow(false);
  };

  /**
   * Updates the order of columns based on the provided order array.
   * @param {Array} order - The new order of columns.
   * @returns {void}
   */
  const changeOrder = (order: any[]) => {
    setColumnOrder(order);
  };

  const [selectedStatus, setSelectedStatus] = useState<number>(NaN);

  const resetFilter = () => {
    setSelectedStatus(NaN);
    setData(processDataForTable(responseDataCopy));
    setFilterModalShow(false);
  };

  const applyFilter = () => {
    setNoResultsFound(false);

    let unfilteredData = responseDataCopy.map((item: any) => ({ ...item })); //creating a deep copy
    let filteredData = unfilteredData;
    if ((statusFilterChecked && selectedStatus) || selectedStatus === 0) {
      filteredData = unfilteredData.filter(
        (item: any) => item.status === selectedStatus
      );
    }

    if (filteredData && filteredData.length === 0) {
      setNoResultsFound(true);
    }

    setData(processDataForTable(filteredData));
    setFilterModalShow(false);
  };

  const [filterModalShow, setFilterModalShow] = useState(false);
  const [statusFilterChecked, setStatusFilterChecked] = useState(false);

  const filterPopoverElement = (
    <Popover>
      <div className="filter-box" ref={filterPopoverBodyRef}>
        <Accordion>
          <div className="scroll-div">
            <Accordion.Header>
              <input
                type="checkbox"
                checked={statusFilterChecked}
                onClick={(e: any) => {
                  e.stopPropagation();
                  setStatusFilterChecked((prevState) => !prevState);
                }}
              />{" "}
              Status
            </Accordion.Header>

            <Accordion.Body>
              <Dropdown className="dropdown-box">
                <Dropdown.Toggle
                  variant="none"
                  id="dropdown-basic"
                  className="dropdown-toggle"
                >
                  <span className="debrieftype-text">
                    {isNaN(selectedStatus)
                      ? "Select status"
                      : selectedStatus === 1
                      ? "Active"
                      : selectedStatus === 0
                      ? "Pending"
                      : "Deactivated"}
                  </span>
                </Dropdown.Toggle>

                {/* <Dropdown.Menu className="dropdown-menu-box shadow"> */}
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => setSelectedStatus(1)}
                    style={{ color: `#31993f`, textAlign: `center` }}
                  >
                    Active
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    onClick={() => setSelectedStatus(0)}
                    style={{ color: `#c56900`, textAlign: `center` }}
                  >
                    Pending
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    onClick={() => setSelectedStatus(2)}
                    style={{ color: `#c1251a`, textAlign: `center` }}
                  >
                    Deactivated
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Accordion.Body>
          </div>
          <div className="footer">
            <button
              className="btn shadow-sm border btn-sm reset"
              onClick={resetFilter}
            >
              Reset
            </button>
            <button className="btn border btn-sm apply" onClick={applyFilter}>
              Apply
            </button>
          </div>
        </Accordion>
      </div>
    </Popover>
  );

  const [addPilotModalOpen, setAddPilotModalOpen] = useState(false);

  const [addInstructorModalOpen, setAddInstructorModalOpen] = useState(false);

  const showAddPilotModal = () => setAddPilotModalOpen(true);
  const hideAddPilotModal = () => setAddPilotModalOpen(false);

  const [pilotFirstName, setPilotFirstName] = useState("");
  const [pilotLastName, setPilotLastName] = useState("");
  const [pilotEmail, setPilotEmail] = useState("");
  const [pilotCFI, setPilotCFI] = useState<number | null | string>(null);
  const [pilotTag, setPilotTag] = useState("");
  const [pilotGroup, setPilotGroup] = useState("");

  const showAddInstructorModal = () => setAddInstructorModalOpen(true);
  const hideAddInstructorModal = () => setAddInstructorModalOpen(false);

  const [instructorFirstName, setInstructorFirstName] = useState("");
  const [instructorLastName, setInstructorLastName] = useState("");
  const [instructorEmail, setInstructorEmail] = useState("");
  const [instructorLicense, setInstructorLicense] = useState("");

  const addNewPilotDetails = () => {
    PilotService.savePilotData({
      firstName: pilotFirstName,
      lastName: pilotLastName,
      email: pilotEmail,
      tag: pilotTag,
      group: pilotGroup,
      instructorid: pilotCFI,
    })
      .then((response) => {
        setPilotToastVisible(true);
        console.log(response);
        console.log(`Pilot saved successfully`);
        setStateChangeBuffer((prevState) => !prevState);
      })
      .catch((error) => {
        console.error(`Error saving pilot details:`, error);
      });
    setAddPilotModalOpen(false);
  };

  const addNewInstructorDetails = () => {
    PilotService.saveInstructorData({
      firstName: instructorFirstName,
      lastName: instructorLastName,
      email: instructorEmail,
      license: instructorLicense,
    })
      .then((response: any) => {
        setInstructorToastVisible(true);
        console.log(response);
        console.log(`Instructor saved successfully`);
        setStateChangeBuffer((prevState) => !prevState);
      })
      .catch((error) => {
        console.error(`Error saving instructor details:`, error);
      });
    setAddInstructorModalOpen(false);
  };

  const updatePilotDetails = () => {
    // console.log(`Assigned CFI: `, pilotCFI);
    PilotService.editPilotDetails({
      pilotid: currentRowSelectedData?.pilotId,
      firstName: pilotFirstName,
      lastName: pilotLastName,
      instructorId: (isNaN(Number(pilotCFI)) ? pilotCFIId : pilotCFI),
      username: pilotEmail,
      tag: pilotTag,
      group: pilotGroup,
    })
      .then((response) => {
        console.log(`Pilot details edited successfully`);
        console.log(response);
        setStateChangeBuffer((prevState) => !prevState);
      })
      .catch((error: any) => {
        console.error(`Error editing pilot details`);
        console.log(error);
      });
    setEditPilotDetailsModal(false);
  };

  const updateInstructorDetails = () => {
    PilotService.editInstructorDetails({
      id: currentRowSelectedData?.instructorId,
      firstName: instructorFirstName,
      lastName: instructorLastName,
      username: instructorEmail,
      license: instructorLicense,
    })
      .then((response: any) => {
        console.log(response);
        console.log(`Instructor details updated successfully`);
        setStateChangeBuffer((prevState) => !prevState);
      })
      .catch((error: any) => {
        console.error(`Error updating instructor details: `, error);
      });
    setEditInstructorDetailsModal(false);
  };

  const [emailAlreadyExists, setEmailAlreadyExists] = useState<boolean>(false);

  useEffect(() => {
    let allExistingEmailsCopy = allExistingEmails;
    if (editPilotDetailsModal || editInstructorDetailsModal) {
      if (activeTab === `pilotsTab`) {
        allExistingEmailsCopy = allExistingEmailsCopy.filter(
          (item) => item !== currentRowSelectedData?.email
        );
      } else {
        allExistingEmailsCopy = allExistingEmailsCopy.filter(
          (item) => item !== currentRowSelectedData?.email
        );
      }
    }

    if (activeTab === `pilotsTab`) {
      if (allExistingEmailsCopy.some((item: string) => item === pilotEmail)) {
        setEmailAlreadyExists(true);
      } else {
        setEmailAlreadyExists(false);
      }
    } else {
      if (
        allExistingEmailsCopy.some((item: string) => item === instructorEmail)
      ) {
        setEmailAlreadyExists(true);
      } else {
        setEmailAlreadyExists(false);
      }
    }
  }, [pilotEmail, instructorEmail]);

  let inputStyles: any;

  if (emailAlreadyExists) {
    inputStyles = {
      height: "100%",
      backgroundColor: emailAlreadyExists ? "#ffebec" : "",
      borderBottom: emailAlreadyExists ? "1px solid #da202a" : "",
      boxShadow: "none",
    };
  } else {
    inputStyles = {
      height: "100%",
      backgroundColor: emailAlreadyExists ? "#ffebec" : "",
      borderBottom: emailAlreadyExists ? "1px solid #da202a" : "",
    };
  }

  const rowClick = () => {};

  // To get the total number of matching rows based on search to show appropriate component if nothing is found
  const matchingRows = tableInstance.rows.length;

  const showPaginationDiv = matchingRows > 0 ? true : false; //show Pagination Div or not

  return (
    <>
      {pilotToastVisible && (
        <CustomToast
          title={`Success`}
          message={`Pilot details have been saved successfully!`}
          visible={pilotToastVisible}
          setVisible={setPilotToastVisible}
        ></CustomToast>
      )}
      {instructorToastVisible && (
        <CustomToast
          title={`Success`}
          message={`Instructor details have been saved successfully!`}
          visible={instructorToastVisible}
          setVisible={setInstructorToastVisible}
        ></CustomToast>
      )}
      <section className="fleet-setting-wrap">
        <section className="top-section">
          <span className="section-text">
            <span className="colored-text">Organisation</span> / My Pilots &
            Instructors
          </span>
          <div className="heading">Manage Flight Crew</div>
          <div className="description">
            Lets you add and organize pilots and flying instructors to
            streamline your flight operations
          </div>

          <div className="tab-button-wrapper">
          <button
              onClick={() => handleTabSwitch("instructorsTab")}
              className={`tab-button-instructors ${
                activeTab === "instructorsTab" ? "tab-active" : ""
              }`}
            >
              Instructors
            </button>
            <button
              onClick={() => handleTabSwitch("pilotsTab")}
              className={`tab-button-pilots ${
                activeTab === "pilotsTab" ? "tab-active" : ""
              }`}
            >
              Pilots
            </button>
            <div className="d-inline space"></div>
          </div>
        </section>

        <section className="bottom-section">
          <div
            className="container-fluid d-flex align-items-center justify-content-around ml-0 pl-0"
            style={{ width: `100%` }}
          >
            <div className="col-9 filter-wrap justify-content-left">
              <div className="search-filter">
                <div className="input-select-wrapper">
                  <input
                    ref={inputRef}
                    type="text"
                    className="text-field text-space debrief-search-icon shadow-none"
                    placeholder={searchFieldPlaceholder}
                    onChange={onSearchInputChange}
                  />
                  <div className="select-wrapper">
                    <div className="select-box-breiftype-1">
                      <Dropdown className="dropdown-box ">
                        <Dropdown.Toggle
                          variant="none"
                          id="dropdown-basic"
                          className="dropdown-toggle"
                        >
                          <span className="debrieftype-text">{searchType}</span>
                        </Dropdown.Toggle>
                        {activeTab === "pilotsTab" && (
                          <Dropdown.Menu className="dropdown-menu-box shadow">
                            <Dropdown.Item
                              href=""
                              onClick={() => {
                                onSearchTypeChange("Pilot");
                              }}
                              className={`${
                                searchType === "Pilot"
                                  ? "debrief-selected-item"
                                  : "debrief-item"
                              }`}
                            >
                              Pilot
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item
                              href=""
                              onClick={() => {
                                onSearchTypeChange("CFI");
                              }}
                              className={`${
                                searchType === "CFI"
                                  ? "debrief-selected-item"
                                  : "debrief-item"
                              }`}
                            >
                              CFI
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        )}
                        {activeTab === "instructorsTab" && (
                          <Dropdown.Menu className="dropdown-menu-box shadow">
                            <Dropdown.Item
                              href=""
                              onClick={() => {
                                onSearchTypeChange("Instructor");
                              }}
                              className={`${
                                searchType === "Instructor"
                                  ? "debrief-selected-item"
                                  : "debrief-item"
                              }`}
                            >
                              Instructor
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item
                              href=""
                              onClick={() => {
                                onSearchTypeChange("License");
                              }}
                              className={`${
                                searchType === "License"
                                  ? "debrief-selected-item"
                                  : "debrief-item"
                              }`}
                            >
                              License
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        )}
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-auto d-flex justify-content-end">
              <div className="row justify-content-left">
                <div className="col-auto">
                  <button
                    type="button"
                    className="btn btn-light settings-btn"
                    onClick={() => setColumnSettingsModalShow(true)}
                  >
                    <i className="settings-btn-icon"></i>
                  </button>
                </div>
                <div className="col-auto">
                  <OverlayTrigger
                    trigger="click"
                    placement="bottom"
                    show={filterModalShow}
                    overlay={filterPopoverElement}
                  >
                    <button
                      type="button"
                      className="btn btn-light filter-btn"
                      ref={filterPopoverRef}
                      onClick={() => setFilterModalShow(!filterModalShow)}
                    >
                      <i className="filter-btn-icon"></i>Filter
                    </button>
                  </OverlayTrigger>
                </div>

                {activeTab === `pilotsTab` && (
                  <div className="col-auto">
                    <button
                      type="button"
                      className="btn btn-primary add-btn"
                      onClick={showAddPilotModal}
                    >
                      <i className="add-icon"></i>Add Pilot
                    </button>
                  </div>
                )}

                {activeTab === `instructorsTab` && (
                  <div className="col-auto">
                    <button
                      type="button"
                      className="btn btn-primary add-btn"
                      onClick={showAddInstructorModal}
                    >
                      <i className="add-icon"></i>Add Instructor
                    </button>
                  </div>
                )}

                <div className="add-aircraft-modal-wrapper">
                  <Modal show={addPilotModalOpen} onHide={hideAddPilotModal}>
                    <Modal.Header closeButton className="settings-modal-header">
                      <Modal.Title className=" pt-4">
                        <p className="modal-title-heading">Add New Pilot</p>
                        <p className="modal-title-subheading">
                          Fill the below details to add a new pilot for your
                          organisation. Once saved, the pilot will be able to
                          access the Dashboard and App with the chosen
                          credentials.
                        </p>
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="assign-modal-scroll-container">
                        <p className="modal-body-heading">
                          <i></i> GENERIC DETAILS
                        </p>
                        <div className="container generic-details mt-1 pb-4">
                          <div className="row mt-4">
                            <div className="col">
                              <p className="modal-input-heading">First Name</p>
                              <div className="rbt assign-modal-text-input">
                                <input
                                  placeholder="Enter pilot’s first name"
                                  type="text"
                                  onChange={(inp) =>
                                    setPilotFirstName(inp.target.value)
                                  }
                                  role="combobox"
                                  className="rbt-input-main form-control rbt-input"
                                  style={{ height: `100%` }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row mt-4">
                            <div className="col">
                              <p className="modal-input-heading">Last Name</p>
                              <div className="rbt assign-modal-text-input">
                                <input
                                  placeholder="Enter pilot’s last name"
                                  onChange={(inp) =>
                                    setPilotLastName(inp.target.value)
                                  }
                                  type="text"
                                  role="combobox"
                                  className="rbt-input-main form-control rbt-input"
                                  style={{ height: `100%` }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row mt-4">
                            <div className="col">
                              <p className="modal-input-heading">
                                Email Address
                              </p>
                              <div className="rbt assign-modal-text-input">
                                <input
                                  placeholder="Enter pilot’s email address"
                                  onChange={(inp) =>
                                    setPilotEmail(inp.target.value)
                                  }
                                  type="text"
                                  role="combobox"
                                  className="rbt-input-main form-control rbt-input"
                                  style={inputStyles}
                                />
                              </div>
                            </div>
                            {emailAlreadyExists && (
                              <div className="error">
                                Email address already exists!
                              </div>
                            )}
                          </div>

                          <div className="row mt-4">
                            <div className="col">
                              <p className="modal-input-heading">
                                Instructor (CFI)
                              </p>
                              <Typeahead
                              id='1'
                                options={instructorList}
                                className="assign-modal-text-input"
                                onChange={(selected) => {
                                  const selectedOption =
                                    selected[0] as Instructor;
                                  const selectedInstructorId = selectedOption
                                    ? selectedOption.instructorId
                                    : null;
                                  setPilotCFI(selectedInstructorId);
                                }}
                                labelKey="instructorName"
                                placeholder="Select an instructor"
                              ></Typeahead>
                            </div>
                          </div>
                        </div>

                        <p className="modal-body-heading">
                          <i></i>TAGS AND GROUP DETAILS
                        </p>
                        <div className="container generic-details mt-1">
                          <div className="row mt-4">
                            <div className="col">
                              <p className="modal-input-heading">Tags</p>
                              <Typeahead
                                id='2'
                                options={tagsList}
                                className="assign-modal-text-input"
                                onChange={(value) => {
                                    const valueString:string = value[0]?.toString();
                                    setPilotTag(valueString);
                                    setGroupsList([tagGroupPair[valueString]])
                                  }
                                }
                                placeholder="Select a tag"
                              ></Typeahead>
                            </div>
                          </div>

                          <div className="row mt-4">
                            <div className="col">
                              <p className="modal-input-heading">Group</p>
                              <Typeahead
                                id='3'
                                options={groupsList}
                                className="assign-modal-text-input"
                                // onChange={(value) => {} }
                                onChange={(value) =>
                                  setPilotGroup(value[0]?.toString())
                                }
                                placeholder="Select a group"
                                // defaultInputValue={pilotGroup || ''}
                              ></Typeahead>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <div className="container-fluid d-flex justify-content-between">
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={hideAddPilotModal}
                        >
                          <strong>Close</strong>
                        </button>
                        <button
                          type="button"
                          onClick={addNewPilotDetails}
                          className="btn btn-primary"
                        >
                          Add Pilot
                        </button>
                      </div>
                    </Modal.Footer>
                  </Modal>
                </div>

                <div className="add-aircraft-modal-wrapper">
                  <Modal
                    show={addInstructorModalOpen}
                    onHide={hideAddInstructorModal}
                  >
                    <Modal.Header closeButton className="settings-modal-header">
                      <Modal.Title className=" pt-4">
                        <p className="modal-title-heading">
                          Add New Instructor
                        </p>
                        <p className="modal-title-subheading">
                          Fill the below details to add a new instructor for
                          your organisation. Once saved, the instructor will be
                          able to access the Dashboard and App with the chosen
                          credentials.
                        </p>
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div
                        className="assign-modal-scroll-container"
                        style={{ height: `100%` }}
                      >
                        {/* <div className="assign-modal-scroll-container"> */}
                        <p className="modal-body-heading">
                          <i></i> GENERIC DETAILS
                        </p>
                        <div className="container geeric-details mt-1 pb-2">
                          <div className="row mt-4">
                            <div className="col">
                              <p className="modal-input-heading">First Name</p>
                              <div className="rbt assign-modal-text-input">
                                <input
                                  onChange={(inp) =>
                                    setInstructorFirstName(inp.target.value)
                                  }
                                  placeholder="Enter instructor’s first name"
                                  type="text"
                                  role="combobox"
                                  className="rbt-input-main form-control rbt-input"
                                  style={{ height: `100%` }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="col">
                              <p className="modal-input-heading">Last Name</p>
                              <div className="rbt assign-modal-text-input">
                                <input
                                  onChange={(inp) =>
                                    setInstructorLastName(inp.target.value)
                                  }
                                  placeholder="Enter instructor’s last name"
                                  type="text"
                                  role="combobox"
                                  className="rbt-input-main form-control rbt-input"
                                  style={{ height: `100%` }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="col">
                              <p className="modal-input-heading">
                                Email Address
                              </p>
                              <div className="rbt assign-modal-text-input">
                                <input
                                  onChange={(inp) =>
                                    setInstructorEmail(inp.target.value)
                                  }
                                  placeholder="Enter instructor’s email address"
                                  type="text"
                                  role="combobox"
                                  className="rbt-input-main form-control rbt-input"
                                  style={inputStyles}
                                />
                              </div>
                            </div>
                            {emailAlreadyExists && (
                              <div className="error">
                                Email address already exists!
                              </div>
                            )}
                          </div>

                          <div className="row mt-4 mb-0">
                            <div className="col">
                              <p className="modal-input-heading">
                                License Details
                              </p>
                              <div className="rbt assign-modal-text-input">
                                <input
                                  onChange={(inp) =>
                                    setInstructorLicense(inp.target.value)
                                  }
                                  placeholder="Enter instructor’s license details"
                                  type="text"
                                  role="combobox"
                                  className="rbt-input-main form-control rbt-input"
                                  style={{ height: `100%` }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <div className="container-fluid d-flex justify-content-between">
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={hideAddInstructorModal}
                        >
                          <strong>Close</strong>
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={addNewInstructorDetails}
                        >
                          Add Instructor
                        </button>
                      </div>
                    </Modal.Footer>
                  </Modal>
                </div>

                {/* EDIT PILOT DETAILS MODAL */}
                <div className="add-aircraft-modal-wrapper">
                  <Modal
                    show={editPilotDetailsModal}
                    onHide={() => setEditPilotDetailsModal(false)}
                  >
                    <Modal.Header closeButton className="settings-modal-header">
                      <Modal.Title className=" pt-4">
                        <p className="modal-title-heading">Edit Pilot</p>
                        <p className="modal-title-subheading">
                          Edit added pilot's details
                        </p>
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="assign-modal-scroll-container">
                        <p className="modal-body-heading">
                          <i></i> GENERIC DETAILS
                        </p>
                        <div className="container generic-details mt-1 pb-4">
                          <div className="row mt-4">
                            <div className="col">
                              <p className="modal-input-heading">First Name</p>
                              <div className="rbt assign-modal-text-input">
                                <input
                                  placeholder="Enter pilot’s first name"
                                  type="text"
                                  value={pilotFirstName}
                                  onChange={(inp) =>
                                    setPilotFirstName(inp.target.value)
                                  }
                                  role="combobox"
                                  className="rbt-input-main form-control rbt-input"
                                  style={{ height: `100%` }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row mt-4">
                            <div className="col">
                              <p className="modal-input-heading">Last Name</p>
                              <div className="rbt assign-modal-text-input">
                                <input
                                  placeholder="Enter pilot’s last name"
                                  value={pilotLastName}
                                  onChange={(inp) =>
                                    setPilotLastName(inp.target.value)
                                  }
                                  type="text"
                                  role="combobox"
                                  className="rbt-input-main form-control rbt-input"
                                  style={{ height: `100%` }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row mt-4">
                            <div className="col">
                              <p className="modal-input-heading">
                                Email Address
                              </p>
                              <div className="rbt assign-modal-text-input">
                                <input
                                  placeholder="Enter pilot’s email address"
                                  value={pilotEmail}
                                  onChange={(inp) =>
                                    setPilotEmail(inp.target.value)
                                  }
                                  type="text"
                                  role="combobox"
                                  className="rbt-input-main form-control rbt-input"
                                  style={inputStyles}
                                />
                              </div>
                            </div>
                            {emailAlreadyExists && (
                              <div className="error">
                                Email address already exists!
                              </div>
                            )}
                          </div>

                          <div className="row mt-4">
                            <div className="col">
                              <p className="modal-input-heading">
                                Instructor (CFI)
                              </p>
                              <Typeahead
                                id='4'
                                options={instructorList}
                                className="assign-modal-text-input"
                                onChange={(selected) => {
                                  const selectedOption =
                                    selected[0] as Instructor;
                                    // console.log(`selectedOption: `, selectedOption)
                                  const selectedInstructorId = selectedOption
                                    ? selectedOption.instructorId
                                    : null;
                                  setPilotCFI(selectedInstructorId);
                                }}
                                labelKey="instructorName"
                                placeholder="Select an instructor"
                                defaultInputValue={ pilotCFI ? String(pilotCFI) : `` }
                              ></Typeahead>
                            </div>
                          </div>
                        </div>

                        <p className="modal-body-heading">
                          <i></i>TAGS AND GROUP DETAILS
                        </p>
                        <div className="container generic-details mt-1">
                          <div className="row mt-4">
                            <div className="col">
                              <p className="modal-input-heading">Tags</p>
                              <Typeahead
                                id='5'
                                options={tagsList}
                                className="assign-modal-text-input"
                                onChange={(value) => {
                                  const valueString:string = value[0]?.toString();
                                    setPilotTag(valueString);
                                    setGroupsList([tagGroupPair[valueString]])
                                  }
                                }
                                placeholder="Select a tag"
                              ></Typeahead>
                            </div>
                          </div>

                          <div className="row mt-4">
                            <div className="col">
                              <p className="modal-input-heading">Group</p>
                              <Typeahead
                                id='6'
                                options={groupsList}
                                className="assign-modal-text-input"
                                onChange={(value) =>
                                  setPilotGroup(value[0]?.toString())
                                }
                                placeholder="Select a group"
                              ></Typeahead>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <div className="container-fluid d-flex justify-content-between">
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={() => setEditPilotDetailsModal(false)}
                        >
                          <strong>Close</strong>
                        </button>
                        <button
                          type="button"
                          onClick={updatePilotDetails}
                          className="btn btn-primary"
                        >
                          Update
                        </button>
                      </div>
                    </Modal.Footer>
                  </Modal>
                </div>

                {/* EDIT INSTRUCTOR DETAILS MODAL */}
                <div className="add-aircraft-modal-wrapper">
                  <Modal
                    show={editInstructorDetailsModal}
                    onHide={() => setEditInstructorDetailsModal(false)}
                  >
                    <Modal.Header closeButton className="settings-modal-header">
                      <Modal.Title className=" pt-4">
                        <p className="modal-title-heading">Edit Instructor</p>
                        <p className="modal-title-subheading">
                          Edit added instructor's details
                        </p>
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="assign-modal-scroll-container">
                        <p className="modal-body-heading">
                          <i></i> GENERIC DETAILS
                        </p>
                        <div className="container generic-details mt-1 pb-4">
                          <div className="row mt-4">
                            <div className="col">
                              <p className="modal-input-heading">First Name</p>
                              <div className="rbt assign-modal-text-input">
                                <input
                                  placeholder="Enter instructor’s first name"
                                  type="text"
                                  value={instructorFirstName}
                                  onChange={(inp) =>
                                    setInstructorFirstName(inp.target.value)
                                  }
                                  role="combobox"
                                  className="rbt-input-main form-control rbt-input"
                                  style={{ height: `100%` }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row mt-4">
                            <div className="col">
                              <p className="modal-input-heading">Last Name</p>
                              <div className="rbt assign-modal-text-input">
                                <input
                                  placeholder="Enter instructor’s last name"
                                  value={instructorLastName}
                                  onChange={(inp) =>
                                    setInstructorLastName(inp.target.value)
                                  }
                                  type="text"
                                  role="combobox"
                                  className="rbt-input-main form-control rbt-input"
                                  style={{ height: `100%` }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row mt-4">
                            <div className="col">
                              <p className="modal-input-heading">
                                Email Address
                              </p>
                              <div className="rbt assign-modal-text-input">
                                <input
                                  placeholder="Enter instructor’s email address"
                                  value={instructorEmail}
                                  onChange={(inp) =>
                                    setInstructorEmail(inp.target.value)
                                  }
                                  type="text"
                                  role="combobox"
                                  className="rbt-input-main form-control rbt-input"
                                  style={inputStyles}
                                />
                              </div>
                            </div>
                            {emailAlreadyExists && (
                              <div className="error">
                                Email address already exists!
                              </div>
                            )}
                          </div>

                          <div className="row mt-4">
                            <div className="col">
                              <p className="modal-input-heading">
                                License Details
                              </p>
                              <div className="rbt assign-modal-text-input">
                                <input
                                  placeholder="Enter instructor’s license details"
                                  value={instructorLicense}
                                  onChange={(inp) =>
                                    setInstructorLicense(inp.target.value)
                                  }
                                  type="text"
                                  role="combobox"
                                  className="rbt-input-main form-control rbt-input"
                                  style={{ height: `100%` }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <div className="container-fluid d-flex justify-content-between">
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={() => setEditInstructorDetailsModal(false)}
                        >
                          <strong>Close</strong>
                        </button>
                        <button
                          type="button"
                          onClick={updateInstructorDetails}
                          className="btn btn-primary"
                        >
                          Update
                        </button>
                      </div>
                    </Modal.Footer>
                  </Modal>
                </div>

                {/* RESET PASSWORD MODAL */}
                <div className="add-aircraft-modal-wrapper">
                  <Modal
                    show={resetPasswordModal}
                    onHide={() => setResetPasswordModal(false)}
                  >
                    <Modal.Header closeButton className="settings-modal-header">
                      <Modal.Title className=" pt-4">
                        <p className="modal-title-heading">Edit Pilot</p>
                        <p className="modal-title-subheading">
                          Edit added pilot's details
                        </p>
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div
                        className="assign-modal-scroll-container"
                        style={{ height: `100%` }}
                      >
                        {/* <div className="assign-modal-scroll-container"> */}
                        <p className="modal-body-heading">
                          <i></i> GENERIC DETAILS
                        </p>
                        <div className="container geeric-details mt-1 pb-2">
                          <div className="row mt-4">
                            <div className="col">
                              <p className="modal-input-heading">First Name</p>
                              <div className="rbt assign-modal-text-input">
                                <input
                                  placeholder="Enter instructor’s first name"
                                  type="text"
                                  role="combobox"
                                  className="rbt-input-main form-control rbt-input"
                                  style={{ height: `100%` }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="col">
                              <p className="modal-input-heading">Last Name</p>
                              <div className="rbt assign-modal-text-input">
                                <input
                                  placeholder="Enter instructor’s last name"
                                  type="text"
                                  role="combobox"
                                  className="rbt-input-main form-control rbt-input"
                                  style={{ height: `100%` }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="col">
                              <p className="modal-input-heading">
                                Email Address
                              </p>
                              <div className="rbt assign-modal-text-input">
                                <input
                                  placeholder="Enter instructor’s email address"
                                  type="text"
                                  role="combobox"
                                  className="rbt-input-main form-control rbt-input"
                                  style={{ height: `100%` }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row mt-4 mb-0">
                            <div className="col">
                              <p className="modal-input-heading">
                                License Details
                              </p>
                              <div className="rbt assign-modal-text-input">
                                <input
                                  placeholder="Enter instructor’s license details"
                                  type="text"
                                  role="combobox"
                                  className="rbt-input-main form-control rbt-input"
                                  style={{ height: `100%` }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <div className="container-fluid d-flex justify-content-between">
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={() => setResetPasswordModal(false)}
                        >
                          <strong>Close</strong>
                        </button>
                        <button type="button" className="btn btn-primary">
                          Add Instructor
                        </button>
                      </div>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
            </div>
          </div>

          {!data.length && !noResultsFound && (
            <div className="text-center no-files-text">No Crew Added!</div>
          )}
          {noResultsFound && matchingRows === 0 && <NoResultsFound />}
          {data.length !== 0 && (
            <div className="sticky-table-wrapper">
              <StickyTable
                tableInstance={tableInstance}
                rowClick={rowClick}
                showPaginationDiv={showPaginationDiv}
              ></StickyTable>

              <ReactivateAccountModal
                show={showReactivateAccountModal}
                onHide={() => setShowReactivateAccountModal(false)}
                type={`Account`}
                name={nameToPass}
                onClickReactivate={onClickReactivate}
              ></ReactivateAccountModal>

              <DeactivateAccountModal
                show={showDeactivateAccountModal}
                onHide={() => setShowDeactivateAccountModal(false)}
                type={`Account`}
                name={nameToPass}
                onClickDeactivate={onClickDeactivate}
              ></DeactivateAccountModal>
            </div>
          )}
          <ColumnSettings
            show={columnSettingsModalShow}
            allColumns={allColumns}
            columnsettingapply={(columnData: any) =>
              onColumnSettingApply(columnData)
            }
            onHide={() => setColumnSettingsModalShow(false)}
          ></ColumnSettings>
        </section>
      </section>
    </>
  );
};

export default PilotsAndInstructors;