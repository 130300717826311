const IconDebrief = (props : any) =>{
    return(
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <title>Debrief</title>
            <g fill="none" fillRule="evenodd">
                <path fillOpacity=".01" fill="#F7F8FA" d="M0 0h24v24H0z"/>
                <path d="M3.153 4.5 7.45 9.672l.46-.413 2.327 2.663 2.046-1.836a1.29 1.29 0 0 1 .895-.336h4.143c.281 0 .537.103.741.284l2.148 2.018.563.543c.281.284.307.698.051.983l-.358.388-1.432 1.448 1.049 1.164-2.046 1.887V19.5h-5.754v-6.491l-.613.543-.665.595a.606.606 0 0 1-.87-.052L9.112 12.93l-2.327-2.664.394-.37-4.295-5.144.27-.253zm13.451 8.535-2.2 2.069 3.172 3.491.486-.44 1.738-1.603-.92-.983-1.381 1.422-1.074-1.086 1.458-1.448-1.279-1.422zm1.457-.026v1.267l.64-.673-.64-.594zm-.946-5.07c-.153.777-.767 1.423-1.56 1.578-1.406.259-2.582-.957-2.327-2.353a1.975 1.975 0 0 1 1.56-1.578c1.407-.258 2.583.957 2.327 2.354z" fill={props.fill} fillRule="nonzero"/>
            </g>
        </svg>
    )
}
export default IconDebrief