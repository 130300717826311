//Cannot be named `Companies` because it's considered Global Module

const Company = () => {

    return (
        <>
            Company Details
        </>
    );
}

export default Company;