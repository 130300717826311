import axiosInstance from "./baseService";
import { getLocalStorage } from "../common/utils/localStorageHandler";

const API_URL = process.env.REACT_APP_AUTHENTICATED_URL;

//gets list of airports and runways
const getAirPortListWithRunways = () =>{
    let authToken:any = getLocalStorage("authTokens", "");

    // console.log(authToken);

    return axiosInstance.get(API_URL+"/Debrief/GetAirportRunwayDetails")
    .then(response => {
        return response.data;
    })
}

//gets list of exercises list
const getExerciseList = () =>{
    // let authToken:any = getLocalStorage("authTokens", "")
    return axiosInstance.get(API_URL+"/Debrief/GetExcerciseList")
    .then(response => {
        // console.log(`getExerciseList res: `, response);
        return response.data.excerciseList;
    })
}

const getAnyLogUploaded = () => {
    let authToken:any = getLocalStorage("authTokens", "")
    let formData = new FormData();
    formData.append(`companyid`, authToken.companyid);
    return axiosInstance.post(API_URL+"/Debrief/CheckLogs",formData,
        {
            headers: {
                'roleid': authToken.roleid,
            }
        }
    ).then(response => {
        return response
    })
}

const checkAircraftInst = () => {
    let authToken:any = getLocalStorage("authTokens", "");
    let formData = new FormData();
    formData.append(`companyid`, authToken.companyid);
    return axiosInstance.post(API_URL+"/ManageAircraftPilot/CheckAircraftInst",formData,
        {
            headers: {
                'roleid': authToken.roleid,
            }
        }
    ).then(res => {
        return res.data
    })
}

const CommonService = {
    getAirPortListWithRunways,
    getExerciseList,
    getAnyLogUploaded,
    checkAircraftInst,
  }
  
  export default CommonService;