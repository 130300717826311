import React, { useState } from "react";
import fullScreenWindow from "../../../../../common/utils/fullScreenWindow";
import "./ExceedancesAndAlerts.scss";

const ExceedancesAndAlertsComp = (props: any) => {
  // console.log(`ExceedancesAndAlertsComp Props: `, props);

  let totalExceedances = 0;
  if (props.exceedanceData) {
    totalExceedances = props.exceedanceData.reduce((sum: number, item: any) => {
      if (
        item.Exceedance_Timestamp &&
        typeof item.Exceedance_Timestamp === "object"
      ) {
        for (let key in item.Exceedance_Timestamp) {
          if (Array.isArray(item.Exceedance_Timestamp[key])) {
            sum += item.Exceedance_Timestamp[key].length;
          }
        }
      }
      return sum;
    }, 0);
  }

  const allExceedances = {
    TaxiSpeed: "Taxi speed exceeded",
    TrackingOnRunway: "Heading not maintained on runway",
    PitchRateHighOnTakeoff: "Pitch rate high during take-off",
    PitchRateLowOnTakeoff: "Pitch rate low during take-off",
    RejectTakeOff: "Abandoned take-off",
    RateOfClimb: "Rate of climb exceeded limit",
    UnstickSpeedHigh: "Unstick speed high",
    UnstickSpeedLow: "Unstick speed low",
    // AboveGroundLevel: '',
    ExcessBanking: "Excess banking detected",
    InitialClimbHeightLoss: "Initial climb height loss",
    MaximumOperatingSpeed: "Maximum operating speed exceeded",
    MaxOperatingAltitude: "Maximum operating altitude exceeded",
    InconsistentVSpeed: "Inconsistent vertical speed",
    HighVerticalAccn: "High vertical acceleration",
    InconsistentIAS: "Inconsistent indicated air speed",
    VSpeedMaintained: "Vertical speed not maintained",
    PitchrateHigh: "High pitch rate",
    PitchrateLow: "Low pitch rate",
    DescentAngle: "Descent angle outside range",
    ApproachSpeedLowBelow: "Approach speed low",
    SpeedExceedanceBelow: "Indicated air speed exceeded limit",
    SinkrateOutBounds: "Sink-rate out of bounds",
    InconsistentSinkRate: "Inconsistent sink-rate",
    AbnormalPitchLandingHigh: "High landing pitch",
    AbnormalPitchLandingLow: "Low landing pitch",
    AbnormalBankLandingHigh: "High bank angle during landing",
    CenterlineDeviation: "Deviated from centerling during landing",
    PitchHighAtTouchDown: "High pitch at touchdown",
    PitchLowAtTouchDown: "Low pitch at touchdown",
    HighNormalAccelerationOnTouchDown: "High normal acceleration at touchdown",
    TouchdownDistance: "Touchdown distance out of bounds",
    IASAtTouchdown: "Indicated air speed out of bounds at touchdown",
    SideLoadingAtTouchdown: "Side loading at touchdown out of bounds",
  };

  type ExceedanceKey = keyof typeof allExceedances;

  //   if (totalExceedances)

  return (
    // <div className="debrief-cards" style={{ zIndex: `10001` }}>
    <div className="debrief-cards" id="exceedanceComp">
      <div className="debrief-cards-handle">
        EXCEEDANCES & ALERTS ({totalExceedances})
        <div className="button-holder d-flex">
          <button
            className="btn-maximize"
            title="Fullscreen"
            onClick={() => fullScreenWindow('exceedanceComp')}
          ></button>
        </div>
      </div>

      <div
        className="debrief-cards-content"
        key={'1'}
        style={{ overflowY: `scroll`, scrollbarWidth: "none" }}
      >
        <div
          className="container justify-content-center pl-3 pb-3"
          style={{ width: "90%" }}
        >
          {props?.exceedanceData?.map((item: any, index: number) => (
            <>
              <div
              key={index}
                className="bagde-pill mt-2"
                style={{
                  backgroundColor: `#c8e067`,
                  color: `black`,
                  width: `fit-content`,
                  borderRadius: `10px`,
                  marginLeft: `-8px`,
                }}
              >
                <span style={{ paddingLeft: `12px`, paddingRight: `12px` }}>
                  {item.labelName}
                </span>
              </div>
              <div
                key={'wrapper'+index}
                className="row mt-2 exceedance-wrapper">
                <div className="row m-1" key={'exceedanceDiv'+index}>
                  {Object.keys(item.Exceedance_Timestamp).map((key) => {
                    const exceedanceKey = key as ExceedanceKey;
                    return (
                      <div className="exceedance-name" key={'exceedanceName'+key} style={{ marginBottom: "10px" }}>
                        <div style={{ marginLeft: '8px' }}>                        
                          <span>{allExceedances[exceedanceKey]}</span>
                          <div>
                            Exceeded limit for{" "}
                            {item.Exceedance_info_Duration[exceedanceKey]}s.
                          </div>
                          </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ExceedancesAndAlertsComp;
