/**
 * Retrieves a value from local storage based on the provided key.
 * @param {string} key - The key for the item stored in local storage.
 * @param {string} initialValue - The initial value to return if the key is not found.
 * @returns {*} - The retrieved value from local storage or the initial value.
 */

export function getLocalStorage(key: string, initialValue: string) {
    try 
    {
        const  value = localStorage.getItem(key);
        return value ? JSON.parse(value) : initialValue;
    } 
    catch (e) 
    {
        return initialValue;
    }
}

/**
 * Sets a value in local storage based on the provided key.
 * @param {any} key - The key for the item to be stored in local storage.
 * @param {any} value - The value to be stored in local storage.
 */
export function setLocalStorage(key: any, value: any) {
    try {
        localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
        console.log(e);
    }
}

/**
 * Removes an item from local storage based on the provided key.
 * @param {any} key - The key for the item to be removed from local storage.
 */
export function removeLocalStorage(key: any) {
    try {
        localStorage.removeItem(key);
    } catch (e) {
        console.log(e);
    }
}
