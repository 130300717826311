import { Modal, Button } from "react-bootstrap";

const DeactivateAccountModal = (props:any) => {

    const { onHide, onClickDeactivate, type, name } = props;

    const deactivateAccount = () => {
        onClickDeactivate();
        onHide();
    }

    const styles = {
        fontFamily: 'IBMPlexSans',
        fontSize: '16px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.5',
        letterSpacing: 'normal',
        color: '#666',
    };

    const descText2 = (type === `Aircraft` ? `aircraft` : `account from your organisation`)

    return (
        <>
            <Modal {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton className="delete-modal-header" style={{ backgroundColor: `#f5ebec`}} onClick={() => onHide()}>
                </Modal.Header>
                <Modal.Body>
                    <div className="delete-modal-title">
                        <span style={{fontSize: `20px`, fontWeight: `600`}}>Deactivate {type}</span>
                        <br />
                        <span style={styles}>Deactivate pilot's/instructor's account from the dashboard</span>
                    </div>

                    <div className="delete-modal-body">
                        <strong>Are you ceratin you want to deactivate the selected {descText2}?</strong>
                        <br /><br />
                        <br />
                        {type} Selected - {name}
                        <br />
                        <span style={{color: `white`}}>.</span>
                    </div>
                </Modal.Body>
                <Modal.Footer className="assign-modal-footer">
                    <Button variant="light border" onClick={() => onHide()}>Close</Button>
                    {/* <Button variant="danger" onClick={deleteFile}>Yes, Delete Logs</Button> */}
                    <Button variant="danger" onClick={() => deactivateAccount()} style={{ backgroundColor: `#da202a`}} >Yes, Deactivate {type}</Button>
                </Modal.Footer>

            </Modal>
        </>
    )

};

export default DeactivateAccountModal;