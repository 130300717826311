/**
 * @module Theme
 * @description Module for managing the theme of the application.
 */

import { createContext, useState, useContext, useEffect } from "react";

/**
 * @constant {Object} initialState - The initial theme state.
 */
let initialState = {
    primaryColor: '#477afe',
    primaryDisabledColor: '',
    secondaryColor: 'blue',
    secondaryDisabledColor: 'blue',
    brandLogo: 'brand.png',
};

/**
 * @function setLocalStorage
 * @description Saves a key-value pair to the local storage.
 * @param {string} key - The key to store the value.
 * @param {any} value - The value to be stored.
 */
export function setLocalStorage(key, value) {
    try {
        localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
        console.log(e);
    }
}

/**
 * @function getLocalStorage
 * @description Retrieves the value associated with a key from local storage.
 * @param {string} key - The key to retrieve the value.
 * @param {any} initialValue - The default value if the key is not found.
 * @returns {any} - The stored value or the initial value if not found.
 */
function getLocalStorage(key, initialValue) {
    try {
        const value = localStorage.getItem(key);
        return value ? JSON.parse(value) : initialValue;
    } catch (e) {
        return initialValue;
    }
}

/**
 * @function SetAppTheme
 * @description Sets the application theme by updating CSS variables.
 * @param {Object} theme - The theme object with color and logo information.
 */
export const SetAppTheme = (theme) => {
    if (theme.brandLogo) {
        //   this.setAppLogo(theme.logo);
    }
    document.body.style.setProperty('--company-logo', `url(${theme.brandLogo})`);
    document.body.style.setProperty('--primary-color', theme.primaryColor);
    document.body.style.setProperty('--disabled-primary-color', theme.primaryDisabledColor);
    document.body.style.setProperty('--secondary-color', theme.secondaryColor);
    document.body.style.setProperty('--disabled-secondary-color', theme.secondaryDisabledColor);
    document.body.style.setProperty('--gradient-color', theme.secondary);
    document.body.style.setProperty('--light-background-color', theme.primary);
    document.body.style.setProperty('--button-background-color', theme.primary);
};

/**
 * @constant {React.Context} ThemeContext - The context to provide theme information to components.
 */
export const ThemeContext = createContext();

/**
 * @function ThemeProvider
 * @description A provider component to wrap the application and provide theme context.
 * @param {Object} children - The child components to be wrapped by the provider.
 * @returns {React.Component} - The component providing the theme context.
 */
export const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState(() => getLocalStorage("theme", initialState));

    useEffect(() => {
        //setLocalStorage("theme", newTheme)
    }, [theme]);

    return (
        <ThemeContext.Provider value={theme}>
            {children}
        </ThemeContext.Provider>
    );
};

/**
 * @function useTheme
 * @description A hook to consume the current theme from the theme context.
 */
export const useTheme = () => {
    return useContext(ThemeContext);
};

export default ThemeContext;
