import { Modal, Button } from "react-bootstrap";

const BulkAssignMismatchAlert = (props:any) => {

    const { onHide, updateMultipleCallback } = props;

    const onYesProceed = () => {
        updateMultipleCallback();
        onHide();
    };

    return (
        <>
            <Modal {...props}
            size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered >
                <Modal.Header closeButton className="assign-modal-header" onClick={() => onHide()} >
                    <div className="assign-modal-title mx-2" >Unselect Logs</div>
                </Modal.Header>
                <Modal.Body>
                    <div className="delete-modal-body">
                        Few of the selected logs with issues (duplicate log, admin cancellations, and 
                        upload failures) will be removed from the selection if you proceed with the assignment.
                        <br></br>
                        <strong>"Proceed with Assignment?"</strong>
                    </div>
                </Modal.Body>
                <Modal.Footer className="assign-modal-footer">
                <Button variant="light border" onClick={() => onHide()}>Close</Button>
                    <Button variant="primary" onClick={() => onYesProceed()} >Yes, Proceed</Button>
                </Modal.Footer>

            </Modal>
        </>
    );
}

export default BulkAssignMismatchAlert;