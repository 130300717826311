import { createContext, useState, useContext, useMemo } from "react";
import Debrief from "../pages/dashboard/components/debrief/Debrief";
import Import from "../pages/dashboard/components/import/Import";
import Flights from "../pages/dashboard/components/flights/Flights";
import Reports from "../pages/dashboard/components/reports/Reports";
import { useLocalStorage } from "../common/utils/useLocalStorage";


const AuthContext = createContext()
let authDataArr = 
{   
    debrief: <Debrief />,
    flights: <Flights />,
    import: <Import />,
    reports: <Reports />
}


const SetUserAuth = () => 
{

}

// export const setLoginData = (value) =>{
//     try{
//         localStorage.setItem("authTokens", JSON.stringify(value));
//     } catch (e) {
//         console.log(e)
//     }
// }


export const AuthProvider = ({ children }) => 
{

    const [authTokens, SetAuthTokens] = useLocalStorage("authTokens", null);
    const setLoginData = (val) =>
    {
        SetAuthTokens(val);
    }
    const value = useMemo( () => ({  authTokens, setLoginData }),
        [authTokens]
    );
    
  
    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => 
{
    return useContext(AuthContext)
}


export default AuthContext