/**
 * print following elements on pfd canvas
 * nav1, nav2 label
 * com1, com2 label
 * @param canvasCtx - 2d canvas context
 * @param canvasWidth - canvas width
 * @param canvasHeight - canvas height
 * @param nav1 - nav1 value
 * @param nav2 - nav2 value
 * @param com1 - com1 value
 * @param com2 - com2 value
 */
const topGroupEle = (canvasCtx : any, canvasWidth : number, canvasHeight : number, nav1 : number, nav2 : number, com1 : number, com2 : number) =>{
    let topGroupEleWidth = 1536

    topGroupEleWidth = canvasWidth < topGroupEleWidth ? canvasWidth : topGroupEleWidth  // group element width is less than or equal to canvas

    // background rectangle
    canvasCtx.beginPath()
    canvasCtx.fillStyle = "rgba(18, 18, 18, 0.64)"
    canvasCtx.roundRect((canvasWidth - topGroupEleWidth)/2, 0, topGroupEleWidth, 84, [0, 0, 24, 24])
    canvasCtx.fill()
    canvasCtx.closePath()

    //nav1, nav2 label
    canvasCtx.beginPath()
    canvasCtx.fillStyle = "#fff"
    canvasCtx.font = "500 24px Arial"
    canvasCtx.fillText("NAV1",  ((canvasWidth - topGroupEleWidth)/2)+16, 36)
    canvasCtx.fillText(nav1.toFixed(3).toString(), ((canvasWidth - topGroupEleWidth)/2)+100, 36)
    canvasCtx.fillText("NAV2",  ((canvasWidth - topGroupEleWidth)/2)+16, 70)
    canvasCtx.fillText(nav2.toFixed(3).toString(), ((canvasWidth - topGroupEleWidth)/2)+100, 70)
    canvasCtx.fill()
    canvasCtx.closePath()

    //com1 , com2 label
    canvasCtx.beginPath()
    canvasCtx.fillStyle = "#fff"
    canvasCtx.font = "500 24px Arial"
    canvasCtx.fillText("COM1", ((canvasWidth - topGroupEleWidth)/2)+topGroupEleWidth-80, 36)
    canvasCtx.fillText(com1.toFixed(3).toString(), ((canvasWidth - topGroupEleWidth)/2)+topGroupEleWidth-200, 36)
    canvasCtx.fillText("COM2", ((canvasWidth - topGroupEleWidth)/2)+topGroupEleWidth-80, 70)
    canvasCtx.fillText(com2.toFixed(3).toString(), ((canvasWidth - topGroupEleWidth)/2)+topGroupEleWidth-200, 70)
    canvasCtx.fill()
    canvasCtx.closePath()

}

export default topGroupEle