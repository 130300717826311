const IconFlyingDutyTime = (props : any) =>{
    return(
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <title>Flying Duty Time</title>
        <g fill="none" fillRule="evenodd">
            <path fillOpacity=".01" fill="#FFF" d="M0 0h24v24H0z"/>
            <path d="M16.5 13a5.402 5.402 0 0 1-2.143-.426 5.527 5.527 0 0 1-1.755-1.182 5.475 5.475 0 0 1-1.179-1.754A5.43 5.43 0 0 1 11 7.497c0-.763.141-1.477.423-2.142a5.49 5.49 0 0 1 2.93-2.932A5.415 5.415 0 0 1 16.493 2a5.465 5.465 0 0 1 3.905 1.601 5.475 5.475 0 0 1 1.179 1.754A5.43 5.43 0 0 1 22 7.497a5.43 5.43 0 0 1-.423 2.141 5.475 5.475 0 0 1-1.18 1.754 5.527 5.527 0 0 1-1.754 1.182A5.402 5.402 0 0 1 16.5 13z" stroke={props.fill} strokeWidth=".8"/>
            <path d="M16.503 4.205a.5.5 0 0 1 .5.5v3.588l1.35 1.354a.5.5 0 0 1 .058.638l-.058.069a.5.5 0 0 1-.707 0l-1.497-1.5a.5.5 0 0 1-.146-.354V4.705a.5.5 0 0 1 .5-.5z" fill={props.fill}/>
            <path d="m13.15 21.837.325-4.616v-3.174l.775-.002.75-.09c.21-.024.423-.047.58-.06.407-.032.61-.336.683-.549l.608-.18a.174.174 0 0 0 .129-.164.177.177 0 0 0-.13-.165l-.607-.178c-.07-.213-.274-.521-.68-.553l-.27-.025-1.083-.126h-.754V8.768l-.327-4.605C13.143 4.07 13.062 4 12.961 4h-1.95c-.093 0-.172.064-.185.149l-.694 4.63v3.197l-4.567.475-.48-2.29a.186.186 0 0 0-.183-.14H3.589a.188.188 0 0 0-.186.147L3 12.594v.013c0 .043.018.083.048.115l.267.28-.267.276a.163.163 0 0 0-.046.14l.4 2.412a.186.186 0 0 0 .187.147h1.313c.09 0 .166-.058.184-.139l.476-2.286 4.57.477.002 3.214.692 4.608a.184.184 0 0 0 .186.149h1.951c.099 0 .18-.07.186-.163zm-3.501-8.442c-.112-.103-.151-.27-.151-.393s.04-.29.151-.39a.332.332 0 0 1 .24-.085c.171.001.223.056.237.09l.006.027v.72c0 .016-.015.113-.242.115a.351.351 0 0 1-.19-.047l-.051-.037zm4.017.257c-.052 0-.094-.038-.094-.086v-1.127c0-.048.043-.087.094-.087h.158c.022 0 .043.007.06.02.224.167.347.391.347.63 0 .24-.123.463-.347.63a.1.1 0 0 1-.06.02h-.158z" fill={props.fill} fillRule="nonzero"/>
        </g>
    </svg>
    )
}

export default IconFlyingDutyTime