/**
 * @module Login
 * @description React component for the login page.
 * @file Imports necessary styles, images, and dependencies.
 */

import './auth-common.scss';
import brandLogo from '../../assets/images/icon-url-branding-icon-aerx@3x.png';
import aerxLogo from '../../assets/images/icon-aerx-logo@3x.png';
import { useTheme } from '../../context/themeContext';
import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

/**
 * @function Login
 * @description React component representing the login page.
 * @param {Object} props - Additional properties passed to the component.
 * @returns {React.Component} - The login page component.
 */
function Login(props: any) {
    const theme = useTheme();
    const { t } = useTranslation();
    const param = useParams();

    return (
        <>
            <div className="container-login d-flex">
                <div className="wrapper-login col-lg-6 col-md-16 col-ms-16 sectio-login">
                    <img className='img-logo' alt='branding' src={brandLogo} />
                    {/* <p className='log-in'>{t('login.log_in')}</p> */}
                    <p className='log-in'>Flight360</p>
                    <Outlet />
                    <div className='login-footer d-flex'>
                        <div className='col d-flex align-items-center'>
                            <img src={aerxLogo} alt='aerx logo' />
                        </div>
                        <div className='col d-flex align-items-center flex-row-reverse'>
                            <a href='/privacy_policy'>Privacy Policy</a>
                            <a data-tooltip-id="my-tooltip" data-tooltip-content="info@aerxlabs.com" data-tooltip-place="top">
                                <u style={{fontWeight: '500', color: '#666' }}>Contact us</u>
                            </a>
                            <Tooltip id="my-tooltip" />
                        </div>
                    </div>
                </div>

                <div className="col-lg-10 d-none d-md-block section-image"></div>
            </div>
        </>
    );
}

export default Login;
