import "./progressBar.scss";

const ProgressBar1=()=>{

    return (
        <>
            <div className="progress-bar-outer">
                <div className="progress-bar-inner3">
                </div>
                <div className="progress-bar-inner4">
                </div> 
             </div>
        </>
    )
}

export default  ProgressBar1;