import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { csvJSonArray } from '../../../../common/utils/cesiumDataHandler';
import { getTailNumber, getStartTime } from '../debrief/debriefPlayer/DebriefPlayer';

const TwoAxisGraphsWithRunway = (props: {
  xaxisArray: any[],
  yaxisArray: any[],
  xlabel: string,
  ylabel: string,
  phase?: string,
}) => {

  // console.log(`props 2AR: `, props)

  const [data, setData] = useState<any[] | undefined>(undefined);
  const [layout, setLayout] = useState<any | undefined>(undefined);


  useEffect(() => {

    const updateTooltip = () => {
      const anchorElement = document.querySelector('.modebar-btn[data-title="Download plot as a png"]');
      if (anchorElement) {
        anchorElement.setAttribute('data-title', 'Download as an Image');
      }
    };

    const trace1 = {
      x: props.xaxisArray,
      y: props.yaxisArray,
      // fill: 'tozeroy',
      type: 'scatter',
      showlegend: false,
    };

    let yRange = [Math.min(...props.yaxisArray), Math.max(...props.yaxisArray)];
    let runway = null, shapes;

    //------------------------------------------------------------
    let additionalLines: any = [];
    if (props.ylabel === 'Vertical Speed (kn)') { //for takeoff phase
      runway = {
        type: 'rect',
        x0: Math.min(...props.xaxisArray),
        x1: 0,
        y0: Math.min(...props.yaxisArray),
        y1: (1.5 * Math.min(...props.yaxisArray)),
        fillcolor: 'grey',
        line: {
          color: 'grey',
        },
      }
      yRange = [1.52 * Math.min(...props.yaxisArray), Math.max(...props.yaxisArray)];
      shapes = [runway];

    }
    else if (props.ylabel === 'Pitch (deg)') { //for landing phase
      runway = {
        type: 'rect',
        x0: Math.min(...props.xaxisArray),
        x1: 0,
        y0: Math.min(...props.yaxisArray),
        y1: (Math.min(...props.yaxisArray) - (0.2 * Math.max(...props.yaxisArray))),
        fillcolor: 'grey',
        line: {
          color: 'grey',
        },
      }
      yRange = [(Math.min(...props.yaxisArray) - (0.2 * Math.max(...props.yaxisArray))), Math.max(...props.yaxisArray)];
      shapes = [runway];
    }
    else if (props.phase === 'Landing' && props.ylabel === 'AltMSL (ft)') { //landing phase

      // console.log(`DIST arr: `)
      // console.log(props.xaxisArray)

      // console.log(`ALT arr: `)
      // console.log(`ALT arr: `, props.yaxisArray)

      runway = {
        type: 'rect',
        x0: Math.min(...props.xaxisArray),
        x1: 0,
        y0: Math.min(...props.yaxisArray),
        y1: (0.99 * Math.min(...props.yaxisArray)),
        fillcolor: 'grey',
        line: {
          color: 'grey',
        },
      };

      // Additional shapes for limit lines
      additionalLines = [
        {
          type: 'line',
          x0: 0,
          x1: Math.max(...props.xaxisArray),
          y0: Math.min(...props.yaxisArray),
          y1: Math.min(...props.yaxisArray) + (0.05240 * Math.max(...props.xaxisArray) * 6076.12),
          line: {
            color: 'teal',
            dash: 'dash',
          },
          name: '3°',
          hoverinfo: 'none',
          showlegend: true,
        },

        {
          type: 'line',
          x0: 0,
          x1: Math.max(...props.xaxisArray),
          y0: Math.min(...props.yaxisArray),
          y1: Math.min(...props.yaxisArray) + (0.04366 * Math.max(...props.xaxisArray) * 6076.12),
          line: {
            color: 'red',
            dash: 'dash',
          },
          name: '2.5°',
          hoverinfo: 'none',
          showlegend: true,
        },

        {
          type: 'line',
          x0: 0,
          x1: Math.max(...props.xaxisArray),
          y0: Math.min(...props.yaxisArray),
          y1: Math.min(...props.yaxisArray) + (0.06116 * Math.max(...props.xaxisArray) * 6076.12),
          line: {
            color: 'purple',
            dash: 'dash',
          },
          name: '3.5°',
          hoverinfo: 'none',
          showlegend: true,
        },
      ]

      // const idealLine = {
      //   type: 'line',
      //   x0: 0,
      //   x1: Math.max(...props.xaxisArray),
      //   y0: Math.min(...props.yaxisArray),
      //   y1: Math.min(...props.yaxisArray) + (0.05240 * Math.max(...props.xaxisArray) * 6076.12),
      //   line: {
      //     color: 'teal',
      //     dash: 'dash',
      //   },
      // };

      // const upperLimit = {
      //   type: 'line',
      //   x0: 0,
      //   x1: Math.max(...props.xaxisArray),
      //   y0: Math.min(...props.yaxisArray),
      //   y1: Math.min(...props.yaxisArray) + (0.06116 * Math.max(...props.xaxisArray) * 6076.12),
      //   line: {
      //     color: 'purple',
      //     dash: 'dash',
      //   },
      // };

      // const lowerLimit = {
      //   type: 'line',
      //   x0: 0,
      //   x1: Math.max(...props.xaxisArray),
      //   y0: Math.min(...props.yaxisArray),
      //   y1: Math.min(...props.yaxisArray) + (0.04366 * Math.max(...props.xaxisArray) * 6076.12),
      //   line: {
      //     color: 'red',
      //     dash: 'dash',
      //   },
      // };

      shapes = [runway, ...additionalLines];
      // shapes = [runway, idealLine, upperLimit, lowerLimit];

      yRange = [(0.99 * Math.min(...props.yaxisArray)), Math.max(Math.max(...props.yaxisArray), (Math.min(...props.yaxisArray) + (0.06116 * Math.max(...props.xaxisArray) * 6076.12)))];
      // yRange = [(0.99 * Math.min(...props.yaxisArray)), Math.min(...props.yaxisArray) + (0.06116262015 * Math.max(...props.yaxisArray))];
    }
    else if (props.phase === 'Takeoff' && props.ylabel === 'AltMSL (ft)') { //for takeoff
      runway = {
        type: 'rect',
        x0: Math.min(...props.xaxisArray),
        x1: 0,
        y0: Math.min(...props.yaxisArray),
        y1: (0.99 * Math.min(...props.yaxisArray)),
        fillcolor: 'grey',
        line: {
          color: 'grey',
        },
      };
      shapes = [runway];

      yRange = [(0.99 * Math.min(...props.yaxisArray)), Math.max(...props.yaxisArray)];

    }

    //------------------------------------------------------------

    const graphData = [trace1, ...additionalLines];
    // const graphData = [trace1];


    // const graphLayout = {
    //   dragmode: false,
    //   xaxis: {
    //     title: {
    //       text: props.xlabel,
    //       font: {
    //         size: 12
    //       }
    //     },
    //     tickfont: {
    //       size: 12,
    //     },
    //     // nticks: 8, //specifying the number of ticks on the x-axis
    //     range: [Math.min(...props.xaxisArray), Math.max(...props.xaxisArray)]
    //   },

    //   yaxis: {
    //     title: {
    //       text: props.ylabel,
    //       font: {
    //         size: 12
    //       }
    //     },
    //     tickfont: {
    //       size: 12,
    //     },
    //     side: 'left',
    //     range: yRange,
    //   },

    //   hovermode: 'closest',
    //   autosize: true,
    //   margin: {
    //     t: 20,
    //     b: 50,
    //     l: 50,
    //     r: 50,
    //   },
    //   shapes: shapes,
    // };

    let graphLayout = {};
    if (props.phase === 'Landing' && props.ylabel === 'AltMSL (ft)') { //landing phase
      graphLayout = {
        dragmode: false,
        xaxis: {
          title: {
            text: props.xlabel,
            font: {
              size: 12
            }
          },
          tickfont: {
            size: 12,
          },
          // range: [Math.min(...props.xaxisArray), Math.max(...props.xaxisArray)],
          autorange: true,
        },

        yaxis: {
          title: {
            text: props.ylabel,
            font: {
              size: 12
            }
          },
          tickfont: {
            size: 12,
          },
          side: 'left',
          // range: yRange,
          autorange: true,
        },

        hovermode: 'closest',
        autosize: true,
        margin: {
          t: 20,
          b: 50,
          l: 50,
          r: 50,
        },
        shapes: shapes,
        legend: {
          x: 0,
          y: 1.22,
          orientation: 'h',
        },
      };
    }
    else {
      graphLayout = {
        dragmode: false,
        xaxis: {
          title: {
            text: props.xlabel,
            font: {
              size: 12
            }
          },
          tickfont: {
            size: 12,
          },
          // nticks: 8, //specifying the number of ticks on the x-axis
          // range: [Math.min(...props.xaxisArray), Math.max(...props.xaxisArray)],
          autorange: true,
        },

        yaxis: {
          title: {
            text: props.ylabel,
            font: {
              size: 12
            }
          },
          tickfont: {
            size: 12,
          },
          side: 'left',
          // range: yRange,
          autorange: true,
        },

        hovermode: 'closest',
        autosize: true,
        margin: {
          t: 20,
          b: 50,
          l: 50,
          r: 50,
        },
        shapes: shapes,
      };
    }
    setData(graphData);
    setLayout(graphLayout);

    updateTooltip();

  }, [csvJSonArray, props.xaxisArray, props.yaxisArray]);

  // Function to extract trace name from a label string
    const extractTraceName = (labelString: string): string | null => {
        const regularExpression = /(.+?) \(/;
        const match = labelString.match(regularExpression);
        return match ? match[1] : null;
    };

    // Function to combine labels into a single string
    const combineLabels = (): string => {
        // Extract trace names from y1label, y2label, and xlabel
        const traceName1 = extractTraceName(props.ylabel);
        const xLabelName = extractTraceName(props.xlabel);

        // Combine the names into a single string
        return `${traceName1} vs ${xLabelName}`;
    };

  const [graphName, setGraphName] = useState(combineLabels());

  return (
    <Plot
      data={data as any}
      layout={layout as any}
      onInitialized={() => { }}
      onUpdate={() => { }}
      // config={{ displayModeBar: false, responsive: true}} //disabling default double-click reset
      // config={{ displayModeBar: false, responsive: true }} //disabling default double-click reset
      config={{
        // doubleClick: false,
        responsive: true,
        displayModeBar: true,
        // scrollZoom: true,
        displaylogo: false,
        modeBarButtonsToRemove: ['autoScale2d', 'resetScale2d', 'lasso2d', 'select2d'],
        toImageButtonOptions: {
          format: `png`,
          filename: `${getTailNumber()}_${graphName}_${getStartTime()}`,
        },
      }}
      style={{ width: '100%', height: '100%' }}
    />
  );

};

export default React.memo(TwoAxisGraphsWithRunway);