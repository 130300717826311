import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/authProvider";
import axiosInstance from "./baseService";
import { SetAuthTokens } from "./baseService";


function Protected({ children, setPath}:any) {
    const {authTokens } = useAuth();
    const activePage = useLocation();

    if (authTokens && new Date() > new Date(authTokens.expiration)) {
      console.log(`Access Token Expired`);
      // refresh token if access token is expired
      axiosInstance.post(process.env.REACT_APP_AUTH_BASE_URL + "/Token/Refresh",
          {
            AccessToken: authTokens.token,
            RefreshToken: authTokens.refreshToken,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        ).then((res) => {
          console.log(res);
          SetAuthTokens(res.data);
        }).catch((error) => {
          console.log(`Error refreshing access token`)
          console.log(error)
        });
    }
    
    if(!authTokens) {//if access token is there in local storage then go to login page
      //  console.log(setPath)
       setPath(activePage.pathname);
      
        return(<Navigate to="/" replace />)
     }
    return children;
}



export default Protected;