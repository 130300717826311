import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import "./Reports.scss"
import StickyTable from "../../../../common/components/StickyTable/StickyTable";
import { useBlockLayout, useTable, useSortBy, useColumnOrder } from "react-table";
import { useSticky } from "react-table-sticky";
import ColumnSettings from "../../../../common/components/ColumnSettings/ColumnSettings";
import ReportsSideMenu from "./ReportsSideMenu";
import FlyingInstructorsReport from "./components/flyingReport/FlyingInstructorsReport";
import SummaryReport from "./components/dataAnalysis/SummaryReport";
import { Outlet } from "react-router-dom";


const Reports = () =>{
    const [modalShow, setModalShow] = React.useState(false);
    const [SearchField, setSearchField] = useState("");
    const onDownloadReportClick = () => {

    }
    const onSearchInputChange = (e: any) => {
        setSearchField(e.target.value);
    };

    const data = React.useMemo(() =>
    [{
        "fI_name": "Kerry",
        "last_data_received": "2/8/2023",
        "total_flying_time": "12:29 AM",
        "duty_time_left": "2:02 AM",
        "percent_left": 53,
        "violation_status": "libero"
      }, {
        "fI_name": "Esme",
        "last_data_received": "4/9/2023",
        "total_flying_time": "2:26 PM",
        "duty_time_left": "1:20 AM",
        "percent_left": 12,
        "violation_status": "libero"
      }, {
        "fI_name": "Davita",
        "last_data_received": "4/3/2023",
        "total_flying_time": "10:13 AM",
        "duty_time_left": "8:23 PM",
        "percent_left": 85,
        "violation_status": "metus"
      }, {
        "fI_name": "Gennifer",
        "last_data_received": "9/7/2022",
        "total_flying_time": "12:51 PM",
        "duty_time_left": "12:01 AM",
        "percent_left": 27,
        "violation_status": " non"
      }, {
        "fI_name": "Eugenia",
        "last_data_received": "8/12/2022",
        "total_flying_time": "5:07 AM",
        "duty_time_left": "12:54 PM",
        "percent_left": 69,
        "violation_status": "=nisl"
      }, {
        "fI_name": "Westbrooke",
        "last_data_received": "9/27/2022",
        "total_flying_time": "6:35 AM",
        "duty_time_left": "7:36 PM",
        "percent_left": 71,
        "violation_status": "elementum in"
      }, {
        "fI_name": "Adams",
        "last_data_received": "9/2/2022",
        "total_flying_time": "1:55 AM",
        "duty_time_left": "11:34 AM",
        "percent_left": 80,
        "violation_status": "elit  non"
      }, {
        "fI_name": "Tedda",
        "last_data_received": "12/16/2022",
        "total_flying_time": "10:22 AM",
        "duty_time_left": "2:08 PM",
        "percent_left": 3,
        "violation_status": "nulla unc"
      }],
      []
    )
  
    const columns: any = React.useMemo(
      () => [
        {
          id: 'fi_name',
          Header: 'FI Name',
          accessor: 'fI_name',
          isFixed: true,
          isDisplayed: true,
        },
        {
          id: 'last_data_received',
          Header: 'Last Data Received',
          accessor: 'last_data_received',
          isFixed: false,
          isDisplayed: true
        },
        {
          id: 'total_flying_time',
          Header: 'Total Flying time',
          accessor: 'total_flying_time',
          isFixed: false,
          isDisplayed: true
        },
        {
          id: 'duty_time_left',
          Header: 'Duty time left',
          accessor: 'duty_time_left',
          isFixed: false,
          isDisplayed: true
        },
        {
          id: 'percent_left',
          Header: '% Left',
          accessor: 'percent_left',
          isFixed: false,
          isDisplayed: true
        },
        {
          id: 'violation_status',
          Header: 'Violation Status',
          accessor: 'violation_status',
          isFixed: false,
          isDisplayed: true
        }
      ],
      []
    );

    
    const onColumnSettingApply = (columnData: any) => {
      console.log(columnData);
      const orderOfColumns = columnData.map((column: any) => {
        return column.id;
      });
      changeOrder(orderOfColumns);
      setModalShow(false);
    }
  
    const changeOrder = (order: any[]) => {
      setColumnOrder(order); 
    }

    const tableInstance = useTable({
        columns,
        data,
      initialState: {
        sortBy: [
          {
            id: `fi_name`,
            desc: false
          }
        ]
      }
      },
      useColumnOrder,
      useSortBy,
      useBlockLayout, 
      useSticky);
    
  const {
    setColumnOrder,
  } = tableInstance;
    

  return (<>Flying Instructor Report List</>)

    return (
      <div className="reports-container">
        {/* Commenting The Below Component For Project Build */}
        <ReportsSideMenu/>
        <div className="reports-wrap-container">
          
          <Outlet/>
          {/* <SummaryReport/> */}
          {/* <FlyingInstructorsReport/> */}
        {/* <section className="reports-wrap">
            <section className="top-section">
                <div className="breadcrumb">
                    Flying Instructor Report List   
                </div>
                <div className="buttons-wrapper">
                    <Button className="f360-btn-with-icon filter-btn">
                    <i className="btn-icon icon-filter"></i>Last 7 days</Button>
                    <Button variant="secondary" className="f360-btn-with-icon-light download-btn me-3" onClick={() => onDownloadReportClick()}>
                    <i className="btn-icon icon-download"></i>
                        Download Report
                    </Button>
    
                </div>
            </section>
            <section className="mid-section">
                <h5 className="head">Flying Insctructors Flying Report</h5>
                <p className="sub-head">Let’s you check the remaining duty time and violation status of your flying instructors.</p>
                <Container fluid className="stats-wrap">  
                    <Row className="w-100">  
                        <Col sm={16} md={5}>
                            <div className="stats-block">
                                <div className="block-top">
                                    <div className="block-status">
                                        <span className="span-safe-icon"></span>
                                        Safe
                                    </div>
                                    <p className="top-text">hrs left 21 - 100% </p>
                                </div>
                                <div className="block-mid">
                                    <span className="head">4</span>
                                    <span className="percent">(66.4%)</span>
                                </div>
                                <div className="stats-progress-bar">
                                  <div className="stats-progress-bar-value" style={{width:"66.4%"}}></div>
                                </div>
                            </div>
                        </Col>  
                        <Col sm={16} md={5}>
                            <div className="stats-block">
                                <div className="block-top">
                                    <div className="block-status">
                                        <span className="span-warning-icon"></span>
                                        Warning
                                    </div>
                                    <p className="top-text">hrs left 21 - 100% </p>
                                </div>
                                <div className="block-mid">
                                    <span className="head">2</span>
                                    <span className="percent">(33.4%)</span>
                                </div>
                                <div className="stats-progress-bar">
                                  <div className="stats-progress-bar-value" style={{width:"33.4%"}}></div>
                                </div>
                            </div>
                        </Col> 
                        <Col sm={16} md={5}>
                            <div className="stats-block">
                                <div className="block-top">
                                    <div className="block-status">
                                        <span className="span-violation-icon"></span>
                                        In-Violation
                                    </div>
                                    <p className="top-text">hrs left 21 - 100% </p>
                                </div>
                                <div className="block-mid">
                                    <span className="head">0</span>
                                    <span className="percent">(0%)</span>
                                </div>
                                <div className="stats-progress-bar">
                                  <div className="stats-progress-bar-value" style={{width:"0%"}}></div>
                                </div>
                            </div>
                        </Col>  
                    </Row>  
                </Container>  
            </section>
            <section className="bottom-section">
                <div className="filter-wrap">
                    <div className="search-filter">
                        <div className='input-select-wrapper '>
                            <input type="text" className="text-field text-space" placeholder="Search by name" 
                            onChange = {onSearchInputChange}/>
                            <i className="search-icon"></i>
                        </div>
                    </div>
                    <div className="col-filter-wrap">
                        <Button variant="secondary" className="f360-btn-with-icon-light me-3 column-btn" onClick={() => setModalShow(true)}>
                        <i className="btn-icon icon-column"></i>
                        Column
                        </Button>
                        <Button className="f360-btn-with-icon filter-btn">
                        <i className="btn-icon icon-filter"></i>Filter</Button>
                    </div>
                </div>
                <div className="table-wrapper">
                    <StickyTable tableInstance={tableInstance}
                    searchField={SearchField}></StickyTable>
                 </div>
                 <ColumnSettings
                    show={modalShow}
                    allColumns={allColumns}
                    columnsettingapply={(columnData: any) => onColumnSettingApply(columnData)}
                    onHide={() => setModalShow(false)}></ColumnSettings>
            </section>
        </section> */}
        </div>
      </div>
      )
}

export default Reports