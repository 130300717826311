import "../PfdScreen/PfdScreen.scss"
import "./CesiumComp.scss"
import { Ion,  CesiumTerrainProvider, JulianDate, createWorldTerrainAsync  } from "cesium";
import { Viewer, Scene, } from "resium";
import { ReadFile,  csvJSonArray,clockViewModel,GetJulianDate } from "../../../../common/utils/cesiumDataHandler";
//import { useEffect, useState } from "react";
//import Cesium2D from "../2DMAP/cesium2D";

const CesiumComp = (props) => {

    return (

        <div className='h-100 w-100' id="viewerWrap">
        <div  id="viewer" className="viewer"> </div>
            </div>
       

    )

}

export default CesiumComp