import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next'
import { isValidEmail } from "../../../../common/utils/validator";
import './Logincomp.scss'
import LoadSpinner from "../../../../common/components/loadSpinner/loadSpinner";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import AuthService from "../../../../services/auth.service";
import CommonService from "../../../../services/common.service";
import { useAuth } from "../../../../context/authProvider";


const Logincomp = (props: any) => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  let { client } = useParams();
  const { authTokens, setLoginData } = useAuth();
  const [email, setEmail] = useState("");
  const [isEmail, setIsEmail] = useState(false);
  const [isEmailExist, setIsEmailExist] = useState(false);
  const [isEmailFound, setIsEmailFound] = useState(true);
  const [pass, SetPass] = useState("");
  const [isLogin, setIslLogin] = useState(true);
  const [isPassValid, setIsPassValid] = useState(false);
  const [isPassLoading, setIsPassLoading] = useState(false);
  const [passwordType, setPasswordType] = useState("password");

  /**
   * Handles the change event for the email input field.
   * @param {React.ChangeEvent<HTMLInputElement>} e - The event object for the input change.
   * @returns {void}
   */
  const emailHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Set flag indicating that email is found
    setIsEmailFound(true);

    // Check if the input value is a valid email
    if (isValidEmail(e.target.value)) {
      //Set the email state if it's a valid email
      setEmail(e.target.value);
    } else {
      // Clear the email state and set flags accordingly if the input is not a valid email
      setEmail("");
      setIsEmailFound(true);
      setIsEmail(false);
    }
  };

  /**
   * Handles the submission of the email and checks its existence using the AuthService.
   * @returns {void}
   */
  const submitEmail = () => {
    // Check if email is present
    if (email) {
      // Set flag indicating that the email is being processed
      setIsEmail(true);

      // Call AuthService to check the existence of the email
      AuthService.checkEmail(email)
        .then((response) => {
          // Set flag indicating that the email exists
          setIsEmailExist(true);
        })
        .catch((err) => {
          // Log error and reset email-related states if an error occurs
          console.log("Email does not exist");
          console.error(err);
          setEmail("");
          setIsEmail(false);
          setIsEmailFound(false);
        });
    }
  };

  /**
   * Handles the change event for the password input field.
   * @param {React.ChangeEvent<HTMLInputElement>} e - The event object for the input change.
   * @returns {void}
   */
  const passHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Set the password state with the input value
    SetPass(e.target.value);

    // Set flag indicating that login is in progress
    setIslLogin(true);

    // Check if the password length is greater than or equal to 5
    if (e.target.value.length >= 5) {
      // Set flag indicating that the password is valid
      setIsPassValid(true);
    } else {
      // Set flag indicating that the password is not valid
      setIsPassValid(false);
    }
  };

  /**
   * Handles the login process using the provided email and password with AuthService.
   * Navigates to the specified path upon successful login.
   * @returns {void}
   */
  const loginHandle = () => {
    // Set flag indicating that password loading is in progress
    setIsPassLoading(true);
    // console.log(`Login Handle Email: `, email)
    // console.log(`Login Handle Pass:`, pass)

    // Call AuthService to perform login
    AuthService.login(email, pass)
      .then((response) => {
        // console.log(`Login Response: `, response);
        // Set login data
        setLoginData(response);

        // Reset flags and navigate to the appropriate path based on the client
        setIsPassLoading(false);

        if (response.roleid === 1) {
        //   console.log(`Admin Login`);
          CommonService.checkAircraftInst()
            .then((res) => {
            //   console.log(`checkAircraftInst Res: `, res);

              if (!res.aircraft && !res.instructor) {
                if (client === undefined) {
                  navigate(`/dashboard/settings`);
                } else {
                  navigate(`/${client}/dashboard/settings`);
                }
              } else {
                checkLogAvailability();
              }
            })
            .catch((error) => {
              console.error(error);
            });
        } else {
          checkLogAvailability();
        }
      })
      .catch((err) => {
        // Handle login failure by resetting relevant flags
        console.error(err);
        setIsPassValid(false);
        setIslLogin(false);
        setIsPassLoading(false);
      });
  };

  const checkLogAvailability = () => {
    CommonService.getAnyLogUploaded()
      .then((res) => {
        if (client === undefined) {
          if (res.data.logspresent) {
            navigate(`/dashboard/flights`);
          } else {
            navigate(`/dashboard/import`);
          }
        } else {
          if (res.data.logspresent) {
            navigate(`/${client}/dashboard/flights`);
          } else {
            navigate(`/${client}/dashboard/import`);
          }
        }
      })
      .catch((error) => {
        console.error(error);
        navigate(`${props.path}`);
      });
  };

  /**
   * Toggles the visibility of the password in the input field between "text" and "password" types.
   * @returns {void}
   */
  const togglePass = () => {
    // Toggle the password type between "text" and "password"
    setPasswordType(passwordType === "password" ? "text" : "password");
  };

  /**
   * Resets the state to go back to the email input step during the login process.
   * @returns {void}
   */
  const backToEmail = () => {
    // Reset relevant flags and states to go back to the email input step
    setIsEmailExist(false);
    setIsEmail(false);
    setIslLogin(true);
    setIsPassLoading(false);
    setIsPassValid(false);
  };

  /**
   * Redirects to the dashboard upon detecting valid authentication tokens.
   * @effect
   */
  useEffect(() => {
    // Check if authentication tokens are present
    if (authTokens) {
      // Redirect to the dashboard based on the client or default path
      if (client !== undefined) {
        navigate(`/${client}/dashboard/`);
      } else {
        navigate(`/dashboard/`);
      }
    }
  }, []);

  return (
    <>
      {isEmailExist && (
        <button
          className="button-back btn-fixed"
          onClick={() => {
            backToEmail();
          }}
        ></button>
      )}
      {!isEmailExist && (
        <div className="content-wrap">
          <p className="hi-there">{t("login.hi_there")}</p>
          <p className="enter-email">{t("login.enter_your_email")}</p>
          <label className="email-label">{t("login.email_id")}</label>
          <form onSubmit={(event) => event.preventDefault()}>
            <input
              type="email"
              className={`d-block ${
                !isEmailFound ? "input-email-notfound" : "input-email"
              }`}
              onChange={emailHandle}
              onCopyCapture={(e) => emailHandle}
              placeholder={t("login.email_placeholder")}
            />
            <p className="error  mb-4">
              {!isEmailFound && <span>{t("login.account_not_found")}</span>}
            </p>
            <div className="btn-wrapper">
              {" "}
              <button
                className={`d-block ${
                  !isEmail ? "input-button" : "input-button-disabled"
                }`}
                onClick={submitEmail}
                disabled={!email}
              >
                <span className={!email ? "opacity-24" : ""}>
                  {t("login.continue")}
                </span>
                <div className={isEmail ? "spinnnerWrap" : "d-none"}>
                  <LoadSpinner />
                </div>
              </button>
            </div>
            {/* <p className='trouble-login'>{t('login.trouble_log')} <NavLink to='reset-password'>{t('login.reset_pass')}</NavLink></p>
                    <p className='dont-have-account'>{t('login.dont_have_account')} <a href='/#'>{t('login.sign_up')}</a></p> */}
          </form>
        </div>
      )}
      {isEmailExist && (
        <div className="content-wrap">
          <p className="hi-there m-0">{t("login.lets_login")}</p>
          <p className="enter-email">{t("login.enter_your_pass")}</p>
          <label className="email-label">{t("login.password")}</label>
          <form onSubmit={(event) => event.preventDefault()}>
            <div className="pass-wrapper">
              <input
                type={passwordType}
                className={`d-block ${
                  !isLogin ? "input-pass-not-found" : "input-pass"
                }`}
                onChange={passHandle}
                onCopyCapture={(e) => passHandle}
                placeholder={t("login.pass_placeholder")}
              />
              {/* <button type="button" className={passwordType === 'password' && isPassValid ? 'btn-pass-toggle' : 'btn-pass-toggle hide-pass'} onClick={togglePass} disabled={!isPassValid}></button> */}
              <button
                type="button"
                className={
                  passwordType === "password" && isPassValid
                    ? "btn-pass-toggle"
                    : "btn-pass-toggle hide-pass"
                }
                onClick={togglePass}
              ></button>
            </div>
            <p className="error mb-4">
              {!isLogin && <span>{t("login.incorrect_password")}</span>}
            </p>
            <div className="btn-wrapper">
              {" "}
              <button
                className={`d-block ${
                  !isPassLoading ? "input-button" : "input-button-disabled"
                }`}
                onClick={loginHandle}
                disabled={!isPassValid}
              >
                {t("login.log_in")}
                <div className={isPassLoading ? "spinnnerWrap" : "d-none"}>
                  <LoadSpinner />
                </div>
              </button>
            </div>
          </form>
          <div className="d-flex justify-content-between">
            <p className="trouble-login">
              <input type="checkbox" /> {t("login.remember_me")}
            </p>
            <p className="trouble-login">
              {t("login.trouble_log")}{" "}
              <NavLink to="reset-password">{t("login.reset_pass")}</NavLink>
            </p>
          </div>
          {/* <p className='dont-have-account'>{t('login.dont_have_account')} <a href='/#'>{t('login.sign_up')}</a></p> */}
        </div>
      )}
    </>
  );
}

export default Logincomp;