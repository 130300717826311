import { Color } from "cesium";


//Color codes for all the phases to plot on the map
export const PhaseColor = {
    "Taxi": [
        Color.fromCssColorString("rgb(200,224,103,0.4)"),
        Color.fromCssColorString("rgb(200,224,103)")
    ],
    "Takeoff": [
        Color.fromCssColorString("rgb(169,73,204,0.4)"),
        Color.fromCssColorString("rgb(169,73,204)")
    ],
    "Landing": [
        Color.fromCssColorString("rgb(245,205,113,0.4)"),
        Color.fromCssColorString("rgb(245,205,113)")
    ],
    "Approach": [
        Color.fromCssColorString("rgb(49,165,245,0.4)"),
        Color.fromCssColorString("rgb(49,165,245)")
    ],
    "Cruise": [
        Color.fromCssColorString("rgb(10,10,245,0.4)"),
        Color.fromCssColorString("rgb(10,10,245)")
    ],
    "Descent": [
        Color.fromCssColorString("rgb(138,6,138,0.4)"),
        Color.fromCssColorString("rgb(138,6,138)")
    ],
    "Climb": [
        Color.fromCssColorString("rgb(7,163,7, 0.4)"),
        Color.fromCssColorString("rgb(7,163,7)")
    ],
    "Undetected": [
        Color.fromCssColorString("rgb(211,211,211, 0.4)"),
        Color.fromCssColorString("rgb(211,211,211)")
    ]
    // "Undetected": [
    //     Color.fromCssColorString("rgb(0,255,255, 0.4)"),
    //     Color.fromCssColorString("rgb(0,255,255)")
    // ]
}

// :["GndSpd"],
// :["Pitch", "HDG"],
// :[""],
// :[],
// "Criuse":["HDG"],
// :["VSpd","GndSpd"],
// :["GndSpd"],
// :["Pitch","VSpd"]
