import "./progressBar.scss";

const ProgressBar=()=>{

    return (
        <>
            <div className="progress-bar-outer">
                <div className="progress-bar-inner">
                </div>
                <div className="progress-bar-inner2">
                </div>
             </div>
        </>
    )
}

export default  ProgressBar;