import "./Debrief.scss";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  createContext,
} from "react";
import StickyTable from "../../../../common/components/StickyTable/StickyTable";
import ColumnSettings from "../../../../common/components/ColumnSettings/ColumnSettings";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import {
  useBlockLayout,
  useTable,
  useSortBy,
  useColumnOrder,
  useFilters,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { useSticky } from "react-table-sticky";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dropdown from "react-bootstrap/Dropdown";
import NoLogFound from "./NoLogFound";
import { getLocalStorage } from "../../../../common/utils/localStorageHandler";
import FilterBox from "./FilterBox";
import PilotService from "../../../../services/pilot.service";
import AircraftService from "../../../../services/aircraft.service";
import { Typeahead } from "react-bootstrap-typeahead";
import DebriefService from "../../../../services/debrief.service";
import DebriefPlayer from "./debriefPlayer/DebriefPlayer";
import {
  GetJulianDateFromTimeStamp,
  PlotWallNPolyline,
  ReadFile,
  csvJSonArray,
} from "../../../../common/utils/cesiumDataHandler";
import { JulianDate } from "cesium";
import LoadSpinnerBig from "../../../../common/components/loadSpinner/loadSpinnerBig";
import {
  changeDateFormat,
  segregateGraphData,
  airportFilterColumnName,
  getDateTimeUtcFromEpochTime,
} from "../../../../common/utils/utils";
import { addTrackEntity } from "../../../../common/utils/cesiumDataHandler";
import CommonService from "../../../../services/common.service";
import {
  InitViewer1,
  InitViewer2,
  LoadTerrain,
} from "../../../../common/utils/cesiumInit";
import LoadSpinner from "../../../../common/components/loadSpinner/loadSpinner";
import NoResultsFound from "./NoResultsFound";
import RecentDebrief from "./RecentDebrief";
import ScoreDetailsModal from "./debriefPlayer/ScoreDetailsModal";
import { error } from "console";

let scoreDataDetails: any, scoreGoalDataDetails: any;

const Debrief = () => {
  type FlightLog = {
    startTimestamp: string;
    endTimestamp: string;
    tailNumber: string;
    pilotName: string;
    secondaryPilotName: string;
    debriefId: number;
    takeoff_runway: string;
    landing_runway: string;
    airTime: string;
    takeoff: string;
    landing: string;
    takeoffRunway: string;
    landingRunway: string;
    distance: number;
    excercise: string;
    score: null | number;
    avgOverallScore: AvgOverallScore;
    status: number;
};

type AvgOverallScore = {
    type: string;
    key: null;
    ref: null;
    props: AvgOverallScoreProps;
    _owner: null;
    _store: {};
};

type AvgOverallScoreProps = {
    className: string;
    style: {
        backgroundColor: string;
    };
    children: AvgOverallScoreChildren;
};

type AvgOverallScoreChildren = {
    type: string;
    key: null;
    ref: null;
    props: {
        className: string;
        children: string;
    };
    _owner: null;
    _store: {};
};

  const [modalShow, setModalShow] = React.useState(false);
  const [SearchField, setSearchField] = useState("undefiend");
  const [DebriefType, setDebriefType] = useState("Aircraft");
  const [DebriefFieldPlaceholder, setDebriefFieldPlaceholder] =
    useState("Enter Reg. No");
  const [UserName, setUserName] = useState("");
  //const [companyId, setCompanyId] = useState(0)
  const [searchType, setSearchType] = useState("Reg. No");
  const [searchFieldPlaceholder, setSearchFieldPlaceholder] = useState(
    "Search by registration number"
  );
  const [filterModalShow, setFilterModalShow] = useState(false);

  const [pilotListValue, setPilotListValue] = useState([]);
  const [aircraftListValue, setAircraftListValue] = useState([]);

  const [filtervalue, setFilterValue] = useState();
  const [type, setType] = useState<number>(0);
  const [date, setDate] = useState<any>();
  const [isOpen, setIsOpen] = useState(false);

  const [isDisabled, setIsDisabled] = useState(true);
  const ref = useRef<any>();
  const [csvFileData, setCsvFileData] = useState(null);
  const [currentCsvFileInfo, setCurrentCsvFileInfo] = useState(null);
  const [currentCsvRowId, SetCurrentCsvRowId] = useState<number>(0);
  const logStatusRef = useRef(null);
  const [viewer, setViewer] = useState<any>();
  const [viewer3D, setViewer3D] = useState<any>();
  const [viewer2D, setViewer2D] = useState<any>();
  // const [data, setData] = useState([]);
  const [data, setData] = useState<FlightLog[]>([]);
  const [unfilteredData, setUnfilteredData] = useState([]);
  const [Range, SetRange] = useState({ min: 0, max: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [logsNotFound, setLogsNotFound] = useState(false);
  const [segregatedData, setSegregatedData] = useState([]);

  const [airPortrunWayList, setAirPortrunWayList] = useState<any>([]);
  const [exerciseList, setExerciseList] = useState<any>([]);
  const [airportAndRunwayFilterParam, setAirportAndRunwayFilterParam] =
    useState({ setFilter: false, filterColumn: 0, airport: "", runway: "" }); //filterColumn 0:for none, 1:for from, 2:for to, 3:for both
  const [
    airportAndRunwayFilterParamCheck,
    setAirportAndRunwayFilterParamCheck,
  ] = useState(false);
  const [runways, setRunways] = useState<string[]>([]);
  const [exerciseFilterParam, setExerciseFilterParam] = useState({
    setFilter: false,
    exercise: "",
  });
  const [exerciseFilterParamCheck, setExerciseFilterParamCheck] =
    useState(false);
  const [listFilter, setListfilter] = useState(false);
  const [analysisData, setAnalysisData] = useState({
    airTime: "",
    noOfTakeOffs: 0,
    timeLineBgString: "",
  });
  const [isFileLoaded, setIsFileLoaded] = useState(false);
  const [loadLogState, setLoadLogState] = useState(1);

  const inputRef = useRef<HTMLInputElement>(null);
  const [isMsg, setIsMsg] = useState(false);

  const [isRecentLoaded, setIsRecentLoaded] = useState(false);

  const [scoreData, setScoreData] = useState<any>();
  const [scoreGoalData, setScoreGoalData] = useState();

  const [exceedanceData, setExceedanceData] = useState<any[]>([]);

  const filterPopoverRef = useRef<any>(null);
  const filterPopoverBodyRef = useRef<any>(null);

  const pillBgColor = (score: any) => {
    if (score <= 5) {
      return `#FF0000`;
    } else if (score > 5 && score < 8.5) {
      return `#eb8a2f`;
    } else {
      return `#39b34a`;
    }
  };

  const processDataForTable = (input: any) => {
    // console.log(`input: `, input);
    let processedData = input.map((item: any) => ({
      ...item,
      pilotName: item.lastName ? (item.pilotName + " " + item.lastName) : (item.pilotName),
      avgOverallScore: (
        <div
          className="badge-pill badge"
          style={{ backgroundColor: pillBgColor(item.avgOverallScore / 10) }}
        >
          <div className="badge-text">
            {(item.avgOverallScore / 10)?.toFixed(1)}
          </div>
        </div>
      ),
    }));
    // console.log(`processedData: `, processedData)
    return processedData;
  };

  /**
   * Updates the search field value for text search filtering.
   * @param {any} value - The new value for the search field.
   * @returns {void}
   */
  const TextSearchFilter = (value: any) => {
    setSearchField(value);
  };

  /**
   * Memoized array containing default settings for columns in sticky table.
   * @type {Array}
   */
  const defaultColumnsSettings: any = React.useMemo(
    () => [
      {
        id: "pilotName",
        Header: "Pilot",
        accessor: "pilotName",
        isFixed: true,
        sticky: "left",
        isDisplayed: true,
      },
      {
        id: "startTimestamp",
        Cell: (data: any) => {
          let dateCh = "";
          if (data?.cell?.value) {
            dateCh = changeDateFormat(data?.cell?.value);
            //  console.log(dateCh)
          }

          return dateCh;
        },
        Header: "Departure",
        accessor: "startTimestamp",
        //sticky: 'left',
        isFixed: false,
        isDisplayed: true,
      },
      {
        id: "endTimestamp",
        Header: "Arrival",
        Cell: (data: any) => {
          let dateCh = "";
          if (data?.cell?.value) {
            // console.log(data)
            dateCh = changeDateFormat(data?.cell?.value);
            // console.log(dateCh)
          }

          return dateCh;
        },
        accessor: "endTimestamp",
        isFixed: false,
        isDisplayed: true,
      },
      {
        id: "takeoff",
        Header: "From",
        accessor: "takeoff_runway",
        isFixed: false,
        isDisplayed: true,
      },
      {
        id: "landing",
        Header: "To",
        accessor: "landing_runway",
        isFixed: false,
        isDisplayed: true,
      },
      {
        id: "tailNumber",
        Header: "Reg. No",
        accessor: "tailNumber",
        isFixed: false,
        isDisplayed: true,
        maxWidth: 1,
        Filter: TextSearchFilter,
      },
      {
        id: "airTime",
        Header: "Flight Duration",
        accessor: "airTime",
        isFixed: false,
        isDisplayed: true,
      },
      {
        id: "excercise",
        Header: "Exercise",
        accessor: "excercise",
        isFixed: false,
        isDisplayed: true,
      },
      {
        id: "avgOverallScore",
        Header: "Score",
        accessor: "avgOverallScore",
        isFixed: false,
        isDisplayed: true,
        sortType: (rowA:any, rowB:any, columnId:any, desc:any) => {
          const a = parseFloat(rowA?.values?.avgOverallScore?.props?.children?.props?.children);
          const b = parseFloat(rowB?.values?.avgOverallScore?.props?.children?.props?.children);
          if (a > b) return 1;
          if (a < b) return -1;
          return 0;
        }
      },
    ],
    []
  );

  /**
   * Handles the application of column settings by updating the order of columns.
   * @param {Array} columnData - The column data containing information about each column.
   * @returns {void}
   */
  const onColumnSettingApply = (columnData: any) => {
    // Extracts the order of columns from the column data
    const orderOfColumns = columnData.map((column: any) => {
      return column.id;
    });

    // Calls the changeOrder function to update the order of columns
    changeOrder(orderOfColumns);

    // Displays a success toast notification
    toast.success("Columns updated successfully", {
      position: "bottom-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
      theme: "light",
    });

    // Closes the modal
    setModalShow(false);
  };

  /**
   * Updates the order of columns based on the provided order array.
   * @param {Array} order - The new order of columns.
   * @returns {void}
   */
  const changeOrder = (order: any[]) => {
    setColumnOrder(order);
  };

  const [searchText, setSearchText] = useState("");

  /**
   * Handles the change event for the search input, updating the search text and applying filters based on the search type.
   * @param {Object} e - The event object.
   * @returns {void}
   */
  const onSearchInputChange = (e: any) => {
    setSearchText(e.target.value);

    // Apply filters based on the selected search type
    if (searchType === "Reg. No") {
      setFilter("tailNumber", e.target.value);
    }
    if (searchType === "Pilot") {
      setFilter("pilotName", e.target.value);
    }
  };

  // To show the 'x' button to clear the search text
  let hasSearchText = searchText.length !== 0;

  /**
   * Clears the search text and filters based on the search type.
   * @returns {void}
   */
  const clearSearchText = () => {
    // Clear the input value using useRef
    if (inputRef.current) {
      inputRef.current.value = "";
    }

    // Clear the filter based on the search type
    if (searchType === "Reg. No") {
      setFilter("tailNumber", "");
    }
    if (searchType === "Pilot") {
      setFilter("pilotName", "");
    }

    // Reset the search text
    setSearchText("");
  };

  // Create a table instance using react-table hooks
  const tableInstance = useTable(
    {
      columns: defaultColumnsSettings,
      data,
      initialState: {
        sortBy: [
          {
            // id: `pilotName`,
            id: `startTimestamp`,
            desc: true,
          },
        ],
      },
    },
    useColumnOrder,
    useFilters,
    useGlobalFilter,
    useSortBy,
    useBlockLayout,
    usePagination,
    useSticky
  );

  // Destructure necessary properties from the table instance
  const { allColumns, setColumnOrder, setFilter } = tableInstance;

  // To get the total number of matching rows based on search to show appropriate component if nothing is found
  const matchingRows = tableInstance.rows.length;

  const showPaginationDiv = matchingRows > 0 ? true : false; //show Pagination Div or not

  /**
   * Changes the debrief type and updates associated state variables.
   * @param {any} debriefType - The selected debrief type.
   * @returns {void}
   */
  const changeDebriefType = (debriefType: any) => {
    // Set the selected debrief type
    setDebriefType(debriefType);

    // Clear the input field using a ref
    ref.current.clear();

    // Disable the input field and set load log state
    setIsDisabled(true);
    setLoadLogState(1);

    // Set the placeholder text based on the debrief type
    if (debriefType === "Pilot") {
      setDebriefFieldPlaceholder("Enter pilot's name");
    } else {
      setDebriefFieldPlaceholder("Enter Reg. No");
    }
  };

  /**
   * Handles the change event for the search type, updating the search type and placeholder text.
   * @param {any} searchTypeText - The selected search type.
   * @returns {void}
   */
  const onSearchTypeChange = (searchTypeText: any) => {
    clearSearchText();
    // Set the selected search type
    setSearchType(searchTypeText);

    // Set the placeholder text based on the selected search type
    if (searchTypeText === "Pilot") {
      setSearchFieldPlaceholder("Search by pilot's name");
    } else if (searchTypeText === "Reg. No") {
      setSearchFieldPlaceholder("Search by registration number");
    } else {
      setSearchFieldPlaceholder("Search by airport");
    }
  };

  // useEffect(() => {
  //   console.log(`pilotListValue: `, pilotListValue)
  // }, [pilotListValue]);

  /**
   * Changes the filter value based on the selected debrief type, updating the filter value, type, and state variables.
   * @param {any} value - The selected value for the filter.
   * @returns {void}
   */
  const changeFilterValue = (value: any) => {
    let filterValue: any;

    // Check the debrief type to determine the filter logic
    if (DebriefType === "Pilot") {
      // Filter the pilot list and map the corresponding pilotId
      filterValue = pilotListValue
        .filter((pilot: any) => pilot.pilotName === value)
        .map((pilot: any) => pilot.pilotId)[0];
      setFilterValue(filterValue);
      setType(1);
    } else {
      // Filter the aircraft list and map the corresponding aircraftId
      filterValue = aircraftListValue
        .filter((u: any) => u.aircraftTailNo === value)
        .map((u: any) => u.aircraftId)[0];
      setFilterValue(filterValue);
      setType(2);
    }

    //Check if all required values are present to enable the button
    if (filterValue && type && date) {
      setIsDisabled(false);
      setLoadLogState(1);
    } else {
      setIsDisabled(true);
      setLoadLogState(1);
    }
  };

  /**
   * Calls the load log functionality, resetting filters, updating state variables, and making an API call to get debrief list.
   * @returns {void}
   */
  const callLoadLog = () => {
    resetFilter();
    setLogsNotFound(false);

    let now_utc: any = new Date(
      date.$d.getUTCFullYear(),
      date.$d.getUTCMonth(),
      date.$d.getUTCDate(),
      date.$d.getUTCHours(),
      date.$d.getUTCMinutes(),
      date.$d.getUTCSeconds()
    );

    let endDate = new Date(
      date.$d.getUTCFullYear(),
      date.$d.getUTCMonth(),
      date.$d.getUTCDate() + 2,
      date.$d.getUTCHours(),
      date.$d.getUTCMinutes(),
      date.$d.getUTCSeconds()
    );

    let userData = getLocalStorage("authTokens", "User");

    let dataBody = {
      company_id: userData.companyid,
      startdate: now_utc.toISOString(),
      enddate: endDate.toISOString(),
      type: type,
      filtervalue: filtervalue,
    };
    console.log(dataBody)
    setLoadLogState(2);

    DebriefService.getDebriefList(dataBody)
      .then((res) => {
        // console.log(`getDebriefList Response: `, res)
        // setData(res);
        // console.log(`Debrief List res: `, res);
        setData(() => processDataForTable(res));
        setUnfilteredData(res);
        setLoadLogState(3);
        setIsDisabled(true);
        setIsRecentLoaded(false);
        if (res.length === 0) setLogsNotFound(true);
      })
      .catch((err) => {
        setLoadLogState(2);
        setIsDisabled(true);
        console.log(err);
      });
  };

  /**
   * Updates the date value and checks if the required fields are set to enable/disable functionality.
   * @param {any} newValue - The new date value.
   * @returns {void}
   */
  const changeDate = (newValue: any) => {
    // console.log(newValue);
    setDate(newValue);

    if (type && newValue) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  /**
   * useEffect hook to fetch user data, pilot list, aircraft list, airport list with runways, and exercise list on component mount.
   * It also sets the initial values for username, pilotListValue, aircraftListValue, airPortrunWayList, and exerciseList states.
   * Uncomment the section for fetching recent debrief list if needed.
   * @returns {void}
   */
  useEffect(() => {
    // Fetch user data from localStorage and set the username state.
    let userData = getLocalStorage("authTokens", "User");
    setUserName(userData.name);

    // Fetch the pilot list and set the pilotListValue state.
    PilotService.getPilotList().then((data) => {
        // console.log(`Debrief Pilot List: `, data.pilotList);

        // Filtering Active and Pending Pilots
      // Status: 1- Active, 0- Pending, 2-Deactivated
      setPilotListValue(() => data.pilotList.filter((item:any) => item.status !== 2).map((item: any) => {
        return {
          pilotId: item.pilotId,
          pilotName: item.lastName ? (item.pilotName + " " + item.lastName) : (item.pilotName),
        };
    })
  );
}).catch((error) => { console.log(`Error getting pilot list: `, error) })

    // Fetch the aircraft list and set the aircraftListValue state.
    AircraftService.getAircraftList()
      .then((data) => {
        setAircraftListValue(data.aircraftList);
      })
      .catch(() => {});

    // Fetch the airport list with runways and set the airPortrunWayList state.
    CommonService.getAirPortListWithRunways()
      .then((data) => {
        setAirPortrunWayList(data.airportList);
      })
      .catch(() => {});

    // Fetch the exercise list and set the exerciseList state.
    CommonService.getExerciseList()
      .then((data) => {
        setExerciseList(data);
      })
      .catch(() => {});

    // Uncomment the section below to fetch the recent debrief list.
    DebriefService.getRecentDebriefList().then((res) => {
      
      // console.log(`getRecentDebriefList: `, res);

        if(res.recentLog.length!==0){
            setIsRecentLoaded(true);
            // setData(res.recentLog)
            setData(() => processDataForTable(res.recentLog))
        }
    }).catch((error)=>{
      console.log('Error fetching recent debrief logs');
      console.error(error);
    })

  }, []);

  const handleClick = (event: any) => {
    if (event.type === "click") {
      const isClickOnFilterButton = filterPopoverRef.current?.contains(event.target as HTMLElement);
      const isClickInsideFilterBody = filterPopoverBodyRef.current?.contains(event.target as HTMLElement) || (typeof event.target.className === 'string' && event.target.className.includes('dropdown-item'));
      if (!isClickOnFilterButton && !isClickInsideFilterBody) {
        setFilterModalShow(false);
      }
    }
}

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  useEffect(() => {
    // console.log(`Debrief Score Data: `, scoreData);
    scoreDataDetails = scoreData;
  }, [scoreData]);

  useEffect(() => {
    scoreGoalDataDetails = scoreGoalData;
  }, [scoreGoalData]);

  function filterObjectsWithMissingKeys(array: any, keys: any) {
    return array.filter((obj: any) => {
      for (let key of keys) {
        if (!(key in obj)) {
          return false; // Object does not contain required key
        }
      }
      return true; // Object contains all required keys
    });
  }
  const requiredKeys = [
    "Latitude",
    "COM1",
    "COM2",
    "CRS",
    "IAS",
    "Longitude",
    "VSpd",
    "HDG",
    "NAV1",
    "NAV2",
    "OAT",
    "Roll",
    "AltGPS",
    "AltMSL",
    "LatAc",
    "Pitch",
    "BaroA",
    "E1 RPM",
    "GndSpd",
    "NormAc",
    "TAS",
    "WndDr",
    "WndSpd",
    "DateTimeUtc",
    "UTCOfst",
  ];

  /**
   *
   * @param rowId - index of the row on which clicked
   * @param data -  array of log files
   */

  const rowClick = async (rowId: any, data: any): Promise<any> => {
    SetCurrentCsvRowId(Number.parseInt(rowId));
    // setCurrentCsvFileInfo(data[rowId]);
    let rowData = data[rowId];
    if (rowData['secondaryPilotLastName'] !== 'N/A') {
      rowData['secondaryPilotName'] = rowData['secondaryPilotName'] + " " + rowData['secondaryPilotLastName']
    }
    setCurrentCsvFileInfo(rowData);
    setIsLoading(true);
    logStatusRef.current = data[rowId].status;

    ///API UPDATES recently played list
    DebriefService.updateRecentlyPlayed(data[rowId].debriefId).then((res) => {
      // console.log("Updated ");
      // console.log(res)
    }).catch((error) => {
      console.log(error)
    });

    DebriefService.getScoreGoalsInfo(data[rowId].debriefId).then((res) => {
      setScoreGoalData(res.GoalsInfo);
    });

    DebriefService.getCsvJsonFromServer(data[rowId].debriefId)
      .then((res) => {

        const csvData = res.data.map((item:any) => {
          return {
              ...item,
              "DateTimeUtc": getDateTimeUtcFromEpochTime(item.EpochTime)
          };
        });

        // console.log(`csvData: `, csvData)

        setIsFileLoaded(true);
        // setCsvFileData(filteredCopyCSV);
        // setCsvFileData(res.data);
        
        setCsvFileData(csvData);

        // console.log("Debrief (ID)")
        // console.log(data[rowId].debriefId)

        DebriefService.getDebriefDetailsInfo(data[rowId].debriefId)
          .then(async (response): Promise<any> => {
            // console.log(`Response Data: `, response) // -----------------------------

            let tempGraphData = response.graphData.map((graphItem:any) => {
              let startIndex = csvData.findIndex((csvItem: any) => csvItem.EpochTime === graphItem.start_timestamp);
              let endIndex = csvData.findIndex((csvItem: any) => csvItem.EpochTime === graphItem.end_timestamp);
              return {
                ...graphItem,
                start: startIndex,
                end: endIndex,
              }
            });

            response.graphData = tempGraphData;
            
            let viewer1;
            let viewer2;

            let tempScoreData = response.graphData.map((item: any) => ({
              labelName: item.labelName,
              data: {
                actual: item.actual,
                overallScore: item.overallScore,
                overallScoreInfo: item.overallScoreInfo,
                sequenceId: item.sequenceId,
                scoreInfo: item.scoreInfo,
              },
            }));

            setScoreData(tempScoreData);

            // Edit the filter criteria to include/exclude exceedances of desired duration (also in Flights.tsx comp)
            let tempExceedanceData = response.graphData.filter((item:any) => item.Exceedance_info_Duration).map((item:any) => ({
              sequenceId: item.sequenceId,
              labelName: item.labelName,
              Exceedance_Timestamp: item.Exceedance_Timestamp,
              Exceedance_info_Duration: item.Exceedance_info_Duration,
            }));

            console.log(`tempExceedanceData: `, tempExceedanceData);
            
            setExceedanceData(tempExceedanceData);

            if (!viewer) {
              let terrainProvider = await LoadTerrain(); //Load terrain for the first time
              viewer1 = InitViewer1(terrainProvider);
              viewer2 = InitViewer2(terrainProvider);
              setViewer(viewer2);
              setViewer3D(viewer1);
            } else {
              //console.log("Viewer is defined")
              viewer1 = viewer3D;
              viewer2 = viewer;
            }

            // ReadFile(filteredCopyCSV, viewer2, PlotWallAndAddFlightModel, response).then(() => {
            ReadFile(res.data, viewer2, PlotWallAndAddFlightModel, response)
              .then(() => {
                //let timeMin = GetJulianDate(csvJSonArray[0]);
                //let timeMax = GetJulianDate(csvJSonArray[csvJSonArray.length - 1] || csvJSonArray[csvJSonArray.length - 11])
                let timeMin = GetJulianDateFromTimeStamp(csvJSonArray[0]);
                let timeMax = GetJulianDateFromTimeStamp(
                  csvJSonArray[csvJSonArray.length - 1] ||
                    csvJSonArray[csvJSonArray.length - 11]
                );
                // console.log(`timeMin: `, timeMin)
                // console.log(`timeMax: `, timeMax)

                let diffSeconds = JulianDate.secondsDifference(
                  timeMax,
                  timeMin
                );
                setNewCsvFileRange(diffSeconds);
                //console.log(csvJSonArray)
              })
              .catch((err) => {
                console.log(err);
                setIsLoading(false);
              });
          })
          .catch((err) => {
            //   console.log("Error in debrief details");
            console.log(err);
          });
      })
      .catch((err) => {
        // console.log("Encountered Error")
        console.log(err);
        setIsLoading(false);
      });
  };

  /**
   *  plots flight and
   *  adds flight model
   *  adds onTick event listner to animate over time
   * @param analysisresponse- response got from debriefDetailsInfo
   */
  const PlotWallAndAddFlightModel = (analysisResponse: any) => {
    // console.log(`analysisResponse PlotWallAndAddFlightModel: `, analysisResponse)

    segregateGraphData(
      csvJSonArray,
      analysisResponse,
      setSegregatedData,
      setAnalysisData
    ).then(async (): Promise<any> => {
      let isStateChanged = false;
      let entityArray: any = [];
      setViewer3D((viewer3D: any) => {
        // setViewer function of useSate is used to get updated value of variable

        if (!isStateChanged) {
          // preventing the function from getting called twice
          let isInnerStateChanged = false;
          setSegregatedData((segregatedData) => {
            //setSegregate function of useSate is used to get updated value of variable

            if (!isInnerStateChanged) {
              // setSegregatedData gets called twice to hanlde that condition is used
              viewer3D?.entities.removeAll();
              // console.log(`segregatedData: `, segregatedData);
              if (segregatedData.length !== 0) {
                // console.log(`Inside IF Statement`);
                segregatedData.map((e: any) => {
                  // if (e.subPhaseMapping.length === 0) {
                  if (e.partitionIndex === null) {
                    let cordArray: any = []; // add  Longitude, Latitude,Altitude,Longitude...  to cordArray for each segment
                    e.data.forEach(function (item: any, index: any, arr: any) {
                      cordArray.push(+item.Longitude); //Adding coordinates to cordArray
                      cordArray.push(+item.Latitude);
                      cordArray.push(+item.AltGPS);
                    });
                    let segWall = PlotWallNPolyline(
                      viewer3D,
                      cordArray,
                      e.label
                    );
                    // console.log(`IF segWall: `, segWall);
                    // entityArr.push(segWall[0]);
                    entityArray.push(segWall[0]);
                    e.cesiumEntity3D = segWall; //assign array containing polyline and wall to cesiumEntity of each segment which is used to show and hide path
                  }
                });
              } else {
                // console.log(`Inside ELSE Statement`);
                let cordArray: any = [];
                csvJSonArray.forEach(function (
                  item: any,
                  index: any,
                  arr: any
                ) {
                  cordArray.push(+item.Longitude); //Adding coordinates to cordArray
                  cordArray.push(+item.Latitude);
                  cordArray.push(+item.AltGPS);
                });
                let segWall = PlotWallNPolyline(
                  viewer3D,
                  cordArray,
                  "completeCSV"
                );
                // entityArr.push(segWall[0]);
                // console.log(`ELSE segWall: `, segWall);
                entityArray.push(segWall[0]);
                // e.cesiumEntity3D = segWall;//assign array containing polyline and wall to cesiumEntity of each segment which is used to show and hide path
              }

              // console.log(`Entity Array: `, entityArray);
              //viewer3D.flyTo(entityArray);
              addTrackEntity(viewer3D).then((entity: any) => {
                // Adds flight entity, sets tracks the entity, adds the ontick event listner
                // console.log(`entity: `, entity);
                setViewer2D(entity);
                setIsLoading(false);
              });
              isInnerStateChanged = true;
            }
            return segregatedData;
          });
          isStateChanged = true;
        }

        return viewer3D;
      });
    });
  };

  /**
   * Setter function to update the csvFileData state with new data.
   * @param {any} data - New data for csvFileData state.
   * @returns {void}
   */
  const setNewCsvFileData = (data: any) => {
    setCsvFileData(data);
  };

  /**
   * Setter function to update the currentCsvRowId state with a new row ID.
   * @param {number} rowId - New row ID for currentCsvRowId state.
   * @returns {void}
   */
  const setNewCurrentCsvRowId = (rowId: number) => {
    SetCurrentCsvRowId(rowId);
  };

  /**
   * Setter function to update the currentCsvFileInfo state with new file data.
   * @param {any} fileData - New file data for currentCsvFileInfo state.
   * @returns {void}
   */
  const SetNewCurrentCsvFileInfo = (fileData: any) => {
    // setCurrentCsvFileInfo(fileData);
    if (fileData['secondaryPilotLastName'] !== 'N/A') {
      fileData['secondaryPilotName'] = fileData['secondaryPilotName'] + " " + fileData['secondaryPilotLastName']
    }
    setCurrentCsvFileInfo(fileData);
  };

  /**
   * Setter function to update the csvFileRange state with a new range.
   * @param {any} diffSeconds - New difference in seconds for the csvFileRange state.
   * @returns {void}
   */
  const setNewCsvFileRange = (diffSeconds: any) => {
    SetRange({ min: 0, max: diffSeconds });
  };

  /**
   * Applies filters to the data based on selected filter criteria.
   * Updates the data state and displays a message.
   * @returns {void}
   */
  const applyFilter = () => {
    let filterData = unfilteredData;
    // setData(unfilteredData);
    setData(() => processDataForTable(unfilteredData));
    setListfilter(true);
    setFilterModalShow(!filterModalShow);

    filterData = applyAirportRunwayFilter(filterData);
    filterData = applyExerciseFilter(filterData);

    setTimeout(() => {
      setIsMsg(false);
    }, 2000);
  };

  /**
   * Resets the filters and updates the data state accordingly.
   * @returns {void}
   */
  const resetFilter = () => {
    // setData(unfilteredData);
    setData(() => processDataForTable(unfilteredData));
    setAirportAndRunwayFilterParamCheck(false);
    setAirportAndRunwayFilterParam({
      ...airportAndRunwayFilterParam,
      setFilter: false,
    });
    setExerciseFilterParamCheck(false);
    setExerciseFilterParam({ ...exerciseFilterParam, setFilter: false });
    setListfilter(false);
  };

  /**
   * Applies airport and runway filters to the provided data and updates the state accordingly.
   * @param {Array} filterData - The data to be filtered.
   * @returns {Array} - The filtered data.
   */
  const applyAirportRunwayFilter = (filterData: any) => {
    let airport = airportAndRunwayFilterParam["airport"];
    let runway = parseInt(airportAndRunwayFilterParam["runway"].substring(3));
    if (
      airportAndRunwayFilterParamCheck &&
      airportAndRunwayFilterParam["filterColumn"] !== 0 &&
      airport
    ) {
      setAirportAndRunwayFilterParam({
        ...airportAndRunwayFilterParam,
        setFilter: true,
      });
      if (airportAndRunwayFilterParam["filterColumn"] === 1) {
        if (runway) {
          filterData = filterData.filter(
            (a: any) => a.takeoff == airport && a.takeoffRunway == runway
          );
        } else {
          filterData = filterData.filter((a: any) => a.takeoff == airport);
        }
      } else if (airportAndRunwayFilterParam["filterColumn"] === 2) {
        if (runway) {
          filterData = filterData.filter(
            (a: any) => a.landing == airport && a.landingRunway == runway
          );
        } else {
          filterData = filterData.filter((a: any) => a.landing == airport);
        }
      } else if (airportAndRunwayFilterParam["filterColumn"] === 3) {
        if (runway) {
          filterData = filterData.filter(
            (a: any) =>
              a.takeoff == airport &&
              a.takeoffRunway == runway &&
              a.landing == airport &&
              a.landingRunway == runway
          );
        } else {
          filterData = filterData.filter(
            (a: any) => a.takeoff == airport && a.landing == airport
          );
        }
      }
      // setData(filterData)
      setData(() => processDataForTable(filterData));
      setIsMsg(true);
      return filterData;
    } else {
      setAirportAndRunwayFilterParam({
        ...airportAndRunwayFilterParam,
        setFilter: false,
      });
      return filterData;
    }
  };

  /**
   * Resets the airport and runway filters, updating the state and data accordingly.
   */
  const resetAirportRunwayFilter = () => {
    setAirportAndRunwayFilterParamCheck(false);
    setAirportAndRunwayFilterParam({
      ...airportAndRunwayFilterParam,
      setFilter: false,
    });

    let filterData = unfilteredData;
    // setData(unfilteredData);
    setData(() => processDataForTable(unfilteredData));
    filterData = applyExerciseFilter(filterData);
  };

  /**
   * Applies the exercise filter to the provided data and updates the state and data accordingly.
   *
   * @param {Array} filterData - The data to be filtered.
   * @returns {Array} - The filtered data.
   */
  const applyExerciseFilter = (filterData: any) => {
    if (exerciseFilterParamCheck && exerciseFilterParam["exercise"]) {
      setExerciseFilterParam({ ...exerciseFilterParam, setFilter: true });
      filterData = filterData.filter(
        (a: any) => a.excercise == exerciseFilterParam["exercise"]
      );
      // setData(filterData)
      setData(() => processDataForTable(filterData));
      setIsMsg(true);
      return filterData;
    } else {
      setExerciseFilterParamCheck(false);
      setExerciseFilterParam({ ...exerciseFilterParam, setFilter: false });
    }
  };

  /**
   * Resets the exercise filter, updating the state and data accordingly.
   */
  const resetExerciseFilter = () => {
    setExerciseFilterParamCheck(false);
    setExerciseFilterParam({ ...exerciseFilterParam, setFilter: false });
    let filterData = unfilteredData;
    // setData(unfilteredData)
    setData(() => processDataForTable(unfilteredData));
    filterData = applyAirportRunwayFilter(filterData);
  };

  /**
   * Popover element for the filter functionality.
   */
  const filterPopoverElement = (
    <Popover>
      <div ref={filterPopoverBodyRef}>
        <FilterBox
          resetFilter={resetFilter}
          airPortNRunwayList={airPortrunWayList}
          airportAndRunwayFilterParam={airportAndRunwayFilterParam}
          airportAndRunwayFilterParamCheck={airportAndRunwayFilterParamCheck}
          setAirportAndRunwayFilterParam={setAirportAndRunwayFilterParam}
          setAirportAndRunwayFilterParamCheck={
            setAirportAndRunwayFilterParamCheck
          }
          exerciseList={exerciseList}
          setExerciseFilterParamCheck={setExerciseFilterParamCheck}
          exerciseFilterParamCheck={exerciseFilterParamCheck}
          setExerciseFilterParam={setExerciseFilterParam}
          exerciseFilterParam={exerciseFilterParam}
          runways={runways}
          setRunways={setRunways}
          applyFilter={applyFilter}
        ></FilterBox>
      </div>
    </Popover>
  );

  /**
   * Clears file data, 2D viewer, and resets the state of 3D viewer and clocks.
   * @returns {void}
   */
  const deleteFileData = () => {
    try {
      // Clear CSV file data state
      setCsvFileData(null);

      // Clear 2D viewer state
      setViewer2D(null);

      //Clear current csv file info
      setCurrentCsvFileInfo(null);

      // Remove all entities from the 3D viewer
      viewer3D?.entities?.removeAll();

      setSearchText("");

      logStatusRef.current = null;

      // Clear the filter based on the search type
      if (searchType === "Reg. No") {
        setFilter("tailNumber", "");
      }
      if (searchType === "Pilot") {
        setFilter("pilotName", "");
      }

      // Disable animation for the 2D and 3D viewer clocks
      viewer.clock.canAnimate = false;
      viewer.clock.shouldAnimate = false;
      viewer3D.clock.canAnimate = false;
      viewer3D.clock.shouldAnimate = false;

      setScoreData(undefined);
    } catch (error: any) {
      console.log(`Error encountered while clearing file data: `, error);
    }
  };

  /**
   * Renders an element based on the current state of log loading.
   * @returns {JSX.Element|null} - The rendered element.
   */
  //Element to be rendered based on loadLogstate
  const renderStateElement = () => {
    switch (loadLogState) {
      case 1: // Initial state
        return <span></span>;
      case 2: // If API is called and waiting for a response
        return (
          <span>
            <LoadSpinner />
          </span>
        );
      case 3: // After getting a success response from the log list API
        return <span className="load-logs">(L)</span>;
      default:
        return null;
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {isLoading && (
        <div className="spinnerWrap">
          {" "}
          <LoadSpinnerBig />
        </div>
      )}
      {isMsg && (
        <div className="toast-msg">
          <span className="title">Success : </span>
          <span className="msg"> Filter applied successfully!</span>
        </div>
      )}
      {!csvFileData && (
        <div className="log-parent col-16 col-md-16 d-flex flex-column h-100">
          {/* <div className='deb-class'>Debrief</div> */}
          <section className="debrief-top-section">
            <div className="deb-class">
              <h2>Debrief</h2>
            </div>
            {/* <div className='deb-class'>Debrief</div> */}
            {/* <div className='content-wrapper col-lg-10' > */}
            <div
              className={
                data.length !== 0
                  ? "content-wrapper-loaded col-lg-10"
                  : "content-wrapper col-lg-10"
              }
            >
              <div className="userWrap">
                <div className="wel-class">
                  {" "}
                  <span className="welcome"> Welcome,</span>{" "}
                  <span>{UserName} </span>
                </div>
                <div className="select-pilot-text">
                  Select a pilot or an aircraft for the desired date to begin
                  the debriefing.
                </div>
              </div>
              <div className="inputbox-wrapper row ">
                <div className="pilot-wrapper col-md-16 col-xl-6 mt-3">
                  {/* <span className='row pilot-date'>{DebriefType}</span> */}
                  <span
                    className="row pilot-date"
                    style={{ marginLeft: "16px" }}
                  >
                    {DebriefType}
                  </span>
                  <div className="input-select-wrapper">
                    {/* <input type="text" className="text-field text-space" placeholder={DebriefFieldPlaceholder} />  */}

                    <Typeahead
                      onChange={(value) => {
                        // console.log("Changed ")
                        // changeFilterValue(value)
                        changeFilterValue(value[0]);
                      }}
                      id="filterTypehead"
                      ref={ref}
                      options={
                        DebriefType === "Pilot"
                          ? pilotListValue.map((p: any) => `${p.pilotName}`)
                          : aircraftListValue.map(
                              (a: any) => `${a.aircraftTailNo}`
                            )
                      }
                      filterBy={(option, props) => {
                        const inputValue = props.text.toLowerCase();
                        const optionValue = option.toLowerCase();
                        return optionValue.startsWith(inputValue);
                      }}
                      minLength={3} //ensures that filtering begins after typing three characters
                      className="text-field text-space"
                      placeholder={DebriefFieldPlaceholder}

                      //defaultSelected={[" "]}
                      // selected={["sd"]}
                    />

                    <div className="select-box-breiftype mb-me-1">
                      <Dropdown className="dropdown-box">
                        <Dropdown.Toggle
                          variant="none"
                          id="dropdown-basic"
                          className="dropdown-toggle"
                        >
                          <span className="debrieftype-text">
                            {DebriefType}{" "}
                          </span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="dropdown-menu-box shadow-sm">
                          <Dropdown.Item
                            href=""
                            onClick={() => {
                              changeDebriefType("Pilot");
                            }}
                            className={` ${
                              DebriefType === "Pilot"
                                ? "debrief-selected-item"
                                : "debrief-item"
                            }`}
                          >
                            Pilot
                          </Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item
                            href=""
                            onClick={() => {
                              changeDebriefType("Aircraft");
                            }}
                            className={`${
                              DebriefType === "Aircraft"
                                ? "debrief-selected-item"
                                : "debrief-item"
                            }`}
                          >
                            Aircraft
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
                <div className="pilot-wrapper col-md-16 col-xl-6 mt-3">
                  {/* <span className='row pilot-date'>Date</span> */}
                  <span
                    className="row pilot-date"
                    style={{ marginLeft: "16px" }}
                  >
                    Date
                  </span>
                  {/* <input type="date" className="text-field datepicker" data-date-format="dd/MM/yyyy" /> */}
                  <DatePicker
                    className="text-field"
                    localeText={{
                      fieldMonthPlaceholder: () => "MMM",
                    }}
                    format="D MMM YYYY"
                    value={date || Date.now}
                    onChange={(newValue) => changeDate(newValue)}
                    open={isOpen}
                    onClose={() => setIsOpen(false)}
                    slotProps={{
                      textField: {
                        onClick: () => setIsOpen(true),
                      },
                    }}
                    views={["year", "month", "day"]}
                  />
                </div>
                <div className="btn-wrapperr col-md-16 col-xl-3">
                  <button
                    onClick={callLoadLog}
                    disabled={isDisabled}
                    className="f360-primary-btn-lg load-btn"
                  >
                    <span className="load-logs">Access Logs</span>
                    {renderStateElement()}
                  </button>
                </div>
              </div>
            </div>
          </section>
          {isRecentLoaded && (
            <div className="bottom-section">
              <RecentDebrief
                tableInstance={tableInstance}
                // searchField={SearchField}
                rowClick={rowClick}
              />{" "}
            </div>
          )}
          {logsNotFound && <NoLogFound />}

          {(data[0] || listFilter) && !isRecentLoaded && (
            <section
              className={
                filterModalShow ? "bottom-section min-height" : "bottom-section"
              }
            >
              <div className="filter-wrap">
                <div className="d-none d-md-block"></div>
                <div
                  className="search-filter"
                  style={{ maxWidth: "520px", minWidth: "300px" }}
                >
                  <div className="input-select-wrapper ">
                    <input
                      ref={inputRef}
                      type="text"
                      className="text-field text-space debrief-search-icon shadow-none"
                      placeholder={searchFieldPlaceholder}
                      onChange={onSearchInputChange}
                    />

                    {hasSearchText && (
                      <span
                        className="clear-icon"
                        onClick={clearSearchText}
                      ></span>
                    )}

                    {/* <i className="search-icon"></i> */}
                    <div className="select-wrapper">
                      <div className="select-box-breiftype-1">
                        <Dropdown className="dropdown-box ">
                          <Dropdown.Toggle
                            variant="none"
                            id="dropdown-basic"
                            className="dropdown-toggle"
                          >
                            <span className="debrieftype-text">
                              {searchType}
                            </span>
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="dropdown-menu-box shadow">
                            <Dropdown.Item
                              href=""
                              onClick={() => {
                                onSearchTypeChange("Reg. No");
                              }}
                              className={`${
                                searchType === "Reg. No"
                                  ? "debrief-selected-item"
                                  : "debrief-item"
                              }`}
                            >
                              Reg. No
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            {/* <Dropdown.Item href="" onClick={()=> {onSearchTypeChange("Airport")}} className={`${searchType==="Airport" ? "debrief-selected-item" : "debrief-item"}`} >Airport</Dropdown.Item>
                              <Dropdown.Divider /> */}
                            <Dropdown.Item
                              href=""
                              onClick={() => {
                                onSearchTypeChange("Pilot");
                              }}
                              className={`${
                                searchType === "Pilot"
                                  ? "debrief-selected-item"
                                  : "debrief-item"
                              }`}
                            >
                              Pilot
                            </Dropdown.Item>
                            {/* <Dropdown.Divider />
                              <Dropdown.Item href=""   onClick={()=>{changeDebriefType("Aircraft")}} className={`${DebriefType==="Aircraft" ? "debrief-selected-item":"debrief-item"}`} >Aircraft</Dropdown.Item> */}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-filter-wrap">
                  <Button
                    variant="secondary"
                    className="f360-btn-with-icon-light me-3 column-btn"
                    onClick={() => setModalShow(true)}
                  >
                    <i className="btn-icon icon-column"></i>
                    Column
                  </Button>
                  <OverlayTrigger
                    trigger="click"
                    placement="bottom"
                    show={filterModalShow}
                    overlay={filterPopoverElement}
                  >
                    <Button
                      className="f360-btn-with-icon filter-btn"
                      ref={filterPopoverRef}
                      onClick={() => setFilterModalShow(!filterModalShow)}
                    >
                      <i className="btn-icon icon-filter"></i>
                      {listFilter &&
                        (airportAndRunwayFilterParam["setFilter"] ||
                          exerciseFilterParam["setFilter"]) && (
                          <span className="red-circle"></span>
                        )}
                      Filter
                    </Button>
                  </OverlayTrigger>
                </div>
              </div>

              <div className="filter-label-wrapper">
                {listFilter && airportAndRunwayFilterParam["setFilter"] && (
                  <span className="filter-label">
                    <span className="filter-name">Airport & Runway : </span>
                    <span className="filter-content">
                      {/* {`${airportFilterColumnName(airportAndRunwayFilterParam)}, ${airportAndRunwayFilterParam["airport"]} (${airportAndRunwayFilterParam["runway"]})`} */}
                      {`${airportFilterColumnName(
                        airportAndRunwayFilterParam
                      )}, ${airportAndRunwayFilterParam["airport"]} (${
                        airportAndRunwayFilterParam["runway"]
                      })`}
                    </span>
                    <button
                      className="btn-cross"
                      onClick={resetAirportRunwayFilter}
                    ></button>
                  </span>
                )}

                {listFilter && exerciseFilterParam["setFilter"] && (
                  <span className="filter-label">
                    <span className="filter-name">Exercise : </span>
                    <span className="filter-content">
                      {`${exerciseFilterParam["exercise"]}`}
                    </span>
                    <button
                      className="btn-cross"
                      onClick={resetExerciseFilter}
                    ></button>
                  </span>
                )}
              </div>
              <div className="table-wrapper">
                <StickyTable
                  tableInstance={tableInstance}
                  searchField={SearchField}
                  rowClick={rowClick}
                  showPaginationDiv={showPaginationDiv}
                ></StickyTable>
                {matchingRows === 0 && <NoResultsFound />}
              </div>
              <ColumnSettings
                show={modalShow}
                allColumns={allColumns}
                columnsettingapply={(columnData: any) =>
                  onColumnSettingApply(columnData)
                }
                onHide={() => setModalShow(false)}
              ></ColumnSettings>
            </section>
          )}
        </div>
      )}

      <div
        className={
          csvFileData
            ? "position-relative h-100 w-100"
            : "d-none position-relative"
        }
      >
        {isFileLoaded && (
          <DebriefPlayer
            closePlayer={deleteFileData}
            setNewCsvFileData={setNewCsvFileData}
            currentCsvFileInfo={currentCsvFileInfo || {}}
            status={logStatusRef.current}
            SetNewCurrentCsvFileInfo={SetNewCurrentCsvFileInfo}
            currentCsvRowId={currentCsvRowId}
            setNewCurrentCsvRowId={setNewCurrentCsvRowId}
            debriefFileList={data}
            currentCsvFileData={csvFileData}
            setViewer={setViewer}
            viewer={viewer}
            setNewCsvFileRange={setNewCsvFileRange}
            range={Range}
            //currTimeValue={currTimeValue}
            //SetNewCurrTimeValue={SetNewCurrTimeValue}
            setSegregatedData={setSegregatedData}
            segregatedData={segregatedData}
            viewer3D={viewer3D}
            setViewer3D={setViewer3D}
            viewer2D={viewer2D}
            setViewer2D={setViewer2D}
            setAnalysisData={setAnalysisData}
            analysisData={analysisData}
            callFrom={`Debrief`}
            exceedanceData={exceedanceData}
            isFileLoading={isLoading}
          />
        )}
      </div>
    </LocalizationProvider>

    // <NoLogFound/>
  );
};

export function getScoreDataDebrief() {
  return scoreDataDetails;
}

export function getScoreGoalDataDebrief() {
  return scoreGoalDataDetails;
}

export default React.memo(Debrief);
