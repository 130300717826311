import "./loadSpinner.scss";

const LoadSpinner=()=>{
    return (
        <div className="spinner-container">
          <div className="loading-spinner">
          </div>
        </div>
      );
}


export default  LoadSpinner;