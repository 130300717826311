// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ----------- Asset paths --------------- */
/* ----------- Primary colors --------------- */
/* ----------- Font Variables --------------- */
/*---------- Font variables for Numbers ----------*/`, "",{"version":3,"sources":["webpack://./src/assets/styles/_variables.scss"],"names":[],"mappings":"AAAA,4CAAA;AAIA,+CAAA;AA2BA,+CAAA;AASA,mDAAA","sourcesContent":["/* ----------- Asset paths --------------- */\n$imageAssetsPath: \"../images\";\n$debriefAssetsPath: \"../images/debrief\";\n\n/* ----------- Primary colors --------------- */\n$black: #000000;\n$white: #ffffff;\n$green: #00b140;\n$blue: rgba(66, 113, 235, 1);\n$pastel-blue: rgba(66, 113, 235, 0.6);\n$light-grey-border: #eaeaea;\n$button-background-color : rgba(66, 113, 235, 0.12);\n\n// Company Based App Colors\n$primary-color: var(--primary-color, $blue);\n$disabled-primary-color: var(--disabled-primary-color, $pastel-blue);\n$disabled-secondary-color: var(--disabled-secondary-color, $blue);\n$gradient-color: var(--gradient-color, $blue);\n$secondary-color: var(--secondary-color, $blue);\n$company-logo: var(--company-logo, url(#{$imageAssetsPath}/aerx-logo.svg));\n$light-background-color: var(--light-background-color, rgba(0, 177, 64, 0.06));\n//$button-background-color: var(--button-background-color, rgba(0, 177, 64, 0.12));\n\n// Font Colors\n$font-color-black: #121212;\n$font-color-grey: #222222;\n$font-color-light-grey: #666666;\n\n// Table styles\n$table-even-bg-color: #f5f5f5;\n\n/* ----------- Font Variables --------------- */\n$ibmPlexSansFont: 'IBM Plex Sans';\n$fontWeightRegular: 400;\n$fontWeightMedium: 500;\n$fontWeightSemiBold: 600;\n$fontWeightBold: 700;\n\n\n\n/*---------- Font variables for Numbers ----------*/\n$ibmPlexMonoFont: 'IBM Plex Mono', monospace;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
