import './Header.scss'
import aerxLogo from '../../../assets/images/aerx-logo.svg'
import orgLogo from '../../../assets/images/common/icon-organisation.svg'
import { getLocalStorage } from '../../utils/localStorageHandler'


// ALL COMMENTED CODE IN THIS COMPONENT IS TEMPORARY

const Header = (props:any) => {

    let authToken = getLocalStorage("authTokens", "");
    // console.log(`Header Auth Token: `, authToken);

    const { sideBarState, setSidebarState } = props;
    
    const handleSidebarSize = () => {
        if (sideBarState === `collapsed`) {
            setSidebarState(`expanded`);
        } else {
            setSidebarState(`collapsed`);
        }
    }


    return(
        <div className="header-container d-flex justify-content-between">
            <div className='d-flex align-items-center'>
                <button className='btn-collapse' onClick={handleSidebarSize}></button>
                {/* <button className='btn-collapse d-none'></button> */}
                {/* Temp Change: Below Div (mx-1) --> Only To Replace The Above Hidden Button */}
                <div className='mx-1'></div>
                <img src={aerxLogo} alt='aerx-logo' className='img-header-logo'/>
            </div>
            {/* <input type='text' className='input-search col-md-5' placeholder='Search'/> */}
            <div className='flight360-text'>Flight360</div>
            {/* <div className='d-flex col-lg-3 justify-content-around'> */}
            <div className='d-flex col-lg-2 justify-content-center'>
                {/* <div className='d-flex justify-content-between col-lg-12 align-items-center dropdown-user'> */}
                <div className='d-flex align-items-center'>
                        <img className='img-orgLogo' src={orgLogo} alt='org logo'/>
                        <div className='org-label-wrap d-none d-md-block'>
                            <p className='org-label d-flext align-items-center mt-2'>
                                Organisation
                            </p>
                            <p className='org-name'>
                                {authToken?.companyName}
                                {/* Maximus Aviators */}
                            </p>
                        </div>
                        {/* Temporarily Commenting the below 2 buttons */}
                    {/* <div className='dropdown-icon d-none d-md-block'> */}
                    {/* </div> */}
                </div>
            {/* <button className='btn-notification'></button> */}
            </div>
        </div>
    )
}

export default Header;