import { Checkbox, Container } from '@mui/material';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './ColumnSettings.scss'
import React, { useEffect, useState } from "react";

function ColumnSettings(props: any) {
  const { columnsettingapply, allColumns, ...others } = props;
  const [AllColumns, setAllColumns] = useState([...allColumns]);
  const [DefaultColumns, setDefaultColumns] = useState([...allColumns]);
  const [PreviousColumnOrder, setPreviousColumnOrder] = useState([...allColumns]);
  const [disableApplyBtn, setDisableApplyBtn] = useState(true);
  const [disableResetBtn, setDisablResetBtn] = useState(true);

  // console.log(`all columns: `, allColumns)

  /**
 * Handles the change event for a checkbox associated with a column in a table.
 * @param {any} column - Represents the metadata or configuration of the column.
 * @param {any} event - The change event triggered when the checkbox state changes.
 * @param {number} index - The index of the column in the array of all columns.
 * @returns {void}
 */

  const onCheckBoxChange = (column: any, event: any, index: number): void => {
    setDisableApplyBtn(false);
    setDisablResetBtn(false);

    const columnsData = [...AllColumns];

    columnsData[index].isDisplayed = event.target.checked;

    setAllColumns(columnsData);
  }



  /**
 * Handles the click event for the Apply button in the column settings.
 * @returns {void}
 *
 * Iterates through all columns, toggles the 'isVisible' property for columns that are not fixed and not visible.
 * Updates the 'isDisplayed' property for columns that are not fixed and not displayed.
 * Sets the previous column order and triggers the 'columnsettingapply' function.
 */
  const onApplyClick = (): void => {
    // Iterate through all columns, toggling 'isVisible' for columns that are not fixed and not visible.
    allColumns.forEach((col: any) => {
      if (!col.isVisible && !col.isFixed) {
        col.toggleHidden();
      }
    });

    allColumns.forEach((e: any) => {
      if (!e.isDisplayed && !e.isFixed) {
        e.toggleHidden();
      }
    });
    setPreviousColumnOrder(AllColumns);
    columnsettingapply(AllColumns);
  }


  // Resets the column data back to original state
  const onResetClick = () => {
    AllColumns.map((col: any) => {
      if (!col.isDisplayed && !col.isFixed) {
        col.isDisplayed = true
      }
    })
    const defaultColumns = [...DefaultColumns];
    setAllColumns([...defaultColumns]);
    setDisablResetBtn(true)
    setDisableApplyBtn(false)
  }

  // Closes the Customise columns dialogue box
  const onCloseClick = () => {
    setAllColumns([...PreviousColumnOrder]);
    props.onHide();
  }


  /**
 * Handles the sorting of columns based on drag and drop interactions.
 * @returns {void}
 *
 * Creates a copy of the 'AllColumns' array and reorders it based on the drag and drop positions.
 * Updates state variables including 'PreviousColumnOrder', 'AllColumns', 'disableApplyBtn', and 'disableResetBtn'.
 */
  const handleSort = (): void => {
    // Create a copy of the 'AllColumns' array.
    let items = [...AllColumns];

    // Remove the dragged item from its original position.
    const draggedItemContent = items.splice(dragItem.current, 1)[0];

    // Insert the dragged item at the new position.
    items.splice(dragOverItem.current, 0, draggedItemContent);

    // Reset the drag and drop references.
    dragItem.current = null;
    dragOverItem.current = null;

    // Set the previous column order based on the current state of 'AllColumns'.
    setPreviousColumnOrder(AllColumns);

    // Update the state with the reordered array of columns.
    setAllColumns(items);

    // Enable Apply and Reset buttons.
    setDisableApplyBtn(false);
    setDisablResetBtn(false);
  }


  // Refs to keep track of the dragged item and the item over which it is dragged during drag and drop interactions.
  const dragItem = React.useRef<any>(null);
  const dragOverItem = React.useRef<any>(null);


  // React useEffect hook to update the 'disableApplyBtn' state based on changes in the 'show' prop.
  useEffect(() => {
    setDisableApplyBtn(props.show);
  }, [props.show]);

  useEffect(() => {

  }, [AllColumns]);

  return (
    <>
      <Modal
        {...others}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className='m-header'>
          <Modal.Title className="m-title">Column Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body className="grid-example">
          <Container>
            <section className="body-wrap">
              <div className='left-Colsection'>
                <h6 className="sec-head">Fixed Columns</h6>
                <div className='checkbox-wrap'>
                  {AllColumns.map((column: any, index: number) => {
                    if (column.isFixed === true) {
                      return <div key={"1" + column.id} className='checkbox-row'>
                        <div key={"div-left-wrap1" + column.id} className="left-wrap">
                          <input key={"input1" + column.id} disabled className='checkbox' type="checkbox" checked={true} />
                          <label key={"label1" + column.id} className='checkbox-label' htmlFor=""> {column.Header}</label>
                        </div>
                      </div>
                    }
                    return <div key={"empty-div" + column.id}></div>
                  })}

                </div>
              </div>
              <div className='right-section'>
                <h6 className="sec-head">Selected Columns</h6>
                {AllColumns.map((column: any, index: number) => {
                  if (column.isFixed === false) {
                    return <div key={column.id} className='checkbox-row' draggable
                      onDragStart={(e) => dragItem.current = index}
                      onDragEnter={(e) => dragOverItem.current = index}
                      onDragEnd={(e) => handleSort()}>
                      <div key={"div-left-wrap" + column.id} className="left-wrap">
                        <input key={"input" + column.id} className='checkbox' id={column.id} type="checkbox" checked={column.isDisplayed} onChange={(event) => onCheckBoxChange(column, event, index)}
                        />
                        <label key={"label" + column.id} className='checkbox-label' htmlFor={column.id}> {column.Header}</label>
                      </div>
                      <div key={"div-right-wrap" + column.id} className="right-wrap">
                        <span key={"drag-icon" + column.id} className="icon drag-icon"></span>
                      </div>
                    </div>
                  }
                  return <div key={"empty-div1" + column.id}></div>
                })}
              </div>
            </section>
          </Container>
        </Modal.Body>
        <Modal.Footer className="footer">
          <div>
            <Button variant="secondary" onClick={() => onCloseClick()} className="f360-btn-light">Close</Button>
          </div>
          <div className='right-buttons'>
            <Button variant="secondary reset-button" className='me-3 f360-btn-light'
              onClick={() => onResetClick()} disabled={disableResetBtn}>
              Reset
            </Button>
            <Button variant="primary" className='f360-btn'
              onClick={() => onApplyClick()} disabled={disableApplyBtn}>Apply</Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ColumnSettings;