import { useMemo, useState, useEffect, useRef } from "react";
import { useSortBy, useTable, useFlexLayout, TableInstance } from "react-table";
import { getScoreDataDebrief, getScoreGoalDataDebrief } from "../Debrief";
import {
  getScoreDataFlights,
  getScoreGoalDataFlights,
} from "../../flights/Flights";

//Table Instances: tableInstanceApproach, tableInstanceLanding, tableInstanceTaxi, tableInstanceTakeoff,
//                 tableInstanceClimb, tableInstanceCruise, tableInstanceDescent

const ScoreTableInstances = () => {
  let scoreData: any = getScoreDataDebrief();

  if (!scoreData) {
    scoreData = getScoreDataFlights();
  }

  console.log(`STI scoreData: `, scoreData);

  let scoreGoalData: any = getScoreGoalDataDebrief();

  if (!scoreGoalData) {
    scoreGoalData = getScoreGoalDataFlights();
  }

  // console.log(`scoreGoalData: `, scoreGoalData);

  const taxiData = {
    actual: {
      GnspdOnRunway: [0, 0],
      HdgMaintained: [0, 0],
      TrackingOnRunway: [0, 0],
    },
    scoreInfo: {
      GnspdOnRunway: 0,
      HdgMaintained: 0,
      TrackingOnRunway: 0,
    },
    overallScore: 0,
  };

  const takeoffData = {
    actual: {
      PitchRateMaintained: [0, 0],
      VspeedMaintained: [0, 0],
    },
    scoreInfo: {
      PitchRateMaintained: 0,
      VspeedMaintained: 0,
    },
    overallScore: 0,
  };

  const climbData = {
    actual: {
      ConsistentVspeedAbove: [0, 0],
      IASMaintained: [0, 0],
    },
    scoreInfo: {
      ConsistentVspeedAbove: 0,
      IASMaintained: 0,
    },
    overallScore: 0,
  };

  const cruiseData = {
    actual: {
      ConsistentIAS: [0, 0],
      CrossingAlt: [0, 0],
      HdgMaintained: [0, 0],
      AltitudeMaintained: [0, 0],
      ConsistentVspeed: [0, 0],
    },
    scoreInfo: {
      ConsistentIAS: 0,
      CrossingAlt: 0,
      HdgMaintained: 0,
      AltitudeMaintained: 0,
      ConsistentVspeed: 0,
    },
    overallScore: 0,
  };

  const descentData = {
    actual: {
      ConsistentIAS: [0, 0],
      ConsistentVspeed: [0, 0],
      VspeedMaintained: [0, 0],
      PitchRateMaintained: [0, 0],
    },
    scoreInfo: {
      ConsistentIAS: 0,
      ConsistentVspeed: 0,
      VspeedMaintained: 0,
      PitchRateMaintained: 0,
    },
    overallScore: 0,
  };

  const approachData = {
    actual: {
      DescentAngleDeviation: [0],
      SinkrateWithinBounds: [0, 0],
      ConsistentIAS: [0, 0],
      ConsistentSinkrate: [0, 0],
    },
    scoreInfo: {
      DescentAngleDeviation: 0,
      SinkrateWithinBounds: 0,
      ConsistentIAS: 0,
      ConsistentSinkrate: 0,
    },
    overallScore: 0,
  };

  const landingData = {
    actual: {
      IASTouchdown: [0, 0],
      SideloadingTouchdown: [0, 0],
      NormgTouchdown: [0, 0],
      CenterlineDeviationOnFinal: [0, 0],
      PitchRateMaintained: [0, 0],
    },
    scoreInfo: {
      IASTouchdown: 0,
      SideloadingTouchdown: 0,
      NormgTouchdown: 0,
      CenterlineDeviationOnFinal: 0,
      PitchRateMaintained: 0,
    },
    overallScore: 0,
  };

  const flareData = {
    actual: {
      TouchdownDistance: [0, 0],
    },
    scoreInfo: {
      TouchdownDistance: 0,
    },
    overallScore: 0,
  };

  const [taxiPhaseData, setTaxiPhaseData] = useState<any>({ ...taxiData });
  const [takeoffPhaseData, setTakeoffPhaseData] = useState<any>({
    ...takeoffData,
  });
  const [climbPhaseData, setClimbPhaseData] = useState<any>({ ...climbData });
  const [cruisePhaseData, setCruisePhaseData] = useState<any>({
    ...cruiseData,
  });
  const [descentPhaseData, setDescentPhaseData] = useState<any>({
    ...descentData,
  });
  const [approachPhaseData, setApproachPhaseData] = useState<any>({
    ...approachData,
  });
  const [landingPhaseData, setLandingPhaseData] = useState<any>({
    ...landingData,
  });
  const [flarePhaseData, setFlarePhaseData] = useState<any>({ ...flareData });

  // const [isScoreDataAvailable, setIsScoreDataAvailable] = useState(true);
  const isScoreDataAvailableRef = useRef<boolean>(true);

  useEffect(() => {
    setTaxiPhaseData({ ...taxiData });
    setTakeoffPhaseData({ ...takeoffData });
    setClimbPhaseData({ ...climbData });
    setCruisePhaseData({ ...cruiseData });
    setDescentPhaseData({ ...descentData });
    setApproachPhaseData({ ...approachData });
    setLandingPhaseData({ ...landingData });
    setFlarePhaseData({ ...flareData });

    try {
      if (scoreData) {
        let taxiData = scoreData
          .filter((item: any) => item.labelName === `Taxi`)
          .map((item: any) => item.data);
        let taxiCount = taxiData.length;
        // console.log(`taxiData: `, taxiData)
        taxiData.forEach((item: any) => {
          //adding up all available data to average it later
          setTaxiPhaseData((prevTaxiPhaseData: any) => {
            const updatedActual = {
              GnspdOnRunway: [
                prevTaxiPhaseData.actual["GnspdOnRunway"][0] +
                  item.actual["GnspdOnRunway"][0],
                prevTaxiPhaseData.actual["GnspdOnRunway"][1] +
                  item.actual["GnspdOnRunway"][1],
              ],
              HdgMaintained: [
                prevTaxiPhaseData.actual["HdgMaintained"][0] +
                  item.actual["HdgMaintained"][0],
                prevTaxiPhaseData.actual["HdgMaintained"][1] +
                  item.actual["HdgMaintained"][1],
              ],
              TrackingOnRunway: [
                prevTaxiPhaseData.actual["TrackingOnRunway"][0] +
                  item.actual["TrackingOnRunway"][0],
                prevTaxiPhaseData.actual["TrackingOnRunway"][1] +
                  item.actual["TrackingOnRunway"][1],
              ],
            };

            const updatedScoreInfo = {
              GnspdOnRunway: [
                Number(prevTaxiPhaseData.scoreInfo["GnspdOnRunway"]) +
                  Number(item.scoreInfo["GnspdOnRunway"]),
              ],
              HdgMaintained: [
                Number(prevTaxiPhaseData.scoreInfo["HdgMaintained"]) +
                  Number(item.scoreInfo["HdgMaintained"]),
              ],
              TrackingOnRunway: [
                Number(prevTaxiPhaseData.scoreInfo["TrackingOnRunway"]) +
                  Number(item.scoreInfo["TrackingOnRunway"]),
              ],
            };

            const updatedOverallScore =
              prevTaxiPhaseData.overallScore + item.overallScore;
            return {
              ...prevTaxiPhaseData,
              actual: updatedActual,
              scoreInfo: updatedScoreInfo,
              overallScore: updatedOverallScore,
            };
          });
        });
        //taking average
        setTaxiPhaseData((prevTaxiPhaseData: any) => {
          const updatedActual = {
            GnspdOnRunway: [
              prevTaxiPhaseData.actual["GnspdOnRunway"][0] / taxiCount,
              prevTaxiPhaseData.actual["GnspdOnRunway"][1] / taxiCount,
            ],
            HdgMaintained: [
              prevTaxiPhaseData.actual["HdgMaintained"][0] / taxiCount,
              prevTaxiPhaseData.actual["HdgMaintained"][1] / taxiCount,
            ],
            TrackingOnRunway: [
              prevTaxiPhaseData.actual["TrackingOnRunway"][0] / taxiCount,
              prevTaxiPhaseData.actual["TrackingOnRunway"][1] / taxiCount,
            ],
          };
          const updatedScoreInfo = {
            GnspdOnRunway: [
              Number(prevTaxiPhaseData.scoreInfo["GnspdOnRunway"]) / taxiCount,
            ],
            HdgMaintained: [
              Number(prevTaxiPhaseData.scoreInfo["HdgMaintained"]) / taxiCount,
            ],
            TrackingOnRunway: [
              Number(prevTaxiPhaseData.scoreInfo["TrackingOnRunway"]) /
                taxiCount,
            ],
          };
          const updatedOverallScore =
            prevTaxiPhaseData.overallScore / taxiCount / 10;
          return {
            ...prevTaxiPhaseData,
            actual: updatedActual,
            scoreInfo: updatedScoreInfo,
            overallScore: updatedOverallScore,
          };
        });

        //--------------------------------------------------------------------------------------------------------

        let takeoffData: any = scoreData
          .filter((item: any) => item.labelName === `Takeoff`)
          .map((item: any) => item.data);
        let takeoffCount = takeoffData.length;
        // console.log(`takeoffData: `, takeoffData)
        takeoffData.forEach((item: any) => {
          //adding up all available data to average it later
          setTakeoffPhaseData((prevTakeoffPhaseData: any) => {
            const updatedActual = {
              PitchRateMaintained: [
                prevTakeoffPhaseData.actual["PitchRateMaintained"][0] +
                  item.actual["PitchRateMaintained"][0],
                prevTakeoffPhaseData.actual["PitchRateMaintained"][1] +
                  item.actual["PitchRateMaintained"][1],
              ],
              VspeedMaintained: [
                prevTakeoffPhaseData.actual["VspeedMaintained"][0] +
                  item.actual["VspeedMaintained"][0],
                prevTakeoffPhaseData.actual["VspeedMaintained"][1] +
                  item.actual["VspeedMaintained"][1],
              ],
            };
            const updatedScoreInfo = {
              PitchRateMaintained: [
                Number(prevTakeoffPhaseData.scoreInfo["PitchRateMaintained"]) +
                  Number(item.scoreInfo["PitchRateMaintained"]),
              ],
              VspeedMaintained: [
                Number(prevTakeoffPhaseData.scoreInfo["VspeedMaintained"]) +
                  Number(item.scoreInfo["VspeedMaintained"]),
              ],
            };
            const updatedOverallScore =
              prevTakeoffPhaseData.overallScore + item.overallScore;
            return {
              ...prevTakeoffPhaseData,
              actual: updatedActual,
              scoreInfo: updatedScoreInfo,
              overallScore: updatedOverallScore,
            };
          });
        });
        //taking average
        setTakeoffPhaseData((prevTakeoffPhaseData: any) => {
          const updatedActual = {
            PitchRateMaintained: [
              prevTakeoffPhaseData.actual["PitchRateMaintained"][0] /
                takeoffCount,
              prevTakeoffPhaseData.actual["PitchRateMaintained"][1] /
                takeoffCount,
            ],
            VspeedMaintained: [
              prevTakeoffPhaseData.actual["VspeedMaintained"][0] / takeoffCount,
              prevTakeoffPhaseData.actual["VspeedMaintained"][1] / takeoffCount,
            ],
          };
          const updatedScoreInfo = {
            PitchRateMaintained: [
              Number(prevTakeoffPhaseData.scoreInfo["PitchRateMaintained"]) /
                takeoffCount,
            ],
            VspeedMaintained: [
              Number(prevTakeoffPhaseData.scoreInfo["VspeedMaintained"]) /
                takeoffCount,
            ],
          };
          const updatedOverallScore =
            prevTakeoffPhaseData.overallScore / takeoffCount / 10;
          return {
            ...prevTakeoffPhaseData,
            actual: updatedActual,
            scoreInfo: updatedScoreInfo,
            overallScore: updatedOverallScore,
          };
        });

        //--------------------------------------------------------------------------------------------------------

        let climbData: any = scoreData
          .filter((item: any) => item.labelName === `Climb`)
          .map((item: any) => item.data);
        let climbCount = climbData.length;
        climbData.forEach((item: any) => {
          //adding up all available data to average it later
          setClimbPhaseData((prevClimbPhaseData: any) => {
            const updatedActual = {
              ConsistentVspeedAbove: [
                prevClimbPhaseData.actual["ConsistentVspeedAbove"][0] +
                  item.actual["ConsistentVspeedAbove"][0],
                prevClimbPhaseData.actual["ConsistentVspeedAbove"][1] +
                  item.actual["ConsistentVspeedAbove"][1],
              ],
              IASMaintained: [
                prevClimbPhaseData.actual["IASMaintained"][0] +
                  item.actual["IASMaintained"][0],
                prevClimbPhaseData.actual["IASMaintained"][1] +
                  item.actual["IASMaintained"][1],
              ],
            };
            const updatedScoreInfo = {
              ConsistentVspeedAbove: [
                Number(prevClimbPhaseData.scoreInfo["ConsistentVspeedAbove"]) +
                  Number(item.scoreInfo["ConsistentVspeedAbove"]),
              ],
              IASMaintained: [
                Number(prevClimbPhaseData.scoreInfo["IASMaintained"]) +
                  Number(item.scoreInfo["IASMaintained"]),
              ],
            };
            const updatedOverallScore =
              prevClimbPhaseData.overallScore + item.overallScore;
            return {
              ...prevClimbPhaseData,
              actual: updatedActual,
              scoreInfo: updatedScoreInfo,
              overallScore: updatedOverallScore,
            };
          });
        });
        //taking average
        setClimbPhaseData((prevClimbPhaseData: any) => {
          const updatedActual = {
            ConsistentVspeedAbove: [
              prevClimbPhaseData.actual["ConsistentVspeedAbove"][0] /
                climbCount,
              prevClimbPhaseData.actual["ConsistentVspeedAbove"][1] /
                climbCount,
            ],
            IASMaintained: [
              prevClimbPhaseData.actual["IASMaintained"][0] / climbCount,
              prevClimbPhaseData.actual["IASMaintained"][1] / climbCount,
            ],
          };
          const updatedScoreInfo = {
            ConsistentVspeedAbove: [
              Number(prevClimbPhaseData.scoreInfo["ConsistentVspeedAbove"]) /
                climbCount,
            ],
            IASMaintained: [
              Number(prevClimbPhaseData.scoreInfo["IASMaintained"]) /
                climbCount,
            ],
          };
          const updatedOverallScore =
            prevClimbPhaseData.overallScore / climbCount / 10;
          return {
            ...prevClimbPhaseData,
            actual: updatedActual,
            scoreInfo: updatedScoreInfo,
            overallScore: updatedOverallScore,
          };
        });

        //--------------------------------------------------------------------------------------------------------

        let cruiseData: any = scoreData
          .filter((item: any) => item.labelName === `Cruise`)
          .map((item: any) => item.data);
        // console.log(`cruiseData: `, cruiseData)
        let cruiseCount = cruiseData.length;
        let vspeedScore = 0,
          iasScore = 0,
          hdgScore = 0,
          altScore = 0,
          crossingScore = 0;
        cruiseData.forEach((item: any) => {
          iasScore = iasScore + item.scoreInfo["ConsistentIAS"];
          crossingScore = crossingScore + item.scoreInfo["CrossingAlt"];
          hdgScore = hdgScore + item.scoreInfo["HdgMaintained"];
          altScore = altScore + item.scoreInfo["AltitudeMaintained"];
          vspeedScore = vspeedScore + item.scoreInfo["ConsistentVspeed"];

          //adding up all available data to average it later
          setCruisePhaseData((prevCruisePhaseData: any) => {
            const updatedActual = {
              ConsistentIAS: [
                prevCruisePhaseData.actual["ConsistentIAS"][0] +
                  item.actual["ConsistentIAS"][0],
                prevCruisePhaseData.actual["ConsistentIAS"][1] +
                  item.actual["ConsistentIAS"][1],
              ],
              CrossingAlt: [
                prevCruisePhaseData.actual["CrossingAlt"][0] +
                  item.actual["CrossingAlt"][0],
                prevCruisePhaseData.actual["CrossingAlt"][1] +
                  item.actual["CrossingAlt"][1],
              ],
              // 'HdgMaintained': [
              //     (prevCruisePhaseData.actual["HdgMaintained"][0] + item.actual["HdgMaintained"][0]),
              //     (prevCruisePhaseData.actual["HdgMaintained"][1] + item.actual["HdgMaintained"][1])
              // ],
              AltitudeMaintained: [
                prevCruisePhaseData.actual["AltitudeMaintained"][0] +
                  item.actual["AltitudeMaintained"][0],
                prevCruisePhaseData.actual["AltitudeMaintained"][1] +
                  item.actual["AltitudeMaintained"][1],
              ],
              ConsistentVspeed: [
                prevCruisePhaseData.actual["ConsistentVspeed"][0] +
                  item.actual["ConsistentVspeed"][0],
                prevCruisePhaseData.actual["ConsistentVspeed"][1] +
                  item.actual["ConsistentVspeed"][1],
              ],
            };
            const updatedOverallScore =
              prevCruisePhaseData.overallScore + item.overallScore;
            return {
              ...prevCruisePhaseData,
              actual: updatedActual,
              overallScore: updatedOverallScore,
            };
          });
        });
        //taking average
        setCruisePhaseData((prevCruisePhaseData: any) => {
          const updatedActual = {
            ConsistentIAS: [
              prevCruisePhaseData.actual["ConsistentIAS"][0] / cruiseCount,
              prevCruisePhaseData.actual["ConsistentIAS"][1] / cruiseCount,
            ],
            CrossingAlt: [
              prevCruisePhaseData.actual["CrossingAlt"][0] / cruiseCount,
              prevCruisePhaseData.actual["CrossingAlt"][1] / cruiseCount,
            ],
            // 'HdgMaintained': [
            //     (prevCruisePhaseData.actual["HdgMaintained"][0] / cruiseCount),
            //     (prevCruisePhaseData.actual["HdgMaintained"][1] / cruiseCount)
            // ],
            AltitudeMaintained: [
              prevCruisePhaseData.actual["AltitudeMaintained"][0] / cruiseCount,
              prevCruisePhaseData.actual["AltitudeMaintained"][1] / cruiseCount,
            ],
            ConsistentVspeed: [
              prevCruisePhaseData.actual["ConsistentVspeed"][0] / cruiseCount,
              prevCruisePhaseData.actual["ConsistentVspeed"][1] / cruiseCount,
            ],
          };
          const updatedScoreInfo = {
            ConsistentIAS: [iasScore / cruiseCount],
            CrossingAlt: [crossingScore / cruiseCount],
            HdgMaintained: [hdgScore / cruiseCount],
            AltitudeMaintained: [altScore / cruiseCount],
            ConsistentVspeed: [vspeedScore / cruiseCount],
          };
          const updatedOverallScore =
            prevCruisePhaseData.overallScore / cruiseCount / 10;
          return {
            ...prevCruisePhaseData,
            actual: updatedActual,
            scoreInfo: updatedScoreInfo,
            overallScore: updatedOverallScore,
          };
        });

        //--------------------------------------------------------------------------------------------------------

        let descentData: any = scoreData
          .filter((item: any) => item.labelName === `Descent`)
          .map((item: any) => item.data);
        let descentCount = descentData.length;
        descentData.forEach((item: any) => {
          //adding up all available data to average it later
          setDescentPhaseData((prevDescentPhaseData: any) => {
            const updatedActual = {
              ConsistentIAS: [
                prevDescentPhaseData.actual["ConsistentIAS"][0] +
                  item.actual["ConsistentIAS"][0],
                prevDescentPhaseData.actual["ConsistentIAS"][1] +
                  item.actual["ConsistentIAS"][1],
              ],
              ConsistentVspeed: [
                prevDescentPhaseData.actual["ConsistentVspeed"][0] +
                  item.actual["ConsistentVspeed"][0],
                prevDescentPhaseData.actual["ConsistentVspeed"][1] +
                  item.actual["ConsistentVspeed"][1],
              ],
              VspeedMaintained: [
                prevDescentPhaseData.actual["VspeedMaintained"][0] +
                  item.actual["VspeedMaintained"][0],
                prevDescentPhaseData.actual["VspeedMaintained"][1] +
                  item.actual["VspeedMaintained"][1],
              ],
              PitchRateMaintained: [
                prevDescentPhaseData.actual["PitchRateMaintained"][0] +
                  item.actual["PitchRateMaintained"][0],
                prevDescentPhaseData.actual["PitchRateMaintained"][1] +
                  item.actual["PitchRateMaintained"][1],
              ],
            };
            const updatedScoreInfo = {
              ConsistentIAS: [
                Number(prevDescentPhaseData.scoreInfo["ConsistentIAS"]) +
                  Number(item.scoreInfo["ConsistentIAS"]),
              ],
              ConsistentVspeed: [
                Number(prevDescentPhaseData.scoreInfo["ConsistentVspeed"]) +
                  Number(item.scoreInfo["ConsistentVspeed"]),
              ],
              VspeedMaintained: [
                Number(prevDescentPhaseData.scoreInfo["VspeedMaintained"]) +
                  Number(item.scoreInfo["VspeedMaintained"]),
              ],
              PitchRateMaintained: [
                Number(prevDescentPhaseData.scoreInfo["PitchRateMaintained"]) +
                  Number(item.scoreInfo["PitchRateMaintained"]),
              ],
            };
            const updatedOverallScore =
              prevDescentPhaseData.overallScore + item.overallScore;
            return {
              ...prevDescentPhaseData,
              actual: updatedActual,
              scoreInfo: updatedScoreInfo,
              overallScore: updatedOverallScore,
            };
          });
        });
        //taking average
        setDescentPhaseData((prevDescentPhaseData: any) => {
          const updatedActual = {
            ConsistentIAS: [
              prevDescentPhaseData.actual["ConsistentIAS"][0] / descentCount,
              prevDescentPhaseData.actual["ConsistentIAS"][1] / descentCount,
            ],
            ConsistentVspeed: [
              prevDescentPhaseData.actual["ConsistentVspeed"][0] / descentCount,
              prevDescentPhaseData.actual["ConsistentVspeed"][1] / descentCount,
            ],
            VspeedMaintained: [
              prevDescentPhaseData.actual["VspeedMaintained"][0] / descentCount,
              prevDescentPhaseData.actual["VspeedMaintained"][1] / descentCount,
            ],
            PitchRateMaintained: [
              prevDescentPhaseData.actual["PitchRateMaintained"][0] /
                descentCount,
              prevDescentPhaseData.actual["PitchRateMaintained"][1] /
                descentCount,
            ],
          };
          const updatedScoreInfo = {
            ConsistentIAS: [
              Number(prevDescentPhaseData.scoreInfo["ConsistentIAS"]) /
                descentCount,
            ],
            ConsistentVspeed: [
              Number(prevDescentPhaseData.scoreInfo["ConsistentVspeed"]) /
                descentCount,
            ],
            VspeedMaintained: [
              Number(prevDescentPhaseData.scoreInfo["VspeedMaintained"]) /
                descentCount,
            ],
            PitchRateMaintained: [
              Number(prevDescentPhaseData.scoreInfo["PitchRateMaintained"]) /
                descentCount,
            ],
          };
          const updatedOverallScore =
            prevDescentPhaseData.overallScore / descentCount / 10;
          return {
            ...prevDescentPhaseData,
            actual: updatedActual,
            scoreInfo: updatedScoreInfo,
            overallScore: updatedOverallScore,
          };
        });

        //--------------------------------------------------------------------------------------------------------

        let approachData = scoreData
          .filter((item: any) => item.labelName === `Approach`)
          .map((item: any) => item.data);
        let approachCount = approachData.length;
        // console.log(`approachData: `, approachData);
        approachData.forEach((item: any) => {
          //adding up all available data to average it later
          setApproachPhaseData((prevApproachPhaseData: any) => {
            const updatedActual = {
              DescentAngleDeviation: [
                prevApproachPhaseData.actual["DescentAngleDeviation"][0] +
                  item.actual["DescentAngleDeviation"][0],
              ],
              SinkrateWithinBounds: [
                prevApproachPhaseData.actual["SinkrateWithinBounds"][0] +
                  item.actual["SinkrateWithinBounds"][0],
                prevApproachPhaseData.actual["SinkrateWithinBounds"][1] +
                  item.actual["SinkrateWithinBounds"][1],
              ],
              ConsistentIAS: [
                prevApproachPhaseData.actual["ConsistentIAS"][0] +
                  item.actual["ConsistentIAS"][0],
                prevApproachPhaseData.actual["ConsistentIAS"][1] +
                  item.actual["ConsistentIAS"][1],
              ],
              ConsistentSinkrate: [
                prevApproachPhaseData.actual["ConsistentSinkrate"][0] +
                  item.actual["ConsistentSinkrate"][0],
                prevApproachPhaseData.actual["ConsistentSinkrate"][1] +
                  item.actual["ConsistentSinkrate"][1],
              ],
            };

            const updatedScoreInfo = {
              DescentAngleDeviation: [
                Number(
                  prevApproachPhaseData.scoreInfo["DescentAngleDeviation"]
                ) + Number(item.scoreInfo["DescentAngleDeviation"]),
              ],
              SinkrateWithinBounds: [
                Number(
                  prevApproachPhaseData.scoreInfo["SinkrateWithinBounds"]
                ) + Number(item.scoreInfo["SinkrateWithinBounds"]),
              ],
              ConsistentIAS: [
                Number(prevApproachPhaseData.scoreInfo["ConsistentIAS"]) +
                  Number(item.scoreInfo["ConsistentIAS"]),
              ],
              ConsistentSinkrate: [
                Number(prevApproachPhaseData.scoreInfo["ConsistentSinkrate"]) +
                  Number(item.scoreInfo["ConsistentSinkrate"]),
              ],
            };
            const updatedOverallScore =
              prevApproachPhaseData.overallScore + item.overallScore;
            return {
              ...prevApproachPhaseData,
              actual: updatedActual,
              scoreInfo: updatedScoreInfo,
              overallScore: updatedOverallScore,
            };
          });
        });
        //taking average
        setApproachPhaseData((prevApproachPhaseData: any) => {
          const updatedActual = {
            DescentAngleDeviation: [
              prevApproachPhaseData.actual["DescentAngleDeviation"][0] /
                approachCount,
            ],
            // 'DescentAngleDeviation': [
            //     (prevApproachPhaseData.actual["DescentAngleDeviation"][0] / approachCount),
            //     (prevApproachPhaseData.actual["DescentAngleDeviation"][1] / approachCount)
            // ],
            SinkrateWithinBounds: [
              prevApproachPhaseData.actual["SinkrateWithinBounds"][0] /
                approachCount,
              prevApproachPhaseData.actual["SinkrateWithinBounds"][1] /
                approachCount,
            ],
            ConsistentIAS: [
              prevApproachPhaseData.actual["ConsistentIAS"][0] / approachCount,
              prevApproachPhaseData.actual["ConsistentIAS"][1] / approachCount,
            ],
            ConsistentSinkrate: [
              prevApproachPhaseData.actual["ConsistentSinkrate"][0] /
                approachCount,
              prevApproachPhaseData.actual["ConsistentSinkrate"][1] /
                approachCount,
            ],
          };
          const updatedScoreInfo = {
            DescentAngleDeviation: [
              Number(prevApproachPhaseData.scoreInfo["DescentAngleDeviation"]) /
                approachCount,
            ],
            SinkrateWithinBounds: [
              Number(prevApproachPhaseData.scoreInfo["SinkrateWithinBounds"]) /
                approachCount,
            ],
            ConsistentIAS: [
              Number(prevApproachPhaseData.scoreInfo["ConsistentIAS"]) /
                approachCount,
            ],
            ConsistentSinkrate: [
              Number(prevApproachPhaseData.scoreInfo["ConsistentSinkrate"]) /
                approachCount,
            ],
          };
          const updatedOverallScore =
            prevApproachPhaseData.overallScore / approachCount / 10;
          return {
            ...prevApproachPhaseData,
            actual: updatedActual,
            scoreInfo: updatedScoreInfo,
            overallScore: updatedOverallScore,
          };
        });

        //--------------------------------------------------------------------------------------------------------

        let landingData = scoreData
          .filter((item: any) => item.labelName === `Landing`)
          .map((item: any) => item.data);
        let landingCount = landingData.length;
        // console.log(`landingData: `, landingData);
        landingData.forEach((item: any) => {
          //adding up all available data to average it later
          setLandingPhaseData((prevLandingPhaseData: any) => {
            const updatedActual = {
              IASTouchdown: [
                prevLandingPhaseData.actual["IASTouchdown"][0] +
                  item.actual["IASTouchdown"][0],
                prevLandingPhaseData.actual["IASTouchdown"][1] +
                  item.actual["IASTouchdown"][1],
              ],
              SideloadingTouchdown: [
                prevLandingPhaseData.actual["SideloadingTouchdown"][0] +
                  item.actual["SideloadingTouchdown"][0],
                prevLandingPhaseData.actual["SideloadingTouchdown"][1] +
                  item.actual["SideloadingTouchdown"][1],
              ],
              NormgTouchdown: [
                prevLandingPhaseData.actual["NormgTouchdown"][0] +
                  item.actual["NormgTouchdown"][0],
                prevLandingPhaseData.actual["NormgTouchdown"][1] +
                  item.actual["NormgTouchdown"][1],
              ],
              CenterlineDeviationOnFinal: [
                prevLandingPhaseData.actual["CenterlineDeviationOnFinal"][0] +
                  item.actual["CenterlineDeviationOnFinal"][0],
                prevLandingPhaseData.actual["CenterlineDeviationOnFinal"][1] +
                  item.actual["CenterlineDeviationOnFinal"][1],
              ],
              PitchRateMaintained: [
                prevLandingPhaseData.actual["PitchRateMaintained"][0] +
                  item.actual["PitchRateMaintained"][0],
                prevLandingPhaseData.actual["PitchRateMaintained"][1] +
                  item.actual["PitchRateMaintained"][1],
              ],
            };

            const updatedScoreInfo = {
              IASTouchdown: [
                Number(prevLandingPhaseData.scoreInfo["IASTouchdown"]) +
                  Number(item.scoreInfo["IASTouchdown"]),
              ],
              SideloadingTouchdown: [
                Number(prevLandingPhaseData.scoreInfo["SideloadingTouchdown"]) +
                  Number(item.scoreInfo["SideloadingTouchdown"]),
              ],
              NormgTouchdown: [
                Number(prevLandingPhaseData.scoreInfo["NormgTouchdown"]) +
                  Number(item.scoreInfo["NormgTouchdown"]),
              ],
              CenterlineDeviationOnFinal: [
                Number(
                  prevLandingPhaseData.scoreInfo["CenterlineDeviationOnFinal"]
                ) + Number(item.scoreInfo["CenterlineDeviationOnFinal"]),
              ],
              PitchRateMaintained: [
                Number(prevLandingPhaseData.scoreInfo["PitchRateMaintained"]) +
                  Number(item.scoreInfo["PitchRateMaintained"]),
              ],
            };
            const updatedOverallScore =
              prevLandingPhaseData.overallScore + item.overallScore;
            return {
              ...prevLandingPhaseData,
              actual: updatedActual,
              scoreInfo: updatedScoreInfo,
              overallScore: updatedOverallScore,
            };
          });
        });
        //taking average
        setLandingPhaseData((prevLandingPhaseData: any) => {
          const updatedActual = {
            IASTouchdown: [
              prevLandingPhaseData.actual["IASTouchdown"][0] / landingCount,
              prevLandingPhaseData.actual["IASTouchdown"][1] / landingCount,
            ],
            SideloadingTouchdown: [
              prevLandingPhaseData.actual["SideloadingTouchdown"][0] /
                landingCount,
              prevLandingPhaseData.actual["SideloadingTouchdown"][1] /
                landingCount,
            ],
            NormgTouchdown: [
              prevLandingPhaseData.actual["NormgTouchdown"][0] / landingCount,
              prevLandingPhaseData.actual["NormgTouchdown"][1] / landingCount,
            ],
            CenterlineDeviationOnFinal: [
              prevLandingPhaseData.actual["CenterlineDeviationOnFinal"][0] /
                landingCount,
              prevLandingPhaseData.actual["CenterlineDeviationOnFinal"][1] /
                landingCount,
            ],
            PitchRateMaintained: [
              prevLandingPhaseData.actual["PitchRateMaintained"][0] /
                landingCount,
              prevLandingPhaseData.actual["PitchRateMaintained"][1] /
                landingCount,
            ],
          };
          const updatedScoreInfo = {
            IASTouchdown: [
              Number(prevLandingPhaseData.scoreInfo["IASTouchdown"]) /
                landingCount,
            ],
            SideloadingTouchdown: [
              Number(prevLandingPhaseData.scoreInfo["SideloadingTouchdown"]) /
                landingCount,
            ],
            NormgTouchdown: [
              Number(prevLandingPhaseData.scoreInfo["NormgTouchdown"]) /
                landingCount,
            ],
            CenterlineDeviationOnFinal: [
              Number(
                prevLandingPhaseData.scoreInfo["CenterlineDeviationOnFinal"]
              ) / landingCount,
            ],
            PitchRateMaintained: [
              Number(prevLandingPhaseData.scoreInfo["PitchRateMaintained"]) /
                landingCount,
            ],
          };
          const updatedOverallScore =
            prevLandingPhaseData.overallScore / landingCount / 10;
          return {
            ...prevLandingPhaseData,
            actual: updatedActual,
            scoreInfo: updatedScoreInfo,
            overallScore: updatedOverallScore,
          };
        });

        //--------------------------------------------------------------------------------------------------------

        let flareData: any = scoreData
          .filter((item: any) => item.labelName === `Flare`)
          .map((item: any) => item.data);
        // console.log(`flareData: `, flareData);
        let flareCount = flareData.length;
        flareData.forEach((item: any) => {
          //adding up all available data to average it later
          setFlarePhaseData((prevFlarePhaseData: any) => {
            const updatedActual = {
              TouchdownDistance: [
                prevFlarePhaseData.actual["TouchdownDistance"][0] +
                  item.actual["TouchdownDistance"][0],
                prevFlarePhaseData.actual["TouchdownDistance"][1] +
                  item.actual["TouchdownDistance"][1],
              ],
            };
            const updatedScoreInfo = {
              TouchdownDistance: [
                Number(prevFlarePhaseData.scoreInfo["TouchdownDistance"]) +
                  Number(item.scoreInfo["TouchdownDistance"]),
              ],
            };
            const updatedOverallScore =
              prevFlarePhaseData.overallScore + item.overallScore;
            return {
              ...prevFlarePhaseData,
              actual: updatedActual,
              scoreInfo: updatedScoreInfo,
              overallScore: updatedOverallScore,
            };
          });
        });
        //taking average
        setFlarePhaseData((prevFlarePhaseData: any) => {
          const updatedActual = {
            TouchdownDistance: [
              prevFlarePhaseData.actual["TouchdownDistance"][0] / flareCount,
              prevFlarePhaseData.actual["TouchdownDistance"][1] / flareCount,
            ],
          };
          const updatedScoreInfo = {
            TouchdownDistance: [
              Number(prevFlarePhaseData.scoreInfo["TouchdownDistance"]) /
                flareCount,
            ],
          };
          const updatedOverallScore =
            prevFlarePhaseData.overallScore / flareCount / 10;
          return {
            ...prevFlarePhaseData,
            actual: updatedActual,
            scoreInfo: updatedScoreInfo,
            overallScore: updatedOverallScore,
          };
        });

        //--------------------------------------------------------------------------------------------------------
        // isScoreDataAvailableRef.current = true;
      }
    } catch (error) {
      console.log(`Error getting complete score data`);
      console.error(error);
      // setIsScoreDataAvailable(false);
      isScoreDataAvailableRef.current = false;
    }
  }, [scoreData]);

  const [filteredGoalData, setFilteredGoalData] = useState<any[]>([]);
  const [isGoalDataLoaded, setIsGoalDataLoaded] = useState(false);

  useEffect(() => {
    // console.log(`scoreGoalData: `, scoreGoalData);
    if (scoreGoalData) {
      // removing the null values and OverallWeight key
      const cleanArray = scoreGoalData.map((obj: any) =>
        Object.fromEntries(
          Object.entries(obj).filter(
            ([key, value]) => value !== null && key !== `OverallWeight`
          )
        )
      );
      
      // merging objects by Phase
      const mergedByPhase = cleanArray.reduce(
        (acc: { [key: string]: any }, obj: { [key: string]: any }) => {
          const { Phase, ...rest } = obj;

          if (acc[Phase]) {
            acc[Phase] = { ...acc[Phase], ...rest };
          } else {
            acc[Phase] = { Phase, ...rest };
          }
          return acc;
        },
        {}
      );

      const processedArray: any[] = Object.values(mergedByPhase);

      // finding the index of the "Landing" and "LandingTdown" phases
      const landingIndex = processedArray.findIndex(
        (item: any) => item.Phase === "Landing"
      );
      const landingTdownIndex = processedArray.findIndex(
        (item: any) => item.Phase === "LandingTdown"
      );

      if (landingIndex !== -1 && landingTdownIndex !== -1) {
        // merging the key-values from "LandingTdown" into "Landing"
        processedArray[landingIndex] = {
          ...processedArray[landingTdownIndex],
          ...processedArray[landingIndex],
        };

        // removing the "LandingTdown" phase from the array
        processedArray.splice(landingTdownIndex, 1);
      }

      setFilteredGoalData(() =>
        processedArray.map((item: any) => {
          let transformedItem: any = {};
          for (let key in item) {
            if (item[key].includes("/")) {
              transformedItem[key] = item[key].split("/").map(Number);
            } else {
              transformedItem[key] = isNaN(item[key])
                ? item[key]
                : Number(item[key]);
            }
          }
          return transformedItem;
        })
      );
    }
  }, [scoreGoalData]);

  const taxiGoalRef = useRef<any>(null);
  const takeoffGoalRef = useRef<any>(null);
  const climbGoalRef = useRef<any>(null);
  const cruiseGoalRef = useRef<any>(null);
  const descentGoalRef = useRef<any>(null);
  const approachGoalRef = useRef<any>(null);
  const landingGoalRef = useRef<any>(null);

  useEffect(() => {
    // console.log(`filteredGoalData: `, filteredGoalData);

    if (filteredGoalData) {
      let taxiGoalDataFiltered = filteredGoalData.filter(
        (item) => item.Phase === "Taxi"
      );
      taxiGoalRef.current = taxiGoalDataFiltered;
      let takeoffGoalDataFiltered = filteredGoalData.filter(
        (item) => item.Phase === "Take-off"
      );
      takeoffGoalRef.current = takeoffGoalDataFiltered;
      let climbGoalDataFiltered = filteredGoalData.filter(
        (item) => item.Phase === "Climb"
      );
      climbGoalRef.current = climbGoalDataFiltered;
      let cruiseGoalDataFiltered = filteredGoalData.filter(
        (item) => item.Phase === "Cruise"
      );
      cruiseGoalRef.current = cruiseGoalDataFiltered;
      let descentGoalDataFiltered = filteredGoalData.filter(
        (item) => item.Phase === "Descent"
      );
      descentGoalRef.current = descentGoalDataFiltered;
      let approachGoalDataFiltered = filteredGoalData.filter(
        (item) => item.Phase === "Approach"
      );
      approachGoalRef.current = approachGoalDataFiltered;
      let landingGoalDataFiltered = filteredGoalData.filter(
        (item) => item.Phase === "Landing"
      );
      landingGoalRef.current = landingGoalDataFiltered;

      setIsGoalDataLoaded(true);
    }
  }, [filteredGoalData]);


  const getTaxiScore = () => {
    const scoreInfo = taxiPhaseData.scoreInfo;

    // console.log(`Taxi scoreInfo: `, scoreInfo);

    const weights = [1, 1, 1];

    const values = [
      scoreInfo["GnspdOnRunway"][0],
      scoreInfo["HdgMaintained"][0],
      scoreInfo["TrackingOnRunway"][0],
    ];

    let validValueCount = 0;
    let weightedSum = 0;

    for (let i = 0; i < values.length; i++) {
      if (!isNaN(values[i])) {
        weightedSum += values[i] * weights[i];
        validValueCount++;
      }
    }

    // Calculate the weighted sum only if there are valid values
    if (validValueCount > 0) {
      return (weightedSum / 10).toFixed(1);
    } else {
      return NaN;
    }
  };

  const getTakeoffScore = () => {
    // const takeoffScoreInfo = takeoffPhaseData.scoreInfo;
    // const iasBetween30And150AGL = takeoffScoreInfo['PitchRateMaintained'][0]; VspeedMaintained

    const scoreInfo = takeoffPhaseData.scoreInfo;

    // console.log(`Takeoff scoreInfo: `, scoreInfo)

    const weights = [1, 1];

    const values = [
      scoreInfo["PitchRateMaintained"][0],
      scoreInfo["VspeedMaintained"][0],
    ];

    let validValueCount = 0;
    let weightedSum = 0;

    for (let i = 0; i < values.length; i++) {
      if (!isNaN(values[i])) {
        weightedSum += values[i] * weights[i];
        validValueCount++;
      }
    }

    // Calculate the weighted sum only if there are valid values
    if (validValueCount > 0) {
      return (weightedSum / 10).toFixed(1);
    } else {
      return NaN;
    }

    // // Check if the value is not NaN
    // if (!isNaN(iasBetween30And150AGL)) {
    //     // Perform the division and rounding
    //     return (iasBetween30And150AGL / 10).toFixed(1);
    // } else {
    //     // Return NaN if the value is NaN
    //     return NaN;
    // }
  };

  const getClimbScore = () => {
    const scoreInfo = climbPhaseData.scoreInfo;

    // console.log(`Climb scoreInfo: `, scoreInfo)

    const weights = [1, 1];

    const values = [
      scoreInfo["ConsistentVspeedAbove"][0],
      scoreInfo["IASMaintained"][0],
    ];

    let validValueCount = 0;
    let weightedSum = 0;

    for (let i = 0; i < values.length; i++) {
      if (!isNaN(values[i])) {
        weightedSum += values[i] * weights[i];
        validValueCount++;
      }
    }

    // Calculate the weighted sum only if there are valid values
    if (validValueCount > 0) {
      return (weightedSum / 10).toFixed(1);
    } else {
      return NaN;
    }
  };

  const getCruiseScore = () => {
    const scoreInfo = cruisePhaseData.scoreInfo;

    // console.log(`Cruise scoreInfo: `, scoreInfo)

    const weights = [1, 1, 1, 1];

    const values = [
      scoreInfo["ConsistentIAS"][0],
      scoreInfo["CrossingAlt"][0],
      // scoreInfo['HdgMaintained'][0],
      scoreInfo["AltitudeMaintained"][0],
      scoreInfo["ConsistentVspeed"][0],
    ];

    let validValueCount = 0;
    let weightedSum = 0;

    for (let i = 0; i < values.length; i++) {
      if (!isNaN(values[i])) {
        weightedSum += values[i] * weights[i];
        validValueCount++;
      }
    }

    // Calculate the weighted sum only if there are valid values
    if (validValueCount > 0) {
      return (weightedSum / 10).toFixed(1);
    } else {
      return NaN;
    }
  };

  const getDescentScore = () => {
    const scoreInfo = descentPhaseData.scoreInfo;

    // console.log(`Descent scoreInfo: `, scoreInfo)

    const weights = [1, 1, 1, 1];

    const values = [
      scoreInfo["ConsistentIAS"][0],
      scoreInfo["ConsistentVspeed"][0],
      scoreInfo["VspeedMaintained"][0],
      scoreInfo["PitchRateMaintained"][0],
    ];

    let validValueCount = 0;
    let weightedSum = 0;

    for (let i = 0; i < values.length; i++) {
      if (!isNaN(values[i])) {
        weightedSum += values[i] * weights[i];
        validValueCount++;
      }
    }

    // Calculate the weighted sum only if there are valid values
    if (validValueCount > 0) {
      return (weightedSum / 10).toFixed(1);
    } else {
      return NaN;
    }
  };

  const getApproachScore = () => {
    const scoreInfo = approachPhaseData.scoreInfo;

    // console.log(`Approach scoreInfo: `, scoreInfo)

    const weights = [1, 1, 1, 1, 1, 1];

    const values = [
      scoreInfo["DescentAngleDeviation"][0],
      scoreInfo["SinkrateWithinBounds"][0],
      scoreInfo["ConsistentIAS"][0],
      scoreInfo["ConsistentSinkrate"][0],
    ];

    let validValueCount = 0;
    let weightedSum = 0;

    for (let i = 0; i < values.length; i++) {
      if (!isNaN(values[i])) {
        weightedSum += values[i] * weights[i];
        validValueCount++;
      }
    }

    // Calculate the weighted sum only if there are valid values
    if (validValueCount > 0) {
      return (weightedSum / 10).toFixed(1);
    } else {
      return NaN;
    }
  };

  const getLandingScore = () => {
    const landingScoreInfo = landingPhaseData.scoreInfo;
    // const flareScoreInfo = flarePhaseData.scoreInfo;

    // console.log(`Landing scoreInfo: `, landingScoreInfo)
    // console.log(`Flare scoreInfo: `, flareScoreInfo)

    const weights = [1, 1, 1, 1, 1];
    
    // const weights = [0.2, 0.2, 0.2, 0.2, 0.2, 1];

    const values = [
      landingScoreInfo["IASTouchdown"][0],
      landingScoreInfo["SideloadingTouchdown"][0],
      landingScoreInfo["NormgTouchdown"][0],
      landingScoreInfo["CenterlineDeviationOnFinal"][0],
      landingScoreInfo["PitchRateMaintained"][0],
      // flareScoreInfo["TouchdownDistance"][0],
    ];

    let validValueCount = 0;
    let weightedSum = 0;

    for (let i = 0; i < values.length; i++) {
      if (!isNaN(values[i])) {
        weightedSum += values[i] * weights[i];
        validValueCount++;
      }
    }

    // Calculate the weighted sum only if there are valid values
    if (validValueCount > 0) {
      return (weightedSum / 10).toFixed(1);
    } else {
      return NaN;
    }
  };

  const getFlareScore = () => {
    const flareScoreInfo = flarePhaseData.scoreInfo;

    // console.log(`Flare scoreInfo: `, flareScoreInfo)

    const weights = [1];
    

    const values = [
      flareScoreInfo["TouchdownDistance"][0],
    ];

    let validValueCount = 0;
    let weightedSum = 0;

    for (let i = 0; i < values.length; i++) {
      if (!isNaN(values[i])) {
        weightedSum += values[i] * weights[i];
        validValueCount++;
      }
    }

    // Calculate the weighted sum only if there are valid values
    if (validValueCount > 0) {
      return (weightedSum / 10).toFixed(1);
    } else {
      return NaN;
    }
  };

  const columns: any = useMemo(
    () => [
      {
        id: "weight",
        Header: "WEIGHT",
        accessor: "weight",
        maxWidth: 7,
      },
      {
        id: "condition",
        Header: "CONDITION",
        accessor: "condition",
        maxWidth: 30,
      },
      {
        id: "goal",
        Header: "IDEAL RANGE",
        accessor: "goal",
        maxWidth: 20,
      },
      {
        id: "actual",
        Header: "ACTUAL",
        accessor: "actual",
        maxWidth: 20,
      },
      {
        id: "score",
        Header: "SCORE",
        accessor: "score",
        maxWidth: 7,
      },
    ],
    []
  );

  const pillBgColor = (score: any) => {
    if (score <= 5) {
      return `#FF0000`;
    } else if (score > 5 && score < 8.5) {
      return `#eb8a2f`;
    } else {
      return `#39b34a`;
    }
  };

  
  // useEffect(() => {
  //   console.log(`---------------`);
  //   console.log(`---------------`);
  //   console.log(`isGoalDataLoaded: `, isGoalDataLoaded);

  //   console.log(`taxiGoalRef: `, taxiGoalRef.current);
  //   console.log(`takeoffGoalRef: `, takeoffGoalRef.current);
  //   console.log(`climbGoalRef: `, climbGoalRef.current);
  //   console.log(`cruiseGoalRef: `, cruiseGoalRef.current);
  //   console.log(`descentGoalRef: `, descentGoalRef.current);
  //   console.log(`approachGoalRef: `, approachGoalRef.current);
  //   console.log(`landingGoalRef: `, landingGoalRef.current);
  //   console.log(`---------------`);
  //   console.log(`---------------`);
  // }, [isGoalDataLoaded])

  const approachesApproachData = useMemo(
    () => [
      {
        weight: "30%",
        condition: `Descent angle deviation`,
        // goal: `2 to 4 deg`,
        goal: (isGoalDataLoaded ? (`${approachGoalRef.current[0]?.DescentAngleDeviation[0]} to ${approachGoalRef.current[0]?.DescentAngleDeviation[1]} deg`) : "  deg"),
        actual: `${approachPhaseData.actual["DescentAngleDeviation"][0].toFixed(
          1
        )} deg`,
        score: (
          <div
            className="badge-pill badge"
            style={{
              backgroundColor: pillBgColor(
                (approachPhaseData.scoreInfo["DescentAngleDeviation"] * 3.33) / 10
              ),
            }}
          >
            <div className="badge-text">
              {(
                (approachPhaseData.scoreInfo["DescentAngleDeviation"][0] * 3.33) / 10
              )?.toFixed(1)}
            </div>
          </div>
        ),
      },
      {
        weight: "30%",
        condition: `Sink-rate is within bounds`,
        // goal: `-800 to -300 ft/min`,
        goal: (isGoalDataLoaded ? (`${approachGoalRef.current[0]?.SinkrateWithinBounds[0]} to ${approachGoalRef.current[0]?.SinkrateWithinBounds[1]} ft/min`) : "  ft/min"),
        actual: `${approachPhaseData.actual["SinkrateWithinBounds"][0].toFixed(
          1
        )} to ${approachPhaseData.actual["SinkrateWithinBounds"][1].toFixed(
          1
        )} ft/min`,
        score: (
          <div
            className="badge-pill badge"
            style={{
              backgroundColor: pillBgColor(
                (approachPhaseData.scoreInfo["SinkrateWithinBounds"] * 3.33) / 10
              ),
            }}
          >
            <div className="badge-text">
              {(
                (approachPhaseData.scoreInfo["SinkrateWithinBounds"][0] * 3.33) / 10
              )?.toFixed(1)}
            </div>
          </div>
        ),
      },
      {
        weight: "20%",
        condition: `Consistent sink-rate`,
        // goal: `± 300 ft/min`,
        goal: (isGoalDataLoaded ? (`± ${approachGoalRef.current[0]?.ConsistentSinkrate} ft/min`) : "  ft/min"),
        actual: `${approachPhaseData.actual["ConsistentSinkrate"][0].toFixed(
          1
        )} to ${approachPhaseData.actual["ConsistentSinkrate"][1].toFixed(
          1
        )} ft/min`,
        score: (
          <div
            className="badge-pill badge"
            style={{
              backgroundColor: pillBgColor(
                (approachPhaseData.scoreInfo["ConsistentSinkrate"] * 5) / 10
              ),
            }}
          >
            <div className="badge-text">
              {(
                (approachPhaseData.scoreInfo["ConsistentSinkrate"][0] * 5) / 10
              )?.toFixed(1)}
            </div>
          </div>
        ),
      },
      {
        weight: "20%",
        condition: `Consistent IAS`,
        // goal: `± 10 kts`,
        goal: (isGoalDataLoaded ? (`± ${approachGoalRef.current[0]?.ConsistentIAS} kts`) : "  kts"),
        actual: `${approachPhaseData.actual["ConsistentIAS"][0].toFixed(
          1
        )} to ${approachPhaseData.actual["ConsistentIAS"][1].toFixed(1)} kts`,
        score: (
          <div
            className="badge-pill badge"
            style={{
              backgroundColor: pillBgColor(
                (approachPhaseData.scoreInfo["ConsistentIAS"] * 5) / 10
              ),
            }}
          >
            <div className="badge-text">
              {((approachPhaseData.scoreInfo["ConsistentIAS"][0] * 5) / 10)?.toFixed(
                1
              )}
            </div>
          </div>
        ),
      },
    ],
    [approachPhaseData]
  );

  const tableInstanceApproach = useTable(
    {
      columns,
      data: approachesApproachData,
    },
    useSortBy,
    useFlexLayout
  );

  const approachesLandingData = useMemo(
    () => [
      {
        weight: `10%`,
        condition: `IAS at touchdown`,
        // goal: `65 to 75 kts`,
        goal: (isGoalDataLoaded ? (`${landingGoalRef.current[0]?.IASTouchdown[0]} to ${landingGoalRef.current[0]?.IASTouchdown[1]} kts`) : "  kts"),
        actual: `${landingPhaseData.actual["IASTouchdown"][0].toFixed(
          1
        )} to ${landingPhaseData.actual["IASTouchdown"][1].toFixed(1)} kts`,
        score: (
          <div
            className="badge-pill badge"
            style={{
              backgroundColor: pillBgColor(
                (landingPhaseData.scoreInfo["IASTouchdown"] * 10) / 10
              ),
            }}
          >
            <div className="badge-text">
              {((landingPhaseData.scoreInfo["IASTouchdown"][0] * 10) / 10)?.toFixed(1)}
            </div>
          </div>
        ),
      },
      {
        weight: `40%`,
        condition: `Side loading at touchdown`,
        // goal: `-1 to 0.3 G`,
        goal: (isGoalDataLoaded ? (`${landingGoalRef.current[0]?.SideloadingTouchdown[0]} to ${landingGoalRef.current[0]?.SideloadingTouchdown[1]} G`) : "  G"),
        actual: `${landingPhaseData.actual["SideloadingTouchdown"][0].toFixed(
          1
        )} to ${landingPhaseData.actual["SideloadingTouchdown"][1].toFixed(
          1
        )} G`,
        score: (
          <div
            className="badge-pill badge"
            style={{
              backgroundColor: pillBgColor(
                (landingPhaseData.scoreInfo["SideloadingTouchdown"] * 2.5) / 10
              ),
            }}
          >
            <div className="badge-text">
              {(
                (landingPhaseData.scoreInfo["SideloadingTouchdown"][0] * 2.5) / 10
              )?.toFixed(1)}
            </div>
          </div>
        ),
      },
      {
        weight: `30%`,
        condition: `Normal Gravity at touchdown`,
        // goal: `0 to 1.5 G`,
        goal: (isGoalDataLoaded ? (`${landingGoalRef.current[0]?.NormgTouchdown[0]} to ${landingGoalRef.current[0]?.NormgTouchdown[1]} G`) : "  G"),
        actual: `${landingPhaseData.actual["NormgTouchdown"][0].toFixed(
          1
        )} to ${landingPhaseData.actual["NormgTouchdown"][1].toFixed(1)} G`,
        score: (
          <div
            className="badge-pill badge"
            style={{
              backgroundColor: pillBgColor(
                (landingPhaseData.scoreInfo["NormgTouchdown"] * 3.33) / 10
              ),
            }}
          >
            <div className="badge-text">
              {((landingPhaseData.scoreInfo["NormgTouchdown"][0] * 3.33) / 10)?.toFixed(
                1
              )}
            </div>
          </div>
        ),
      },
      {
        weight: `10%`,
        condition: `Centerline Deviation at touchdown`,
        // goal: `± 0.5 meters`,
        goal: (isGoalDataLoaded ? (`± ${landingGoalRef.current[0]?.CenterlineDeviationOnFinal} meters`) : "  meters"),
        actual: `${landingPhaseData.actual[
          "CenterlineDeviationOnFinal"
        ][0].toFixed(1)} to ${landingPhaseData.actual[
          "CenterlineDeviationOnFinal"
        ][1].toFixed(1)} meters`,
        score: (
          <div
            className="badge-pill badge"
            style={{
              backgroundColor: pillBgColor(
                (landingPhaseData.scoreInfo["CenterlineDeviationOnFinal"] * 10) / 10
              ),
            }}
          >
            <div className="badge-text">
              {(
                (landingPhaseData.scoreInfo["CenterlineDeviationOnFinal"][0] * 10) / 10
              )?.toFixed(1)}
            </div>
          </div>
        ),
      },
      // {
      //   weight: `20%`,
      //   condition: `Distance at Touchdown`,
      //   // goal: `400 to 1295 ft`,
      //   goal: (isGoalDataLoaded ? (`${landingGoalRef.current[0]?.TouchdownDistance[0]} to ${landingGoalRef.current[0]?.TouchdownDistance[1]} ft`) : "  ft"),
      //   actual: `${flarePhaseData.actual["TouchdownDistance"][0].toFixed(
      //     1
      //   )} to ${flarePhaseData.actual["TouchdownDistance"][1].toFixed(1)} ft`,
      //   score: (
      //     <div
      //       className="badge-pill badge"
      //       style={{
      //         backgroundColor: pillBgColor(
      //           (flarePhaseData.scoreInfo["TouchdownDistance"] * 1) / 10
      //         ),
      //       }}
      //     >
      //       <div className="badge-text">
      //         {((flarePhaseData.scoreInfo["TouchdownDistance"][0] * 1) / 10)?.toFixed(
      //           1
      //         )}
      //       </div>
      //     </div>
      //   ),
      // },
      {
        weight: `10%`,
        condition: `Pitch rate maintained`,
        // goal: `400 to 1295 ft`,
        goal: (isGoalDataLoaded ? (`${landingGoalRef.current[0]?.PitchRateMaintained[0]} to ${landingGoalRef.current[0]?.PitchRateMaintained[1]} deg`) : "  deg"),
        actual: `${landingPhaseData.actual["PitchRateMaintained"][0].toFixed(
          1
        )} to ${landingPhaseData.actual["PitchRateMaintained"][1].toFixed(1)} deg`,
        score: (
          <div
            className="badge-pill badge"
            style={{
              backgroundColor: pillBgColor(
                (landingPhaseData.scoreInfo["PitchRateMaintained"] * 10) / 10
              ),
            }}
          >
            <div className="badge-text">
              {((landingPhaseData.scoreInfo["PitchRateMaintained"][0] * 10) / 10)?.toFixed(
                1
              )}
            </div>
          </div>
        ),
      },
    ],
    [landingPhaseData, flarePhaseData]
  );

  const tableInstanceLanding = useTable(
    {
      columns,
      data: approachesLandingData,
    },
    useSortBy,
    useFlexLayout
  );

  const approachesFlareData = useMemo(
    () => [
      {
        weight: `100%`,
        condition: `Distance at Touchdown`,
        // goal: `400 to 1295 ft`,
        goal: (isGoalDataLoaded ? (`${landingGoalRef.current[0]?.TouchdownDistance[0]} to ${landingGoalRef.current[0]?.TouchdownDistance[1]} ft`) : "  ft"),
        actual: `${flarePhaseData.actual["TouchdownDistance"][0].toFixed(
          1
        )} to ${flarePhaseData.actual["TouchdownDistance"][1].toFixed(1)} ft`,
        score: (
          <div
            className="badge-pill badge"
            style={{
              backgroundColor: pillBgColor(
                (flarePhaseData.scoreInfo["TouchdownDistance"] * 1) / 10
              ),
            }}
          >
            <div className="badge-text">
              {((flarePhaseData.scoreInfo["TouchdownDistance"][0] * 1) / 10)?.toFixed(
                1
              )}
            </div>
          </div>
        ),
      },
    ],
    [flarePhaseData]
  );

  const tableInstanceFlare = useTable(
    {
      columns,
      data: approachesFlareData,
    },
    useSortBy,
    useFlexLayout
  );

  const maneuversTaxiData = useMemo(
    () => [
      {
        weight: `50%`,
        condition: `Ground speed on runway`,
        // 'goal': `0 to 20 kts`,
        goal: (isGoalDataLoaded ? (`${taxiGoalRef.current[0]?.GnspdOnRunway[0]} to ${taxiGoalRef.current[0]?.GnspdOnRunway[1]} kts`) : "  kts"),
        actual: `${taxiPhaseData.actual["GnspdOnRunway"][0].toFixed(
          1
        )} to ${taxiPhaseData.actual["GnspdOnRunway"][1].toFixed(1)} kts`,
        score: (
          <div
            className="badge-pill badge"
            style={{
              backgroundColor: pillBgColor(
                (taxiPhaseData.scoreInfo["GnspdOnRunway"] * 2) / 10
              ),
            }}
          >
            <div className="badge-text">
              {((taxiPhaseData.scoreInfo["GnspdOnRunway"][0] * 2) / 10)?.toFixed(1)}
            </div>
          </div>
        ),
      },
      {
        weight: `24%`,
        condition: `TrackingOnRunway`,
        // 'goal': `-0.2 to 0.2 deg`,
        goal: (isGoalDataLoaded ? (`${taxiGoalRef.current[0]?.TrackingOnRunway[0]} to ${taxiGoalRef.current[0]?.TrackingOnRunway[1]} deg`) : "  deg"),
        actual: `${taxiPhaseData.actual["TrackingOnRunway"][0].toFixed(
          1
        )} to ${taxiPhaseData.actual["TrackingOnRunway"][1].toFixed(1)} deg`,
        score: (
          <div
            className="badge-pill badge"
            style={{
              backgroundColor: pillBgColor(
                (taxiPhaseData.scoreInfo["TrackingOnRunway"] * 4.16) / 10
              ),
            }}
          >
            <div className="badge-text">
              {((taxiPhaseData.scoreInfo["TrackingOnRunway"][0] * 4.16) / 10)?.toFixed(
                1
              )}
            </div>
          </div>
        ),
      },
      {
        weight: `26%`,
        condition: `Heading maintained`,
        // 'goal': `± 10 deg`,
        goal: (isGoalDataLoaded ? (`± ${taxiGoalRef.current[0]?.HdgMaintained} deg`) : "  deg"),
        actual: `${taxiPhaseData.actual["HdgMaintained"][0].toFixed(
          1
        )} to ${taxiPhaseData.actual["HdgMaintained"][1].toFixed(1)} deg`,
        score: (
          <div
            className="badge-pill badge"
            style={{
              backgroundColor: pillBgColor(
                (taxiPhaseData.scoreInfo["HdgMaintained"] * 3.84) / 10
              ),
            }}
          >
            <div className="badge-text">
              {((taxiPhaseData.scoreInfo["HdgMaintained"][0] * 3.84) / 10)?.toFixed(1)}
            </div>
          </div>
        ),
      },
    ],
    [taxiPhaseData, filteredGoalData, isGoalDataLoaded]
  );

  const tableInstanceTaxi = useTable(
    {
      columns,
      data: maneuversTaxiData,
    },
    useSortBy,
    useFlexLayout
  );

  const maneuversTakeoffData = useMemo(
    () => [
      {
        weight: `50%`,
        condition: `Pitch rate maintained`,
        // goal: `65 to 75 kts`,
        goal: (isGoalDataLoaded ? (`${takeoffGoalRef.current[0]?.PitchRateMaintained[0]} to ${takeoffGoalRef.current[0]?.PitchRateMaintained[1]} deg`) : "  deg"),
        actual: `${takeoffPhaseData.actual["PitchRateMaintained"][0].toFixed(
          1
        )} to ${takeoffPhaseData.actual["PitchRateMaintained"][1].toFixed(
          1
        )} deg`,
        score: (
          <div
            className="badge-pill badge"
            style={{
              backgroundColor: pillBgColor(
                (takeoffPhaseData.scoreInfo["PitchRateMaintained"] * 2) / 10
              ),
            }}
          >
            <div className="badge-text">
              {(
                (takeoffPhaseData.scoreInfo["PitchRateMaintained"][0] * 2) / 10
              )?.toFixed(1)}
            </div>
          </div>
        ),
      },
      {
        weight: `50%`,
        condition: `Vertical speed maintained`,
        // goal: `65 to 75 kts`,
        goal: (isGoalDataLoaded ? (`${takeoffGoalRef.current[0]?.VspeedMaintained[0]} to ${takeoffGoalRef.current[0]?.VspeedMaintained[1]} kts`) : "  kts"),
        actual: `${takeoffPhaseData.actual["VspeedMaintained"][0].toFixed(
          1
        )} to ${takeoffPhaseData.actual["VspeedMaintained"][1].toFixed(1)} kts`,
        score: (
          <div
            className="badge-pill badge"
            style={{
              backgroundColor: pillBgColor(
                (takeoffPhaseData.scoreInfo["VspeedMaintained"] * 2) / 10
              ),
            }}
          >
            <div className="badge-text">
              {(
                (takeoffPhaseData.scoreInfo["VspeedMaintained"][0] * 2) / 10
              )?.toFixed(1)}
            </div>
          </div>
        ),
      },
    ],
    [takeoffPhaseData]
  );

  const tableInstanceTakeoff = useTable(
    {
      columns,
      data: maneuversTakeoffData,
    },
    useSortBy,
    useFlexLayout
  );

  const airborneClimbData = useMemo(
    () => [
      {
        weight: `10%`,
        // condition: `Consistent vertical speed`,
        // condition: `Consistent vertical speed above ${climbGoalRef.current[0]?.MaxVspeed} ft/min`,
        condition: (isGoalDataLoaded ? (`Consistent vertical speed above ${climbGoalRef.current[0]?.MaxVspeed} ft/min`) : (`Consistent vertical speed`)),
        // goal: `79 to 85 kts`,
        goal: (isGoalDataLoaded ? (`± ${climbGoalRef.current[0]?.ConsistentVspeedAbove} ft/min`) : "  ft/min"),
        actual: `${climbPhaseData.actual["ConsistentVspeedAbove"][0].toFixed(
          1
        )} to ${climbPhaseData.actual["ConsistentVspeedAbove"][1].toFixed(
          1
        )} ft/min`,
        score: (
          <div
            className="badge-pill badge"
            style={{
              backgroundColor: pillBgColor(
                (climbPhaseData.scoreInfo["ConsistentVspeedAbove"] * 10) / 10
              ),
            }}
          >
            <div className="badge-text">
              {(
                (climbPhaseData.scoreInfo["ConsistentVspeedAbove"][0] * 10) / 10
              )?.toFixed(1)}
            </div>
          </div>
        ),
      },
      {
        weight: `90%`,
        condition: `IAS maintained`,
        // goal: `79 to 85 kts`,
        goal: (isGoalDataLoaded ? (`${climbGoalRef.current[0]?.IASMaintained[0]} to ${climbGoalRef.current[0]?.IASMaintained[1]} kts`) : "  kts"),
        actual: `${climbPhaseData.actual["IASMaintained"][0].toFixed(
          1
        )} to ${climbPhaseData.actual["IASMaintained"][1].toFixed(1)} kts`,
        score: (
          <div
            className="badge-pill badge"
            style={{
              backgroundColor: pillBgColor(
                (climbPhaseData.scoreInfo["IASMaintained"] * 1.11) / 10
              ),
            }}
          >
            <div className="badge-text">
              {((climbPhaseData.scoreInfo["IASMaintained"][0] * 1.11) / 10)?.toFixed(1)}
            </div>
          </div>
        ),
      },
    ],
    [climbPhaseData]
  );

  const tableInstanceClimb = useTable(
    {
      columns,
      data: airborneClimbData,
    },
    useSortBy,
    useFlexLayout
  );

  const airborneCruiseData = useMemo(
    () => [
      {
        weight: `30%`,
        condition: `Consistent IAS`,
        // goal: `± 10 kts`,
        goal: (isGoalDataLoaded ? (`± ${cruiseGoalRef.current[0]?.ConsistentIAS} kts`) : "  kts"),
        actual: `${cruisePhaseData.actual["ConsistentIAS"][0].toFixed(
          1
        )} to ${cruisePhaseData.actual["ConsistentIAS"][1].toFixed(1)} kts`,
        score: (
          <div
            className="badge-pill badge"
            style={{
              backgroundColor: pillBgColor(
                (cruisePhaseData.scoreInfo["ConsistentIAS"] * 3.33) / 10
              ),
            }}
          >
            <div className="badge-text">
              {((cruisePhaseData.scoreInfo["ConsistentIAS"][0] * 3.33) / 10)?.toFixed(1)}
            </div>
          </div>
        ),
      },
      {
        weight: `10%`,
        condition: `Crossing altitude is within bounds `,
        // goal: `± 10 ft`,
        goal: (isGoalDataLoaded ? (`± ${cruiseGoalRef.current[0]?.CrossingAlt[0]} to ${cruiseGoalRef.current[0]?.CrossingAlt[1]} ft`) : "  ft"),
        actual: `${cruisePhaseData.actual["CrossingAlt"][0].toFixed(
          1
        )} to ${cruisePhaseData.actual["CrossingAlt"][1].toFixed(1)} ft`,
        score: (
          <div
            className="badge-pill badge"
            style={{
              backgroundColor: pillBgColor(
                (cruisePhaseData.scoreInfo["CrossingAlt"] * 10) / 10
              ),
            }}
          >
            <div className="badge-text">
              {((cruisePhaseData.scoreInfo["CrossingAlt"][0] * 10) / 10)?.toFixed(1)}
            </div>
          </div>
        ),
      },
      {
        weight: `30%`,
        condition: `Altitude maintained`,
        // goal: `± 10 ft`,
        goal: (isGoalDataLoaded ? (`± ${climbGoalRef.current[0]?.AltitudeMaintained} ft`) : "  ft"),
        actual: `${cruisePhaseData.actual["AltitudeMaintained"][0].toFixed(
          1
        )} to ${cruisePhaseData.actual["AltitudeMaintained"][1].toFixed(
          1
        )} ft`,
        score: (
          <div
            className="badge-pill badge"
            style={{
              backgroundColor: pillBgColor(
                (cruisePhaseData.scoreInfo["AltitudeMaintained"] * 3.33) / 10
              ),
            }}
          >
            <div className="badge-text">
              {(
                (cruisePhaseData.scoreInfo["AltitudeMaintained"][0] * 3.33) / 10
              )?.toFixed(1)}
            </div>
          </div>
        ),
      },
      {
        weight: `30%`,
        condition: `Consistent vertical speed`,
        // goal: `± 10 ft/min`,
        goal: (isGoalDataLoaded ? (`± ${climbGoalRef.current[0]?.ConsistentVspeed} ft/min`) : "  ft/min"),
        actual: `${cruisePhaseData.actual["ConsistentVspeed"][0].toFixed(
          1
        )} to ${cruisePhaseData.actual["ConsistentVspeed"][1].toFixed(1)} ft/min`,
        score: (
          <div
            className="badge-pill badge"
            style={{
              backgroundColor: pillBgColor(
                (cruisePhaseData.scoreInfo["ConsistentVspeed"] * 3.33) / 10
              ),
            }}
          >
            <div className="badge-text">
              {((cruisePhaseData.scoreInfo["ConsistentVspeed"][0] * 3.33) / 10)?.toFixed(
                1
              )}
            </div>
          </div>
        ),
      },
    ],
    [cruisePhaseData]
  );

  const tableInstanceCruise = useTable(
    {
      columns,
      data: airborneCruiseData,
    },
    useSortBy,
    useFlexLayout
  );

  const airborneDescentData = useMemo(
    () => [
      {
        weight: `30%`,
        condition: `Consistent IAS`,
        // goal: `± 10 kts`,
        goal: (isGoalDataLoaded ? (`± ${descentGoalRef.current[0]?.ConsistentIAS} kts`) : "  kts"),
        actual: `${descentPhaseData.actual["ConsistentIAS"][0].toFixed(
          1
        )} to ${descentPhaseData.actual["ConsistentIAS"][1].toFixed(1)} kts`,
        score: (
          <div
            className="badge-pill badge"
            style={{
              backgroundColor: pillBgColor(
                (descentPhaseData.scoreInfo["ConsistentIAS"] * 3.33) / 10
              ),
            }}
          >
            <div className="badge-text">
              {((descentPhaseData.scoreInfo["ConsistentIAS"][0] * 3.33) / 10)?.toFixed(
                1
              )}
            </div>
          </div>
        ),
      },
      {
        weight: `20%`,
        condition: `Consistent Vspeed`,
        // goal: `± 10 ft/min`,
        goal: (isGoalDataLoaded ? (`± ${descentGoalRef.current[0]?.ConsistentVspeed} ft/min`) : "  ft/min"),
        actual: `${descentPhaseData.actual["ConsistentVspeed"][0].toFixed(
          1
        )} to ${descentPhaseData.actual["ConsistentVspeed"][1].toFixed(1)} ft/min`,
        score: (
          <div
            className="badge-pill badge"
            style={{
              backgroundColor: pillBgColor(
                (descentPhaseData.scoreInfo["ConsistentVspeed"] * 5) / 10
              ),
            }}
          >
            <div className="badge-text">
              {(
                (descentPhaseData.scoreInfo["ConsistentVspeed"][0] * 5) / 10
              )?.toFixed(1)}
            </div>
          </div>
        ),
      },
      {
        weight: `20%`,
        condition: `Vspeed maintained`,
        // goal: `± 10 ft/min`,
        goal: (isGoalDataLoaded ? (`${descentGoalRef.current[0]?.VspeedMaintained[0]} to ${descentGoalRef.current[0]?.VspeedMaintained[1]} ft/min`) : "  ft/min"),
        actual: `${descentPhaseData.actual["VspeedMaintained"][0].toFixed(
          1
        )} to ${descentPhaseData.actual["VspeedMaintained"][1].toFixed(1)} ft/min`,
        score: (
          <div
            className="badge-pill badge"
            style={{
              backgroundColor: pillBgColor(
                (descentPhaseData.scoreInfo["VspeedMaintained"] * 5) / 10
              ),
            }}
          >
            <div className="badge-text">
              {(
                (descentPhaseData.scoreInfo["VspeedMaintained"][0] * 5) / 10
              )?.toFixed(1)}
            </div>
          </div>
        ),
      },
      {
        weight: `30%`,
        condition: `Pitch rate maintained`,
        // goal: `± 10 deg`,
        goal: (isGoalDataLoaded ? (`${descentGoalRef.current[0]?.PitchRateMaintained[0]} to ${descentGoalRef.current[0]?.PitchRateMaintained[1]} deg`) : "  deg"),
        actual: `${descentPhaseData.actual["PitchRateMaintained"][0].toFixed(
          1
        )} to ${descentPhaseData.actual["PitchRateMaintained"][1].toFixed(
          1
        )} deg`,
        score: (
          <div
            className="badge-pill badge"
            style={{
              backgroundColor: pillBgColor(
                (descentPhaseData.scoreInfo["PitchRateMaintained"] * 3.33) / 10
              ),
            }}
          >
            <div className="badge-text">
              {(
                (descentPhaseData.scoreInfo["PitchRateMaintained"][0] * 3.33) / 10
              )?.toFixed(1)}
            </div>
          </div>
        ),
      },
    ],
    [descentPhaseData]
  );

  const tableInstanceDescent = useTable(
    {
      columns,
      data: airborneDescentData,
    },
    useSortBy,
    useFlexLayout
  );

  const getIsScoreDataAvailable = () => {
    return isScoreDataAvailableRef.current;
  };

  return {
    getIsScoreDataAvailable,
    tableInstanceApproach,
    tableInstanceLanding,
    tableInstanceFlare,
    tableInstanceTaxi,
    tableInstanceTakeoff,
    tableInstanceClimb,
    tableInstanceCruise,
    tableInstanceDescent,
    getTaxiScore,
    getTakeoffScore,
    getClimbScore,
    getCruiseScore,
    getDescentScore,
    getApproachScore,
    getLandingScore,
    getFlareScore,
  };
};

export default ScoreTableInstances;
