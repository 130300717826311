import axiosInstance from "./baseService";
import { getLocalStorage } from "../common/utils/localStorageHandler";


const AUTH_API_URL = process.env.REACT_APP_AUTHENTICATED_URL;
//const AUTH_API_URL = "https://localhost:5000/api";


const flightOverview = (object: any) => {
  let authTokens = getLocalStorage("authTokens", "");
  // console.log(`object: `, object)
  return axiosInstance.post(AUTH_API_URL + "/FlightOverview/FlightOverview", object,
    {
      headers:
      {
        'Content-Type': 'application/json',
        'roleid': authTokens.roleid,
      }
    }
  ).then((response) => {
      return response.data;
    });
};

const FlyingDutyService = {
  flightOverview
};

export default FlyingDutyService;