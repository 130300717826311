import axios from "axios";
import { useAuth } from "../context/authProvider";
//import { setLoginData } from "../context/authProvider";
import axiosInstance from "./baseService";
import { getLocalStorage } from "../common/utils/localStorageHandler";

const DEBRIEF_API_URL = process.env.REACT_APP_AUTHENTICATED_URL;


// const [authToken, setAuthToken] = useState(getLocalStorage("authTokens", ""));

const uploadDebriefFile = (file: File, otherDetails: any) => {
  let authToken = getLocalStorage("authTokens", "");
  let formData = new FormData();
  formData.append("File", file)
  // formData.append("userid", "36")
  formData.append("userid", authToken.userid)
  formData.append("companyid", authToken.companyid)

  return axiosInstance
    .post(DEBRIEF_API_URL + "/Debrief/UploadCSV", formData,
      {
        headers:
        {
          'Content-Type': 'multipart/form-data',
          'roleid': authToken.roleid,

        },
        cancelToken: otherDetails.axiosSource.token

      }
    )
    .then((response) => {
      //  console.log(response.data)
      return response.data;
    }).catch((err: any) => {
      //  console.log("Upload file error")
      //  console.log(err)
      return Promise.reject({ err: err, fileDetails: otherDetails })
    })

}

const updateFileDetails = (fileDetails: any) => {
  //console.log(fileDetails)
  return axiosInstance
    .post(DEBRIEF_API_URL + "/Debrief/UpdateDebriefLog", fileDetails,
      {
        headers:
        {
          'Content-Type': 'application/json'
        }
      }
    )
    .then((response) => {
      //console.log(response.data)
      response.data["index"] = fileDetails["index"];
      response.data["file"] = fileDetails["file"];
      response.data["fileID"] = fileDetails["rowid"];
      //  console.log("In api")
      //  console.log(response.data)
      return response.data;
    }).catch((err: any) => {
      //  console.log("Upload file erro")
      //  console.log(err)
      return Promise.reject({ err: err, index: fileDetails["index"], file: fileDetails["file"], fileID: fileDetails["rowid"] })
    });
}

const deleteFileDetails = (fileId: any) => {
  return axiosInstance
    .post(DEBRIEF_API_URL + "/Debrief/DeleteUploadedLog", { "id": fileId },
      {
        headers: {
          'Content-Type': 'application/json'

        }
      }
    )
    .then((response) => {
      //response.data["index"]= index;
      return response.data;
    }).catch((err: any) => {
      //  console.log("Upload file erro")
      //  console.log(err)
      return Promise.reject({ err: err })
    });;
}

const getAirportList = (lat: Number, long: Number) => {

  return axiosInstance
    .post(DEBRIEF_API_URL + "/Debrief/GetAirportList", {
      "Latitude": lat, "Longitude": long
    },
      {
        headers: {
          'Content-Type': 'application/json'

        }
      }
    )
    .then((response) => {
      //console.log(response.data)
      return response.data.airportList;
    });
}

const getDebriefList = (obj: any) => {
  let authToken = getLocalStorage("authTokens", "");
  return axiosInstance
    // .post(DEBRIEF_API_URL + "/Debrief/DebriefLogList",obj,
    .post(DEBRIEF_API_URL + "/Debrief/DebriefLogList/?pageNo=1&itemsPerPage=4", obj,
      {
        headers: {
          'Content-Type': 'application/json',
          'roleid': authToken.roleid,

        }
      }
    )
    .then((response) => {
      // console.log(`service response: `, response);
      // return response.data;
      return response.data.data;
    });
}

const sendToRabbitMQ = (id: any) => {
  let formData = new FormData();
  formData.append("id", id)

  return axiosInstance
    .post(DEBRIEF_API_URL + "/Debrief/SaveToDbQueue", formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      }
    )
    .then((response) => {
      //console.log(response.data)
      return response.data;
    });
}

const getCsvFile = (url: any) => {
  return axios.get(url).then((res) => {
    return res.data
  }
  )
}

const getDebriefDetailsInfo = (id: any) => {
  let authToken = getLocalStorage("authTokens", "");
  let formData = new FormData();
  formData.append("id", id)

  return axiosInstance
    .post(DEBRIEF_API_URL + "/Debrief/DebriefDetailsInfo", formData,
      // .post(DEBRIEF_API_URL + "/Debrief/DebriefDetailsInfo/?pageNo=2&itemsPerPage=4",formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'roleid': authToken.roleid,

        }
      }
    )
    .then((response) => {
      //console.log(response.data)
      return response.data;
    });
};


const getRecentDebriefList = () => {
  let authData = getLocalStorage("authTokens", '');
  // console.log(`userid: `, authData.userid)
  return axiosInstance.get(DEBRIEF_API_URL + "/Debrief/RecentDebrief/" + authData.userid,{
    headers: {
      'roleid': authData.roleid,
    }
  })
    .then(response => {
      return response.data
    })
}

const updateRecentlyPlayed = (id: string) => {
  let formData = new FormData();
  formData.append("id", id)

  return axiosInstance
    .post(DEBRIEF_API_URL + "/Debrief/UpdateTimestamp", formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      }
    )
    .then((response) => {
      //console.log(response.data)
      return response.data;
    });
}


const getCsvJsonFromServer = (id: any) => {
  return axiosInstance
    .get(DEBRIEF_API_URL + "/Debrief/SendJsonObject/" + id, {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      }
    }).then((response) => {
      return response.data
    })
}

const getScoreGoalsInfo = (id: string) => {
  // console.log(`id: `, id);
  // let authData = getLocalStorage("authTokens","")
  return axiosInstance.get(DEBRIEF_API_URL + "/Debrief/GetGoalData/" + id).then((response) => {
    // console.log(`getScoreGoalsInfo response: `, response)
    return response.data
  })
}

const getAllLogs = () => {
  let authToken = getLocalStorage("authTokens", "");
  return axiosInstance.get(DEBRIEF_API_URL + `/Debrief/GetAllLogs/` + authToken.companyid)
    .then(response => {
      return response
    })
}

const DebriefService = {
  uploadDebriefFile,
  updateFileDetails,
  deleteFileDetails,
  getAirportList,
  getDebriefList,
  getCsvFile,
  getDebriefDetailsInfo,
  sendToRabbitMQ,
  getCsvJsonFromServer,
  getRecentDebriefList,
  updateRecentlyPlayed,
  getScoreGoalsInfo,
  getAllLogs,
}

export default DebriefService;