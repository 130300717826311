import axios from "axios";
import { useAuth } from "../context/authProvider";
//import { setLoginData } from "../context/authProvider";

const AUTH_API_URL = process.env.REACT_APP_AUTH_BASE_URL;
//const AUTH_API_URL = "https://localhost:44338/api"

  const  checkEmail = (user : string) => 
  {
        return axios
          .post(AUTH_API_URL+"/ResetPassword/VerifyEmail", { "Username": user }, 
          {
             headers: 
            {
              'Content-Type': 'application/json'
            }
          }
        )
          .then((response) => 
          {
            // if (response.data.username) {
            //   //localStorage.setItem("user", JSON.stringify(response.data));
            // }
            return response.data;
          });
  };
    
    const  login = (user : string, pass : string) => 
    {
          return axios
          .post(AUTH_API_URL+"/authorization/Login", {"Username" : user, "Password" : pass}, 
            {
            headers: 
            {
              'Content-Type': 'application/json'
            }
           }
        )
          .then((response) => {
         if(response.status===200){
           
         }
            return response.data;
          });
      };

    const  sendEmail = (user : string) => {
        return axios
          .post(AUTH_API_URL+"/ResetPassword/SendEmail", {"Username" : user}, 
          { 
            headers: 
            {
            'Content-Type': 'application/json'
            }
          }
        )
          .then((response) => 
          {
            return response.data;
          });
      };

      const  verifyCode = (code : string,userId:String) => {
        // console.log(`verifyCode code: ` , code)
        // console.log(`verifyCode user id: `, userId)
        return axios
          .post(AUTH_API_URL+"/ResetPassword/VerifyCode", {"Code" : code,"Userid":userId}, 
          {
            headers: 
             {
              'Content-Type': 'application/json'
              }
          }
        )
          .then((response) => 
          {
            return response.data;
          });
      };

      const  changePassword = (code : string, newPassword : string,id:string) => {
        return axios
          .post(AUTH_API_URL+"/ResetPassword/ChangePassword", {"Code" : code, "Newpassword" : newPassword, "Userid":id}, 
          {
            headers: {
              'Content-Type': 'application/json'
              }
          }
        )
          .then((response) => 
          {
            return response.data;
          });
      };

      const AuthService = 
      {
        checkEmail,
        login,
        sendEmail,
        verifyCode,
        changePassword
      }
      
      export default AuthService;