import SettingsSideMenu from "./SettingsSideMenu";
import './Settings.scss'
import { Outlet } from "react-router-dom";


const Settings = () => {

    return (
        <div className="settings-container" style={{ width: `calc(100vw - 48px)`}}>
            <SettingsSideMenu />
            {/* <div className="settings-wrap-container"> */}
                <Outlet />
            {/* </div> */}
        </div>
    );
}

export default Settings;