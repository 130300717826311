//Cannot be named `Notifications` because it's considered Global Module

const Notification = () => {

    return (
        <>
            Notification Details
        </>
    );
}

export default Notification;