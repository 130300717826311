import { Modal, Button } from "react-bootstrap";

const ReactivateAccountModal = (props:any) => {

    const { onHide, onClickReactivate, name, type } = props;

    const reactivateAccount = () => {
        onClickReactivate();
        onHide();
    }

    const styles = {
        fontFamily: 'IBMPlexSans',
        fontSize: '16px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.5',
        letterSpacing: 'normal',
        color: '#666',
    };

    const descText1 = (type === `Aircraft` ? `aircraft` : `pilot's/instructor's account`)
    const descText2 = (type === `Aircraft` ? `aircraft` : `account from your organisation`)

    return (
        <>
            <Modal {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered >
                <Modal.Header closeButton className="assign-modal-header" onClick={() => onHide()} >
                    {/* <div className="assign-modal-title mx-2" >Reactivate {type}</div> */}
                </Modal.Header>
                <Modal.Body>
                    <div className="delete-modal-title" style={{ backgroundColor: `#f5f8ff`}}>
                        <span style={{ fontSize: `20px`, fontWeight: `600` }}>Reactivate {type}</span>
                        <br />
                        <span style={styles}>Reactivate {descText1} from the dashboard</span>
                    </div>
                    
                    <div className="delete-modal-body">
                        <strong>Are you ceratin you want to reactivate the selected {descText2}?</strong>
                        <br /><br />
                        {type} Selected - {name}
                        <br />
                        <span style={{ color: `white` }}>.</span>
                    </div>
                </Modal.Body>
                <Modal.Footer className="assign-modal-footer">
                    <Button variant="light border" onClick={() => onHide()}>Close</Button>
                    <Button variant="primary" onClick={() => reactivateAccount()} >Yes, Reactivate {type}</Button>
                </Modal.Footer>

            </Modal>
        </>
    )
}

export default ReactivateAccountModal;