const IconReports = (props : any) =>{
    return (
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>Reports</title>
            <g id="Giga-Plus" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="[Giga-Plus]Debrief---No-Logs" transform="translate(-12.000000, -454.000000)">
                    <g id="//-Tab-Bar" transform="translate(0.000000, 56.000000)">
                        <g id="report" transform="translate(0.000000, 386.000000)">
                            <g id="icon" transform="translate(8.000000, 8.000000)">
                                <g id="Icon---Reports" transform="translate(4.000000, 4.000000)">
                                    <rect id="bounding-box" fillOpacity="0.01" fill="#FFFFFF" x="0" y="0" width="24" height="24"></rect>
                                    <path d="M11.3905473,3 L11.3905473,9.22669284 C11.3905473,10.2688911 11.907131,10.7899902 12.9402985,10.7899902 L19,10.7899902 L19,18.3238469 C19,19.2129539 18.7808872,19.8812561 18.3426617,20.3287537 C17.9044362,20.7762512 17.25,21 16.3793532,21 L7.62064677,21 C6.75580431,21 6.10281924,20.7762512 5.66169154,20.3287537 C5.22056385,19.8812561 5,19.2129539 5,18.3238469 L5,5.68498528 C5,4.79587831 5.22056385,4.12610402 5.66169154,3.67566241 C6.10281924,3.2252208 6.75580431,3 7.62064677,3 L11.3905473,3 Z M10.5,17 L7.5,17 C7.22385763,17 7,17.2238576 7,17.5 C7,17.7761424 7.22385763,18 7.5,18 L10.5,18 C10.7761424,18 11,17.7761424 11,17.5 C11,17.2238576 10.7761424,17 10.5,17 Z M14.5,15 L7.5,15 C7.22385763,15 7,15.2238576 7,15.5 C7,15.7761424 7.22385763,16 7.5,16 L14.5,16 C14.7761424,16 15,15.7761424 15,15.5 C15,15.2238576 14.7761424,15 14.5,15 Z M16.5,13 L7.5,13 C7.22385763,13 7,13.2238576 7,13.5 C7,13.7761424 7.22385763,14 7.5,14 L16.5,14 C16.7761424,14 17,13.7761424 17,13.5 C17,13.2238576 16.7761424,13 16.5,13 Z M12.5572139,3.08832188 C12.7371476,3.11187439 12.9185323,3.18842002 13.1013682,3.31795878 C13.284204,3.44749755 13.4742952,3.61530913 13.6716418,3.82139352 L18.1902985,8.47595682 C18.3992537,8.68792934 18.5646766,8.88370952 18.6865672,9.06329735 C18.8084577,9.24288518 18.8810116,9.42688911 18.9042289,9.61530913 L13.0186567,9.61530913 C12.7110282,9.61530913 12.5572139,9.4592738 12.5572139,9.14720314 Z" id="shape" fill={props.fill} fillRule="nonzero"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
} 

export default IconReports