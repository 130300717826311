import { Button } from "react-bootstrap";
import "../../Reports.scss"

const EGCAUploadReport = () =>{
    return(
            <section className="reports-wrap">
                <section className="top-section">
                    <div className="breadcrumb">
                        Data eGCA Upload Report...
                    </div>
                    <div className="buttons-wrapper">
                        <Button className="f360-btn-with-icon filter-btn">
                        <i className="btn-icon icon-filter"></i>Last 7 days</Button>
                        <Button variant="secondary" className="f360-btn-with-icon-light download-btn me-3">
                        <i className="btn-icon icon-download"></i>
                            Download Report
                        </Button>
    
                    </div>
                </section>
            </section>
        )
}

export default EGCAUploadReport;