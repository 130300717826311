import Cesium3D from "../cesium3dView/CesiumComp"
import { GetJulianDateFromTimeStamp, clockViewModel } from "../../../../common/utils/cesiumDataHandler"
import { useEffect, useState } from "react"
import "./specialTabs.scss"
import { InitViewer2 } from "../../../../common/utils/cesiumInit"
import { LoadTerrain } from "../../../../common/utils/cesiumInit"
import { AddVariablesToSampleProperty } from "../../../../common/utils/cesiumDataHandler"
import { SetClock } from "../../../../common/utils/cesiumDataHandler"
import { sampleTerrainMostDetailed, Cartographic, JulianDate, ClockRange, ClockStep } from "cesium"
import { TabDataBrodacast, TabTimeSyncBroadcast } from "../../../../common/utils/utils"
import { TabDataMsgType } from "../../../../common/utils/utils"

const TabPfd = () => {
    const [data, setData] = useState<any>(null)
    const [viewer, setViewer] = useState<any>();
    const [isViewerLoaded, setIsViewerLoaded] = useState(false)
    const [clockState, setClockState] = useState<boolean>();
    

    TabDataBrodacast.onmessage = (msg) => {
      // console.log(`Tab msg :` , msg)
      if(msg.id === TabDataMsgType.setPfdCsv){
        setData(msg.data)
      }
      if(msg.id === TabDataMsgType.setClockState){
        setClockState(msg.data);
        clockViewModel.canAnimate = msg.data
        clockViewModel.shouldAnimate = msg.data
      }
      if(msg.id === TabDataMsgType.closeNewTabs){
        window.close()
      }
      if(msg.id === TabDataMsgType.setClockSpeed){
        clockViewModel.multiplier = msg.data
      }
      if(msg.id === TabDataMsgType.resetTab){
        resetTab()
      }
    }

    TabTimeSyncBroadcast.onmessage = (time) => {
     //console.log("On message called in PFD")
      // if (Math.abs(JulianDate.secondsDifference(clockViewModel.currentTime, time)) > 0.001) {
      //   clockViewModel.currentTime = time;
      // }

     if(JulianDate.secondsDifference(clockViewModel.currentTime, time) > 1 || JulianDate.secondsDifference(clockViewModel.currentTime, time) < -1){
      clockViewModel.currentTime = time
     }
    }


    useEffect(() => {
      TabDataBrodacast.postMessage({ id: TabDataMsgType.getClockState, data:"" })
    }, [viewer])

    const getCsvData= () => {
      //console.log("request for csv")
      TabDataBrodacast.postMessage({id:TabDataMsgType.getPfdCsv, data:""})
    }

    useEffect(()=>{
        try{
        if(!data){
          //console.log("request for csv")
          TabDataBrodacast.postMessage({id : TabDataMsgType.getPfdCsv, data: ""})
        }
        
        if(isViewerLoaded && data){
          //console.log("new csv with old viewer")
          AddVariablesToSampleProperty(data)
          SetClock(data, viewer, clockState)
        }

        if(!isViewerLoaded && data){
          setIsViewerLoaded(true)
          //console.log("enter a viwer")

            InitViewer().then((viewer)=>{
              let interval:any;
                  interval = setInterval(()=>{
                    if(data !== null){
                      let pointArr = []
                      if(data !== null){ 
                        
                      for (let index = 0; index < data.length; index++) {
                        pointArr.push( Cartographic.fromRadians(
                            +data[index].Longitude,
                            +data[index].Latitude,
                        ));
          
                      };}
                      
                      viewer.scene.globe.depthTestAgainstTerrain = true
                      let calibarHeight=0;
          
                      //sampleTerrainMostDetailed(viewer.terrainProvider, pointArr)
                        //.then(() => {
                            AddVariablesToSampleProperty(data)
                            SetClock(data, viewer, clockState)
                            //updateHeightCallBack(response)
                           // console.log("from detailed terrain provider")
                          //});
                      clearInterval(interval)
                    }
                  }, 200)                
                    //  AddVariablesToSampleProperty(data)
                    //  SetClock(data, viewer)
                      
        }).catch(()=>{})
        }
        }catch(err){console.log(err)}
    }, [data])

window.onbeforeunload = function () {
  //TabChannel.postMessage({id:"pfd-close", data:""})
};

const InitViewer = async (): Promise<any> => {
        let viewer2
        if (!viewer2) {
          let terrainProvider = await LoadTerrain();
          if(terrainProvider){
            //console.log("got terrainProvider")
            viewer2 = InitViewer2(terrainProvider);
            setViewer(viewer2);
          }else{
            //console.log("not got terrainProvider")
          }
      
        } else {
          viewer2 = viewer;
        }
        //console.log(viewer2)
        return viewer2
        //updateHeightCallBack(response)
  }

  const resetTab = () => {
    setTimeout(()=>{
      setData(null)
    }, 500)
  }

  return (
      <div className="spl-tab">
          <div className='tab-parent' id="pfdLayerParent">
          <Cesium3D callback={null} currentCsvFileData={data} setViewer={setViewer} />
          <canvas id='pfdLayer' className="w-100 h-100"></canvas>
          </div>
      </div>
  )
}

export default TabPfd;