// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-bar-outer {
  width: 100px;
  height: 10px;
  flex: auto;
  display: flex;
  flex-direction: row;
  border-radius: 0.5em;
  overflow: hidden;
  background-color: rgba(36, 62, 128, 0.16);
}

.progress-bar-inner {
  /* You can change the \`width\` to change the amount of progress. */
  width: 90%;
  height: 100%;
  background-color: #477afe;
}

.progress-bar-inner2 {
  /* You can change the \`width\` to change the amount of progress. */
  width: 10%;
  height: 100%;
  background-color: rgba(36, 62, 128, 0.16);
}

.progress-bar-inner3 {
  /* You can change the \`width\` to change the amount of progress. */
  width: 10%;
  height: 100%;
  background-color: #477afe;
}

.progress-bar-inner4 {
  /* You can change the \`width\` to change the amount of progress. */
  width: 0%;
  height: 100%;
  background-color: rgba(36, 62, 128, 0.16);
}

.progress-bar-outer div {
  animation: loadbar1 3s;
  -webkit-animation: loadbar1 3s;
}

@keyframes loadbar {
  0% {
    width: 10%;
    left: 0;
    right: 0;
  }
}
@keyframes loadbar1 {
  0% {
    width: 0%;
    left: 0;
    right: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/common/components/progressBar/progressBar.scss"],"names":[],"mappings":"AACA;EACI,YAAA;EACA,YAAA;EACA,UAAA;EACA,aAAA;EACA,mBAAA;EACA,oBAAA;EACA,gBAAA;EACA,yCAAA;AAAJ;;AAEE;EACE,iEAAA;EACA,UAAA;EACA,YAAA;EACA,yBAAA;AACJ;;AACE;EACE,iEAAA;EACA,UAAA;EACA,YAAA;EACA,yCAAA;AAEJ;;AACE;EACE,iEAAA;EACA,UAAA;EACA,YAAA;EACA,yBAAA;AAEJ;;AAAE;EACE,iEAAA;EACA,SAAA;EACA,YAAA;EACA,yCAAA;AAGJ;;AAAE;EACM,sBAAA;EACF,8BAAA;AAGN;;AAAE;EACI;IAAI,UAAA;IAAW,OAAA;IAAO,QAAA;EAM1B;AACF;AALE;EACE;IAAI,SAAA;IAAU,OAAA;IAAO,QAAA;EAUvB;AACF","sourcesContent":["\n.progress-bar-outer {\n    width: 100px;\n    height: 10px;\n    flex: auto;\n    display: flex;\n    flex-direction: row;\n    border-radius: 0.5em;\n    overflow: hidden;\n    background-color:rgba(36, 62, 128, 0.16);\n  }\n  .progress-bar-inner {\n    /* You can change the `width` to change the amount of progress. */\n    width: 90%;\n    height: 100%;\n    background-color: #477afe;\n  }\n  .progress-bar-inner2 {\n    /* You can change the `width` to change the amount of progress. */\n    width: 10%;\n    height: 100%;\n    background-color: rgba(36, 62, 128, 0.16);\n  }\n  \n  .progress-bar-inner3 {\n    /* You can change the `width` to change the amount of progress. */\n    width: 10%;\n    height: 100%;\n    background-color: #477afe;\n  }\n  .progress-bar-inner4 {\n    /* You can change the `width` to change the amount of progress. */\n    width: 0%;\n    height: 100%;\n    background-color: rgba(36, 62, 128, 0.16);\n  }\n  \n  .progress-bar-outer div {\n        animation:loadbar1 3s;\n      -webkit-animation:loadbar1 3s;\n  }\n  \n  @keyframes loadbar {\n      0% {width: 10%;left:0;right:0}\n  }\n  @keyframes loadbar1 {\n    0% {width: 0%;left:0;right:0}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
