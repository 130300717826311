const IconFlights = (props : any) => {
    return(
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <title>All Flights</title>
        <g fill="none" fillRule="evenodd">
            <path fillOpacity=".01" fill="#F7F8FA" d="M0 0h24v24H0z"/>
            <path d="m20.837 8.85-4.616-.325h-3.174l-.002-.775-.09-.75a20.88 20.88 0 0 1-.06-.58c-.032-.407-.336-.61-.549-.683l-.18-.608A.174.174 0 0 0 12.003 5a.177.177 0 0 0-.165.13l-.178.607c-.213.07-.521.274-.553.68l-.025.27-.126 1.083v.754H7.768l-4.605.327C3.07 8.857 3 8.938 3 9.039v1.95c0 .093.064.172.149.185l4.63.694h3.197l.475 4.567-2.29.48a.186.186 0 0 0-.14.183v1.313c0 .092.064.17.147.186l2.426.403h.013a.166.166 0 0 0 .115-.048l.28-.267.276.267c.038.037.09.054.14.046l2.412-.4a.186.186 0 0 0 .147-.187v-1.313a.184.184 0 0 0-.139-.184l-2.286-.476.477-4.57 3.214-.002 4.608-.692a.184.184 0 0 0 .149-.186V9.037c0-.099-.07-.18-.163-.186zm-8.442 3.501c-.103.112-.27.151-.393.151s-.29-.04-.39-.151a.332.332 0 0 1-.085-.24c.001-.171.056-.223.09-.237l.027-.006h.72c.016 0 .113.015.115.242a.351.351 0 0 1-.047.19l-.037.051zm.257-4.017c0 .052-.038.094-.086.094h-1.127c-.048 0-.087-.043-.087-.094v-.158c0-.022.007-.043.02-.06.167-.224.391-.347.63-.347.24 0 .463.123.63.347a.1.1 0 0 1 .02.06v.158z" fill={props.fill} fillRule="nonzero"/>
        </g>
    </svg>
    )
}
export default IconFlights