import { useEffect, useState } from 'react'
import './Resetpass.scss'
import { useTranslation } from 'react-i18next'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { isValidEmail } from '../../../../common/utils/validator'
import LoadSpinner from '../../../../common/components/loadSpinner/loadSpinner'
import AuthService from '../../../../services/auth.service';
import successICon from "../../../../assets/images/authentication/icon-success.svg";
import { useAuth } from '../../../../context/authProvider'

const Resetpass = () => {
    const { t } = useTranslation()
    const params = useParams()
    const navigate = useNavigate()
    const activePage = useLocation();
    let { client } = useParams();
    const { authTokens, setLoginData } = useAuth();
    const timeInterval = 60;

    const [email, setEmail] = useState("");
    const [isEmailPresent, setIsEmailPresent] = useState(false);
    const [isEmail, setIsEmail] = useState(false)
    const [isEmailFound, setIsEmailFound] = useState(true);

    const [isTempcodeValid, setIsTempcodeValid] = useState(false);
    const [tempcode, setTempcode] = useState("");
    const [isTempcodeVerifiied, setIsTempcodeVerified] = useState(false);
    const [isTempcodeError, setIsTempcodeError] = useState(false);

    const [passwordType, setPasswordType] = useState("password")
    const [cnfpassType, setCnfPassType] = useState("passoword");
    const [isPassValid, SetIsPassValid] = useState(true);
    const [isPassMatch, setISPassMatch] = useState(true);
    const [pass, SetPass] = useState("");
    const [cnfPass, SetCnfPass] = useState("");
    const [isPassLoading, SetIsPassLoading] = useState(false);

    const [returnCode, SetReturnCode] = useState("");
    //   const [isTempcodeSent, SetIsTempCodeSent] = useState(false);
    const [clockCount, setClockCount] = useState(timeInterval);
    const [clockInt, setClockInt] = useState<NodeJS.Timer>();
    const [userID, SetUserID] = useState("");

    /**
  * Handles the change event for the email input field.
  * @param {React.ChangeEvent<HTMLInputElement>} e - The event object for the input change.
  * @returns {void}
  */
    const emailHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
        // Set flag indicating that email is found
        setIsEmailFound(true);

        // Check if the input value is a valid email
        if (isValidEmail(e.target.value)) {
            // Set the email state if it's a valid email
            setEmail(e.target.value);
        } else {
            // Clear the email state if the input is not a valid email
            setEmail("");
            //setIsEmail(false);
        }
    };


    /**
 * Submits the email for further processing, checks its existence, and sends an email.
 * @returns {void}
 */
    const submitEmail = () => {
        // Set flags indicating that the email is being processed
        setIsEmail(true);

        // Call AuthService to check the existence of the email
        AuthService.checkEmail(email)
            .then((response) => {
                // Set user ID and send email if the email exists
                SetUserID(response.userid);
                AuthService.sendEmail(email)
                    .then((res) => {
                        // Set flags indicating the success of email submission
                        setIsEmailPresent(true);
                        setIsEmail(false);
                    })
                    .catch((err) => {
                        // Handle email sending error by logging and resetting flags
                        console.log("Error ");
                        console.log(err);
                        setIsEmailFound(false);
                        setIsEmail(false);
                        setEmail("");
                    });
            })
            .catch((error) => {
                // Handle email existence check error by logging and resetting flags
                console.log("Error ");
                console.log(error);
                setIsEmailFound(false);
                setIsEmail(false);
                setEmail("");
            });
    };


    /**
 * Handles the change event for the temporary code input field.
 * @param {React.ChangeEvent<HTMLInputElement>} e - The event object for the input change.
 * @returns {void}
 */
    const handleTempcode = (e: React.ChangeEvent<HTMLInputElement>) => {
        // Get the code from the input value
        let code = e.target.value;

        // Reset the temporary code error flag
        setIsTempcodeError(false);

        // Check if the code length is greater than or equal to 6
        if (code.length >= 6) {
            // Set the temporary code state if the length is sufficient
            setTempcode(code);
        } else {
            // Clear the temporary code state if the length is insufficient
            setTempcode("");
        }
    };


    /**
 * Verifies the temporary code using AuthService and updates states accordingly.
 * @returns {void}
 */
    const verifyTempcode = () => {
        // Set flag indicating that the temporary code is valid
        setIsTempcodeValid(true);

        // Call AuthService to verify the code
        AuthService.verifyCode(tempcode, userID)
            .then((res) => {
                // console.log(`verifyCode res: `, res)
                // Set flags indicating the successful verification and reset valid flag
                setIsTempcodeVerified(true);
                setIsTempcodeValid(false);
                SetReturnCode(res.newcode);
            })
            .catch((err) => {
                // Handle verification error by logging and setting error flag
                console.log(err);
                setIsTempcodeError(true);
                setIsTempcodeValid(false);
            });
    };



    /**
 * Toggles the visibility of the confirm password in the input field between "text" and "password" types.
 * @returns {void}
 */
    const cnfPassToggel = () => {
        // Toggle the confirm password type between "text" and "password"
        setCnfPassType(cnfpassType === "password" ? "text" : "password");
    };


    /**
 * Toggles the visibility of the password in the input field between "text" and "password" types.
 * @returns {void}
 */
    const togglePass = () => {
        // Toggle the password type between "text" and "password"
        setPasswordType(passwordType === "password" ? "text" : "password");
    };

    function checkString(str:string) {
        const regex = /^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/;
        if (regex.test(str)) {
          return true;
        } else {
          return false;
        }
      }

    /**
 * Handles the change event for the password input field.
 * @param {React.ChangeEvent<HTMLInputElement>} e - The event object for the input change.
 * @returns {void}
 */
    const handlePass = (e: React.ChangeEvent<HTMLInputElement>) => {
        // Get the temporary password from the input value
        let tempPass = e.target.value;

        // Reset the password match flag
        setISPassMatch(false);

        // Check if the temporary password length is greater than or equal to 5
        if (checkString(tempPass)) {
            // Set the password validity flag to true and update the password state
            SetIsPassValid(true);
            SetPass(tempPass);

            // Check if the temporary password matches the confirm password
            if (tempPass === cnfPass) {
                setISPassMatch(true);
            }
        } else {
            // Reset the password validity flag and clear the password state
            SetIsPassValid(false);
            SetPass("");
        }
    };


    /**
 * Handles the change event for the confirm password input field.
 * @param {React.ChangeEvent<HTMLInputElement>} e - The event object for the input change.
 * @returns {void}
 */
    const handleCnfPass = (e: React.ChangeEvent<HTMLInputElement>) => {
        // Get the temporary password from the input value
        let tempPass = e.target.value;

        // Check if the temporary password matches the password
        if (tempPass === pass) {
            // Set the password match flag to true and update the confirm password state
            setISPassMatch(true);
            SetCnfPass(tempPass);
        } else {
            // Reset the password match flag and clear the confirm password state
            setISPassMatch(false);
            SetCnfPass("");
        }
    };


    /**
 * Updates the password using AuthService and performs login after successful password change.
 * @returns {void}
 */
    const updatePass = () => {
        // Set flag indicating that password loading is in progress
        SetIsPassLoading(true);

        // Call AuthService to change the password using the return code and user ID
        // console.log(`return code: `, returnCode)
        // console.log(`pass: `, pass)
        // console.log(`userID: `, userID)
        AuthService.changePassword(returnCode, pass, userID)
            .then((res) => {
                // Perform login after successful password change
                // console.log(`Login Email: `, email)
                // console.log(`Login Pass: `, pass)
                AuthService.login(email, pass)
                    .then((response) => {
                        // Set login data and navigate to the dashboard based on the client or default path
                        setLoginData(response);
                        SetIsPassLoading(false);
                        if (client !== undefined) {
                            navigate(`/${client}/dashboard/`);
                        } else {
                            navigate(`/dashboard/`);
                        }
                    })
                    .catch((err) => {
                        // Handle login error by logging and resetting loading flag
                        console.log("Error ");
                        console.log(err);
                        SetIsPassLoading(false);
                    });
            })
            .catch((err) => {
                // Handle password change error by logging and resetting loading flag
                console.log("Error ");
                console.log(err);
                SetIsPassLoading(false);
            });
    };



    /**
 * Sends the email again and sets up the countdown timer.
 * @returns {void}
 */
    const sendCodeAgain = () => {
        // Check if the clock count has reached the time interval
        if (clockCount === timeInterval) {
            // Call AuthService to send the email again
            AuthService.sendEmail(email)
                .then((res) => {
                    // Set up the countdown timer with interval of 1 second
                    let clockInt = setInterval(() => {
                        setClockCount(clockCount => clockCount - 1);
                    }, 1000);
                    // Set the countdown timer interval
                    setClockInt(clockInt);
                })
                .catch((err) => {
                    // Handle email sending error by logging
                    console.log("Error ");
                    console.log(err);
                });
        }
    };



    /**
 * useEffect hook to reset the clock count and clear the interval when it reaches 0.
 */
    useEffect(() => {
        if (clockCount === 0) {
            setClockCount(clockCount => clockCount + timeInterval);
            clearInterval(clockInt);
        }
    }, [clockCount]);



    return (
        <>
            {(activePage.pathname.split('/').pop() === 'reset-password') && <button onClick={() => {
                params.client === undefined ? navigate('/') : navigate('/' + params.client)
            }} className='btn-back d-flex btn-fixed'></button>}
            {!isEmailPresent &&
                <div className='content-wrap'>
                    <p className='hi-there mt-5'>{t('resetpass.reset_pass')}</p>
                    <p className='enter-email'>{t('resetpass.reset_pass_instruction')}</p>
                    <form onSubmit={(event) => event.preventDefault()}>
                        <label className='email-label mt-4'>{t('login.email_id')}</label>
                        <input type="email" className={`d-block ${!isEmailFound ? "input-email-notfound" : "input-email"}`} placeholder={t('login.email_placeholder')} onChange={emailHandle} onCopyCapture={(e) => emailHandle} />
                        <p className="error  mb-0 mt-1">{!isEmailFound && <span>{t('resetpass.account_not_found')}</span>}</p>
                        <div className="btn-wrapper">  <button className={`d-block margin-css ${!isEmail ? "input-button" : "input-button-disabled"}`} onClick={submitEmail} disabled={!email}>{t('resetpass.send_code')}<div className={isEmail ? "spinnnerWrap" : "d-none"} ><LoadSpinner /></div></button></div>
                    </form>
                </div>}

            {isEmailPresent && !isTempcodeVerifiied && <div className='content-wrap'>
                <p className='hi-there mt-5'>{t('resetpass.check_your_email')}</p>
                <p className='enter-email'>{t('resetpass.temp_code_first_part')} <b>"{email}"</b> {t('resetpass.temp_code_second_part')}</p>
                <form onSubmit={(event) => event.preventDefault()}>
                    <label className='email-label mt-4'>{t('resetpass.temp_code')}</label>
                    <input type="text" className="d-block input-email" placeholder={t('resetpass.temp_code_placeholder')} onChange={handleTempcode} onCopyCapture={(e) => handleTempcode} />
                    {/* <p className="error  mb-0 mt-1">{isTempcodeError && <span className="opacity-24">{t('resetpass.invalid_temp_code')}</span>}</p> */}
                    <p className="error  mb-0 mt-1">{isTempcodeError && <span className="opacity-20">{t('resetpass.invalid_temp_code')}</span>}</p>
                    <div className="btn-wrapper"> <button className={`d-block margin-css ${!isTempcodeValid ? "input-button" : "input-button-disabled"}`} disabled={!tempcode} onClick={verifyTempcode}>{t('resetpass.validate_code')}<div className={isTempcodeValid ? "spinnnerWrap" : "d-none"} ><LoadSpinner /></div></button></div>
                </form>
                <p className='didnt-receive-mail'>{t('resetpass.didnt_receive_email')} <br></br> <a href='#/' className={`${clockCount !== timeInterval ? "disabled-link" : ""}`} onClick={sendCodeAgain}>{t('resetpass.resend_email')}</a> {!(clockCount === timeInterval) && <span> ({clockCount} secs) </span>}</p>
                {!(clockCount === timeInterval) && <div className="main-bg"><div className="side-fill"></div> <img src={successICon} className='svg-success'></img><div className='text-successsent'><span className='suc-bold'>{t('resetpass.success')} </span>{t('resetpass.resent_temp_code')} </div></div>}

            </div>
            }
            {isTempcodeVerifiied &&
                <div className='content-wrap'>
                    <form onSubmit={(event) => event.preventDefault()}>
                        <p className='hi-there mt-5'>{t('resetpass.reset_your_pass')}</p>
                        <p className='enter-email'>{t('resetpass.please_enter_new_pass')}</p>
                        <label className='email-label mt-4'>{t('resetpass.new_pass')}</label>
                        <div className='pass-wrapper'>
                            <input type={passwordType} className="d-block input-pass" placeholder={t('resetpass.new_pass_placeholder')} onChange={handlePass} />
                            <button type='button' className={passwordType === 'password' ? 'btn-pass-toggle' : 'btn-pass-toggle hide-pass'} onClick={togglePass} ></button>
                        </div>
                        <p className="pass-err  mb-1">{!isPassValid && <span>{t('resetpass.need_atleast_8_char')}</span>}</p>
                        <label className='email-label mt-2'>{t('resetpass.confirm_new_pass')}</label>
                        <div className='pass-wrapper'>
                            <input type={cnfpassType} className="d-block input-pass" placeholder={t('resetpass.retype_pass_placeholder')} onChange={handleCnfPass} />
                            <button type='button' className={cnfpassType === 'password' ? 'btn-pass-toggle ' : 'btn-pass-toggle hide-pass'} onClick={cnfPassToggel}></button>
                        </div>
                        <p className="error  mb-1">{!isPassMatch && <span>{t('resetpass.pass_not_match')}</span>}</p>
                        <div className="btn-wrapper"> <button className={`d-block mt-4 ${!isPassLoading ? "input-button" : "input-button-disabled"}`} disabled={!(isPassValid && isPassMatch && pass)} onClick={updatePass}>{t('resetpass.update_login')}<div className={isPassLoading ? "spinnnerWrap" : "d-none"} ><LoadSpinner /></div></button></div>
                    </form>
                </div>
            }
        </>
    )
}

export default Resetpass