import axiosInstance from "./baseService";
import { getLocalStorage } from "../common/utils/localStorageHandler";

const API_URL = process.env.REACT_APP_AUTHENTICATED_URL;

//let authTokens = getLocalStorage("authTokens", "companyid")
// const [authTokens, setAuthTokens] = useState(getLocalStorage("authTokens", ""));

//gets list of pilot types
const getPilotType = () => {
    return axiosInstance.get(API_URL + "/PilotInfo/PilotType/1")
        .then((response) => {
            return response.data
        })
}

//gets list of registered pilots
const getPilotList = () => {
    let authToken: any = getLocalStorage("authTokens", "")
    return axiosInstance.get(API_URL + "/UserCompany/GetPilotList/" + authToken.companyid)
        .then(response => {
            // console.log(`Pilot List: `, response);
            return response.data
        })
}

const getTagList = () => {
    let authToken: any = getLocalStorage("authTokens", "")
    return axiosInstance.get(API_URL + `/ManageAircraftPilot/GetTagList/` + authToken.companyid,
        {
            headers: {
                'roleid': authToken.roleid
            }
        }).then(response => {
            return response;
        })

    // return axiosInstance.get(API_URL + `/ManageAircraftPilot/GetTagList/`+authTokens.companyid).then(response => {
    //         return response;
    //     })
}

const getPilotInstructorInfo = (type: any) => {
    let authToken: any = getLocalStorage("authTokens", "")
    const obj = {
        'type': type,
        'companyid': authToken.companyid
    }
    return axiosInstance.post(API_URL + `/ManageAircraftPilot/GetPilotInstructorInfo`, obj,
        {
            headers: {
                'roleid': authToken.roleid,
            }
        })
        .then(response => {
            return response;
        })
}

const savePilotData = (inputObj: any) => {
    let authToken: any = getLocalStorage("authTokens", "")  //Getting authtokens in function scope 
    let obj = { ...inputObj, 'companyid': authToken.companyid };
    // console.log(`savePilotData: `, obj);
    return axiosInstance.post(API_URL + `/ManageAircraftPilot/SavePilotData`, obj,
        {
            headers: {
                'roleid': authToken.roleid
            }
        }).then(response => {
            return response
        });
}

const editPilotDetails = (inputObj:any) => {
    console.log(`EDIT PILOT OBJECT: `, inputObj);
    let authToken: any = getLocalStorage("authTokens", "")  //Getting authtokens in function scope 
    return axiosInstance.post(API_URL +`/ManageAircraftPilot/EditPilotDetails`,inputObj,
    {
        headers: {
            'roleid': authToken.roleid
        }
    }).then(response => {
        return  response
    })
}

const editInstructorDetails = (inputObj:any) => {
    console.log(`EDIT INSTRUCTOR OBJECT: `, inputObj);
    let authToken: any = getLocalStorage("authTokens", "")  //Getting authtokens in function scope 
    inputObj = {
        ...inputObj,
        "companyid" : authToken.companyid
    }
    return axiosInstance.post(API_URL +`/ManageAircraftPilot/EditInstructorDetails`,inputObj,
    {
        headers: {
            'roleid': authToken.roleid
        }
    }).then(response => {
        return  response
    })
}

const reactivateUser = (userId:any) => {
    let authToken: any = getLocalStorage("authTokens", "")
    return axiosInstance.get(API_URL +`/ManageAircraftPilot/ReactivateUser/`+userId,
    {
        headers: {
            'roleid': authToken.roleid
        }
    }).then(response => {
        return response
    })
}

const deactivateUser = (userId:any) => {
    let authToken: any = getLocalStorage("authTokens", "")
    return axiosInstance.get(API_URL +`/ManageAircraftPilot/DeactivateUser/`+userId,
    {
        headers: {
            'roleid': authToken.roleid
        }
    }).then(response => {
        return response
    })
}

const resetPassword = (userId:any) => {
    let authToken: any = getLocalStorage("authTokens", "")
    return axiosInstance.get(API_URL +`/ManageAircraftPilot/ResetPassword/`+userId,
    {
        headers: {
            'roleid': authToken.roleid
        }
    }).then(response => {
        return response
    })
}

const saveInstructorData = (inputObj: any) => {
    let authToken: any = getLocalStorage("authTokens", "")
    let obj = { ...inputObj, 'companyid': authToken.companyid };
    console.log(`saveInstructorData: `, obj)
    // return axiosInstance.post(API_URL + `/ManageAircraftPilot/SaveInstructorData2`, obj,
    return axiosInstance.post(API_URL + `/ManageAircraftPilot/SaveInstructorData`, obj,
        {
            headers: {
                'roleid': authToken.roleid,
            }
        }).then(response => {
            return response
        });
}

const updateInstructorStatus = (id:any, status:any) => {
    let authToken: any = getLocalStorage("authTokens", "")
    let formData = new FormData();
    formData.append('id', id)
    formData.append('status', status)
    return axiosInstance.post(API_URL+`/ManageAircraftPilot/UpdateInstructorStatus`, formData,
        {
            headers: {
                'roleid': authToken.roleid
            }
        }
    )
}

const getAssignableCfi = () => {
    let authToken: any = getLocalStorage("authTokens", "")
    let formData = new FormData();
    formData.append("companyid", authToken.companyid);
    return axiosInstance.post(API_URL+`/ManageAircraftPilot/GetAssignableCfi`, formData,
    {
        headers: {
            'roleid': authToken.roleid
        }
    })
    .then((response) => {
        return response
    })
}

const getActivePilots = () => {
    let authToken: any = getLocalStorage("authTokens", "")
    let formData = new FormData();
    formData.append("companyid", authToken.companyid);
    return axiosInstance.post(API_URL+`/ManageAircraftPilot/GetActivePilots`, formData,
    {
        headers: {
            'roleid': authToken.roleid
        }
    })
    .then((response) => {
        return response
    })
}

const getActiveInstructors = () => {
    let authToken: any = getLocalStorage("authTokens", "")
    let formData = new FormData();
    formData.append("companyid", authToken.companyid);
    return axiosInstance.post(API_URL+`/ManageAircraftPilot/GetActiveInstructors`, formData,
    {
        headers: {
            'roleid': authToken.roleid
        }
    })
    .then((response) => {
        return response
    })
}

const getExistingEmails = () => {
    let authToken: any = getLocalStorage("authTokens", "")
    return axiosInstance.get(API_URL+`/ManageAircraftPilot/GetUserName`,
        {
            headers: {
                'roleid': authToken.roleid
            }
        }
    ).then((response) => {
        return response.data.userNames
    })
}

const PilotService = {
    getPilotType,
    getPilotList,
    getTagList,
    getPilotInstructorInfo,
    savePilotData,
    editPilotDetails,
    editInstructorDetails,
    reactivateUser,
    deactivateUser,
    resetPassword,
    saveInstructorData,
    updateInstructorStatus,
    getAssignableCfi,
    getActivePilots,
    getActiveInstructors,
    getExistingEmails,
    // getAllInstructors,
}

export default PilotService;