import "./loadSpinner.scss";

const LoadSpinnerBig=()=>{
    return (
        <div className="spinner-container">
          <div className="loading-spinner-big">
          </div>
        </div>
      );
}


export default  LoadSpinnerBig;