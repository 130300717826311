

/**
 * this function prints bottom group elements on canvas namely-
 * Engine 1 RPM reading
 * G Norm Acceleration reading
 * @param {any} canvasCtx - 2d canvas context
 * @param {number} canvasWidth - width of canvas
 * @param {number} canvasHeight - height of canvas
 * @param {number} E1RPMReading - Engine 1 reading
 * @param {number} GNormAcReading - G Norm. Acc. reading
 * @returns {void}
 */

const bottomGroupEle = (canvasCtx : any, canvasWidth : number, canvasHeight : number, E1RPMReading:number, GNormAcReading:number ) =>{
   
    let bottomGroupEleWidth = 1536  // element width
    let bottomGroupEleheight = 48   // element height

    bottomGroupEleWidth = canvasWidth < bottomGroupEleWidth ? canvasWidth : bottomGroupEleWidth // element width should be equal or less than canvas width

    let topPos = canvasHeight - bottomGroupEleheight    // vertically positoned to bottom of canvas
    let leftPose = (canvasWidth - bottomGroupEleWidth)/2    // horizontally positioned to center of canvas

    canvasCtx.beginPath()
    canvasCtx.fillStyle = "rgba(18, 18, 18, 0.64)"
    canvasCtx.roundRect(leftPose, topPos, bottomGroupEleWidth, 48, [0, 0, 14, 14]) //rectangle for bottom element
    canvasCtx.fill()
    canvasCtx.closePath()

    canvasCtx.beginPath()
    canvasCtx.fillStyle = "#fff"
    canvasCtx.font = "500 28px Arial"
    canvasCtx.fillText("RPM "+E1RPMReading, leftPose+(bottomGroupEleWidth * 0.2), topPos+36) //Engine1 RPM 
    canvasCtx.fill()
    canvasCtx.closePath()

    canvasCtx.beginPath()
    canvasCtx.fillStyle = "#fff"
    canvasCtx.font = "500 28px Arial"
    canvasCtx.fillText("G "+GNormAcReading.toFixed(2), leftPose+(bottomGroupEleWidth * 0.6), topPos+36) //Norm Acceleration 
    canvasCtx.fill()
    canvasCtx.closePath()

    // G Norm. Acc. is also printed using a small horizontal bar scale
    // with a static vertcal centre line and a moving vertical line
    // for positive G value moving line moves on right side of static line and for negative value of G it moves to left 
    // value of G should be in between -1 to 1
    canvasCtx.beginPath()
    canvasCtx.fillStyle = "black"
    canvasCtx.roundRect(leftPose+(bottomGroupEleWidth * 0.6)+160, topPos+16, 160, 16, [16, 16, 16, 16]) // horizontal scale line
    canvasCtx.fill()
    canvasCtx.closePath()

    canvasCtx.beginPath()
    canvasCtx.fillStyle = "#fff"
    canvasCtx.roundRect(leftPose+(bottomGroupEleWidth * 0.6)+240, +topPos+16, 2, 16) // center vertical line
    canvasCtx.fill()
    canvasCtx.closePath()

    canvasCtx.beginPath()
    canvasCtx.fillStyle = "#5df558"
    canvasCtx.roundRect(leftPose+(bottomGroupEleWidth * 0.6)+240 + (80 * GNormAcReading), topPos+16, 4, 16) // moving vertical line
    canvasCtx.fill()
    canvasCtx.closePath()
}

export default bottomGroupEle