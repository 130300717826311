import "./Import.scss"
import UploadLogs from "./components/uploadLogs/UploadLogs";
const Import = () =>{
    return(
        <>
        <div className="import-wraper">
            <div className="import-tab-heads">
                <h2>Flight Log Uploads</h2>
                <p>Manage, upload, and monitor your flight logs status efficiently all in one place</p>
                <div className="tab-button-wrapper">
                    <button className="tab-button-upload-logs tab-active">Upload Logs</button>
                    {/* Temporarily Commenting: Do Not Remove */}
                    {/* <button className="tab-button-upload-history">Upload History</button> */}
                    <div className="d-inline space"></div>
                </div>
            </div>
        <UploadLogs/>
        </div>
        </>
    )
}

export default Import;