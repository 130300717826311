import { colorStrings } from "./colorStrings"

/**
 * prints following elements
 * ISA label
 * OAT label
 * @param canvasCtx - 2d canvas context
 * @param canvasWidth - width of canvas 
 * @param canvasHeight - height of canvas
 * @param OATReading - OAT reading
 * @param altMslReading - altMsl reading
 */
const rightBottomGroup = (canvasCtx:any, canvasWidth:number, canvasHeight:number, OATReading:number, altMslReading:number) => {
    let eleWidth = 336
    let eleHeight = 168

    let leftPos = (canvasWidth - 1536)/2 + 1536 - eleWidth - 10
    let topPos = canvasHeight - eleHeight - 56

    if(canvasWidth < 1536){
        leftPos = canvasWidth - eleWidth - 10  
    }

    let mobileView = canvasWidth <= 1280 ? true : false                                 //mobileView for screen width less than equal to 1280 
    if(!mobileView){

        canvasCtx.fillStyle = "rgba(18, 18, 18, 0.48)";
        canvasCtx.beginPath()
        canvasCtx.rect( leftPos, topPos, eleWidth, eleHeight)
        //canvasCtx.stroke()
        canvasCtx.closePath()

        canvasCtx.beginPath()
        canvasCtx.roundRect(leftPos+0, topPos+120, 160, 48, [8,8,8,8])
        canvasCtx.fill()
        canvasCtx.closePath()

        canvasCtx.beginPath()
        canvasCtx.roundRect(leftPos+176, topPos+120, 160, 48, [8,8,8,8])       
        canvasCtx.fill()
        canvasCtx.closePath()

        //OAT text
        canvasCtx.beginPath()
        canvasCtx.fillStyle = colorStrings.fontColor
        canvasCtx.fillText("OAT "+" "+Math.round(OATReading)+"°C", leftPos+12, topPos+155)
        canvasCtx.closePath()

        //ISA calculation
        let isaReading = (15 - (1.98*altMslReading/1000)) - OATReading

        //ISA text
        canvasCtx.beginPath()
        canvasCtx.fillStyle = colorStrings.fontColor
        canvasCtx.fillText("ISA "+" "+Math.round(isaReading)+"°C", leftPos+190, topPos+155)
        canvasCtx.closePath()
    }
  
    
}

export default rightBottomGroup