import {colorStrings} from "./colorStrings"

/**
 * this function prints elements namely-
 * IAS scale (Indicative Air Speed) 
 * TAS scale (True Air Speed)
 * gear label
 * flaps label
 * @param {any} canvasCtx - 2d canvas context
 * @param {number} canvasWidth - canvas width 
 * @param {number} canvasHeight - canvas height
 * @param {number} airSpeedReading - IAS reading
 * @param {number} TASReading - TAS reading
 */
const leftGroupEle = (canvasCtx : any, canvasWidth : number, canvasHeight : number, airSpeedReading : number, TASReading :number) =>{
    let groupHeight = 520 
    let groupWidth = 300 
    let topPos = (canvasHeight - groupHeight)/2                       // vericaly center
    let leftPos = (canvasWidth/2 - groupWidth) / 2                    //positioned from centre of canvas
    let mobileView = false

    if(canvasWidth > 1536){
        leftPos = (canvasWidth/2) - (1536/2)
    }

    if(canvasWidth < 1536){
        leftPos = 10
    }

    if(canvasHeight < 550 * 2.3){                 //scalingFacotr = 2.3
        topPos = 100
    }

    mobileView = canvasWidth <= 1280 ? true : false                         //mobileView is on for screen width equal to or less than 1280

    canvasCtx.beginPath()
    canvasCtx.rect(leftPos, topPos, groupWidth, groupHeight)
    //canvasCtx.stroke()
    canvasCtx.closePath()


    if(mobileView){
        airSpeedScale(canvasCtx, leftPos + 0, topPos + 0, airSpeedReading)
        trueAirSpeedScale(canvasCtx, leftPos+0, topPos+480, TASReading)
    }else{
        airSpeedScale(canvasCtx, leftPos + 140, topPos + 0, airSpeedReading)
        trueAirSpeedScale(canvasCtx, leftPos+140, topPos+480, TASReading)
         //gearLabel(canvasCtx, 44, 48)
        //flapsLabel(canvasCtx, 0, 198)
    }
}

/**
 * prints IAS scale
 * @param {any} canvasCtx - 2d canvas context
 * @param {number} poseX - position from top
 * @param {number} poseY - position from left
 * @param {number} airSpeedReading - IAS reading
 */
const airSpeedScale = (canvasCtx : any, poseX : number, poseY : number, airSpeedReading : number) =>{

    let eleWidth = 160
    let eleHeight = 480

    //scale mark calculation
    let visibleMajorMark = 6
    let majorMarkDiff = 480 / visibleMajorMark          //480 is height of IAS scale
    let nextMajorMark = 0
    let minorMarkDiff = 480 / visibleMajorMark / 2
    let oneUnit = 480 / visibleMajorMark / 10           //smallest unit of our scale
    let nextNumberinMulti10 = getNextNumber2(airSpeedReading)
    let scaleOffset = (getNextNumber2(airSpeedReading) - airSpeedReading) * oneUnit


    //clipping rectangle 
    canvasCtx.save()
    canvasCtx.beginPath()
    canvasCtx.rect(poseX, poseY, eleWidth, eleHeight)
    canvasCtx.clip()
    canvasCtx.closePath()

    canvasCtx.beginPath()
    canvasCtx.fillStyle = colorStrings.bgLight
    canvasCtx.roundRect(poseX, poseY, eleWidth, eleHeight, [32, 8, 0, 0])
    canvasCtx.fill()
    canvasCtx.closePath()

    canvasCtx.beginPath()
    canvasCtx.fillStyle = colorStrings.bgWhiteLight
    canvasCtx.roundRect(poseX+142, poseY, 16, eleHeight, [0, 8, 0, 0])
    canvasCtx.fill()
    canvasCtx.closePath()

    canvasCtx.beginPath();
    canvasCtx.fillStyle = colorStrings.whiteColor
    canvasCtx.rect(poseX+158, poseY, 2, 480);
    canvasCtx.fill();

    canvasCtx.strokeStyle = colorStrings.fontColor
    canvasCtx.lineWidth = 2
    canvasCtx.font = "500 28px Arial";


    //print major mark and minor mark and number on scale
    for (let i = 0; i <= visibleMajorMark; i++) {
        if (nextNumberinMulti10 - i * 10 + 35 > 0) {
            canvasCtx.strokeStyle = colorStrings.fontColor
            canvasCtx.fillStyle = colorStrings.fontColor
            canvasCtx.beginPath()
            canvasCtx.moveTo(poseX + 126, poseY + nextMajorMark - scaleOffset)
            canvasCtx.lineTo(poseX + 158, poseY + nextMajorMark - scaleOffset)
            canvasCtx.fillText(nextNumberinMulti10 - i * 10 + 30, poseX + 72, poseY + nextMajorMark - scaleOffset + 8)
            canvasCtx.stroke()
            canvasCtx.closePath()

            if (nextNumberinMulti10 - i * 10 + 30 > 0) {
                canvasCtx.beginPath()
                canvasCtx.moveTo(poseX + 142, poseY + nextMajorMark + minorMarkDiff - scaleOffset)
                canvasCtx.lineTo(poseX + 158, poseY + nextMajorMark + minorMarkDiff - scaleOffset)
                canvasCtx.stroke()
                canvasCtx.closePath()
            }
        }
        nextMajorMark += majorMarkDiff;
    }


    //IAS value label 
    canvasCtx.beginPath()
    canvasCtx.fillStyle = colorStrings.blackColor
    canvasCtx.rect(poseX + 4, poseY + (eleHeight/2) - 33, 138, 66)
    canvasCtx.fill()
    canvasCtx.closePath()

    // triangle next to label
    canvasCtx.beginPath()
    canvasCtx.fillStyle = colorStrings.whiteColor
    canvasCtx.strokeStyle = colorStrings.blackColor
    canvasCtx.moveTo( poseX+156,poseY+240)
    canvasCtx.lineTo(poseX+132,poseY+228)
    canvasCtx.lineTo(poseX+132,poseY+252)
    canvasCtx.lineTo( poseX+156,poseY+240)
    canvasCtx.fill()
    canvasCtx.stroke()
    canvasCtx.closePath()

    //print number on label
    canvasCtx.beginPath()
    //canvasCtx.strokeStyle = colorStrings.fontColor
    canvasCtx.fillStyle = colorStrings.fontColor
    canvasCtx.font = "600 42px Arial";
    canvasCtx.fillText(Math.round(airSpeedReading), poseX + 20, poseY + (eleHeight/2) + 12)
    canvasCtx.stroke()
    canvasCtx.closePath()

   canvasCtx.restore()

}


/**
 * print label and text in TAS scale
 * @param {any} canvasCtx -2d canvas context 
 * @param {number} poseX - position from top
 * @param {number} poseY - position from left
 * @param {number} TASReading - true air speed reading 
 */
const trueAirSpeedScale = (canvasCtx : any, poseX : number, poseY : number, TASReading : number) =>{

    //TAS label rectangle
    canvasCtx.beginPath()
    canvasCtx.fillStyle = colorStrings.bgDark
    canvasCtx.roundRect(poseX,poseY,160,40, [0, 0, 8, 32])
    canvasCtx.fill()
    canvasCtx.closePath()

    //text on label rectangle
    canvasCtx.beginPath()
    canvasCtx.fillStyle = colorStrings.fontColor
    canvasCtx.font = "500 28px Arial";
    canvasCtx.fillText("TAS "+Math.round(TASReading)+"kt", poseX+20, poseY+30)
    canvasCtx.fill()
    canvasCtx.closePath()

}

/**
 * prints gear label
 * @param {any} canvasCtx - 2d canvas context 
 * @param {number} poseX - position from top
 * @param {number} poseY - position from left
 */
const gearLabel = (canvasCtx : any, poseX : number, poseY : number) =>{
    canvasCtx.beginPath()
    canvasCtx.roundRect(poseX, poseY, 96, 118, [16, 0, 0, 16])
    canvasCtx.fill()
    canvasCtx.closePath()
}

/**
 * prints flaps label
 * @param {any} canvasCtx - 2d canvas context 
 * @param {number} poseX - position from top
 * @param {number} poseY - position from left
 */
const flapsLabel = (canvasCtx : any, poseX : number, poseY : number) =>{
    canvasCtx.beginPath()
    canvasCtx.roundRect(poseX, poseY, 140, 280, [16, 0, 0, 16])
    canvasCtx.fill()
    canvasCtx.closePath()
}

/**
 * returns nearest integer whic is multiple of 10 
 * @param x {number} -
 * @returns {number} - returns nearest integer which is multiple of 10
 */
function getNextNumber2(x:number){
    return Math.ceil(x/10)*10
}

export default leftGroupEle