import Cesium3D from "../../cesium3dView/CesiumComp"
import { useState, useRef } from "react";
import fullScreenWindow from "../../../../../common/utils/fullScreenWindow";
import { csvJSonArray } from "../../../../../common/utils/cesiumDataHandler";
import { TabDataBrodacast, TabDataMsgType } from "../../../../../common/utils/utils";
import { segregatedDataToExport } from "../../../../../common/utils/utils";
import { analysedDataToExport } from "../../../../../common/utils/utils";
import { getSegData } from "./DebriefPlayer";
import { clockViewModel } from "../../../../../common/utils/cesiumDataHandler";

const PfdCard = (props: any) => {
    const [flag, setFlag] = useState(false);
    const pfdConatiner = useRef(null);
    const idOne = useRef(null);

    /**
     * Event handler function that logs event type, event, and data.
     * @param {any} e - The event object.
     * @param {any} data - Additional data associated with the event.
     * @returns {void}
     */
    const eventHandler = (e: any, data: any) => {
        console.log('Event Type', e.type);
        console.log({ e, data });
    }

    /**
     * Activates full screen for the element with id 'pdfLayerParent'.
     * @returns {void}
     */
    const activeFullScreen = () => {
        fullScreenWindow('pdfLayerParent');
    }


    /**
     * Event handler for messages received from the TabDataBrodacast.
     * Processes the messages based on their IDs and sends appropriate responses.
     * @param {Object} msg - The message object received.
     * @returns {void}
     */
    TabDataBrodacast.onmessage = (msg) => {
        // console.log(`MAIN onMessage: `, msg)
        if (msg.id === TabDataMsgType.getClockState) {
            TabDataBrodacast.postMessage({ id: TabDataMsgType.setClockState, data: clockViewModel.shouldAnimate})
        }
        if (msg.id === TabDataMsgType.getPfdCsv) {
            TabDataBrodacast.postMessage({ id: TabDataMsgType.setPfdCsv, data: csvJSonArray })
        }
        if (msg.id === TabDataMsgType.getFpdCsv) {
            // console.log(csvJSonArray)
            TabDataBrodacast.postMessage({ id: TabDataMsgType.setFpdCsv, data: csvJSonArray })
        }
        if (msg.id === TabDataMsgType.getFpdAnalysisData) {
            //console.log(analysedDataToExport)
            TabDataBrodacast.postMessage({ id: TabDataMsgType.setFpdAnalysisData, data: analysedDataToExport })
        }
        if (msg.id === TabDataMsgType.getFpdInitPhaseCheckState) {
            let uncheckedPhases = initPhaseStateToNewTab() || []
            TabDataBrodacast.postMessage({ id: TabDataMsgType.setFpdInitPhaseCheckState, data: uncheckedPhases })
        }
        if (msg.id === TabDataMsgType.getGraphData) {
            TabDataBrodacast.postMessage({ id: TabDataMsgType.setGraphData, data: csvJSonArray })
        }
        if (msg.id === TabDataMsgType.getPhaseGraphData) {
            let segData = getSegData() || [];
            let phaseGraphData: any = [];
            let landingDistanceOverRunwayFt: Number[] = [], landingPitchOverRunway: Number[] = [], landingVspdOverRunway: Number[] = [];
            let landingALTMSL: Number[] = [], landingDistance: Number[] = [];
            segData.forEach((element: any) => {
                if (element.sequenceId === msg.data && element.label === `Landing`) {
                    const landingPhaseALTMSL = element.data.map((item: any) => Number(item.AltMSL));
                    const landingPhaseDistance = element.data.map((item: any) => Number(item.distFromRwy));

                    const seqIdLanding = msg.data;
                    const seqIdApproach = (seqIdLanding - 1);

                    const approachPhaseData = segData.find((item: any) => item.sequenceId === (seqIdApproach));

                    const approachPhaseALTMSL = approachPhaseData.data.map((item: any) => Number(item.AltMSL));
                    const approachPhaseDistance = approachPhaseData.data.map((item: any) => Number(item.distFromRwy));

                    landingALTMSL = approachPhaseALTMSL.concat(landingPhaseALTMSL);
                    landingDistance = approachPhaseDistance.concat(landingPhaseDistance);

                    let count = 0;
                    const landingDistanceOverRunway = element.data.map((item: any) => {
                        if (item.distFromRwy > 0) count++;
                        return Math.abs(Math.min(0, Number(item.distFromRwy * 6076.12)))
                    });
                    landingDistanceOverRunwayFt = landingDistanceOverRunway.slice(count);
                    landingPitchOverRunway = element.data.map((item: any) => Number(item.Pitch)).slice(count)
                    landingVspdOverRunway = element.data.map((item: any) => Number(item.VSpd)).slice(count)

                    phaseGraphData = {
                        landingPhaseOnlyData: element.data,
                        landingDistance: landingDistance,
                        landingALTMSL: landingALTMSL,
                        landingDistanceOverRunwayFt: landingDistanceOverRunwayFt,
                        landingPitchOverRunway: landingPitchOverRunway,
                        landingVspdOverRunway: landingVspdOverRunway
                    }
                }
                else if (element.sequenceId === msg.data) {
                    phaseGraphData = element.data;
                }
            });
            TabDataBrodacast.postMessage({ id: TabDataMsgType.setPhaseGraphData, data: phaseGraphData })
        }
    }


    /**
     * Event handler for the "beforeunload" event.
     * Sends a message to TabDataBrodacast to close new tabs.
     * @returns {void}
     */
    window.onbeforeunload = function () {
        TabDataBrodacast.postMessage({ id: TabDataMsgType.closeNewTabs, data: "" });
    };

    /**
     * Event handler for the "unload" event.
     * Sends a message to TabDataBrodacast to close new tabs.
     * @returns {void}
     */
    window.onunload = () => {
        TabDataBrodacast.postMessage({ id: TabDataMsgType.closeNewTabs, data: "" });
    };


    /**
 * Retrieves the list of unchecked phases from the segregatedDataToExport
 * and returns an array containing their sequence IDs.
 * @returns {number[]} - Array of unchecked phase sequence IDs.
 */
    const initPhaseStateToNewTab = () => {
        let uncheckedPhases: number[] = [];
        segregatedDataToExport?.filter((val: any, index: any) => val.isVisible === false).map((val: any) => {
            uncheckedPhases.push(val.sequenceId);
        });
        // console.log(uncheckedPhases);
        return uncheckedPhases;
    };

    /**
     * Opens a new tab with the URL '/special-tabs/pfd' and specific window properties.
     * @returns {void}
     */
    // const newTab = () => {
    //     window.open('/special-tabs/pfd', '_blank', 'toolbar=no, location=no, status=no');
    // };

    const newTab = () => {
        window.open(
            '/special-tabs/pfd',
            '_blank',
            'toolbar=no, location=no, status=no, width=550, height=800, top=0, left=800, resizable=yes, scrollbars=yes'
        );
    };



    return (
        <div className="debrief-cards" id="pfdScreen">
            <div className="debrief-cards-handle">
                PRIMARY FLIGHT DISPLAY
                <div className="button-holder d-flex">
                    {/* <button className="btn-customize"></button> */}
                    <button className="btn-maximize" title="Fullscreen" onClick={() => fullScreenWindow("pfdScreen")}></button>
                    {/* <button className="btn-option"></button> */}
                    <button className="btn-new-tab" title="Open in new tab" onClick={newTab}></button>

                </div>
            </div>
            <div className={props.ispointerEventDisable ? 'debrief-cards-content pe-none overflow-auto' : 'debrief-cards-content overflow-auto'} id="pfdLayerParent">
                <Cesium3D callback={setFlag} currentCsvFileData={props.currentCsvFileData} setViewer={props.setViewer} />
                <canvas id='pfdLayer'></canvas>
                {/* <PfdExperimet></PfdExperimet> */}
                {/* <div className='pfd-canvas-layer d-flex flex-column'>
                            <TopGroup/>
                            <div className='d-flex flex-grow-1 center-block'>
                                <LeftGroup flag={flag} />
                                <MiddleGroup/>
                                <RightGroup/>
                            </div>
                            <BottomGroup/>
                        </div> */}
            </div>
        </div>
    )
}

export default PfdCard;