import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { csvJSonArray } from '../../../../common/utils/cesiumDataHandler';
import { getTailNumber, getStartTime } from '../debrief/debriefPlayer/DebriefPlayer';

const TwoAxisGraphTime = (props: {
  xaxisArray: any[],
  yaxisArray: any[],
  xlabel: string,
  ylabel: string,
  phase?: string;
}) => {

  // console.log(`props 2A: `, props)

  const [data, setData] = useState<any[] | undefined>(undefined);
  const [layout, setLayout] = useState<any | undefined>(undefined);

  useEffect(() => {

    // const traceName = extractTraceName(props.ylabel);

    const updateTooltip = () => {
      const anchorElement = document.querySelector('.modebar-btn[data-title="Download plot as a png"]');
      if (anchorElement) {
        anchorElement.setAttribute('data-title', 'Download as an Image');
      }
    };

    const trace1 = {
      x: props.xaxisArray,
      y: props.yaxisArray,
      // fill: 'tozeroy',
      type: 'scatter',
      showlegend: false,
      // name: traceName,
      name: false,
    };

    let additionalLines: any = [], yaxisRange = [Math.min(...props.yaxisArray), Math.max(...props.yaxisArray)];

    // Check if xlabel and ylabel meet the specified conditions
    if (props.xlabel === 'Local Time (HH:MM:SS)' && props.ylabel === 'Ground Speed (kn)') {
      // Add dotted lines at GndSpd = 10 and GndSpd = 15
      additionalLines = [
        {
          type: 'scatter',
          mode: 'lines',
          x: props.xaxisArray,
          y: Array(props.xaxisArray.length).fill(10), //preset range (static)
          line: {
            dash: 'dash',
            color: 'red',
          },
          name: 'Lower Limit',
          hoverinfo: 'none',
        },
        {
          type: 'scatter',
          mode: 'lines',
          x: props.xaxisArray,
          y: Array(props.xaxisArray.length).fill(15), //preset range (static)
          line: {
            dash: 'dash',
            color: 'purple',
          },
          name: 'Upper Limit',
          hoverinfo: 'none',
        },
      ];
      yaxisRange = [Math.min(...props.yaxisArray), (15 + Math.abs(Math.max(...props.yaxisArray) - 15))];
    }
    else if (props.phase === "Cruise" && props.xlabel === 'Local Time (HH:MM:SS)' && props.ylabel === 'AltMSL (ft)') {
      const mean = (props.yaxisArray.reduce((acc, num) => acc + num, 0)) / props.yaxisArray.length;
      const rangeOffset = 500; //change the offset to desired value
      additionalLines = [
        {
          type: 'scatter',
          mode: 'lines',
          x: props.xaxisArray,
          y: Array(props.xaxisArray.length).fill(mean),
          line: {
            dash: 'dash',
            color: 'Green',
          },
          name: 'Ideal',
          hoverinfo: 'none',
        },
        {
          type: 'scatter',
          mode: 'lines',
          x: props.xaxisArray,
          y: Array(props.xaxisArray.length).fill(mean + rangeOffset),
          line: {
            dash: 'dash',
            color: 'red',
          },
          name: 'Lower Limit',
          hoverinfo: 'none',
        },
        {
          type: 'scatter',
          mode: 'lines',
          x: props.xaxisArray,
          y: Array(props.xaxisArray.length).fill(mean - rangeOffset), //change the offset to desired value
          line: {
            dash: 'dash',
            color: 'purple',
          },
          name: 'Upper Limit',
          hoverinfo: 'none',
        },
      ];
      yaxisRange = [Math.min(...props.yaxisArray) - (1.02 * rangeOffset), Math.max(...props.yaxisArray) + (1.02 * rangeOffset)];
    }

    const graphData = [trace1, ...additionalLines];

    const graphLayout = {
      dragmode: false,
      xaxis: {
        title: {
          text: props.xlabel,
          font: {
            size: 12
          }
        },
        tickformat: '%H:%M:%S',
        tickfont: {
          size: 12,
        },
        // nticks: 8, //specifying the max number of ticks on the x-axis
        // range: [Math.min(...props.xaxisArray), Math.max(...props.xaxisArray)],
        autorange: true,
      },

      yaxis: {
        title: {
          text: props.ylabel,
          font: {
            size: 12
          }
        },
        tickfont: {
          size: 12,
        },
        side: 'left',
        // range: yaxisRange,
        autorange: true,
      },

      legend: {
        // x:0.29,
        x: 0.00001,
        // // y:1.25,
        y: 1.2,
        orientation: 'h',
      },
      hovermode: 'closest',
      autosize: true,
      margin: {
        t: 20,
        b: 50,
        l: 50,
        r: 50,
      },
    };

    setData(graphData);
    setLayout(graphLayout);

    updateTooltip();

  }, [csvJSonArray, props.xaxisArray, props.yaxisArray]);

  // useEffect (() => {
  //     const anchorElement = document.querySelector('.modebar-btn');
  //     if (anchorElement) {
  //       console.log(`Inside IF: `, anchorElement)
  //       anchorElement.setAttribute('data-title', 'Download as an Image');
  //   }
  // }, [ data, layout ]);

  // Handle double-click event
  // const handleDoubleClick = () => {
  //   // Explicitly set axis range after double-click reset
  //   setLayout((prevLayout: any) => ({
  //     ...prevLayout,
  //     xaxis:{...prevLayout.xaxis, range: [Math.min(...props.xaxisArray), Math.max(...props.xaxisArray)] },
  //     yaxis: { ...prevLayout.yaxis, range: [Math.min(...props.yaxisArray), Math.max(...props.yaxisArray)] },
  //   }));
  // };


  // Function to extract trace name from a label string
  const extractTraceName = (labelString: string): string | null => {
    const regularExpression = /(.+?) \(/;
    const match = labelString.match(regularExpression);
    return match ? match[1] : null;
  };

  // Function to combine labels into a single string
  const combineLabels = (): string => {
    // Extract trace names from y1label, y2label, and xlabel
    const traceName1 = extractTraceName(props.ylabel);
    const xLabelName = extractTraceName(props.xlabel);

    // Combine the names into a single string
    return `${traceName1} vs ${xLabelName}`;
  };

  const [graphName, setGraphName] = useState(combineLabels());

  return (
    <Plot
      data={data as any}
      layout={layout as any}
      onInitialized={() => { }}
      onUpdate={() => { }}
      // config={{ doubleClick: false ,  displayModeBar: false, responsive: true}} //disabling default double-click reset
      // config={{ displayModeBar: false, responsive: true }}
      config={{
        // doubleClick: false,
        responsive: true,
        displayModeBar: true,
        // scrollZoom: true,
        displaylogo: false,
        modeBarButtonsToRemove: ['autoScale2d', 'resetScale2d', 'lasso2d', 'select2d'],
        toImageButtonOptions: {
          format: `png`,
          filename: `${getTailNumber()}_${graphName}_${getStartTime()}`,
        },
      }}
      style={{ width: '100%', height: '100%' }}
    // onDoubleClick={handleDoubleClick}
    />
  );

};

// export default TwoAxisGraphTime;
export default React.memo(TwoAxisGraphTime);