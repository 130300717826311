import { useLocation } from "react-router-dom"
import IconDebrief from "../../../assets/images/common/tsxImages/IconDebrief"
import IconFlights from "../../../assets/images/common/tsxImages/IconFlights"
import IconFlyingDutyTime from "../../../assets/images/common/tsxImages/IconFlyingDutyTime"
import IconImport from "../../../assets/images/common/tsxImages/IconImport"
import IconReports from "../../../assets/images/common/tsxImages/IconReports"
import IconSettings from "../../../assets/images/common/tsxImages/IconSettings"
import { useTheme } from "../../../context/themeContext"

const SidebarIcons = ({ props }: any) => {
    const activeTab = useLocation();
    // Get the theme from the 'themeContext'.
    const theme = useTheme();

    // Determine the fill color of the icon based on the active tab.
    const pathElements = activeTab.pathname.split('/');
    const dashboardIndex = pathElements.indexOf('dashboard');
    const isMatchingElement = dashboardIndex !== -1 && pathElements[dashboardIndex + 1] === props.id;
    const fillColor = isMatchingElement ? theme.primaryColor : "grey";

    // Render different icons based on the 'props.id' value.
    switch (props.id) {
        case "debrief": return (<IconDebrief fill={fillColor} />)
        case "flights": return (<IconFlights fill={fillColor} />)
        case "flying_duty_time": return (<IconFlyingDutyTime fill={fillColor} />)
        case "import": return (<IconImport fill={fillColor} />)
        case "reports": return (<IconReports fill={fillColor} />)
        case "settings": return (<IconSettings fill={fillColor} />)
        default: return (<></>)
    }
}

export default SidebarIcons;