import { radiantLine } from "./colorStrings"

/**
 * prints following elemets on canvas
 * roll scale
 * pitch scale
 * 
 * @param {any} canvasCtx - 2d canvas context
 * @param {number} canvasWidth - canvas width
 * @param {number} canvasHeight - canvas height
 * @param {number} rollReading - roll reading
 * @param {number} pitchReading - pitch reading
 * @param {number} GLatAcReading - lateral acceleration reading for slip indicator
 */
const rollAndPitch = (canvasCtx:any, canvasWidth:number, canvasHeight:number, rollReading:number, pitchReading:number, GLatAcReading:number) => {


    let eleWidth = 600
    let eleHeight = 510

    let topPos = (canvasHeight - eleHeight)/2
    let leftPos = (canvasWidth - eleWidth) / 2

    if(canvasHeight < 550 * 2.3){                      //scalingFcator = 2.3
        topPos = 100
    }
   
    canvasCtx.beginPath()
    canvasCtx.rect(leftPos, topPos, eleWidth, eleHeight)
    //canvasCtx.stroke()
    canvasCtx.closePath()
  
    //pich angle should show 0 - 90

    if(90 > pitchReading*180/Math.PI  && 180 < pitchReading*180/Math.PI){
        let pitchReadingDeg = 180 - pitchReading*180/Math.PI
        pitchReading = -pitchReadingDeg * Math.PI / 180
        //console.log("pitch angle is more than 90")
    }
    if(180 < pitchReading*180/Math.PI && 270 > pitchReading*180/Math.PI){
        let pitchReadingDeg = 270 - pitchReading*180/Math.PI
        pitchReading = -pitchReadingDeg * Math.PI / 180
        //console.log("pitch angle is between 180 and 270")
    }
    if(270 < pitchReading*180/Math.PI){
        let pitchReadingDeg = 360 - pitchReading*180/Math.PI
        pitchReading = -pitchReadingDeg * Math.PI / 180
        //console.log("pitch angle is more than 270")
    }

    // if(345 < pitchReading*180/Math.PI){
    //     let pitchReadingDeg = 360 - pitchReading*180/Math.PI
    //     pitchReading = -pitchReadingDeg * Math.PI / 180
    // }

    let rollReadingDeg = 360 - rollReading*180/Math.PI                  //change direction of roll
    rollReading = rollReadingDeg * Math.PI/180                          //change roll unit from degree to rad
    
    canvasCtx.save()

    rotateClipRect1(canvasCtx, leftPos+((eleWidth-416)/2), topPos+0, 416, 540, rollReading*180/Math.PI)

    canvasCtx.clip()  //testing

    canvasCtx.save()

    canvasCtx.translate(leftPos+(eleWidth/2), topPos+(eleHeight/2)+15)
    canvasCtx.rotate(rollReading)
    canvasCtx.translate(-leftPos-(eleWidth/2), -topPos-(eleHeight/2)-15)

    canvasCtx.beginPath();
    canvasCtx.fillStyle = "rgba(64, 143, 245, 0.2)";
    
    // two color background of pitch scale
    let grd = canvasCtx.createLinearGradient(leftPos+(eleWidth/2), topPos+(eleHeight/2) - 240 + 15, leftPos+(eleWidth/2), topPos+(eleHeight/2) + 240 + 15);     
    let pitchDeflectionForBg = (pitchReading*180/Math.PI) * 240/20

    let diflectPercent = pitchDeflectionForBg / 480
    if(diflectPercent > 0.49){
        diflectPercent = 0.49
    } 
    if(diflectPercent < -0.49){
        diflectPercent = -0.49
    }
   grd.addColorStop(0, "rgba(64, 143, 245, 0.2)");
   grd.addColorStop(.5 + diflectPercent, "rgba(64, 143, 245, 0.2)");
   grd.addColorStop(.5 + diflectPercent, "rgba(144, 75, 30, 0.2)");
   grd.addColorStop(1, "rgba(144, 75, 30, 0.2)");
   canvasCtx.fillStyle = grd
   canvasCtx.filter ="blur(2px)"

   canvasCtx.arc(leftPos+(eleWidth/2), topPos+(eleHeight/2)+15, 240, 0, 2*Math.PI, false);
    //canvasCtx.stroke()
    canvasCtx.fill();
    canvasCtx.clip()
    canvasCtx.closePath();

    // let pitchDeflectionForBg = (pitchReading*180/Math.PI) * 240/20

    // console.log(pitchReading*180/Math.PI)

    // canvasCtx.beginPath()
    // canvasCtx.fillStyle = "rgba(64, 143, 245, 0.2)"
    // canvasCtx.filter ="blur(2px)"
    // canvasCtx.rect(leftPos,topPos,eleWidth, 270+pitchDeflectionForBg)
    // canvasCtx.fill()
    // canvasCtx.closePath()

    // canvasCtx.beginPath()
    // canvasCtx.fillStyle = "rgba(144, 75, 30, 0.2)"
    // canvasCtx.filter ="blur(2px)"
    // canvasCtx.rect(leftPos,topPos+270+pitchDeflectionForBg,eleWidth,eleHeight)
    // canvasCtx.fill()
    // canvasCtx.closePath()

    canvasCtx.restore()
    

    canvasCtx.restore();

    pitchScale1(canvasCtx, leftPos, topPos, pitchReading*180/Math.PI, rollReading)

    // circle top border 
    canvasCtx.beginPath();
    canvasCtx.lineWidth = 4
    canvasCtx.strokeStyle = "rgba(255, 255, 255, 0.8)"
    canvasCtx.setLineDash([0, 0])
    canvasCtx.arc(leftPos+(eleWidth/2), topPos+(eleHeight/2)+15, 239,(5*Math.PI/4)+rollReading, (7*Math.PI/4)+rollReading);
    canvasCtx.stroke();
    canvasCtx.closePath();

    // centre horizontal dot line
    // ctx!.beginPath();
    // ctx!.lineWidth = 4
    // ctx!.strokeStyle = "#fff"
    // ctx!.setLineDash([12, 8])
    // ctx!.moveTo(90, 270);
    // ctx!.lineTo(510, 270);
    // ctx!.stroke();
    // ctx!.closePath();

    canvasCtx.beginPath()
    // canvasCtx.moveTo(300, 270)
    // canvasCtx.lineTo(120,320)
    // canvasCtx.lineTo(200,320)

    //yellow triangles
    canvasCtx.moveTo(leftPos+(eleWidth/2), topPos+270)
    canvasCtx.lineTo(leftPos+120,topPos+320)
    canvasCtx.lineTo(leftPos+200,topPos+320)
    canvasCtx.fillStyle = "yellow"
    canvasCtx.fill()
    canvasCtx.closePath()

    canvasCtx.beginPath()
    canvasCtx.moveTo(leftPos+(eleWidth/2), topPos+270)
    canvasCtx.lineTo(leftPos+480,topPos+320)
    canvasCtx.lineTo(leftPos+400,topPos+320)
    canvasCtx.fillStyle = "yellow"
    canvasCtx.fill()
    canvasCtx.closePath()

    //slip indicator top triangle
    canvasCtx.beginPath()
    canvasCtx.lineWidth = 1
    canvasCtx.moveTo(leftPos+(eleWidth/2), topPos+34)
    canvasCtx.lineTo(leftPos+(eleWidth/2)-16, topPos+58)
    canvasCtx.lineTo(leftPos+(eleWidth/2)+16, topPos+58)
    canvasCtx.lineTo(leftPos+(eleWidth/2), topPos+34)
    canvasCtx.fillStyle = "#fff"
    canvasCtx.fill()
    canvasCtx.strokeStyle = "#121212"
    canvasCtx.setLineDash([0, 0])
    canvasCtx.stroke()
    canvasCtx.closePath()

    // ctx!.beginPath()
    // ctx!.lineWidth = 1
    // ctx!.moveTo(282,62)
    // ctx!.lineTo(318, 62)
    // ctx!.lineTo(324, 70)
    // ctx!.lineTo(276, 70)
    // ctx!.lineTo(282, 62)
    // ctx!.fillStyle = "#fff"
    // ctx!.fill()
    // ctx!.strokeStyle = "#121212"
    // ctx!.setLineDash([0, 0])
    // ctx!.stroke()
    // ctx!.closePath()

    canvasCtx.beginPath()
    canvasCtx.lineWidth = 1

    //slip indicator moving trapezoid
    canvasCtx.moveTo(leftPos+(eleWidth/2)-18+(120*GLatAcReading), topPos+62)
    canvasCtx.lineTo(leftPos+(eleWidth/2)+18+(120*GLatAcReading), topPos+62)
    canvasCtx.lineTo(leftPos+(eleWidth/2)+24+(120*GLatAcReading), topPos+70)
    canvasCtx.lineTo(leftPos+(eleWidth/2)-24+(120*GLatAcReading), topPos+70)
    canvasCtx.lineTo(leftPos+(eleWidth/2)-18+(120*GLatAcReading), topPos+62)

    canvasCtx.fillStyle = "#fff"
    canvasCtx.fill()
    canvasCtx.strokeStyle = "#121212"
    canvasCtx.setLineDash([0, 0])
    canvasCtx.stroke()
    canvasCtx.closePath()

    //roll scale top circle radial marks
    radiantLine(canvasCtx, leftPos+(eleWidth/2), topPos+(eleHeight/2)+15, 240, 256, -135+(rollReading*180/Math.PI), 4, "#fff")
    radiantLine(canvasCtx, leftPos+(eleWidth/2), topPos+(eleHeight/2)+15, 240, 272, -120+(rollReading*180/Math.PI), 4, "#fff")
    radiantLine(canvasCtx, leftPos+(eleWidth/2), topPos+(eleHeight/2)+15, 240, 256, -110+(rollReading*180/Math.PI), 4, "#fff")
    radiantLine(canvasCtx, leftPos+(eleWidth/2), topPos+(eleHeight/2)+15, 240, 256, -100+(rollReading*180/Math.PI), 4, "#fff")
    // rotatingTriangle(canvasCtx, leftPos+(eleWidth/2), topPos+(eleHeight/2)+30, 240, 256, -90+(rollReading*180/Math.PI), 2, "black")
    radiantLine(canvasCtx, leftPos+(eleWidth/2), topPos+(eleHeight/2)+15, 240, 256, -80+(rollReading*180/Math.PI), 4, "#fff")
    radiantLine(canvasCtx, leftPos+(eleWidth/2), topPos+(eleHeight/2)+15, 240, 256, -70+(rollReading*180/Math.PI), 4, "#fff")
    radiantLine(canvasCtx, leftPos+(eleWidth/2), topPos+(eleHeight/2)+15, 240, 272, -60+(rollReading*180/Math.PI), 4, "#fff")
    radiantLine(canvasCtx, leftPos+(eleWidth/2), topPos+(eleHeight/2)+15, 240, 256, -45+(rollReading*180/Math.PI), 4, "#fff")

 
}


/**
 * rotates roll scale together with pitch scale and slip indicator
 * @param {any} ctx - 2d canvas context 
 * @param {number} x - position from top
 * @param {number} y - position from left
 * @param {number} width - width of rotating rectangle
 * @param {number} height - height of rotating rectangle
 * @param {number} degrees - roll angle for rotation of rectangle in degree
 */
const rotateClipRect1 = (ctx : any, x : number, y : number, width : number, height : number, degrees : number) => {
    ctx.save();
    ctx.beginPath();
    ctx.translate(x + width / 2, y + height / 2);                            //to change direction of rotaion
    ctx.rotate(degrees * Math.PI / 180);
    ctx.rect(-width / 2, -height / 2, width, height);
    //ctx.stroke()
    ctx.restore();
}

/**
 * prints pitch scale
 * @param {any} ctx - 2d canvas context
 * @param {number} poseX - position from top
 * @param {number} poseY - position from left
 * @param {number} pitchReading - pitch reading
 * @param {number} rollAngleReading - roll reading
 */
const pitchScale1 = (ctx : any, poseX:number, poseY:number, pitchReading : number, rollAngleReading : number) =>{
    //let elewidth = 240
    //let eleHeight = 400

    let parentWidth = 600
    //let parentHeight = 510

    ctx.save()
    //ctx.translate(600/2, 510/2)
    //ctx.translate(poseX/2, poseY/2)
    ctx.translate(poseX+(parentWidth/2), poseY+270)
    ctx.rotate(rollAngleReading);
    //ctx.translate(-600/2, -510/2)
    ctx.translate(-poseX-(parentWidth/2), -poseY-270)
    
    ctx.beginPath()
    ctx.lineWidth = 1
    ctx.moveTo(poseX+(parentWidth/2), poseY+24)
    ctx.lineTo(poseX+(parentWidth/2)-16, poseY+0)
    ctx.lineTo(poseX+(parentWidth/2)+16, poseY+0)
    ctx.lineTo(poseX+(parentWidth/2), poseY+24)
    ctx.fillStyle = "#fff"
    ctx.fill()
    ctx.strokeStyle = "#121212"
    ctx.stroke()
    ctx.closePath()

    ctx.beginPath()
    ctx.rect(poseX+(parentWidth/2)-120, poseY+80, 240, 400)                         
    ctx.clip()
    //ctx.stroke()
    ctx.closePath()
    ctx.strokeStyle = "#fff"

    let nextNumDivBy10 = getNextNumber2(pitchReading);
    let pitchDeflection = (pitchReading - nextNumDivBy10)*480/4/10
    //console.log(pitchReading)
    let offsetY = 0
    ctx!.lineWidth = 3

    for(let i=0; i<=4; i++){
        ctx.beginPath()
        ctx.font = "500 28px Arial";

        ctx.fillText(nextNumDivBy10+20, poseX+200, poseY+40+offsetY+pitchDeflection)
        ctx.fillText(nextNumDivBy10+20, poseX+360, poseY+40+offsetY+pitchDeflection)
        ctx.moveTo(poseX+252, poseY+40-10+offsetY+pitchDeflection)
        ctx.lineTo(poseX+348, poseY+40-10+offsetY+pitchDeflection)

        ctx.stroke()
        ctx.closePath()

        ctx.beginPath()
      
        ctx.moveTo(poseX+284, poseY+40-10+offsetY+pitchDeflection+30)
        ctx.lineTo(poseX+316, poseY+40-10+offsetY+pitchDeflection+30)
        ctx.stroke()
        ctx.closePath()

        if(nextNumDivBy10+20 === 0){
              // ctx!.beginPath();
            ctx.lineWidth = 4
            ctx.strokeStyle = "#fff"
            ctx.setLineDash([12, 8])
            ctx.moveTo(0, 40-10+offsetY+pitchDeflection)
            ctx.lineTo(600, 40-10+offsetY+pitchDeflection)
            ctx.stroke();
            ctx.closePath();
        }

        ctx.beginPath()
        ctx.font = "500 28px Arial";

        ctx.fillText(nextNumDivBy10+20-5, poseX+225, poseY+40+offsetY+pitchDeflection+60)
        ctx.fillText(nextNumDivBy10+20-5, poseX+340, poseY+40+offsetY+pitchDeflection+60)

        ctx.setLineDash([0, 0])
     
        ctx.moveTo(poseX+268, poseY+40-10+offsetY+pitchDeflection+60)
        ctx.lineTo(poseX+332, poseY+40-10+offsetY+pitchDeflection+60)
        ctx.stroke()
        ctx.closePath()

        ctx.beginPath()
  
        ctx.moveTo(poseX+284, poseY+40-10+offsetY+pitchDeflection+90)
        ctx.lineTo(poseX+316, poseY+40-10+offsetY+pitchDeflection+90)
        ctx.stroke()
        ctx.closePath()

        offsetY += 480/4
        nextNumDivBy10 -= 10
        
    }
    ctx.restore()
 
}

/**
 * returns nearest integer whic is multiple of 10 
 * @param x {number} - input number
 * @returns {number} - returns nearest integer which is multiple of 10
 */
function getNextNumber2(x:number){
    return Math.ceil(x/10)*10
}



export default rollAndPitch