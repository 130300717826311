import "./Debrief.scss"
import noLogs from '../../../../assets/images/debrief/illustration-no-logs-found.svg'
// import iconImportFile from '../../../../assets/images/debrief/icon-import.svg'
import { Button } from "react-bootstrap"
import { useNavigate } from "react-router-dom"


const NoLogFound = () =>{

    const navigate = useNavigate();
    
    return (
    <div className="h-100 w-100 d-flex align-items-center justify-content-center bg-white">
        <div className="text-center">
            <img width='288' height='176' src={noLogs}/>
            <p className="no-logs-found mt-3">
                No Logs Found
            </p>
            <p className="to-start-debrief">
                To start debriefing please import files or log your next flight through the import tab by clicking on the button below.  
            </p>
            <div className="d-flex justify-content-evenly">
                <div className="container row justify-content-center">
                    {/* <button className="btn-download" style={{ marginRight:'10px' }}>Download App</button> */}
                    <button className="input-import-file " onClick={()=>navigate("/dashboard/import")}>
                        Import Files
                    {/* // <button /> */}
                    </button>
                </div>
            </div>
            
        </div>
    </div>
    )
}
export default NoLogFound