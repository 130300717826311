import "./Debrief.scss"
import NoResultsFoundImg from '../../../../assets/images/debrief/illustration-debrief-empty-filter-results.svg'

const NoResultsFound = () => {
    return (
        <div className="h-100 w-100 d-flex align-items-center justify-content-center bg-white">
            <div className="text-center">
                <img width='288' height='176' src={NoResultsFoundImg} />
                <p className="no-logs-found mt-3">
                    No Results Found!
                </p>
                <p className="to-start-debrief">
                    We didn't find any result matching your search criteria. Try adjusting your search keyword/filter.
                </p>
            </div>
        </div>
    )
}

export default NoResultsFound;