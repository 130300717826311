
/**
 * enum contains color used in PFD canvas elements
 */
export const enum colorStrings {
    bgLight = "rgba(18, 18, 18, 0.32)",
    bgWhiteLight = "rgba(255, 255, 255, 0.37)",
    bgDark = "rgba(18, 18, 18, 0.64)",
    whiteColor = "#fff",
    blackColor = "black",
    fontColor = "#fff",
    cyan = "#80e1d9"

}

/**
 * this function can print radial line on canvas-
 * @param {any} ctx - 2d canvas context
 * @param {number} centerX - X cordinate of centre of radial line
 * @param {number} centerY - Y cordinate of centre of radial line
 * @param {number} innerRadius - inner radius of radial line
 * @param {number} outerRadius - outer radius of radial line
 * @param {number} degrees - angle of radial line with horizontal axis
 * @param {number} linewidth - width of radial line
 * @param {string} color - color of line
 * @returns {void}
 */
export const radiantLine = (ctx:any, centerX:number, centerY:number, innerRadius:number, outerRadius:number, degrees:number, linewidth:number, color:string) => {

    var radians=degrees*Math.PI/180;
    var innerX = centerX + innerRadius * Math.cos(radians);
    var innerY = centerY + innerRadius * Math.sin(radians);
    var outerX = centerX + outerRadius * Math.cos(radians);
    var outerY = centerY + outerRadius * Math.sin(radians);

    ctx.beginPath();
    ctx.moveTo(innerX,innerY);
    ctx.lineTo(outerX,outerY);
    ctx.strokeStyle=color;
    ctx.lineWidth=linewidth;
    ctx.stroke();

}